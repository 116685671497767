import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import logServices from "../../services/logService";

import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useOverSpeedMutation,
} from "../../store/slice/ApiSlice";
import { useGetFuelRefillEventMutation, useVehicleDetailVoltMutation, useVehicleLatestDetailMutation } from "../../store/slice/ReportSlice/ReportSlice";
import GetLocation from "../Location";
const text =
  "This report shows the Fuel Refill Events.";

const FuelRefillEventReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let distanceUnit = userData.distanceDisplayUnit ;
  let distanceValue = distanceUnit === "mi"?2:1;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [assignedval, setAssignedval] = useState([]);
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > fuelRefill";
    auditPayload.action = "View";
    auditPayload.featureName = "fuelRefill";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

 
  const columns = React.useMemo(() => [
 
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
        width:150,
      },
      {
        Header: "Date",
        accessor: "receivedDate",
        width:150,
      },
      {
        Header: "Odometer (mi)",
        accessor: "odometer",
        width:100,
      },
      {
        Header: "GPS Location",
        accessor: "gpsLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.gpsLocation !== "" &&   row.original.gpsLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.gpsLocation}/> : '-'
          )
        }
      },
      {
        Header: "Refill Event",
        accessor: "refillEvent",
        width:120,
      },
      {
        Header: "Current Fuel Value (L)",
        accessor: "fuelLevel",
        width:120,
      },
      {
        Header: "Previous Fuel Value (L)",
        accessor: "previousFuelValue",
        width:120,
      },
      {
        Header: "Fuel Refill Value (L)",
        accessor: "fuelRefillValue",
        width:150,
      },
      // {
      //   Header: "Received Date",
      //   accessor: "receivedDate",
      //   width:120,
      // },
      
    ],
    []
  );

  let initialState1 = {};
  
  // Get Data from New Dropdown
  const [getRefillEvent, { data: fuelRefill, isLoading, isSuccess }] =
    useGetFuelRefillEventMutation();
  let commonPayload = {};

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.status = data.fuelStatusId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.sortBy = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    
    //Service Call
    setPayload(commonPayload);
    getRefillEvent(commonPayload);

    auditPayload.message = "Reports > fuelRefill > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > " + data.statusName + " > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "fuelRefill";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FuelRefillEventReport/getFuelRefillEvent.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId +
        "&distanceUnit=" +
        distanceValue;
      window.open(url);

      auditPayload.message = "Reports > FuelRefill > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "FuelRefill";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getRefillEvent(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getRefillEvent(payload);
      
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getRefillEvent(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getRefillEvent(payload);
    }
  };

  
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (fuelRefill.data !== null) {
        setResponseData(fuelRefill.data.content);
        setTotalElements(fuelRefill.data.totalElements);
        setTotalPages(fuelRefill.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Overspeed"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Overspeed"} />
      <Box>
        <FilterDropdown
          getData={onSubmitForm}
          isFuelData={true}
          status={false}
          allowDays={31}
          allowToday={true}
          last6Month={true}
          time = {true}
          allowedMonth = {3}
          last31={false}
          currentMonth={true}
          thisMonth={true}
          getDataLoading={isLoading}
          offToday={true}
         
        />


        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              initialState={initialState1}
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              handleChange={handleSearch}
              includeExportButton={true}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default FuelRefillEventReport;