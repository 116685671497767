import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import {CardContent, Chip } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import MouseOverPopover from "../shared/PopOver";
import { Button } from "@mui/material";
import {
  StyledCardShadow,
  StyledPageLayout,
} from "../../theme/StyledEle";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useVehicleDataSCVMutation, useVehicleDataTerraMutation } from "../../store/slice/ReportSlice/ReportSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";


const ManualCanDataReportTerraMoto = () => {

  const text = "Note:This Report gets the details of battery in a Fleet based on date selection.";

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog]= useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId:orgId,
    userId:userId, //User ID
    orgName:orgName, //Org Name
    userName:userName,
  }

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    auditPayload.message = 'Reports > VehicleData';
    auditPayload.action = 'View';
    auditPayload.featureName = 'VehicleData';
    auditPayload.customerName = 'All';
    auditPayload.fleetName = "All";
    auditPayload.result = 'Success';
    auditPayload.access = 'Portal';
    auditPayload.severity = 'INFO';
    auditLog(auditPayload);
  
  }, []);


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },
      {
        Header: "Vehicle Status",
        accessor: "strgpsMoveStatus",
        Cell:({row}) => {
          // console.log('row', row.original.strgpsMoveStatus);
          if(row.original.strgpsMoveStatus !== '-' ){
              return <Chip label={row.original.strgpsMoveStatus} size="small" color={row.original.strgpsMoveStatus === 'Moving' ? 'success' : row.original.strgpsMoveStatus === 'Charging' ? 'warning' : 'error'}/>
          }else{
              return row.original.strgpsMoveStatus
          }
        }
      },
      {
        Header: "GPS Location",
        accessor: "location",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async(e) => {
            if(refAddres.current.innerText === ''){
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (await ReportServices.getGeocodeAddress(request)).data;
              refAddres.current.innerText = responseData;
              if(responseData !== ''){
                refLink.current.style.display = 'none'
                refAddres.current.style.display = 'block'
                toggleAddressBtn.current.style.display = 'block'
                getAddressBtn.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
          }
        
          //Address toggle
          const ToggleAdderss = () => {
            if( refAddres.current.style.display === 'block' ){
              refAddres.current.style.display = 'none'
              refLink.current.style.display = 'block'
              toggleAddressBtn.current.innerText='Address'
            }else{
              refAddres.current.style.display = 'block'
              refLink.current.style.display = 'none'
              toggleAddressBtn.current.innerText='LatLng'
            }
          }
          return(
            <Box sx={{display:'flex', alignItems:'center'}} >
            <Box className='addresslink' id={row.original.location} sx={{display:'flex', alignItems:'center'}}>
                <Button onClick={(e) => getAddress(e)} value={row.original.location} ref={getAddressBtn}>Address</Button>
                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="latlink"  ref={refLink} id={row.original.location}>{row.original.location}</a>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="address" ref={refAddres} id={row.original.location} key={row.original.location}></a>
            </Box>
        </Box>
          )
        },
      },

      {
        Header: "GPS Odometer (km)",
        accessor: "odometer",
      },
      {
        Header: "GPS Speed (km/h)",
        accessor: "speed",
      },
      {
        Header: "CAN Odometer (km)",
        accessor: "canOdometer",
      },
      {
        Header: "CAN Speed (km)",
        accessor: "canVehicleSpeed",
      },
      {
        Header: "Battery Voltage (V)",
        accessor: "batteryVoltage1",
      },
      {
        Header: "Battery Current (A)",
        accessor: "batteryCurrent",
      },
      {
        Header: "Battery SOC (%)",
        accessor: "batterySoc",
      },
      {
        Header: "Battery SOH (%)",
        accessor: "soh",
      },
      {
        Header: "Battery Capacity",
        accessor: "batteryCapacity",
      },
      {
        Header: "Remaining Energy (wh)",
        accessor: "strEnergyConsumed",
      },
      {
        Header: "Battery Status",
        accessor: "batteryStatus",
      },
      {
        Header: "Battery Cycle Count",
        accessor: "cycleCount",
      },
      {
        Header: "Battery Monomer Maximum Voltage Cell",
        accessor: "maxParallelVoltage",
      },
    
      {
        Header: "Battery Monomer Minimum Voltage Cell",
        accessor: "minParallelVoltage",
      },
      {
        Header: "Charging Mos Status",
        accessor: "chargingStatus",
      },
      {
        Header: "Discharging Mos Status",
        accessor: "disChargingStatus",
      },
      {
        Header: "Battery Fault",
        accessor: "batteryFault",
      },
      {
        Header: "Cell Voltage1",
        accessor: "cellVolt1",
      },
      {
        Header: "Cell Voltage2",
        accessor: "cellVolt2",
      },
      {
        Header: "Cell Voltage3",
        accessor: "cellVolt3",
      },
      {
        Header: "Cell Voltage4",
        accessor: "cellVolt4",
      },
      {
        Header: "Cell Voltage5",
        accessor: "cellVolt5",
      },
      {
        Header: "Cell Voltage6",
        accessor: "cellVolt6",
      },
      {
        Header: "Cell Voltage7",
        accessor: "cellVolt7",
      },
      {
        Header: "Cell Voltage8",
        accessor: "cellVolt8",
      },
      {
        Header: "Cell Voltage9",
        accessor: "cellVolt9",
      },
      {
        Header: "Cell Voltage10",
        accessor: "cellVolt10",
      },
      {
        Header: "Cell Voltage11",
        accessor: "cellVolt11",
      },
      {
        Header: "Cell Voltage12",
        accessor: "cellVolt12",
      },
      {
        Header: "Cell Voltage13",
        accessor: "cellVolt13",
      },
      {
        Header: "Cell Voltage14",
        accessor: "cellVolt14",
      },
      {
        Header: "NTC Probe1",
        accessor: "ntcProbe1",
      },
      {
        Header: "NTC Probe2",
        accessor: "ntcProbe2",
      },
      {
        Header: "NTC Probe3",
        accessor: "ntcProbe3",
      },
      {
        Header: "NTC Probe4",
        accessor: "ntcProbe4",
      },
      {
        Header: "NTC Probe5",
        accessor: "ntcProbe5",
      },
      {
        Header: "NTC Probe6",
        accessor: "ntcProbe6",
      },
      {
        Header: "AEE Probe",
        accessor: "aeeProbe",
      },
      {
        Header: "MOSFET Probe",
        accessor: "mosfetProbe",
      },
      {
        Header: "DTE Eco Mode",
        accessor: "dteEcoMode",
      },
      {
        Header: "DTE Booster Mode",
        accessor: "dteBoosterMode",
      },
      {
        Header: "IGN Key Status",
        accessor: "ignKeyStatus",
      },

     
    ],
    []
  );

  const [ vehicleDataTerra, { data:terraVehicleData, isLoading, isSuccess }] = useVehicleDataTerraMutation();
  const [payload, setPayload] = useState();
  let commonPayload = {
    'resellerId': resellerId,
  }
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(50);
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = 'desc';
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage; 
    commonPayload.status = data.vehicleStatusId;
    commonPayload.search = '';
    setPayload(commonPayload);
    vehicleDataTerra(commonPayload);

    auditPayload.message = "Reports > Vehicle Data > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.statusName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Data Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }

  useEffect(() => {
    if(isSuccess){
      setResponseData([]);
      if(terraVehicleData.data !== null){
        setResponseData(terraVehicleData.data?.content);
        setTotalElements(terraVehicleData.data?.totalElements);
        setTotalPages(terraVehicleData.data?.totalPages);
      }
    }
  },[isSuccess]);

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    payload.size = 0;
    payload.rowsPerPage = 50;
    setNewPage(0);
    setRowsPerPage(50);
    if(payload.search.length >= 3){
        vehicleDataTerra(payload);
    }else if(payload.search.length === 0){
        vehicleDataTerra(payload);
    }
  };

//Onchange PDF
const onChangePdf = async () => {
  try {
    var url =
      process.env.REACT_APP_API_BASE_URL +
      "ManualCanDataReport/vehicledataPDF.pdf?startDate=" +
      payload.startDate +
      "&endDate=" +
      payload.endDate +
      "&orderDir=" +
      payload.orderDir +
      "&orgId=" +
      payload.orgId +
      "&status=" +
      payload.status +
      "&vehicleId=" +
      payload.vehicleId;
      window.open(url);

      auditPayload.message = "Reports > Vehicle Data > Export > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "VDR Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    

  } catch (error) {
    console.log('error', error);
  }
};

//Onchange XLS
const onChangeXcl = async () => {
  try {
      var url =
      process.env.REACT_APP_API_BASE_URL +
      "ManualCanDataReport/getVehicleDataReportForTerraMotoCSV?startDate=" +
      payload.startDate +
      "&endDate=" +
      payload.endDate +
      "&orderDir=" +
      payload.orderDir +
      "&orgId=" +
      payload.orgId +
      "&status=" +
      payload.status +
      "&resellerId=" +
      payload.resellerId +
      "&vehicleId=" +
      payload.vehicleId;
// console.log(url);
    window.open(url);
    auditPayload.message = "Reports > Vehicle Data > Export > Excel";
    auditPayload.action = "View";
    auditPayload.featureName = "VDR Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);

  } catch (error) {
    console.log('error', error)
  }
};

 //Page Change
 const pageChange = (newPage, rowsPerPage) => {
  setNewPage(newPage);
  setRowsPerPage(rowsPerPage);
  payload.page = newPage;
  payload.size = rowsPerPage;
  payload.start=newPage;
  payload.length=rowsPerPage;
  vehicleDataTerra(payload);
} 

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Vehicle Data Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Data Report"} />
      <Box>
        <FilterDropdown getData={onSubmitForm} isVehicleData={true} isShowAll={false} allowDays={0} last7={false}  setToday={true} />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              isLoading={isLoading}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePdf}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "89vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default ManualCanDataReportTerraMoto;
