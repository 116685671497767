import { dashboardApi } from "../ApiSlice";

const RouteTripSlice = dashboardApi.injectEndpoints({
  endpoints: (builder) => ({

    //Get Route for map
    getRouteListForMap:builder.mutation({
      query: (body) => ({
        url: "/mapView/showRouteTrips",
        method: "POST",
        body: body,
      }),
    }),
    getRouteTripSummaryList: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getRouteTripSummaryList",
        method: "POST",
        body: body,
      }),
    }),
    ///vehicle/getVehicleListForCustomer
    getVehicleByCustomer: builder.mutation({
      query: (body) => ({
        url: "/vehicle/getVehicleListForCustomer",
        method: "POST",
        body: body,
      }),
    }),

    //GeoFence/getGeoZoneForCustomer
    getGeoZoneByCustomer: builder.mutation({
      query: (body) => ({
        url: "/GeoFence/getGeoZoneForCustomer",
        method: "POST",
        body: body,
      }),
    }),
    //routeTrip/storeRouteTrip
    saveRoute: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/storeRouteTrip",
        method: "POST",
        body: body,
      }),
    }),

    getManageRouteList: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getManageRouteList",
        method: "POST",
        body: body,
      }),
    }),
    getRoutesFilterList: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getRoutesFilter",
        method: "POST",
        body: body,
      }),
    }),
    getEditRouteTrip: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/editRouteTrip",
        method: "POST",
        body: body,
      }),
    }),
    saveAssignRouteTrip: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/editRoutes",
        method: "POST",
        body: body,
      }),
    }),
    getGeoZoneDetails: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getGeoZoneDetails",
        method: "POST",
        body: body,
      }),
    }),
    getCapturedGeoZoneDetails: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getCapturedGeoZoneDetails",
        method: "POST",
        body: body,
      }),
    }),
    deleteRoute: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/deleteRouteTrip",
        method: "POST",
        body: body,
      }),
    }),
    //get Route Trip
    getRouteTrip: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getRouteTripTimeLine",
        method: "POST",
        body: body,
      }),
    }),
    getRouteTripSummaryHistoryList: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getRouteTripSummaryHistoryList",
        method: "POST",
        body: body,
      }),
    }),
    getCapturedGeoZoneHistoryDetails: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getCapturedGeoZoneHistoryDetails",
        method: "POST",
        body: body,
      }),
    }),
    getCapturedGeoZoneCategoryList: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/getCapturedGeoZoneCategoryList",
    //Update Load Condition routeTrip/updateGeozoneLoadCondition
    method: "POST",
    body: body,
  }),
}),
    //Update Load Condition routeTrip/updateGeozoneLoadCondition
    updateLoad: builder.mutation({
      query: (body) => ({
        url: "/routeTrip/updateGeozoneLoadCondition",
        method: "POST",
        body: body,
      }),
    }),
    // Chord diagram
    getChordDiagram: builder.mutation({
      query: (body) => ({
        url: "/chordChart/getChordChartData",
        method: "POST",
        body: body,
      }),
    }),

    //snapToRoad
    snapToRoadApi: builder.query({
      query: (body) => ({
        url: `https://roads.googleapis.com/v1/snapToRoads?interpolate=true&path=${body.path}&key=${body.key}`,
        method: "GET",
      }),
    }),

  }),
});



export const {
  useGetRouteTripSummaryListMutation,
  useGetVehicleByCustomerMutation,
  useGetGeoZoneByCustomerMutation,
  useSaveRouteMutation,
  useGetManageRouteListMutation,
  useGetRoutesFilterListMutation,
  useGetEditRouteTripMutation,
  useSaveAssignRouteTripMutation,
  useGetGeoZoneDetailsMutation,
  useGetCapturedGeoZoneDetailsMutation,
  useGetRouteListForMapMutation,
  useDeleteRouteMutation,
  useGetRouteTripMutation,
  useGetRouteTripSummaryHistoryListMutation,
  useGetCapturedGeoZoneHistoryDetailsMutation,
  useGetCapturedGeoZoneCategoryListMutation,
  useUpdateLoadMutation,
  useGetChordDiagramMutation,
  useLazySnapToRoadApiQuery
} = RouteTripSlice;
