import { dashboardApi } from "../ApiSlice";

const GeoZoneSlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        
    //load all Geo zone
    loadAllGeoZone: builder.mutation({
        query: (body) => ({
          url: "/GeoFence/loadAllGeoZone",
          method: "POST",
          body: body,
        }),
      }),
  
      //Geo zone
      getAllGeoZoneForOrg: builder.mutation({
        query: (body) => ({
          url: "/GeoFence/getAllGeoZoneForOrg",
          method: "POST",
          body: body,
        }),
      }),
  
      //Geo zone grid view
      geozoneGridView: builder.mutation({
        query: (body) => ({
          url: "/GeoFence/geozoneGridView",
          method: "POST",
          body: body,
        }),
      }),
  
      //Assigned Geo zone for vehicle
      getAllGeoZoneForVehicle: builder.mutation({
        query: (body) => ({
          url: "/GeoFence/getAllGeoZoneForVehicle",
          method: "POST",
          body: body,
        }),
      }),

      //Save Geozone 
      saveGeoZone:builder.mutation({
        query:(body) => ({
            url:'/GeoFence/saveGeoZone',
            method: "POST",
            body: body,
        })
      }),
      deleteGeoZone:builder.mutation({
        query:(body) => ({
            url:'/GeoFence/deleteGeozone',
            method: "POST",
            body: body,
        })
      }),
      //GeoFence/geozoneAssignedVehicle
      geoAssignedVehicle:builder.mutation({
        query:(body) => ({
            url:'/GeoFence/geozoneAssignedVehicle',
            method: "POST",
            body: body,
        })
      }),
      ///GeoFence/assignGeoZoneVehicleMap
      assignGeoZone:builder.mutation({
        query:(body) => ({
            url:'/GeoFence/assignGeoZoneVehicleMap',
            method: "POST",
            body: body,
        })
      }),

      ///GeoFence/chkIsGeoExist
      checkExistZone:builder.mutation({
        query:(body) => ({
            url:'/GeoFence/chkIsGeoExist',
            method: "POST",
            body: body,
        })
      }),
      ///GeoFence/chkIsGeoExist
      checkExistZoneEdit:builder.mutation({
        query:(body) => ({
            url:'/GeoFence/chkIsGeoExistForEdit',
            method: "POST",
            body: body,
        })
      }),
       ///GeoFence/chkIsGeoExist
       geoZoneCategoryList:builder.mutation({
        query:(body) => ({
            url:'/GeoFence/getZoneCategory',
            method: "POST",
            body: body,
        })
      }),

      geoZoneGridListView : builder.mutation({
        query:(body) => ({
          url:'/GeoFence/geozoneGridListView',
          method: "POST",
          body: body,

        })
      }),

      zoneAssignedVehicleCount : builder.mutation({
        query:(body) => ({
          url:'/GeoFence/getAssignedVehicleCount',
          method: "POST",
          body: body,

        })
      }),
    })
})


export const { useLoadAllGeoZoneMutation, useGetAllGeoZoneForOrgMutation, useGeozoneGridViewMutation, useGetAllGeoZoneForVehicleMutation, useSaveGeoZoneMutation, useDeleteGeoZoneMutation, useGeoAssignedVehicleMutation, useAssignGeoZoneMutation, useCheckExistZoneMutation, useCheckExistZoneEditMutation ,useGeoZoneCategoryListMutation , useGeoZoneGridListViewMutation, useZoneAssignedVehicleCountMutation,} = GeoZoneSlice;