import { id } from "date-fns/locale";

export const VehicleStatus = {
  content: [
    {
      id: 1,
      key: "9",
      value: "All",
    },
    {
      id: 2,
      key: "1",
      value: "Moving",
    },
    {
      id: 3,
      key: "0",
      value: "Stop",
    },
    {
      id: 4,
      key: "2",
      value: "Idle",
    },
  ],
};

export const LatestVehicleStatus = {
  content: [
    {
      id: 1,
      key: "9",
      value: "All",
    },
    {
      id: 2,
      key: "1",
      value: "Moving",
    },
    {
      id: 3,
      key: "0",
      value: "Stop",
    },
    {
      id: 4,
      key: "6",
      value: "Charging",
    },
    {
      id: 5,
      key: "4",
      value: "Offline",
    },
  ],
};

export const FuelStatus = {
  content: [
    {
      id: 1,
      key: "9",
      value: "All",
    },
    {
      id: 2,
      key: "1",
      value: "Refill",
    },
    {
      id: 3,
      key: "2",
      value: "FuelDip",
    },

  ],
};

export const Severity = {
  content: [
    {
      id: 1,
      key: "1",
      value: "Critical",
    },
    {
      id: 2,
      key: "2",
      value: "Non Critical",
    },
    {
      id: 3,
      key: "3",
      value: "Regualr",
    },
  ],
};

export const Status = {
  content: [
    {
      id: 1,
      key: "1",
      value: "Active",
    },
    {
      id: 2,
      key: "0",
      value: "InActive",
    },
  ],
};

export const benchStatusList = {
  content: [
    {
      id: 1,
      key: "1",
      value: "Verified",
    },
  ],
};

//Only Charge Type

export const ChargeTypeList = {
  content: [
    {
      id: 1,
      key: "0",
      value: "Manual",
    },
    {
      id: 2,
      key: "1",
      value: "Regenerative",
    },
  ],
}
//declare Static values

export const temprise ={
  content:[
          {
            key:10,
            value:10,
           
        },
        {
            key:15,
            value:15,
           
        },
        {
          key:20,
          value:20,
          
        },
        {
          key:25,
          value:25,
          
       }, 
       {
        key:30,
        value:30,
        
     }, 
       {
          key:35,
          value:35,
          
       },
        {
            key:40,
            value:40,
            
        },
        {
            key:45,
            value:45,
            
        },
        {
            key:50,
            value:50,
            
        }
      ]
    }

//Zone category

export const zoneCategoryList = {
  content: [
    {
      id: 1,
      key: "Route",
      value: "Route"
  },
  {
    id: 2,
      key: "Loading",
      value: "Loading"
  },
  {
    id: 3,
      key: "UnLoading",
      value: "UnLoading"
  },
  {
    id: 4,
      key: "Charging",
      value: "Charging"
  },
  {
    id: 5,
      key: "Rest",
      value: "Rest"
  },
  {
    id: 6,
      key: "General",
      value: "General"
  }
  ],
};


export const dehydrationdropdown ={
  content:[
          {
            key:5,
            value:"5%",
           
        },
        {
            key:10,
            value:"10%",
           
        },
        {
          key:15,
          value:"15%",
          
        },
        {
          key:20,
          value:"20%",
          
        },
        {
          key:25,
          value:"25%",
          
       }, 
       {
        key:30,
        value:"30%",
        
     }
      ]
    }

export const geozoneTypeDropdown = {
  content : [
    {
      
      key:"Circles",
      value: "Circles"
    },

    {
      key:"Polygons",
      value: "Polygons"
    }
  ]
}

export const geozoneCategoryDropdown ={
  content:[
        {
            key:"General",
            value:"General",
           
        },
        {
          key:"CAZ",
          value:"CAZ",
          
        },
      
      ]
    }



export const servicePlanStatusDropDown = {
      content:[
            {
                key:1,
                value:"Due for service",
               
            },
            {
              key:2,
              value:"Completed",
              
            },
            {
              key:3,
              value:"Overdue",
              
            },
          
          ]
        }

 