import { Box, IconButton, ListItem, ListItemText, Menu, MenuItem, Switch, Tooltip } from "@mui/material";
import React, { useEffect, useState } from "react";
import { FaFilter } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { FilterMenu } from "../../../theme/StyledEle";

export const TableFiters = ({columns, getHideName}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const [hideCols, setHideCols] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    useEffect(() =>{
        columns.length > 0 && setHideCols(columns[0].headers);
    },[])
    const handleClose = () => {
        setAnchorEl(null);
    };
    
    return(
        <Box>
        <Tooltip title='Filter' arrow sx={{mr:1}}>
        <IconButton 
        size="small" 
        id="table-Hide-columns"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        >
            <FiFilter/>
        </IconButton>
        </Tooltip>

        <FilterMenu
            id="table-Hide-columns"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
            'aria-labelledby': 'basic-button',
            }}
            elevation={0}
        >
            {
                hideCols?.map((headerLabel, index) => {
                    return(
                        <ListItem dense={true} key={index} >
                            <ListItemText id="switch-list-label-wifi" primary={headerLabel.Header} />
                            <Switch edge="end" inputProps={{ 'aria-labelledby': 'switch-list-label-wifi'}}
                            value={headerLabel.Header}
                            onChange={(e) => getHideName(e.target.value)}
                            />
                        </ListItem> 
                    )
                })
            }
        {/* <ListItem dense={true}>
            <ListItemText id="switch-list-label-wifi" primary="Organaization" />
            <Switch edge="end" inputProps={{ 'aria-labelledby': 'switch-list-label-wifi'}}
        />
        </ListItem>
        */}
      </FilterMenu>

        </Box>
    )
}