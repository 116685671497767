import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import StyledTabs from "../shared/StyledTabs";
import CreateSim from "./CreateSim";
import ListSim from "./ListSim";
import { Breadcrumb } from "../shared/Breadcrumbs";
import SimUpload from "./SimUpload";
import RecentActivity from "./RecentActivity";
import logServices from "../../services/logService";
import { StyledPageLayout } from "../../theme/StyledEle";
import { useDispatch, useSelector } from "react-redux";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { snackService } from "../../store/slice/ControlsSlice";
import { useNavigate } from "react-router-dom";
import SimView from "./SimView";
import FormDialog from "../../Utils/FormDialog";

const globalJson1 =
  localStorage.getItem("userData") !== null
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
var globalOrgId = 1;
var globaluserId = 1;
var globalRoleId = 0;
var globalJson = {};
var globalResellerId = 2;
var globalOrgType = 2;
if (
  "null" === localStorage.getItem("userData") ||
  null === localStorage.getItem("userData")
) {
} else {
  globalJson =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : 0;
  globalOrgId = globalJson.orgId;
  globalOrgType = globalJson.userOrgType;
  globaluserId = globalJson.id;
  globalRoleId = globalJson.roleId;
  globalResellerId = globalJson.resellerId;
}

const Sim = () => {
  const [menuUser, setMenuUser] = useState();
  const [simToEdit, setSimToEdit] = React.useState(null);

  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tempcommonFilter, setTempcommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [createSim, setCreateSim] = React.useState("Create Sim");
  const [tabShow, setTabShow] = React.useState("1");

  useEffect(async () => {
    var request = {};
    request.message = "Admin > Sim > ListSim";
    request.action = "View";
    request.featureName = "sim";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;

    const responseData = await logServices.saveAuditLogDetails(request);
  }, []);

  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "sim"
              ) {
                setMenuUser(
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                );
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                }
                setCommonFilter(dataStr);
                setTempcommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }
    //setTabShow
    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("8");
      tabshowval = "8";
      displaydefaulttab = 1;
    }

    // if(tabshowval===6){
    //   displaydefaulttab=1;
    // }
    //return (dataStr.create===false)?1:3;
    return displaydefaulttab;
  };

  const [tabValue, setTabValue] = useState(() => callfun());
  const handleTabChange = async (event, newValue) => {
    setSimToEdit();
    setCreateSim("Create Sim");
    var dataStr = { ...tempcommonFilter };
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("8");
    }

    setTabValue(newValue);
    var request = {};
    if (newValue === 3) {
      request.message = "Admin > Sim > Sim List Tab";
      request.action = "View";
    } else if (newValue === 2) {
      request.message = "Admin > Sim > Create New Sim";
      request.action = "  Create";
    } else if (newValue === 1) {
      request.message = "Admin > Sim > RECENT ACTIVITY";
      request.action = "Recent Activity";
    } else if (newValue === 0) {
      request.message = "Admin > Sim > IMPORT";
      request.action = "Import";
    }
    request.featureName = "sim";
    request.customerName = "All";
    request.fleetName = "All";
    request.result = "Success";
    request.access = "Portal";
    request.severity = "INFO";
    request.orgId = globalOrgId;
    request.userId = globaluserId;
    const responseData = await logServices.saveAuditLogDetails(request);
  };

  const navigate = useNavigate();
  const [simData, setSimData] = useState();
  const handleSimView = (simEditedId, array) => {
    navigate('#view');
    setSimData(simEditedId);
    // var dataStr = { ...tempcommonFilter };
    // var displaydefaulttab = 2;
    // if (tabShow === "6") {
    //   //displaydefaulttab=3;
    //   setTabShow("5");
    //   displaydefaulttab = 0;
    // } else if (tabShow === "5") {
    //   displaydefaulttab = 0;
    // } else if (tabShow === "4") {
    //   displaydefaulttab = 1;
    // } else if (tabShow === "1") {
    //   displaydefaulttab = 2;
    // } else if (tabShow === "2") {
    //   displaydefaulttab = 5;
    // } else if (tabShow === "3") {
    //   displaydefaulttab = 1;
    // } else if (tabShow === "8") {
    //   setTabShow("5");
    //   displaydefaulttab = 0;
    // }
    //setTabValue(displaydefaulttab);
    // setCreateSim("View Sim");

    // var tempProps = JSON.parse(JSON.stringify(simEditedId));
    // tempProps.clickedval = "view";
    // setSimToEdit(Object.preventExtensions(tempProps));
    // var dataStr={...tempcommonFilter};
    // dataStr.create=true;
    // setCommonFilter(dataStr);
  };
  const handleSimEdit = (simEditedId, array) => {
    var dataStr = { ...tempcommonFilter };
    var displaydefaulttab = 2;
    if (tabShow === "6") {
      //displaydefaulttab=3;
      setTabShow("5");
      displaydefaulttab = 0;
    } else if (tabShow === "5") {
      displaydefaulttab = 0;
    } else if (tabShow === "4") {
      displaydefaulttab = 1;
    } else if (tabShow === "1") {
      displaydefaulttab = 2;
    } else if (tabShow === "2") {
      displaydefaulttab = 5;
    } else if (tabShow === "3") {
      displaydefaulttab = 1;
    } else if (tabShow === "8") {
      setTabShow("5");
      displaydefaulttab = 0;
    }
    setTabValue(displaydefaulttab);
    setCreateSim("Edit Sim");
    var tempProps = JSON.parse(JSON.stringify(simEditedId));
    tempProps.clickedval = "edit";
    setSimToEdit(Object.preventExtensions(tempProps));
    var dataStr = { ...tempcommonFilter };
    dataStr.create = true;
    setCommonFilter(dataStr);
  };

  const handleCancel = () => {
    setCreateSim("Create Sim");
    var dataStr = { ...tempcommonFilter };
    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("8");
      tabshowval = "8";
      displaydefaulttab = 2;
    }

    setTabValue(displaydefaulttab);
    /*
    var dataStr={...tempcommonFilter};
    if(dataStr.create===false){
      setTabValue(2);
    }else{
      setTabValue(3);
    }
    setCommonFilter(dataStr);
    */
  };

  const TABS8 = [
    {
      label: "Import Sim Details",
      children: <SimUpload onCancel={handleCancel} />,
    },
    {
      label: "Sim List",
      children: (
        <ListSim
          onEditSim={handleSimEdit}
          onViewSim={handleSimView}
          menuUser={menuUser}
        />
      ),
    },
  ];
  const TABS = [
    {
      label: "Import Sim Details",
      children: <SimUpload onCancel={handleCancel} />,
    },
    {
      label: "Recent Activity",
      children: <RecentActivity />,
    },
    {
      label: createSim,
      children: <CreateSim simToEdit={simToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Sim List",
      children: (
        <ListSim
          onEditSim={handleSimEdit}
          onViewSim={handleSimView}
          menuUser={menuUser}
        />
      ),
    },
  ];

  const TABS2 = [
    {
      label: "Import Sim Details",
      children: <SimUpload onCancel={handleCancel} />,
    },
    {
      label: "Recent Activity",
      children: <RecentActivity />,
    },
    {
      label: "Sim List",
      children: (
        <ListSim
          onEditSim={handleSimEdit}
          onViewSim={handleSimView}
          menuUser={menuUser}
        />
      ),
    },
  ];

  const TABS3 = [
    {
      label: "Import Sim Details",
      children: <SimUpload onCancel={handleCancel} />,
    },
    {
      label: createSim,
      children: <CreateSim simToEdit={simToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Sim List",
      children: (
        <ListSim
          onEditSim={handleSimEdit}
          onViewSim={handleSimView}
          menuUser={menuUser}
        />
      ),
    },
  ];

  const TABS4 = [
    {
      label: "Recent Activity",
      children: <RecentActivity />,
    },
    {
      label: createSim,
      children: <CreateSim simToEdit={simToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Sim List",
      children: (
        <ListSim
          onEditSim={handleSimEdit}
          onViewSim={handleSimView}
          menuUser={menuUser}
        />
      ),
    },
  ];

  const TABS5 = [
    {
      label: createSim,
      children: <CreateSim simToEdit={simToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Sim List",
      children: (
        <ListSim
          onEditSim={handleSimEdit}
          onViewSim={handleSimView}
          menuUser={menuUser}
        />
      ),
    },
  ];

  const TABS6 = [
    {
      label: "Sim List",
      children: (
        <ListSim
          onEditSim={handleSimEdit}
          onViewSim={handleSimView}
          menuUser={menuUser}
        />
      ),
    },
  ];

  const TABS7 = [
    {
      label: createSim,
      children: <CreateSim simToEdit={simToEdit} onCancel={handleCancel} />,
    },
  ];

  //sector
  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(() => {
    if (isSuccess.isSuccess) {
      setTimeout(() => dispatch(snackService([null, null, null])), 6000);
    }
  }, [isSuccess.isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="Sim"></Breadcrumb>
      {tabShow === "1" && (
        <StyledTabs
          tabs={TABS}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "2" && (
        <StyledTabs
          tabs={TABS2}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "3" && (
        <StyledTabs
          tabs={TABS3}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "4" && (
        <StyledTabs
          tabs={TABS4}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "5" && (
        <StyledTabs
          tabs={TABS5}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "6" && (
        <StyledTabs
          tabs={TABS6}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "8" && (
        <StyledTabs
          tabs={TABS8}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {isSuccess.isSuccess && (
        <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
      )}
      <FormDialog title={'View Sim'} padding={false} size="md">
          <SimView data={simData} />
      </FormDialog>
      
    </StyledPageLayout>
  );
};

export default Sim;
