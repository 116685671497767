import { Breadcrumbs, Button, IconButton, Stack, Tab, Tabs } from '@mui/material'
import { Box, Typography } from '@mui/material'
import React, { useState } from 'react'
import { MdChevronLeft, MdClose } from 'react-icons/md'
import { StyledCardShadow, StyledTab, StyledTabBox, StyledTabsGroup } from '../../theme/StyledEle'
import PropTypes from "prop-types";
import HealthAlerts from './HealthAlerts'
import TripDetails from './TripDetails'


function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box >{children}</Box>}
      </Box>
    );
  }

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

export const VehicleSummary = ({data, closeSummary}) => {
    const [innerTab, setInnerTab] = useState(39)
    const handleChange = async(event, newValue) => {
        setInnerTab(newValue)
    }

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;


  return (
    <Box>
        {/* <Box>
            <Stack direction={'row'} alignItems={'center'} spacing={2}>
                <IconButton size='small'><MdChevronLeft /></IconButton>
                <Typography variant='h5'>Ciaz</Typography>
            </Stack>
        </Box> */}
        <Box sx={{px:2, py:1, background:'#f9f9f9'}}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Breadcrumbs>
                  <Button color={'primary'} size='small' onClick={closeSummary}>Vehicles</Button>
                  <Typography color={'text.disabled'}>{data?.original.number}</Typography>
              </Breadcrumbs>
              <IconButton onClick={closeSummary} size='small'><MdClose /></IconButton>
            </Stack>
        </Box>
        <Box>
            <Tabs
              value={innerTab}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="view summary tab"
            >
                <Tab value={39} label="Health & Alerts"  />
                <Tab value={40} label="Trip Details" />
            </Tabs>
        </Box>
        <StyledCardShadow>
        
        <CustomTabPanel value={innerTab} index={39}>
            {
              data !== null &&  <HealthAlerts vehicleId={data.original.id} resellerId={resellerId}/>
            }
           
        </CustomTabPanel>
        <CustomTabPanel value={innerTab} index={40}>
            {
              data !== null &&  <TripDetails  vehicleId={data.original.id} resellerId={orgId} vehicleNo={data?.original?.number}/>
            }
           
        </CustomTabPanel>
        </StyledCardShadow>
    </Box>
  )
}



export default VehicleSummary