
import React, { useEffect, useState } from 'react'
import { LowBridge, weightBridge } from '../Constans';
import { Circle, InfoWindow, Marker } from '@react-google-maps/api';
import { Box, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { AiOutlineMessage } from 'react-icons/ai';
import { chatBotSingleVehicleData } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";

function Bridges(props) {
    const { data, center,isWeightBridge } = props;
    const theme = useTheme()
    const [infoData, setInfoData] = useState(null);
    const dispatch = useDispatch();
    const getMarkerData = (markers) => {
        let markerObj = {};
        markerObj.lat = parseFloat(markers?.location?.split(',')[0]);
        markerObj.lng = parseFloat(markers?.location?.split(',')[1]);
        markerObj.height = markers?.height;
        markerObj.weightLimit = markers?.weightLimit;
        markerObj.name = markers?.name;
        setInfoData(markerObj);
    }

    const handleGptClick=(value,name)=>{
      console.log("call value::"+value+"::name::"+name);
      var payload={
        "mapSignal":value,
        "location":name
      }
      dispatch(chatBotSingleVehicleData(payload));
    }
   
    const circleOptions = {
      fillColor:theme.palette.secondary.main,
      fillOpacity:.2,
      strokeWeight:1.2,
      strokeColor:theme.palette.secondary.main,
    }

  return (
    <>
        {/* {
          <Circle
              radius={50000}
              center={{lat:parseFloat(center?.lat), lng:parseFloat(center?.lng)}}
              options={circleOptions}
          />
        } */}
        {
            data !== undefined && data.length > 0 && data.map((item, index) => {
            let location = {};
            location.lat = parseFloat(item.latitude);
            location.lng = parseFloat(item.longitude);
            return(
            <Marker
                key={index}
                position={location}
                onClick={() => getMarkerData(item)}
                icon={{
                url: isWeightBridge ? weightBridge : LowBridge,
                scaledSize: new window.google.maps.Size(35, 35), 
                }}
            />
            ) 
            })
        }
         
    {
       infoData !== null && 
       <InfoWindow
          position={{lat:infoData.lat, lng:infoData.lng}}
          onCloseClick={() => setInfoData(null)}
          options={{pixelOffset: new window.google.maps.Size(0,-70)}}   
       >
          <Paper sx={{padding:'8px', minWidth:'250px', width:'100%', maxWidth:'250px'}}>
              <Stack>
                  <Grid container spacing={1}>
                      <Grid item lg={12}>
                        <Box>
                          <Typography variant='caption' color={'text.disabled'}>Bridge Name</Typography>
                          <Typography variant='body2'>{infoData?.name}</Typography>
                        </Box>
                      </Grid>
                      {!isWeightBridge ? (<>
                      <Grid item lg={12}>
                        <Box>
                          <Typography variant='caption' color={'text.disabled'}>Height</Typography>
                          <Typography variant='body2'>{infoData?.height}</Typography>
                        </Box>
                      </Grid>                         
                        <Grid item lg={11} sx={{marginTop:"3px",marginBottom: "-12px !important"}}>
                              <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Box
                                className="addresslink"
                                sx={{ display: "flex", alignItems: "center" }}
                              >
                              <a
                                  href={`https://www.google.com/maps/search/?api=1&query=${infoData.lat+","+infoData.lng}`}
                                  target="_blank"
                                  id={"tolllatlngId"}
                                >
                                 <Typography variant='caption' >View map</Typography>
                                </a>
                              </Box>
                            </Box>
                         </Grid>
                        <Grid item lg={1} sx={{marginTop:"3px",marginBottom: "-12px !important"}}>
                        <Box>                  
                        <Typography variant='body2'><div style={{ fontSize: '16px', color: '#4CAF50' }}>
                          <AiOutlineMessage  onClick={(event) => handleGptClick("Low bridge",infoData.name)} />
                        </div></Typography>
                        </Box>
                        </Grid>
                        </>                  
                    ):(<><Grid item lg={12}>
                        <Box>
                          <Typography variant='caption' color={'text.disabled'}>Weight Limit</Typography>
                          <Typography variant='body2'>{infoData?.weightLimit}</Typography>
                        </Box>
                      </Grid>                    
                      <Grid item lg={11}  sx={{marginTop:"3px",marginBottom: "-12px !important"}}>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Box
                          className="addresslink"
                          sx={{ display: "flex", alignItems: "center" }}
                        >
                        <a
                            href={`https://www.google.com/maps/search/?api=1&query=${infoData.lat+","+infoData.lng}`}
                            target="_blank"
                            id={"tolllatlngId"}
                          >
                            <Typography variant='caption' >View map</Typography>
                            
                          </a>
                        </Box>
                      </Box>
                        </Grid>
                        <Grid item lg={1}  sx={{marginTop:"3px",marginBottom: "-12px !important"}}>
                        <Box>                  
                        <Typography variant='body2'><div style={{ fontSize: '16px', color: '#4CAF50' }}>
                          <AiOutlineMessage  onClick={(event) => handleGptClick("High bridge",infoData.name)} />
                        </div></Typography>
                        </Box>
                        </Grid>
                      </>)
                      }
                      <Grid item lg={6}>
                        <Box>
                            
                        </Box>
                      </Grid>
                  </Grid>
              </Stack>
          </Paper>
       </InfoWindow>
    }
    </>
  )
}

export default Bridges