import { Box, useTheme } from '@mui/material'
import React from 'react'
import VehicleMainSumary from './VehicleMainSumary';

function SummaryWindow(props) {
    const { position, trailsData, showCategory } = props;
    const theme = useTheme();
    
    let top = position.current?.getBoundingClientRect().top;
    let left = position.current?.getBoundingClientRect().width;
    let height = position.current?.getBoundingClientRect().height;
  return (
    <Box sx={{position:'absolute', top:`${top}px`, left:`${left + 40}px`, background:'#fff', width:'fit-content', height:'380px', borderRadius:'10px', boxShadow:'0px 5px 10px rgba(0,0,0,.1)',[theme.breakpoints.down("xl")]: {
      transform: "scale(.9)",
      filter: 'blur(.0px)',
      left:`${left + 50}px`
  },
  [theme.breakpoints.down("lg")]: {
      transform: "scale(.8)",
      filter: 'blur(.0px)',
  },}}>
        <VehicleMainSumary trailsData={trailsData} height={height} showCategory={showCategory}/>
    </Box>  
  )
}

export default SummaryWindow