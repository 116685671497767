import React, { useMemo, useState, useEffect } from "react";
import { DataTable } from "../shared/data_table/DataTable";
import UserServices from "../../services/userServices";
import DraggableDialog from "../shared/DailogDrag";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import { Box, CardContent, CardHeader, Typography } from "@mui/material";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import {
  useUserListMutation,
  useAuditLogMutation,
  useDeleteUserMutation,
  useUserResendActMutation,
} from "../../store/slice/ApiSlice";
import { useDispatch } from "react-redux";
import StyledModel from "../../Utils/StyledModel";
import { snackService } from "../../store/slice/ControlsSlice";

const ListUser = ({ onEditUser, onViewUser, menuUser }) => {
  let path = window.location.origin;
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;
  let orgType = userData.userOrgType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [savedData, setSavedData] = useState({});
  const [open, setOpen] = useState(false);
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
    resendActivation: false,
  });
  const [deleteItem, setDeleteItem] = useState(null);
  const [resendActivationItem, setResendActivationItem] = useState(null);

  const columns = useMemo(
    () => [
      {
        Header: "Org Type",
        accessor: "userType",
      },
      {
        Header: "Org Name",
        accessor: "userOrgName",
      },
      {
        Header: "First Name",
        accessor: "firstName",
      },
      {
        Header: "Last Name",
        accessor: "lastName",
      },
      {
        Header: "User Name",
        accessor: "userName",
      },
      {
        Header: "Primary Contact",
        accessor: "primaryContactStr",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Mobile No",
        accessor: "mobile",
      },
      {
        Header: "Role",
        accessor: "userRole",
      },
      {
        Header: "Status",
        accessor: "statusStr",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
        width:120
      },
    ],
    []
  );

  // Get Data from New Admin Dropdown
  const [getUserList, { data: userListData, isLoading, isSuccess }] =
    useUserListMutation();
  let commonPayload = {};
  //get Data
  const getData = (data) => {
    var dataStr = {};
    dataStr.edit = false;
    dataStr.delete = false;
    dataStr.view = false;
    dataStr.excel = false;
    dataStr.pdf = false;
    dataStr.resendActivation = false;
    var strval;
    strval = menuUser.split(",");
    for (var i = 0; i < strval.length; i++) {
      if (parseInt(strval[i]) === 4) {
        dataStr.edit = true;
      }
      if (parseInt(strval[i]) === 2) {
        dataStr.view = true;
      }
      if (parseInt(strval[i]) === 3) {
        dataStr.delete = true;
      }
      if (parseInt(strval[i]) === 6) {
        dataStr.pdf = true;
      }
      if (parseInt(strval[i]) === 5) {
        dataStr.excel = true;
      }
      if (parseInt(strval[i]) === 38) {
        dataStr.resendActivation = true;
      }
    }
    setCommonFilter(dataStr);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.region;
    commonPayload.dealerId = data.dealer;
    commonPayload.subResellerId = data.customer;
    commonPayload.orgId = resellerId;
    commonPayload.searchText = "";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.orgType = data.orgType;
    commonPayload.columnName = "CREATED_DATE";
    commonPayload.orderDir = "desc";
    commonPayload.roleId = roleId;
    commonPayload.loggedinOrgId = resellerId;
    commonPayload.orgTypeFilter = data.orgType;
    commonPayload.fleetId= data.fleet;
    //Service Call
    setPayload(commonPayload);
    getUserList(commonPayload);

    auditPayload.message = "Admin > User > List User > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.userOrganizationType+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "user";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);

  };

  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "user/userListXLS.csv?resellerId=" +
        payload.resellerId +
        "&orgId=" +
        payload.orgId +
        "&searchText=" +
        payload.searchText +
        "&subResellerId=" +
        payload.subResellerId +
        "&loggedinOrgId=" +
        payload.loggedinOrgId +
        "&roleId=" +
        payload.roleId +
        "&orgType=" +
        payload.orgType +
        "&orgTypeFilter=" +
        payload.orgTypeFilter +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&fleetId=" +
        payload.fleetId +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir ;
      window.open(url);

      auditPayload.message = "Admin > User > List User > Export > Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "user";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      //auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  const onChangepdf = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "user/userListXLS.xls?resellerId=" +
        payload.resellerId +
        "&orgId=" +
        payload.orgId +
        "&searchText=" +
        payload.searchText +
        "&subResellerId=" +
        payload.subResellerId +
        "&loggedinOrgId=" +
        payload.loggedinOrgId +
        "&roleId=" +
        payload.roleId +
        "&orgType=" +
        payload.orgType +
        "&orgTypeFilter=" +
        payload.orgTypeFilter +
        "&dealerId=" +
        payload.dealerId +
        "&regionId=" +
        payload.regionId +
        "&customerId=" +
        payload.subResellerId;
      window.open(url);

      auditPayload.message = "Admin > User > List User > Export > PDF";
      auditPayload.action = "Download";
      auditPayload.featureName = "user";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    } catch (error) {}
  };

  //Search Text
  const handleSearch = async (searchText) => {
    setNewPage(0);
    setRowsPerPage(25);
    payload.searchText = searchText;
    payload.page = 0;
    payload.size = 25;
    if (payload.searchText.length >= 3) {
      getUserList(payload);
    } else if (payload.searchText.length === 0) {
      getUserList(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getUserList(payload);
  };

  //ACTION VIEW
  const onView = async (handleUserView) => {
    onViewUser(handleUserView);

    auditPayload.message = "Admin > User > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "User";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //ACTION EDIT
  const onEdit = (handleUserEdit) => {
    onEditUser(handleUserEdit);

    auditPayload.message = "Admin > User > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "User";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //ACTION DELETE
  const onDelete = async (itemDeleted) => {
    setDeleteItem(itemDeleted);
  };

  // const handleSbmtClose = async () => {
  //   var deleteRow = {};

  //   deleteRow = savedData.id;
  //   await UserServices.deleteUserById(deleteRow);

  //   auditPayload.message = "Admin > User > DeleteUser";
  //   auditPayload.action = "View";
  //   auditPayload.featureName = "user";
  //   auditPayload.customerName = "All";
  //   auditPayload.fleetName = "All";
  //   auditPayload.result = "Success";
  //   auditPayload.access = "Portal";
  //   auditPayload.severity = "INFO";

  //   auditLog(auditPayload);

  //   const updatedResponse = responseData.filter(
  //     (item) => item.id !== savedData.id
  //   );
  //   setResponseData(updatedResponse);
  //   var obj = {};
  //   obj.dialogButtonText = "Delete Status";
  //   obj.submitButtonText = "";
  //   obj.closeButtonText = "Close";
  //   obj.dialogText = "Data Deleted Successfully";
  //   setDialogContent(obj);
  //   setDataStatusConfirm(false);
  //   setDataStatus(true);
  //   setOpen(true);
  // };

  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (userListData.data !== null) {
        setResponseData(userListData.data.content);
        setTotalElements(userListData.data.totalElements);
        setTotalPages(userListData.data.totalPages);
      }
    }
  }, [isSuccess]);
  const [
    delteItem,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useDeleteUserMutation();
  const deleteItems = () => {
    let payload = {
      id: deleteItem.id,
    };
    delteItem(payload);
    auditPayload.message = "Admin > User > List User > Delete";
    auditPayload.action = "Delete";
    auditPayload.featureName = "user";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

   // auditLog(auditPayload);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );
      getUserList(payload);
    }
  }, [isDeleteSuccess]);
  // const handleSort = (e, sortColumn, sortVal) => {
  //   var obj1 = {};
  //   obj1 = { ...arraySortOrder };
  //   var obj = { sortColumn };
  //   //fetchUserDetails(sortColumn.id);
  //   var orderdir = "desc";
  //   var seltdsrtngcolumn = "";
  //   if (obj.sortColumn.id === "firstName") {
  //     if (obj1.firstname !== undefined && obj1.firstname === "asc") {
  //       obj1.firstname = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.firstname = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "FIRST_NAME";
  //   } else if (obj.sortColumn.id === "lastName") {
  //     if (obj1.lastName !== undefined && obj1.lastName === "asc") {
  //       obj1.lastName = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.lastName = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "LAST_NAME";
  //     //arraySortOrderFn(obj.sortColumn.id,orderdir);
  //   } else if (obj.sortColumn.id === "userName") {
  //     if (obj1.userName !== undefined && obj1.userName === "asc") {
  //       obj1.userName = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.userName = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "USER_NAME";
  //     //arraySortOrderFn(obj.sortColumn.id,orderdir);
  //   } else if (obj.sortColumn.id === "email") {
  //     if (obj1.email !== undefined && obj1.email === "asc") {
  //       obj1.email = "desc";
  //       orderdir = "desc";
  //     } else {
  //       obj1.email = "asc";
  //       orderdir = "asc";
  //     }
  //     seltdsrtngcolumn = "EMAIL";
  //     //arraySortOrderFn(obj.sortColumn.id,orderdir);
  //   }
  //   setArraySortOrder(obj1);
  //   var objSel = { ...selectedDropdownAry };
  //   fetchUserDetails(
  //     "",
  //     objSel.seltdResellerId,
  //     objSel.seltdOrgTypeId,
  //     objSel.seltdLastOrgId,
  //     seltdsrtngcolumn,
  //     orderdir
  //   );
  // };
  //get row data from data table
  const getRow = (selectRow, rowId) => {};

  const onResendActivation = (data) => {
    setResendActivationItem(data);
  };

  const [
    resendItem,
    {
      data: resendData,
      isLoading: isResendLoading,
      isSuccess: isresendSuccess,
    },
  ] = useUserResendActMutation();

  const resendItems = () => {
    let payload = {
      hashCode: resendActivationItem.id,
      path:path,
      userName:userName
    };
    resendItem(payload);
    auditPayload.message = "Admin > User > Resend Activation";
    auditPayload.action = "Send";
    auditPayload.featureName = "user";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };
  useEffect(() => {
    if (isresendSuccess) {
      setResendActivationItem(null);
      dispatch(
        snackService([
          resendData?.resultMessage,
          resendData?.resultText,
          isresendSuccess,
        ])
      );
      getUserList(payload);
    }
  }, [isresendSuccess]);
  return (
    <>
      <AdminDropdown
        getData={getData}
        regionDrop={true}
        dealerDrop={true}
        customerDrop={true}
        orgDrop={true}
        createdate={true}
        allowDays={30}
        allowedMonth={24}
        createDateStatus={false}
      />
      <StyledCardShadow sx={{ mt: 1 }}>
        <CardHeader title={<StyledTableTitle>User List</StyledTableTitle>} />
        <CardContent sx={{ py: 0 }}>
          <DataTable
            isLoading={isLoading}
            includeSearchBox={true}
            onChangeXcl={onChangeXcl}
            onChangePdf={onChangepdf}
            commonFilter={commonFilter}
            handleChange={handleSearch}
            //callbck={handleSort}
            onEdit={onEdit}
            onView={onView}
            onDelete={onDelete}
            onResendActivation={onResendActivation}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            checkbox={false}
            selectRow={getRow}
          />
        </CardContent>
      </StyledCardShadow>
      {deleteItem !== null && (
        <StyledModel data={deleteItem} deleteItem={deleteItems}>
          <Box sx={{ px: 2 }}>
            <Typography sx={{ textAlign: "center", my: 2 }}>
              User Name: {deleteItem.userName}
            </Typography>
          </Box>
        </StyledModel>
      )}
      {resendActivationItem !== null && (
        <StyledModel data={resendActivationItem} deleteItem={resendItems} resendActivation={true}>
          <Box sx={{ px: 2 }}>
            <Typography sx={{ textAlign: "center", my: 2 }}>
              User Name: {resendActivationItem.userName}
            </Typography>
          </Box>
        </StyledModel>
      )}
    </>
  );
};

export default ListUser;
