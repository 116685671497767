import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Typography } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import logServices from "../../services/logService";
import StyledTooltip from "../shared/StyledTooltip";

import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import {
  useAuditLogMutation,
  useMonthlyVehicleMutation,
} from "../../store/slice/ApiSlice";

const text =
"This Report displays the selected month's trip details based on fleet either Vehicle-wise or Day-wise.";

const MonthlySummaryvehicleWiseReport = ({
  data,
  payload,
  isLoadingOut,
  handleSearch,
  pageChange,
  newPage,
  rowsPerPage,
  totalElements
}) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let distanceDisplay = userData.distanceDisplayUnit ;
  let distanceValue = distanceDisplay === "mi"?2:1;



  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  useEffect(async () => {

    auditPayload.message = "Reports > MonthlySummary-Vehiclewise";
    auditPayload.action = "View";
    auditPayload.featureName = "Monthly Summary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "S.No",
        accessor: "S.No",
        width:80,
        Cell: ({ row }) => (
          <Typography id={row.index}>{row.index + 1}</Typography>
        ),
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleNumber",
        width:180
      },      {
        Header: "Customer Name",
        accessor: "customerName",
       
      },
      {
        Header: "Vehicle Details",
        columns: [
          {
            Header: "Months In Service",
            accessor: "monthsinService",
          },
          {
            Header: "Start Odometer ("+distanceDisplay +")",
            accessor: "startOdometer",
          },
          {
            Header: "End Odometer (" + distanceDisplay +")",
            accessor: "endOdometer",
          },
          {
            Header: "Distance Traveled ("+distanceDisplay +")",
            accessor: "odoDistance",
          },
          {
            Header: "Fuel Consumed (litre)",
            accessor: "fuelConsumed",
          },
          {
            Header: "Mileage ("+distanceDisplay +")/litre",
            accessor: "fuelMileage",
          },
          // {
          //   Header: "No Of Run Days",
          //   accessor: "numberofNORUNdays",
          // },
        ],
      },
      {
        Header: " Top Line Metrics / Asset Utilization",
        columns: [
          // {
          //   Header: "Avg No Of Trips/ Run Day",
          //   accessor: "averageNumberofTrips",
          // },
          // {
          //   Header: "Avg Distance (km)/Run Day",
          //   accessor: "averageDistance",
          // },
          // {
          //   Header: "Offline Days",
          //   accessor: "offlineDays",
          // },
          // {
          //   Header: "Online Run Days",
          //   accessor: "onlineRunDays",
          // },

          {
            Header: "Online No Run Days",
            accessor: "onlineNoRunDays",
          },
          {
            Header: "Avg No Of Trips/ Run Day",
            accessor: "averageNumberofTrips",
          },
          {
            Header: "Avg Distance ("+distanceDisplay+")/ Run Day",
            accessor: "avgDisPerDay",
          },
          {
            Header: "Avg Run Time (hh:mm) /Run Day",
            accessor: "averageRuntime",
            Cell: ({ row, column, cell }) => {
              const averageRuntime = row.original.averageRuntime;

              return row.original.averageRuntime !== "" &&
                row.original.averageRuntime !== null
                ? averageRuntime
                : "00:00";
            },
          },
          {
            Header: "Avg Idle Time (hh:mm) /Day",
            accessor: "averageIdletime",
          },
        ],
      },
      {
        Header: " Alerts",
        columns: [
          // {
          //   Header: "Harsh Driving Instances",
          //   accessor: "rashDrivingInstances",
          //   width: 200
          // },
                    {
            Header: "Harsh Acceleration",
            accessor: "harshAccelerationCount",
          },
          {
            Header: "Harsh Braking",
            accessor: "harshBrakeCount",
          },
          {
            Header: "Harsh Cornering",
            accessor: "harshCornerCount",
          },
          

        ],
      },
    ],
    []
  );

  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
    arr.push("efficiency");
    arr.push("stopEnergyConsumedinpark");
    arr.push("customerName");
    arr.push("moveEnergyConsumed");
    initialState1.hiddenColumns = arr;
  }

  // Get Data from New Dropdown

  //Export PDF & Excel

  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FleetSummaryReportVehicleWise/fleetSummaryVehicleWise.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderdir=" +
        payload.orderdir +
        "&orderColumn=" +
        payload.orderColumn +
        "&sortBy=" +
        payload.sortBy +
        "&distanceDisplay=" +
        distanceDisplay +
        "&distanceUnit=" +
        distanceValue;

      window.open(url);

      auditPayload.message = "Reports > MonthlySummaryVehicleWise > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Monthly Summary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";

      auditLog(auditPayload);
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "FleetSummaryReportVehicleWise/fleetSummaryVehicleWise.pdf?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&search=" +
        payload.search +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&orderdir=" +
        payload.orderdir +
        "&orderColumn=" +
        payload.orderColumn +
        "&sortBy=" +
        payload.sortBy;

      window.open(url);

      auditPayload.message = "Reports > MonthlySummaryVehicleWise > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Monthly Summary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";

      auditLog(auditPayload);
    } catch (error) {}
  };


  return (
    <Box>
      {/* <ReportNewDropDown
        onSubmit={handleSubmit}
        isShowVeh={true}
        displayMonth={true}
      /> */}
      {/* <FilterDropdown getData={onSubmitForm} status={false} monthPicker={true}/> */}
      <StyledCardShadow sx={{ mt: 2 }}>
        <CardContent sx={{ py: 1 }}>
          <DataTable
            payload={payload}
            exportWithAll={true}
            initialState={initialState1}
            columns={columns}
            data={data}
            onChangeXcl={onChangeXcl}
            onChangePdf={onChangePDF}
            includeSearchBox={true}
            handleChange={handleSearch}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={2}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={rowsPerPage}
            isLoading={isLoadingOut}
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};

export default MonthlySummaryvehicleWiseReport;
