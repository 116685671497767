
import React, { useState } from 'react'
import { useCleanAirZonesMutation } from '../../store/slice/NeweFeatureSlice/FeaturesSlice';
import { InfoBox, InfoWindow, Marker, Polygon } from '@react-google-maps/api';
import { Box, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { CleanAir } from '../Constans';
import { CAZBoundaries } from '../../constants/CazUK';
import { AiOutlineMessage } from 'react-icons/ai';
import { chatBotSingleVehicleData } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";


function ClearnAirZones(props) {
  const { data } = props;
  const [infoData, setInfoData] = useState(null);
  const theme = useTheme();
  const dispatch = useDispatch();
  const getMarkerData = (markers) => {
    let markerObj = {};
    markerObj.lat = parseFloat(markers?.latLng?.split(',')[0]);
    markerObj.lng = parseFloat(markers?.latLng?.split(',')[1]);
    markerObj.name = markers.location;
    markerObj.dailyCharge = markers.dailyCharge;
    markerObj.type = markers.type;
    setInfoData(markerObj);
  }

  const handleGptClick=(value,name)=>{
    console.log("************************value",value,name);
    var payload={
      "mapSignal":value,
      "location":name
    }
    dispatch(chatBotSingleVehicleData(payload));
  }
 
  
  const handleClick = (event, item) => {
    let markerObj = {};
    markerObj.lat = event.latLng.lat();
    markerObj.lng = event.latLng.lng();
    markerObj.name = item.name;
    markerObj.dailyCharge = item.dailyCharge;
    markerObj.type = item.type;
    setInfoData(markerObj);
  }
  let PolygonOptions = {
    strokeColor:'#1eb800',
    fillColor:'#37ff00',
}
  return (
    <>
    {
      CAZBoundaries.map((item, index) => <Polygon key={index} path={item.path} onClick={(event) => handleClick(event, item)} options={{
        ...PolygonOptions
      }}/>)
    }
    {
       infoData !== null && 
       <InfoWindow
          position={{lat:infoData.lat, lng:infoData.lng}}
          onCloseClick={() => setInfoData(null)}
          options={{pixelOffset: new window.google.maps.Size(0,-50)}}   
       >
          <Paper sx={{padding:'8px', minWidth:'250px', width:'100%', maxWidth:'250px'}}>
              <Stack>
                  <Grid container spacing={1}>
                      <Grid item lg={6}>
                        <Box>
                          <Typography variant='caption' color={'text.disabled'}>Location</Typography>
                          <Typography variant='body2'>{infoData.name}</Typography>
                        </Box>
                      </Grid>
                      <Grid item lg={6}>
                        <Box>
                          <Typography variant='caption' color={'text.disabled'}>Class</Typography>
                          <Typography variant='body2'>{infoData.type}</Typography>
                        </Box>
                      </Grid>
                  </Grid>
                  
                  <Box>
                      <Typography variant='caption' color={'text.disabled'}>Charges</Typography>
                      <Typography variant='body2'>{infoData.dailyCharge}</Typography>
                  </Box>
                  <Grid container sx={{marginTop:"5px",marginBottom: "-6px !important"}}>
                  <Grid item lg={11}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    className="addresslink"
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                  <a
                      href={`https://www.google.com/maps/search/?api=1&query=${infoData.lat+","+infoData.lng}`}
                      target="_blank"
                      id={"tolllatlngId"}
                    >
                    <Typography variant='caption' >View map</Typography> 
                    </a>
                  </Box>
                </Box>
                  </Grid>
                  <Grid item lg={1}>
                  <Box>                  
                  <Typography variant='body2'><div style={{ fontSize: '16px', color: '#4CAF50' }}>
                    <AiOutlineMessage  onClick={(event) => handleGptClick("ClearAirZones",infoData.name)} />
                  </div></Typography>
                  </Box>
                  </Grid>
                  </Grid>
              </Stack>
          </Paper>
       </InfoWindow>
    }
    
    </>
  )
}

export default ClearnAirZones