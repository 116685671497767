import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuth, logout } from "./ControlsSlice";
import { useNavigate } from "react-router-dom";

//Base Query
const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_API_BASE_URL, //http://52.10.212.186:7000/member/createMember
  // baseUrl: "http://3.109.148.47:8002",
 
  //credentials: 'include',
  prepareHeaders: (headers, { getState }) => {
    let auth = getState().controls.authToken;
    let refToekn = getState().controls.refToken;
    if (auth) {
      headers.set("Access-Control-Allow", "*");
      headers.set("Authorization", `Bearer ` + auth);
      headers.set("refreshTokenKey", refToekn);
      headers.set("isRefreshToken", true);
    }
    return headers;
  },
});

const baseQueryWithReauth = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);
  if (result?.error?.data?.status === 401) {
    const refreshResult = await baseQuery("/refreshToken", api, extraOptions);
    if (refreshResult?.data) {
      //const user = api.getState().Controls.customizer.authToken
      api.dispatch(
        getAuth([
          refreshResult?.data.accessToken,
          refreshResult?.data.refreshToken,
        ])
      );
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logout());
      window.location.href = "/";
    }
  }
  return result;
};

export const dashboardApi = createApi({
  reducerPath: "dashboardApi",
  // baseQuery: fetchBaseQuery({
  //   baseUrl: process.env.REACT_APP_API_BASE_URL,
  //   prepareHeaders: (headers, { getState }) => {
  //     let auth = getState().controls.authToken;
  //     let refToekn = getState().controls.refToken;
  //     headers.set("Access-Control-Allow", "*");
  //     headers.set("Authorization", `Bearer ` + auth);
  //     headers.set("isRefreshToken", refToekn);
  //     headers.set("refreshTokenKey", true);
  //   },
  // }),

  baseQuery: baseQueryWithReauth,

  //add endpoints
  endpoints: (builder) => ({
    setLogin: builder.mutation({
      query: (authDetails) => ({
        url: `/authenticate`,
        method: "post",
        body: authDetails,
      }),
    }),
    getThemeList: builder.query({
      query: (id) => {
        return {
          url: `logo/logoTheme?resellerId=${id === undefined ? 0 : id}`,
          method: "post",
        };
      },
    }),
    getLogoApi: builder.mutation({
      query: (body) => ({
        url: `logo/getLogo`,
        method: "post",
        body: body,
      }),
    }),
    setLogoApi: builder.mutation({
      query: (body) => ({
        url: `logo/saveLogo`,
        method: "post",
        body: body,
      }),
    }),
    playBack: builder.mutation({
      query: (body) => ({
        url: `PlayBackRng/playbackhistory`,
        method: "post",
        body: body,
      }),
    }),
    saveTheme: builder.mutation({
      query: (body) => ({
        url: `logo/saveLogoTheme`,
        method: "post",
        body: body,
      }),
    }),
    getVehicleCount: builder.mutation({
      query: (body) => ({
        url: `mapView/getVehiclesCountForLegend`,
        method: "POST",
        body: body,
      }),
    }),
    //get mapView/mapViewCommontreeDataByLimit?region=All
    getRegion: builder.mutation({
      query: (body) => ({
        url: `mapView/mapViewCommontreeDataByLimit?region=All`,
        method: "POST",
        body: body,
      }),
    }),
    //get all vehicle by reseller
    getVehicleByReseller: builder.mutation({
      query: (body) => ({
        url: "mapView/getVehiclesByReseller",
        method: "POST",
        body: body,
      }),
    }),
    getVehicleCategoryByReseller: builder.mutation({
      query: (body) => ({
        url: "mapView/getCategoryVehicleList",
        method: "POST",
        body: body,
      }),
    }),
    //getRegioList
    getRegionList: builder.mutation({
      query: (body) => ({
        url: "mapView/mapViewCommontreeDataByLimitTest",
        method: "POST",
        body: body,
      }),
    }),
    //mapView/mapViewCommontreeDateRangeTest
    getVehicleDetails: builder.mutation({
      query: (body) => ({
        url: "mapView/mapViewCommontreeDateRangeTest",
        method: "POST",
        body: body,
      }),
    }),





    //get playback
    playback: builder.mutation({
      query: (body) => ({
        url: "PlayBackRng/playbackhistory1",
        method: "POST",
        body: body,
      }),
    }),
    //Start Dropdowns
    //GerRegion & Get Dealer & Get Customer
    getDropdownList: builder.mutation({
      query: (body) => ({
        url: "organization/organizationDropdownWithPagination",
        method: "POST",
        body: body,
      }),
    }),
    //get vehicle List //vehiclePaginationDropdown
    getVehicleDropdownList: builder.mutation({
      query: (body) => ({
        url: "vehicle/vehiclePaginationDropdown",
        method: "POST",
        body: body,
      }),
    }),
    //audi Log
    auditLog: builder.mutation({
      query: (body) => ({
        url: "/AuditLog/saveAuditLogDetails",
        method: "POST",
        body: body,
      }),
    }),
    //Overspeed Report
    overSpeed: builder.mutation({
      query: (body) => ({
        url: "/OverSpeedReport/getOverSpeedReportGridView",
        method: "POST",
        body: body,
      }),
    }),
    //GEO fence Report
    geoFence: builder.mutation({
      query: (body) => ({
        url: "/GeoFenceReport/getGeoFenceReport",
        method: "POST",
        body: body,
      }),
    }),
    //Stop Page Report
    stopPage: builder.mutation({
      query: (body) => ({
        url: "/StoppageReport/getStoppageReportGridView",
        method: "POST",
        body: body,
      }),
    }),
    //Charging Report
    charging: builder.mutation({
      query: (body) => ({
        url: "/ChargingReport/getChargingReport",
        method: "POST",
        body: body,
      }),
    }),
    //Trip Report
    trip: builder.mutation({
      query: (body) => ({
        url: "/TripReport/gridView",
        method: "POST",
        body: body,
      }),
    }),
    //Vehicle Summary
    vehicleSummary: builder.mutation({
      query: (body) => ({
        url: "VehicleSummaryReport/gridView",
        method: "POST",
        body: body,
      }),
    }),
    //Fault  Code
    faultCode: builder.mutation({
      query: (body) => ({
        url: "FaultCodeReport/getFaultCodeReport",
        method: "POST",
        body: body,
      }),
    }),
    //VDR Report
    vehicleData: builder.mutation({
      query: (body) => ({
        url: "ManualCanDataReport/getvehicleDataReportForWizard",
        method: "POST",
        body: body,
      }),
    }),

//Latest Detail Report
    latestVehicleData: builder.mutation({
      query: (body) => ({
        url: "VehicleLatestDetailReport/getLatestDetailGridView",
        method: "POST",
        body: body,
      }),
    }),

    //Monthly Report - Vehicle Wise
    monthlyVehicle: builder.mutation({
      query: (body) => ({
        url: "FleetSummaryReportVehicleWise/getFleetSummaryVehicleWiseGridView",
        method: "POST",
        body: body,
      }),
    }),
    //Monthly Report - Day Wise
    monthlyDay: builder.mutation({
      query: (body) => ({
        url: "FleetSummaryReportDayWise/getFleetDayReportGridView",
        method: "POST",
        body: body,
      }),
    }),
    //EV Data Report - Day Wise
    evdata: builder.mutation({
      query: (body) => ({
        url: "/EVDataReport/gridView",
        method: "POST",
        body: body,
      }),
    }),
    //AdminOrgnazation
    adminOtherDropdowns: builder.mutation({
      query: (body) => ({
        url: "/organization/organizationDropdownWithPagination",
        method: "POST",
        body: body,
      }),
    }),
    //SIM
    simPlanList: builder.mutation({
      query: (body) => ({
        url: "/sim/getSimPlanStatus",
        method: "POST",
        body: body,
      }),
    }),
    //User Page
    //ListUser
    userList: builder.mutation({
      query: (body) => ({
        url: "/user/listUser",
        method: "POST",
        body: body,
      }),
    }),
    //SaveUser
    saveUser: builder.mutation({
      query: (body) => ({
        url: "/user/createUser",
        method: "POST",
        body: body,
      }),
    }),
    //UpdateUser
    updateUser: builder.mutation({
      query: (body) => ({
        url: "/user/updateUser",
        method: "POST",
        body: body,
      }),
    }),
    //GetUser
    getUser: builder.mutation({
      query: (body) => ({
        url: "/user/getUser",
        method: "POST",
        body: body,
      }),
    }),

    //Sim Page
    //List Sim /sim/listSim /sim/updateSim /sim/createSim
    simList: builder.mutation({
      query: (body) => ({
        url: "/sim/listSim",
        method: "POST",
        body: body,
      }),
    }),
    //Sim Update
    simUpdate: builder.mutation({
      query: (body) => ({
        url: "/sim/updateSim",
        method: "POST",
        body: body,
      }),
    }),
    //Sim Create
    simCreate: builder.mutation({
      query: (body) => ({
        url: "/sim/createSim",
        method: "POST",
        body: body,
      }),
    }),
    //Sim Delete
    simDelete: builder.mutation({
      query: (body) => ({
        url: "/sim/deleteSim",
        method: "POST",
        body: body,
      }),
    }),
     //Sim vendor Delete
     simVendorDelete: builder.mutation({
      query: (body) => ({
        url: "/simOnBoard/deleteSim",
        method: "POST",
        body: body,
      }),
    }),
    //list organization
    OrgList: builder.mutation({
      query: (body) => ({
        url: "/organization/listOrganization",
        method: "POST",
        body: body,
      }),
    }),

    //User Delete
    deleteUser: builder.mutation({
      query: (body) => ({
        url: "/user/deleteUser",
        method: "POST",
        body: body,
      }),
    }),

    vehicleDelete: builder.mutation({
      query: (body) => ({
        url: "/vehicle/deleteVehicle",
        method: "POST",
        body: body,
      }),
    }),
    //List Device
    listDevice: builder.mutation({
      query: (body) => ({
        url: "/device/listDevice",
        method: "POST",
        body: body,
      }),
    }), //Device Update /device/updateDevice
    updateDevice: builder.mutation({
      query: (body) => ({
        url: "/device/updateDevice",
        method: "POST",
        body: body,
      }),
    }),
    //Device Create /device/createDevice
    createDevice: builder.mutation({
      query: (body) => ({
        url: "/device/createDevice",
        method: "POST",
        body: body,
      }),
    }),
    //deleteDevice /device/deleteDevice
    deleteDevice: builder.mutation({
      query: (body) => ({
        url: "/device/deleteDevice",
        method: "POST",
        body: body,
      }),
    }),

    //List Device (Vendor)
    listDeviceOnBoard: builder.mutation({
      query: (body) => ({
        url: "/deviceOnBoard/listDevice",
        method: "POST",
        body: body,
      }),
    }),

// move device to reseller
moveDeviceToReseller: builder.mutation({
      query: (body) => ({
        url: "/deviceOnBoard/moveDeviceToReseller",
        method: "POST",
        body: body,
      }),
    }),

    // verify reseller
verifyReseller: builder.mutation({
  query: (body) => ({
    url: "/deviceOnBoard/moveDeviceToSystem",
    method: "POST",
    body: body,
  }),
}),

    //Device Update /device/updateDevice
    updateDevice: builder.mutation({
      query: (body) => ({
        url: "/device/updateDevice",
        method: "POST",
        body: body,
      }),
    }),

    //Device Update(Vendor) /device/updateDevice
    updateDeviceOnBoard: builder.mutation({
      query: (body) => ({
        url: "/deviceOnBoard/updateDevice",
        method: "POST",
        body: body,
      }),
    }),

    // simActivate
    simActivate: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/simActivation",
        method: "POST",
        body: body,
      }),
    }),
    //Device Create /device/createDevice(Vendor)
    createDevice: builder.mutation({
      query: (body) => ({
        url: "/device/createDevice",
        method: "POST",
        body: body,
      }),
    }),

    //Device Create /device/createDevice
    createDeviceOnBoard: builder.mutation({
      query: (body) => ({
        url: "/deviceOnBoard/createDevice",
        method: "POST",
        body: body,
      }),
    }),

    // simSafeCustody
    simSafeCustody: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/simSafeCustody",
        method: "POST",
        body: body,
      }),
    }),
    //deleteDevice /deviceOnBoard/deleteDevice
    deleteDeviceOnBoard: builder.mutation({
      query: (body) => ({
        url: "/deviceOnBoard/deleteDevice",
        method: "POST",
        body: body,
      }),
    }),

    // simOutOfSafeCustody
    simOutOfSafeCustody: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/simOutOfSafeCustody",
        method: "POST",
        body: body,
      }),
    }),
    // simDisconnect
    simDisconnect: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/simDisconnect",
        method: "POST",
        body: body,
      }),
    }),
    // resumeFromTempDisconnect
    resumeFromTempDisconnect: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/resumeFromTempDisconnect",
        method: "POST",
        body: body,
      }),
    }),

    // Whitelist-Airtel Log
    saveWhitelist: builder.mutation({
      query: (body) => ({
        url: "/airtelAPI/numberWhiteListing",
        method: "POST",
        body: body,
      }),
    }),
    //Vehicle Page
    vehicleList: builder.mutation({
      query: (body) => ({
        url: "/vehicle/listVehicle",
        method: "POST",
        body: body,
      }),
    }),

    createVehicle: builder.mutation({
      query: (body) => ({
        url: "/vehicle/createVehicle",
        method: "POST",
        body: body,
      }),
    }),

    //vehicle Update
    vehicleUpdate: builder.mutation({
      query: (body) => ({
        url: "/vehicle/updateVehicle",
        method: "POST",
        body: body,
      }),
    }),

    //getVehicle
    getVehicle: builder.mutation({
      query: (body) => ({
        url: "/vehicle/getVehicle",
        method: "POST",
        body: body,
      }),
    }),

    //createOrganization

    OrgCreate: builder.mutation({
      query: (body) => ({
        url: "/organization/createOrganization",
        method: "POST",
        body: body,
      }),
    }),
    //updateOrganization
    OrgUpdate: builder.mutation({
      query: (body) => ({
        url: "/organization/updateOrganization",
        method: "POST",
        body: body,
      }),
    }),
    //getOrganization
    OrgDet: builder.mutation({
      query: (body) => ({
        url: "/organization/getOrganization",
        method: "POST",
        body: body,
      }),
    }),
    //deleteOrganization

    orgDelete: builder.mutation({
      query: (body) => ({
        url: "/organization/deleteOrganization",
        method: "POST",
        body: body,
      }),
    }),

    // Logs getUserOrgList
    auditOrgList: builder.mutation({
      query: (body) => ({
        url: "/AuditLog/getUserOrgList",
        method: "POST",
        body: body,
      }),
    }),
    auditUserList: builder.mutation({
      query: (body) => ({
        url: "/user/getUserlist",
        method: "POST",
        body: body,
      }),
    }),

    getAuditUserList: builder.mutation({
      query: (body) => ({
        url: "/user/getUserListForAudit",
        method: "POST",
        body: body,
      }),
    }),

    //Organization list for super admin
    auditOrganizationList: builder.mutation({
      query: (body) => ({
        url: "/AuditLog/getOrgList",
        method: "POST",
        body: body,
      }),
    }),

    simOnBoardList: builder.mutation({
      query: (body) => ({
        url: "/simOnBoard/listSim",
        method: "POST",
        body: body,
      }),
    }),
    //OTA Log

    otaList: builder.mutation({
      query: (body) => ({
        url: "/OTA/getOTALog",
        method: "POST",
        body: body,
      }),
    }),
    simOnBoardCreate: builder.mutation({
      query: (body) => ({
        url: "/simOnBoard/createSim",
        method: "POST",
        body: body,
      }),
    }),
    simMovetoVendor: builder.mutation({
      query: (body) => ({
        url: "/simOnBoard/moveSimToVendor",
        method: "POST",
        body: body,
      }),
    }),
    //OTA Log

    otaList: builder.mutation({
      query: (body) => ({
        url: "/OTA/getOTALog",
        method: "POST",
        body: body,
      }),
    }),
    //Device-OTA Log

    deviceOtaList: builder.mutation({
      query: (body) => ({
        url: "/OTA/getDeviceOTALog",
        method: "POST",
        body: body,
      }),
    }),

    changePassword: builder.mutation({
      query: (body) => ({
        url: "/user/ChangePassword",
        method: "POST",
        body: body,
      }),
    }),
      //Device Data grid view
      deviceDataGridView: builder.mutation({
        query: (body) => ({
          url: "/ManualCanDataReport/getDeviceDataReportForWizard",
          method: "POST",
          body: body,
        }),
      }),
  
      //Device Data Verify
      verifyDeviceBenchTest: builder.mutation({
        query: (body) => ({
          url: "/ManualCanDataReport/verifyDeviceBenchTest",
          method: "POST",
          body: body,
        }),
      }),

 //Get Route List
 getRouetList: builder.mutation({
  query: (body) => ({
    url: "/routeTrip/getRoutesFilter",
    method: "POST",
    body: body,
  }),
}),

//Get Slot List
getSlotDetailList: builder.mutation({
  query: (body) => ({
    url: "/testDrive/getSlotDetailList",
    method: "POST",
    body: body,
  }),
}),


//resend Activation
userResendAct: builder.mutation({
  query: (body) => ({
    url: "/user/sendResetActivationLink",
    method: "POST",
    body: body,
  }),
}),

//License Report
licenseReport: builder.mutation({
  query: (body) => ({
    url: "/LicenseReport/getLicenseReport",
    method: "POST",
    body: body,
  }),
}),

//Instrumentation List
instrumentationList: builder.mutation({
  query: (body) => ({
    url: "/Instrumentation/getInstrumentationList",
    method: "POST",
    body: body,
  }),
}),

//Feed Summary List - instrumentation
feedSummaryList: builder.mutation({
  query: (body) => ({
    url: "/Instrumentation/getInvalidFeedList",
    method: "POST",
    body: body,
  }),
}),

VehicleUploaddata: builder.mutation({
  query: (body) => ({
    url: "vehicle/saveVehicleImage",
    method: "POST",
    body: body,
  }),
}),

Vehicleimage: builder.mutation({
  query: (body) => ({
    url: "vehicle/getVehicleImage",
    method: "POST",
    body: body,
  }),
}),
//DisCharging Temp Behaviour
disCharging: builder.mutation({
  query: (body) => ({
    url: "/ChargingReport/getDisChargingReport",
    method: "POST",
    body: body,
  }),
}),


//Deep DisCharging
deepDisCharge: builder.mutation({
  query: (body) => ({
    url: "/ChargingReport/getDeepDischarge",
    method: "POST",
    body: body,
  }),
}),


getMapType: builder.mutation({
  query: (body) => ({
    url: "/user/getMapType",
    method: "POST",
    body: body,
  }),
}),
getUserActivation: builder.mutation({
  query: (body) => ({
    url: "/user/accountActivation",
    method: "POST",
    body: body,
  }),
}),

 

MapType: builder.mutation({
  query: (body) => ({
    url: "/user/updateMapType",
    method: "POST",
    body: body,

  }),
}),

SaveTheme: builder.mutation({
  query: (body) => ({
    url: "/theme/createOrUpdateTheme",
    method: "POST",
    body: body,
  }),
}),

GetTheme: builder.mutation({
  query: (body) => ({
    url: "/theme/getTheme",
    method: "POST",
    body: body,
  }),
}),
    //Overspeed Report
    getIdleReport: builder.mutation({
      query: (body) => ({
        url: "/IdleReport/getIdleReportGridView",
        method: "POST",
        body: body,
      }),
    }),
    getCleanAirZoneDetails: builder.mutation({ //FOR OBD
      query: (body) => ({
        url: `GeoFenceReport/CAZReport`,
        method: "POST",
        body: body,
      }),
    }),

    GetDistanceType : builder.mutation({
      query : (body) =>({
        url : "/organization/getDistanceType",
        method : "Post",
        body : body,
      })
      }
      ),
      
      SaveDistanceType : builder.mutation({
        query : (body) =>({
          url : "/organization/updateDistanceType",
          method : "Post",
          body : body,
        })
        }
        ),

        driverList: builder.mutation({
          query: (body) => ({
            url: "/vehicle/getDriverList",
            method: "POST",
            body: body,
          }),
        }),

        driverVehicleMap: builder.mutation({
          query: (body) => ({
            url: "/vehicle/driverVehicleMap",
            method: "POST",
            body: body,
          }),
        }),

         assignedDriver: builder.mutation({
          query: (body) => ({
            url: "/vehicle/getAssignedDriver",
            method: "POST",
            body: body,
          }),
        }),

      saveServicePlan : builder.mutation({
        query : (body) =>({
          url : "/servicePlanner/createServicePlan",
          method : "Post",
          body : body,
        })
      }),

      servicePlanList :  builder.mutation({
        query : (body) =>({
          url : "/servicePlanner/pendingServicePlans",
          method : "Post",
          body : body,
        })
      }),

      upcomingServicePlans :  builder.mutation({
        query : (body) =>({
          url : "/servicePlanner/upcomingServicePlans",
          method : "Post",
          body : body,
        })
      }),

      checkServiceExist :  builder.mutation({
        query : (body) =>({
          url : "/servicePlanner/checkExistingServicePlanStatus",
          method : "Post",
          body : body,
        })
      }),

      updateServicePlan : builder.mutation ({
        query : (body) =>({
          url : "/servicePlanner/updateServicePlan",
          method : "Post",
          body : body,
        })
      }),

      servicePlanHistory : builder.mutation ({
        query : (body) =>({
          url : "/servicePlanner/servicePlansHistory",
          method : "Post",
          body : body,
        })
      }),

      deleteServicePlan : builder.mutation ({
        query : (body) =>({
          url : "/servicePlanner/deleteServicePlan",
          method : "Post",
          body : body,
        })
      }),
      listReminder : builder.mutation ({
        query : (body) =>({
          url : "/servicePlanner/listReminder",
          method : "Post",
          body : body,
        })
      }),

      getServicePlan : builder.mutation ({
        query : (body) =>({
          url : "/servicePlanner/getServicePlan",
          method : "Post",
          body : body,
        })
      }),

      editServicePlan : builder.mutation ({
        query : (body) =>({
          url : "/servicePlanner/editServicePlan",
          method : "Post",
          body : body,
        })
      }),


  }),
});

export const {
  useGetThemeListQuery,
  useGetLogoApiMutation,
  useSetLogoApiMutation,
  usePlayBackMutation,
  useSetLoginMutation,
  useGetVehicleCountMutation,
  useGetRegionMutation,
  useGetVehicleByResellerMutation,
  useGetVehicleCategoryByResellerMutation,
  useGetRegionListMutation,
  usePlaybackMutation,
  useGetVehicleDetailsMutation,
  useGetDropdownListMutation,
  useGetVehicleDropdownListMutation,
  useOverSpeedMutation,
  useAuditLogMutation,
  useGeoFenceMutation,
  useStopPageMutation,
  useChargingMutation,
  useTripMutation,
  useVehicleSummaryMutation,
  useFaultCodeMutation,
  useVehicleDataMutation,
  useLatestVehicleDataMutation,
  useMonthlyDayMutation,
  useMonthlyVehicleMutation,
  useEvdataMutation,
  useAdminOtherDropdownsMutation,
  useSimPlanListMutation,
  useUserListMutation,
  useSaveUserMutation,
  useGetUserMutation,
  useUpdateUserMutation,
  //Sim
  useSimListMutation,
  useSimUpdateMutation,
  useSimCreateMutation,
  useSimDeleteMutation,
  useVehicleListMutation,
  useDeleteUserMutation,
  //Device
  useListDeviceMutation,
  useUpdateDeviceMutation,
  useCreateDeviceMutation,
  useDeleteDeviceMutation,

  //DeviceOnBoard
  useListDeviceOnBoardMutation,
  useCreateDeviceOnBoardMutation,
  useUpdateDeviceOnBoardMutation,
  useMoveDeviceToResellerMutation,
  useVerifyResellerMutation,
  useDeleteDeviceOnBoardMutation,
  //SIM
  useSimActivateMutation,
  useSimSafeCustodyMutation,
  useSimOutOfSafeCustodyMutation,
  useSimDisconnectMutation,
  useResumeFromTempDisconnectMutation,

  useSaveWhitelistMutation,

  //Orgnaization
  useOrgCreateMutation,
  useOrgDeleteMutation,
  useOrgListMutation,
  useOrgDetMutation,
  useOrgUpdateMutation,
  //Vehicles
  useCreateVehicleMutation,
  useGetVehicleMutation,
  useVehicleUpdateMutation,
  useVehicleDeleteMutation,
  useDriverListMutation,
  useDriverVehicleMapMutation,
  useAssignedDriverMutation,
  //Logs
  useAuditOrgListMutation,
  useAuditUserListMutation,
  useAuditOrganizationListMutation,
  useGetAuditUserListMutation,

  //SimOn Board
  useSimOnBoardListMutation,
  useSimOnBoardCreateMutation,
  useSimMovetoVendorMutation,
  useSimVendorDeleteMutation,

  useOtaListMutation,
  useDeviceOtaListMutation,

  //change Password
  useChangePasswordMutation,

  //Device data
  useDeviceDataGridViewMutation,
  useVerifyDeviceBenchTestMutation,

  //Route Trip
  useGetRouetListMutation,

  //Resend Activation
useUserResendActMutation,

  //slot Detail List
  useGetSlotDetailListMutation,

  //license Report
  useLicenseReportMutation,

  //Instrumentation 
  useInstrumentationListMutation,
  useFeedSummaryListMutation,

  //VechicleUpload
  useVehicleUploaddataMutation,
  useVehicleimageMutation,

  // Discharging
  useDisChargingMutation,
  // deep DisCharge
  useDeepDisChargeMutation,

  useMapTypeMutation,
  useGetMapTypeMutation,
  
 useGetThemeMutation,
 useSaveThemeMutation,
  useGetUserActivationMutation,
  useGetIdleReportMutation,

  // CAZ Report

  useGetCleanAirZoneDetailsMutation,
   //DistanceType
   useGetDistanceTypeMutation,

   

   useSaveDistanceTypeMutation,

   // Service Planner
   useSaveServicePlanMutation,
   useServicePlanListMutation,
   useUpcomingServicePlansMutation,
   useCheckServiceExistMutation,
   useUpdateServicePlanMutation,
   useServicePlanHistoryMutation,
   useDeleteServicePlanMutation,
   useListReminderMutation,
   useGetServicePlanMutation,
   useEditServicePlanMutation,
  
} = dashboardApi;
