import { dashboardApi } from "../ApiSlice";

const FeaturesSlice = dashboardApi.injectEndpoints({
    endpoints: (builder) => ({
        cleanAirZones:builder.mutation({
            query: (body) => ({
                url: "/GeoFence/getClearAirZones",
                method: "POST",
                body: body,
            }),
        }),
        lowBridges:builder.mutation({
            query: (body) => ({
                url: "/GeoFence/getLowBridges",
                method: "POST",
                body: body,
            }),
        }),
        timeline:builder.mutation({ // For single Day
            query: (body) => ({
                url: "/mapView/getVehicleTimelineForSingleDay",
                method: "POST",
                body: body,
            }),
        }),
        weightBridges:builder.mutation({
            query: (body) => ({
                url: "/GeoFence/getWeightBridges",
                method: "POST",
                body: body,
            }),
        }),

        tolls:builder.mutation({
            query: (body) => ({
                url: "/GeoFence/getTollDetails",
                method: "POST",
                body: body,
            })
        })
    })
})


//mapView/getVehicleTimelineForSingleDay


export const {
    useCleanAirZonesMutation,
    useLowBridgesMutation,
    useTimelineMutation,
    useWeightBridgesMutation,
    useTollsMutation
} = FeaturesSlice