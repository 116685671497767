import { alpha, Divider, Icon, ListItemButton, ListItemText, Skeleton, useTheme } from '@mui/material';
import { Box, Button, Grid, List, ListItem, Stack, Typography } from '@mui/material'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IoCalendarOutline, IoSpeedometerOutline } from "react-icons/io5";
import GoogleMapUtil from '../../Utils/GoogleMapUtil';
import { SingleDatePicker } from '../../Utils/RsuiteDatePicker';
import { useGetAddressMutation, useVehicleTripsMutation } from '../../store/slice/VehicleSummarySlice/VehicleSummarySlice';
import { Empty } from 'antd';
import { TbRoute } from 'react-icons/tb';
import { MdSpeed } from 'react-icons/md';
import reportServices from '../../services/reportServices';
import { Marker, Polyline } from '@react-google-maps/api';
import moment from 'moment';
import { PiTrafficSignalBold, PiSpeedometerBold } from "react-icons/pi";
import { RiSlowDownLine, RiSpeedUpLine } from 'react-icons/ri';
import ScaleLoader from "react-spinners/ScaleLoader";


function TripDetails({ vehicleId, resellerId, vehicleNo }) {

    const theme = useTheme();
    const userData = JSON.parse(localStorage.getItem("userData"));
    let distanceUnit = userData?.distanceDisplayUnit;
    const [getTripsDetails, { data, isLoading: tripLoading, isSuccess: tripSuccess }] = useVehicleTripsMutation();
    const [getAddress] = useGetAddressMutation();
    const [Trips, setTrips] = useState([]);
    const [center, setCenter] = useState({ lat: 52.92214338014607, lng: -1.4703258589163344 })
    const [selectTrip, setSelectTrip] = useState(0);

    const mapRef = useRef();
    const getTrips = async (fromdate, toDate) => {
        setTrips([]);
        let payload = {
            "vehicleId": vehicleId,
            "orgId": resellerId,
            "startDate": fromdate === undefined ? moment().subtract(1, 'day').format('YYYY-MM-DD 00:00:00') : fromdate,
            "endDate": toDate === undefined ? moment().subtract(1, 'day').format('YYYY-MM-DD 23:59:59') : toDate,
        }
        const res = await getTripsDetails(payload);
        if (res.data !== undefined && res.data !== null && res.data.length > 0) {
            setTrips(res.data);
            getTripTrails(res.data[selectTrip]?.trailData);
            showAdd(res.data[selectTrip].startLocation, 'start');
            showAdd(res.data[selectTrip].endLocation, 'end');

            setSelectTrip(0);
        } else {
            setTrips([]);
        }

    }
    useEffect(() => {
        setTimeout(() => {
            getTrips();
        }, 50)
    }, [])

    const [path, setPath] = useState([])
    const getTripTrails = (trails) => {
        setPath([])
        trails.forEach(element => {
            let location = element.split(',');
            let latLng = {};
            latLng.lat = parseFloat(location[0]);
            latLng.lng = parseFloat(location[1]);
            setCenter(latLng);
            setPath((prevState) => [...prevState, latLng])
        });
    }

    const TripItem = (props) => {
        const { data, index } = props;
        return (
            <ListItem dense sx={{ px: 0, border: `.5px solid ${theme.palette.divider}`, p: 0, my: .5 }}>
                <ListItemButton sx={{ display: 'block' }} dense selected={index === selectTrip} onClick={() => tripOnChange(index)}>
                    <ListItemText
                        primary={tripLoading ? <Skeleton variant='rectangular' width={100} height={15} sx={{ mb: .5 }} /> : <Typography variant='body1' gutterBottom>{`${data?.startDate} - ${data?.endDate}`}</Typography>}
                        secondary={tripLoading ? <Skeleton variant='rectangular' width={130} height={8} sx={{ mb: .5 }} /> : `Trip Duration: ${data?.tripDuration}`}
                        sx={{ width: '100%' }}
                    />
                    <Stack direction={'row'} alignItems={'center'} spacing={3} sx={{ width: '100%', mt: .5, mb: .5 }}>
                        {
                            tripLoading ? <Skeleton variant='rectangular' width={60} height={20} /> :
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Icon color={'primary'} sx={{ display: 'flex', fontSize: '20px' }}>
                                        <MdSpeed />
                                    </Icon>
                                    <Typography variant='body2' color={'primary'}>{data.avgSpeed}</Typography>
                                </Box>
                        }
                        {
                            tripLoading ? <Skeleton variant='rectangular' width={60} height={20} /> :
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                                    <Icon color={'primary'} sx={{ display: 'flex', fontSize: '20px' }}>
                                        <TbRoute />
                                    </Icon>
                                    <Typography variant='body2' color={'primary'}>{data.totDistance} {distanceUnit}</Typography>
                                </Box>
                        }

                    </Stack>
                </ListItemButton>
            </ListItem>
        )
    }

    const ValueCard = (props) => {
        const { label, value, } = props;
        return (
            <Box>
                <Stack spacing={1}>
                    {tripLoading ? <Skeleton variant='rectangular' width={100} height={15} /> : <Typography variant='body2' color={'text.disabled'}>{label}</Typography>}
                    {
                        tripLoading ? <Skeleton variant='rectangular' width={80} height={15} /> : <Typography variant='body1'>{value}</Typography>
                    }

                </Stack>
            </Box>
        )
    }

    const [fromAddress, setFromAddress] = useState('text');
    const [toAddress, setToAddress] = useState('text');

    const showAdd = async (location, where) => {
        let Location = location?.split(',');
        let startLat = Location !== undefined && parseFloat(Location[0]);
        let startLng = Location !== undefined && parseFloat(Location[1]);
        setCenter({ lat: startLat, lng: startLng })
        var requestN = {};
        requestN.latLng = startLat + "," + startLng;
        if (where === 'start') {
            const addRes = await getAddress(requestN).then((data) => {
                setFromAddress(data?.error?.data);
            }).catch((error) => {
                console.log(error);
            })
        } else {
            const addRes = await getAddress(requestN).then((data) => {
                setToAddress(data?.error?.data);
            }).catch((error) => {
                console.log(error);
            })
        }


    }

    const tripOnChange = (id) => {
        setSelectTrip(id);
        showAdd(Trips[id].startLocation, 'start');
        showAdd(Trips[id]?.endLocation, 'end');
        getTripTrails(Trips[id]?.trailData)
    }




    const TripTiming = (props) => {
        const { startDate, endDate, startLocation, endLocation } = props;

        return (
            <Box>
                <Stack
                    columnGap={15}
                    direction={'column'}
                    sx={{ width: 'fit-content' }}
                >
                    <ListItemText
                        primary={tripLoading ? <Skeleton variant='rectangular' width={120} height={15} sx={{ mb: .5 }} /> : startDate}
                        secondary={tripLoading ? <Skeleton variant='rectangular' width={150} height={8} /> : fromAddress}
                        sx={{
                            ml: 3,
                            flex: 'inherit',
                            position: 'relative',
                            '&:before': {
                                position: 'absolute',
                                content: '""',
                                width: '12px',
                                height: '12px',
                                background: theme.palette.success.main,
                                left: '-25px',
                                top: '5px',
                                borderRadius: '50px',
                                zIndex: 9
                            },
                            '&:after': {
                                position: 'absolute',
                                content: '""',
                                width: '2px',
                                height: '60px',
                                background: '#000',
                                left: '-20px',
                                top: '10px',
                                borderRadius: '50px',
                                zIndex: 1
                            }
                        }}
                    />

                    <ListItemText
                        primary={tripLoading ? <Skeleton variant='rectangular' width={120} height={15} sx={{ mb: .5 }} /> : endDate}
                        secondary={tripLoading ? <Skeleton variant='rectangular' width={150} height={8} /> : toAddress}
                        sx={{
                            ml: 3,
                            flex: 'inherit',
                            position: 'relative',
                            '&:before': {
                                position: 'absolute',
                                content: '""',
                                width: '12px',
                                height: '12px',
                                background: theme.palette.error.main,
                                left: '-25px',
                                top: '5px',
                                borderRadius: '50px',
                                zIndex: 9
                            }
                        }}
                    />
                </Stack>
            </Box>
        )
    }


    const IconBox = (props) => {
        const { label, value, stackSx = {}, icon = null, dir = 'column', boxSx = {} } = props
        return (
            !tripLoading ?
                <Box sx={boxSx}>
                    <Stack sx={stackSx} direction={dir}>
                        <Box sx={{ display: dir === 'row' ? 'flex' : 'column' }} gap={1}>
                            {icon !== null && <Icon sx={{ display: 'flex' }}>{icon}</Icon>}
                            <Typography variant='body2' color={'text.secondary'}>{label}</Typography>
                        </Box>
                        <Typography variant='body1'>{value}</Typography>
                    </Stack>
                </Box> : <Skeleton variant='rectangular' width={120} height={60} />

        )
    }

    const GreyCard = (props) => {
        const { label, value } = props
        return (
            !tripLoading ?
                <Box sx={{ background: alpha(theme.palette.divider, 0.05), py: .5, px: 1.5 }}>
                    <Stack>
                        <Typography variant='body1'>{value}</Typography>
                        <Typography variant='body2' color={'text.secondary'}>{label}</Typography>
                    </Stack>
                </Box> : <Skeleton variant='rectangular' width={120} height={60} />

        )
    }

    const DateChanged = (date) => {
        let startDate = moment(date).format('YYYY-MM-DD 00:00:00')
        let endtDate = moment(date).format('YYYY-MM-DD 23:59:59')
        getTrips(startDate, endtDate);
    }

    useEffect(() => {
        if (mapRef.current !== undefined) {
            mapRef.current.setZoom(10);
            var bounds = new window.google.maps.LatLngBounds();
            for (var i = 0; i < path.length; i++) {
                bounds.extend(path[i])
            }
            mapRef.current.fitBounds(bounds);
        }
    }, [mapRef.current, selectTrip])

    return (
        <Box sx={{ p: 0 }}>
            <Grid container spacing={2} sx={{ height: '100%' }}>
                <Grid item lg={3}>
                    <Box sx={{ p: 2, borderRight: `.8px solid ${theme.palette.divider}`, height: '100%' }}>
                        <Box>
                            {
                                tripLoading ? <Skeleton variant='rectangular' width={100} height={15} /> :
                                    <Typography color={'secondary'} gutterBottom>{vehicleNo}</Typography>
                            }

                        </Box>
                        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                            <Typography>Trip List</Typography>
                            <SingleDatePicker onChangeDate={DateChanged} />

                        </Stack>
                        <Box sx={{ height: '100%', maxHeight: '90%', overflowY: 'auto', mt: .8 }}>
                            {
                                tripLoading ? <Skeleton variant='rectangular' width={285} height={60} /> :
                                    <List sx={{ pr: 1, p: 0, overflowY: 'auto', height: '100%' }}>
                                        {
                                            Trips.length > 0 ? Trips.map((item, index) => <TripItem data={item} index={index} key={index} />) : !tripLoading && <Empty description={'Trips not found'} />
                                        }

                                    </List>
                            }
                        </Box>
                    </Box>
                </Grid>
                <Grid item lg={9} sx={{ height: '100%' }}>
                    <Box sx={{ display: 'grid', gridTemplateRows: 'auto auto auto 1fr auto', p: 2 }}>
                        {/* <Box>
                    <Typography variant='h6' sx={{fontWeight:'600'}} gutterBottom>{tripLoading ? <Skeleton variant='rectangular' width={250} height={15} /> : "Trip & Vehicle Summary"}</Typography>
                    <Stack direction={'row'} spacing={2} columnGap={3}>
                        <ValueCard label={'Odometer Reading'}  value={Trips[selectTrip]?.endOdometer !== undefined ? Trips[selectTrip]?.endOdometer : '-'}/>
                        <ValueCard label={'Last Service Date'} value={'-'}/>
                        <ValueCard label={'Next Due Service Date'} value={'-'}/>
                    </Stack>
                </Box> */}
                        <Box>
                            <Typography variant='h6' sx={{ fontWeight: '600' }} gutterBottom>Trip & Vehicle Summary</Typography>
                            {tripLoading ?(
                            <Box
                                sx={{
                                    height: "100px",
                                    display: "flex",
                                    width: "100%",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                <ScaleLoader color={theme.palette.secondary.main} />
                            </Box>)
                            :
                            
                                Trips.length > 0 ? <>
                                    <Box sx={{ border: `.8px solid ${theme.palette.divider}`, mt: 2, p: 1 }}>
                                        <Stack direction={'row'} spacing={2}>
                                            {/* <GreyCard label={'Driver Score'} value={Trips[selectTrip]?.driverScore}/> */}
                                            <GreyCard label={'Odometer Reading'} value={Trips[selectTrip]?.endOdometer} />
                                            <GreyCard label={'Trip Distance'} value={Trips[selectTrip]?.totDistance} />
                                            <GreyCard label={'Average Speed'} value={Trips[selectTrip]?.avgSpeed} />
                                            <GreyCard label={'Max Speed'} value={Trips[selectTrip]?.maxSpeed} />
                                        </Stack>
                                    </Box>
                                    <Box sx={{ mt: 1.5 }}>
                                        <Grid container spacing={2}>
                                            <Grid item lg={3}>
                                                <Box sx={{ mt: 2 }}>
                                                    <TripTiming
                                                        startDate={Trips[selectTrip]?.startDate}
                                                        endDate={Trips[selectTrip]?.endDate}
                                                        startLocation={Trips[selectTrip]?.startLocation}
                                                        endLocation={Trips[selectTrip]?.endLocation}
                                                    />
                                                    <Stack sx={{ mt: 2 }} spacing={2}>
                                                        <IconBox label={'Idling'} value={Trips[selectTrip]?.cumIdleMins} stackSx={{ display: 'flex', justifyContent: 'space-between' }} icon={<PiTrafficSignalBold />} dir={'row'} boxSx={{ background: alpha(theme.palette.info.main, 0.05), py: 1, px: 1.5 }} />
                                                        <IconBox label={'Harsh Acceleration'} value={Trips[selectTrip]?.haAlertCount} icon={<RiSpeedUpLine />} dir={'row'} stackSx={{ display: 'flex', justifyContent: 'space-between' }} boxSx={{ background: alpha(theme.palette.error.main, 0.08), py: 1, px: 1.5 }} />
                                                        <IconBox label={'Harsh Braking'} value={Trips[selectTrip]?.hbAlertCount} icon={<RiSlowDownLine />} dir={'row'} stackSx={{ display: 'flex', justifyContent: 'space-between' }} boxSx={{ background: alpha(theme.palette.error.main, 0.06), py: 1, px: 1.5 }} />
                                                    </Stack>
                                                </Box>
                                            </Grid>
                                            <Grid item lg={9}>
                                                <Box sx={{ height: 'calc(100vh - 45vh)' }}>
                                                    {
                                                        tripLoading ? <Skeleton variant='rounded' width={'100%'} height={'100%'} />
                                                            : <GoogleMapUtil trails={Trips[selectTrip]?.trailData} mapDepend={[selectTrip, path, center, mapRef.current]} center={center} zoom={10} mapRef={mapRef}>
                                                                <Polyline
                                                                    path={path}
                                                                />
                                                                {/* Start Marker */}
                                                                <Marker
                                                                    position={path[0]}
                                                                    label={{
                                                                        text: 'S'
                                                                    }}
                                                                />

                                                                {/* End Marker */}
                                                                <Marker
                                                                    position={path[path.length - 1]}
                                                                    label={{
                                                                        text: 'E'
                                                                    }}
                                                                />
                                                            </GoogleMapUtil>
                                                    }

                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </Box> </> :
                                    !tripLoading && <Empty description={'No trip Data'} />
                            }
                        </Box>


                    </Box>

                </Grid>
            </Grid>
        </Box>
    )
}

export default TripDetails