import React, { useEffect, useState } from 'react'
import reportServices from "../../../services/reportServices";
import { Circle, InfoWindow, Marker, Polygon } from '@react-google-maps/api';
import { StyledInfoWindow } from '../Treeview/TreeviewStyledEle';
import { Box, Divider, Stack, Typography, alpha ,Skeleton} from '@mui/material';
import { useShowAlertMutation } from '../../../store/slice/TrackingSlice/TrackingSlice';
import { useTheme } from '@emotion/react';
import alertMarker from '../../../assets/images/alertsMarker.svg'
import { useSelector } from "react-redux";
function AlertMarker(props) {
    const {activeFeature, selectVehicleData, vehicleTrails} = props;
    const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] = useState(false);
    const [alertList, setAlertList] = useState(null);


    const [ showAlert, { data: showAlertData, isLoading: showAlertLoading, isSuccess: showAlertSuccess } ] = useShowAlertMutation();
    const theme = useTheme();
    let [address, setAddress] = useState(null);

        //SetGeoZon
        const getAlertList = async () => {
            if (vehicleTrails !== null) {
              const showAlertData = await showAlert({
                vehicleId: selectVehicleData.vehicleId,
                strDate:selectVehicleData.strDate,
                endDate:selectVehicleData.endDate
              });
              
              setAlertList(showAlertData?.data);
            } else {
            setAlertList(null);
              setOpenInfoWindowMarkerId(null);
            }
          };
        
          useEffect(() => {
            if(activeFeature === 3){
                getAlertList();
            }
          }, [activeFeature, selectVehicleData]);

          let getAlertDetails = (alertDetailsId,location) => {
            getAddress(location)
            setOpenInfoWindowMarkerId(alertDetailsId);
          };
       
          const getAddress = (location) => {
            setAddress(null);
                var requestN = {};
                requestN.latLng = location;
                return reportServices.getGeocodeAddress(requestN)
                  .then((resN) => {
                    setAddress(resN.data);
                })
                .catch((err1) => {
                console.log(err1);
                });
          } 
 
    return (
        alertList !== null && activeFeature === 3 &&
        alertList.map((item, index) => {
              return(
                <>
                <Marker
                  position={{
                    lat: parseFloat(item.latitude),
                    lng: parseFloat(item.longitude),
                  }}
                  onClick={() => getAlertDetails(index,item.location)}
                  options={{
                      zIndex:99999
                  }}
                  animation={window.google.maps.Animation.DROP}
                  icon={alertMarker}
                />
        
                {openInfoWindowMarkerId === index && (
                  <InfoWindow
                    options={{
                      pixelOffset: new window.google.maps.Size(0, -60),
                    }}
                    position={{
                      lat: parseFloat(item.latitude),
                      lng: parseFloat(item.longitude),
                    }}
                    onCloseClick={() => setOpenInfoWindowMarkerId(false)}
                  >
                    <StyledInfoWindow sx={{background:alpha(theme.palette.background.paper, .8)}}>
                    <Box
                          className='infowindowHHeader'
                          sx={{background:"#f8ea8c", width:'100%'}}
                      >
                         <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                        <Box>
                          <Typography variant='caption'  style={{color: 'black'}}> Alert</Typography>
                          <Typography variant='subtitle2'  style={{color: 'black'}}>{item?.alertType}</Typography>
                        </Box>
                    </Stack>
                      </Box>
                      <Box sx={{padding:'5px 15px', width:'100%'}}>
                      <Stack
                        direction="column"
                        justifyContent="space-between"
                        spacing={.5}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography variant="caption" sx={{ width: "25%" }}>
                            Date
                          </Typography>
                          <Typography variant="subtitle2">
                            {item?.gpsDate} 
                          </Typography>
                        </Box>
                        <Box>
                              <Typography variant='caption'>Address</Typography>
                              {
                                address !== null ? 
                                <Typography variant='subtitle2'> {address} </Typography> :
                                <Skeleton variant='subtitle2'/>
                              }
                            </Box>
                      </Stack>
                      </Box>
                    </StyledInfoWindow>
                  </InfoWindow>
                )}
      
                
              </>
              )
             
        })
    )
    
    

}

export default AlertMarker