import { CardContent } from "@mui/material";
import { useTheme } from "@mui/styles";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import {
  useAuditLogMutation,
  useGetCleanAirZoneDetailsMutation
} from "../../store/slice/ApiSlice";
import {
  StyledCardShadow,
  StyledPageLayout
} from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { DataTableNew } from "../shared/data_table/DataTableNew";
import MouseOverPopover from "../shared/PopOver";

const text =
  "This report captures the vehicle details whenever it comes " +
  '"in & out"' +
  " of the Clean Air Zone Area.";

const CAZReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let distanceUnit = userData.distanceDisplayUnit;
  let distanceValue = distanceUnit === "mi"?2:1;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const theme = useTheme();
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > GeoZone";
    auditPayload.action = "View";
    auditPayload.featureName = "Geozone In/Out";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
        show:true
      },
      {
        Header: "Vehicle No",
        accessor: "vehicleno",
        show:true
      },
     
      {
        Header: "Zone Name",
        accessor: "geoZone",
        show:true
      },
      
      {
        Header: "Zone-In-Date/Time",
        accessor: "inDateTime",
        show:true
      },
      {
        Header: "Zone-Out-Date/Time",
        accessor: "outDateTime",
        show:true
      },
      {
        Header: "Zone-In Odometer ("+distanceUnit+")",
        accessor: "startOdometer",
        show:true
      },
      {
        Header: "Zone-Out Odometer ("+distanceUnit+")",
        accessor: "endOdometer",
        show:true
      },
      {
        Header: "Vehicle Type",
        accessor: "vehicleType",
        show:true,
        Footer:'Total:'
      },
      {
        Header: "Charges (£)",
        accessor: "charges",
        show:true,
        Footer: info => {
          // Only calculate total visits if rows change
          const total = React.useMemo(
            () =>
              info.rows.reduce((sum, row) => parseInt(row.values.charges) + sum, 0),
            [info.rows]
          );

          return <> {total}</>;
        }
     
      },
     
  
    
    ],
    []
  );
  let initialState1 = {};
  var arr = [];

  

  // Get Data from New Dropdown
  const [getCleanAirZoneDetails, { data: resultData, isLoading, isSuccess }] =
    useGetCleanAirZoneDetailsMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.reselId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.custId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.size = rowsPerPage;
    // commonPayload.zoneCategory ="CAZ";
    //Service Call
    setPayload(commonPayload);
    getCleanAirZoneDetails(commonPayload);

    auditPayload.message = "Reports > Geozone In/Out > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "GeoFenceReport";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "GeoFenceReport/CAZReportCSV.csv?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&start=" +
        payload.start +
        "&length=" +
        payload.length +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&zoneCategory=" +
        payload.zoneCategory +
        "&custId=" +
        payload.custId +
        "&distanceUnit=" +
        distanceUnit +
        "&distanceDisplayOption=" +
        distanceValue;
      window.open(url);

      auditPayload.message = "Reports > Geo Fence > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Geozone In/Out";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "GeoFenceReport/GeoFencePDF.pdf?startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&search=" +
        payload.search +
        "&orderDir=" +
        payload.orderDir +
        "&orgId=" +
        payload.orgId +
        "&start=" +
        payload.start +
        "&length=" +
        payload.length +
        "&vehicleId=" +
        payload.vehicleId +
        "&reselId=" +
        payload.reselId +
        "&regionId=" +
        payload.regionId +
        "&dealerId=" +
        payload.dealerId +
        "&custId=" +
        payload.custId;
      window.open(url);

      auditPayload.message = "Reports > Geo Fence > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Geozone In/Out";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
     
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.rowsPerPage = 25;
    if (payload.search.length >= 3) {
      getCleanAirZoneDetails(payload);
    } else if (payload.search.length === 0) {
        getCleanAirZoneDetails(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getCleanAirZoneDetails(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getCleanAirZoneDetails(payload);
    }
  };



  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (resultData.data !== null) {
        setResponseData(resultData.data.content);
        setTotalElements(resultData.data.totalElements);
        setTotalPages(resultData.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Geo Zone In/Out Detail"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Geo Zone In/Out Detail"} />
      <Box>
        {/* <ReportNewDropDown
          onSubmit={handleSubmit}
          isShowVeh={true}
          displayDate={true}
          allowDays={30}
        /> */}
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = {31}
        time = {true} allowedMonth = {3}  zoneCategoryShow={false} last31={false} currentMonth={true}
        thisMonth={true} getDataLoading={isLoading}  offToday={true}  />
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTableNew
              initialState={initialState1}
              columns={columns}
              payload={payload}
              exportWithAll={true}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              handleChange={handleSearch}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default CAZReport;
