import { useState,useEffect,useMemo } from "react";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useGeoZoneGridListViewMutation } from "../../store/slice/GeozoneSlice/GeozoneSlice";
import { StyledPageLayout } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import { Breadcrumb } from "../shared/Breadcrumbs";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent } from "@mui/material";
import {StyledCardShadow} from "../../theme/StyledEle";
import { Box } from "@mui/system";


const text =
  "This report lists all the created geozones";

const GeoZoneGridListView  = (props) => {

  const {gridResponseData} = props;
  

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    const [responseData , setResponseData] = useState(gridResponseData);
    const [newPage , setNewPage] = useState(0);
    const [rowsPerPage , setRowsPerPage] = useState(25);
    const [totalElements , setTotalElements] = useState(5);
    const [totalPages , setTotalPages] = useState(1);
    const [auditLog] = useAuditLogMutation();

    let auditPayload = {
        orgId:orgId,
        userId: userId, 
        orgName: orgName,
        userName: userName,
    }

    useEffect(async () => {
        auditPayload.message = "Tracking > GeoZone > GridView";
        auditPayload.action = "View";
        auditPayload.featureName = "GeoZone Grid";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);
      }, []);

      const columns = useMemo(() =>
        [
            {
                Header: "GeoZone Name",
                accessor: "name",
            },
            {
                Header: "GeoZone Type",
                accessor: "zoneType",
            },
            {
                Header: "Radius",
                accessor: "radius",
            },
            {
                Header: "GeoZone Category",
                accessor: "zoneCategory",
            },
        ],[gridResponseData]);

  


  return (
    <StyledPageLayout>

     
      <MouseOverPopover text={text} title={"GeoZone Grid"} />
      <Box>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              exportWithAll={true}
              columns={columns}
              includeSearchBox={false}
              includeExportButton={false}
              data={gridResponseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              includePagination = {false}
            />
          </CardContent>
        </StyledCardShadow>
    </Box>
    </StyledPageLayout>
  );


};


export default GeoZoneGridListView;