import React, { useState, useEffect, useMemo } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { Typography, Paper, Divider, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import ReportDropDown from "../shared/ReportDropDown";
import MouseOverPopover from "../shared/PopOver";

const text =
  "Geo Zone report captures the vehicle details whenever it comes in {&} out of the assigned GeoZone.You can choose a date range with a maximum of 30 days.";

const GeoZoneDetail = () => {
  const [responseData, setResponseData] = useState([]);
  let userData = JSON.parse(localStorage.getItem("userData"));
  let distanceUnit = userData.distanceDisplayUnit ;
  //   useEffect(async () => {
  //     const responseData = (await ReportServices.getOverSpeed()).data.data;
  //     setResponseData(responseData);
  //   }, []);
  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "value1",
      },
      {
        Header: "Vehicle Number",
        accessor: "value2",
      },
      {
        Header: "Zone Name",
        accessor: "value3",
      },
      {
        Header: "Zone-In-Date/Time",
        accessor: "value4",
      },
      {
        Header: "Zone-Out-Date/Time",
        accessor: "value5",
      },
      {
        Header: "Zone-In Odometer( "+ distanceUnit +")",
        accessor: "value6",
      },
      {
        Header: "Battery(%)",
        accessor: "value7",
      },
    ],
    []
  );
  return (
    <Box sx={{ padding: "5px" }}>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Geo Zone In/Out Detail"
      ></Breadcrumb>
      <Box sx={{ padding: "15px" }}>
        <Paper elevation={2} sx={{ padding: "1em" }}>
          <MouseOverPopover text={text} title={"Geo Zone In/Out Detail"} />
          <Typography sx={{ fontSize: "12px" }}>
            <b>Note</b>:Geo Zone report captures the vehicle details whenever it
            comes "in {"&"} out" of the assigned GeoZone.
          </Typography>

          <Divider sx={{ borderBottomWidth: 2, mt: 1 }} />
          <Paper sx={{ backgroundColor: "#fff", marginTop: 2 }}>
            <ReportDropDown />
            <Button
              variant="contained"
              sx={{ color: "white", m: 3, mt: 3, textTransform: "none" }}
            >
              Submit
            </Button>
          </Paper>
          <Box
            display="flex"
            flexDirection="column"
            sx={{
              marginTop: 2,
            }}
          >
            <DataTable
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
            />
          </Box>
        </Paper>
      </Box>
    </Box>
  );
};

export default GeoZoneDetail;
