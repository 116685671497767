import React, { useMemo, useState, useEffect, useCallback } from "react";
import { DataTable } from "../shared/data_table/NestedTable";
import { useGetCapturedGeoZoneHistoryDetailsMutation, useUpdateLoadMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";
import { StyledDropDown } from "../../theme/StyledEle";
import { Alert, Box, Grid, Icon, InputBase, MenuItem, Snackbar, Stack, Table, TableBody, TableContainer, TableHead, TableRow, Typography, useTheme } from "@mui/material";
import { PiMinusSquareLight, PiPlusSquareLight } from "react-icons/pi";
import { BodyTableCell, HeaderTableCell, StyledTableHead, StyledTableRow } from "../shared/data_table/StyledEle";
import { TableBodyCell } from "mui-datatables";
import { useDispatch } from "react-redux";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useSelector } from "react-redux";


const CollapseRouteTripHistory = ({ routeId }) => {
  const theme = useTheme();
  const [
    getCapturedGeozonesHistory,
    {
      data: getCapturedGeozoneData,
      isLoading: capturedGeozoneDataLoading,
      isSuccess: capturedGeozoneDataSuccess,
    },
  ] = useGetCapturedGeoZoneHistoryDetailsMutation();

  const [responseData, setResponseData] = useState([]);

  const userData = JSON.parse(localStorage.getItem('userData'));
  const orgId = userData.orgId;
  let distanceUnit = userData.distanceDisplayUnit ;
  let speedUnit = userData.speedDisplayUnit ;



  const fetchSlotDetails = async () => {
    var request = {};
    request.tripId = routeId?.routeTripId;
    request.vehicleId = routeId?.vehicleId;
    if(routeId?.zoneSummary.length > 0){
      setResponseData(routeId?.zoneSummary);
    }else{
      const responseData = await getCapturedGeozonesHistory(request);
      setResponseData(responseData?.data?.data);
    }
   
  };

  useEffect(() => {
    fetchSlotDetails();
  }, [routeId]);

     //Export PDF & Excel
     const onChangeXcl = async () => {
      try {
        var url =
          process.env.REACT_APP_API_BASE_URL +
          "routeTrip/getCapturedGeoZoneDetailsXLS.xls?tripId=" +
          routeId?.id +
          "&vehicleId=" +
          routeId?.vehicleId +
          "&vehicleNo=" +
          routeId?.vehicleNo +
          "&startDate=" +
          routeId?.startTime +
          "&endDate=" +
          routeId?.endTime +
          "&orgId=" +
          orgId ;
          
        window.open(url);
  
       //  auditPayload.message = "Reports > View Route > XLS";
       //  auditPayload.action = "View";
       //  auditPayload.featureName = "View Route";
       //  auditPayload.customerName = "All";
       //  auditPayload.fleetName = "All";
       //  auditPayload.result = "Success";
       //  auditPayload.access = "Portal";
       //  auditPayload.severity = "INFO";
       //  auditLog(auditPayload);
  
        fetch(url, {
          headers: {
            Authorization:
              "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          },
        }) // FETCH BLOB FROM IT
          .then((response) => {
            response.blob();
          })
          .then((blob) => {})
          .catch((err) => {});
      } catch (error) {}
    };

    const dispatch = useDispatch();
    const isSuccess = useSelector((state) => state.controls.alert);
  const [alert, setAlert] = useState({show:false, message:null, code:0});
   const [updateLoadConditions, {data:updateLoadData, isLoading:updateLoadLoading, isSuccess:updateLoadSuccess}] = useUpdateLoadMutation();
    //Load On Change
    const loadOnChange = (event, row) => {
      updateLoad(routeId, row, event.target.value, 'status');
    }

    const loadActivityOnChange = (event, row) => {
      updateLoad(routeId, row, event.target.value, 'activity');
    }

    let message = false

    const updateLoad = async (routeId, row, value, type) => {
      let payload = {
        "tripId": routeId.routeTripId,
        "vehicleId": routeId.vehicleId,
        "sequenceNo": row.original.sequenceNumber,
        "loadCondition": value,
        "type":type
      }
      const updateLoadRes = await updateLoadConditions(payload);
      
      if(updateLoadRes?.data !== undefined && updateLoadRes?.data !== null){
        //dispatch(snackService([updateLoadRes?.data.resultMessage, updateLoadRes?.data.resultCode, true]));
        setAlert({show:true, message:updateLoadRes?.data.resultMessage, code:updateLoadRes?.data.resultCode})
      }else{
        //dispatch(snackService(['Request Failed', 0, true]));
        setAlert({show:true, message:'Request Failed', code:0})
      }
      setTimeout(() => {
        setAlert({show:false, message:null, code:0})
      },5000)
    }



    const columns = useMemo(
      () => [
        // {
        //   Header: "S.No",
        //   accessor: "sequenceNumber",
        //   width:'80',
        //   Cell:({row}) => {
        //       console.log('row', row);
        //       return row.original.zoneType === 'within'
        //   }
        // },
        {
          Header: "Geozone",
          accessor: "geoZoneName",
          width:'280',
          // Cell:({row}) => (
          //   <Stack direction={'row'} justifyContent={'space-between'} {...row.getToggleRowExpandedProps()} sx={{width:'100%'}}>
          //       <Typography variant="body2">{row.original.geoZoneName}</Typography>
          //       <Icon sx={{display:'flex'}} color="info">{row.isExpanded ? <PiMinusSquareLight  /> : <PiPlusSquareLight /> } </Icon>
          //   </Stack>
          //   // <span {...row.getToggleRowExpandedProps()}>
          //   //     {row.isExpanded ? '👇' : '👉'}
          //   // </span>
          // )
        },
        {
          Header: "Category",
          accessor: "zoneCategory",    
        },
        {
          Header: "Radius (m)",
          accessor: "radius",
        },
        {
          Header: "Entry Time",
          accessor: "entryTime",
    
        },
        {
          Header: "Exit Time",
          accessor: "exitTime",
          
        },
        {
          Header: "Planned Duration",
          accessor: "plannedDuration",
        },
        {
          Header: "Actual Duration",
          accessor: "actualDuration",
    
        },
        {
          Header: "Duration Difference",
          accessor: "durationDifference",    
        },

        {
          Header: "Running Time (hh:mm)",
          accessor: "moveMins",
        },
        {
          Header: "Start Odometer ("+distanceUnit+")",
          accessor: "startOdometer",
        },
        {
          Header: "End Odometer ("+distanceUnit+")",
          accessor: "endOdometer",
        },
        {
          Header: "Distance Traveled ("+distanceUnit+")",
          accessor: "distanceTraveled",
        },,
        {
          Header: "Max Speed ("+speedUnit+"ph)",
          accessor: "maxSpeed",
        },
        {
          Header: "Average Speed ("+speedUnit+"ph)",
          accessor: "avgSpeed",
        },
        // {
        //   Header: "Energy Consumed (kWh)",
        //   accessor: "energyConsumed",
        // },
        // {
        //   Header: "Entry SOC (%)",
        //   accessor: "startSOC",
        // },
        // {
        //   Header: "Exit SOC (%)",
        //   accessor: "endSOC",
        // },

        // {
        //   Header: "Charging Mins",
        //   accessor: "chargingMins",
        // },
        // {
        //   Header: "Energy Gained (kWh)",
        //   accessor: "gainedSOC",
        // },
        // {
        //   Header: "Battery Temperature Rise (℃)",
        //   accessor: "riseInTempZoneIn",
        // },
        // {
        //   Header: "Max Battery Temp (℃)",
        //   accessor: "maxTemp",
        // },
        // {
        //   Header: "Avg Battery Temp (℃)",
        //   accessor: "avgBatteryTempZoneIn",
        // },
        // {
        //   Header: "Max Battery Current (A)",
        //   accessor: "maxCurrent",
        // },
        // {
        //   Header: "Load Condition",
        //   accessor: "",
        //   Cell:({row}) => {
        //     return (

        //       row.original.zoneType === 'within' && (row.original.zoneCategory === 'Loading' || row.original.zoneCategory === 'UnLoading') ?
        //       <StyledDropDown size="small" defaultValue={row.original.loadCondition !== "-" ? row.original.loadCondition : "-1" } fullWidth sx={{
        //         '& .MuiOutlinedInput-notchedOutline':{
        //           borderWidth:'0px !important'
        //         }}}
        //         onChange={(event) => loadOnChange(event, row)}
        //       >
        //           <MenuItem value={'-1'}>Select</MenuItem>
        //           <MenuItem value={'empty'}>Empty</MenuItem>
        //           <MenuItem value={'semiLoad'}>Semi Load</MenuItem>
        //           <MenuItem value={'fullLoad'}>Full Load</MenuItem>
        //           <MenuItem value={'overLoad'}>Overload</MenuItem>
        //       </StyledDropDown> : '-'
        //     )
        //   }
        // },
        // {
        //   Header: "Load Activity",
        //   accessor: "",
        //   Cell:({row}) => {
        //     return (

        //       row.original.zoneType === 'within' && (row.original.zoneCategory === 'Loading' || row.original.zoneCategory === 'UnLoading') ?
        //       <StyledDropDown size="small" defaultValue={row.original.loadActivity !== "-" ? row.original.loadActivity : "-1" } fullWidth sx={{
        //         '& .MuiOutlinedInput-notchedOutline':{
        //           borderWidth:'0px !important'
        //         }}}
        //         onChange={(event) => loadActivityOnChange(event, row)}
        //       >
        //           <MenuItem value={'-1'}>Select</MenuItem>
        //           <MenuItem value={'loading'}>Loading</MenuItem>
        //           <MenuItem value={'unloading'}>Unloading</MenuItem>
        //           <MenuItem value={'charging'}>Charging</MenuItem>
        //       </StyledDropDown> : '-'
        //     )
        //   }
        // },
    // {
    //   Header: "Zone Between",
    //     columns: [
    // // {
    // //   Header: "Entry Time",
    // //   accessor: "startTimeZoneBetween",

    // // },
    // // {
    // //   Header: "Exit Time",
    // //   accessor: "endTimeZoneBetween",
      
    // // },
    // // {
    // //   Header: "Planned Duration",
    // //   accessor: "plannedDuration",
    // // },
    // // {
    // //   Header: "Actual Duration",
    // //   accessor: "duration",

    // // },
    // // {
    // //   Header: "Duration Difference",
    // //   accessor: "routeDeviationDurationZoneIn",    
    // // },
    // {
     
    //     Header: "Total Duration",
    //     accessor: "actualDurationZoneBetween",
    
        
    // },
    // {
    //   Header: "Running Time (hh:mm)",
    //   accessor: "moveMinsZoneBetween",
    // },
    // {
    //   Header: "Start Odometer (km)",
    //   accessor: "startCanOdometerZoneBetween",
    // },
    // {
    //   Header: "End Odometer (km)",
    //   accessor: "endCanOdometerZoneBetween",
    // },
    // {
    //   Header: "Distance Traveled (km)",
    //   accessor: "canDistanceTraveledZoneBetween",
    // },
    // {
    //   Header: "Max Speed (km/h)",
    //   accessor: "maxCanSpeedZoneBetween",
    // },
    // {
    //   Header: "Average Speed (km/h)",
    //   accessor: "avgCanSpeedZoneBetween",
    // },
    // {
    //   Header: "Energy Consumed (kWh)",
    //   accessor: "energyConsumedZoneBetween",
    // },
    // {
    //   Header: "Entry SOC (%)",
    //   accessor: "startSOCZoneBetween",
    // },
    // {
    //   Header: "Exit SOC (%)",
    //   accessor: "endSOCZoneBetween",
    // },

    // {
    //   Header: "Charging Mins",
    //   accessor: "chargingSummaryMinsZoneBetween",
    // },
    // {
    //   Header: "Energy Gained (kWh)",
    //   accessor: "gainedSocSummaryMinsZoneBetween",
    // },
    // {
    //   Header: "Battery Temperature Rise (℃)",
    //   accessor: "riseInTempZoneBetween",
    // },
    // {
    //   Header: "Max Battery Temp (℃)",
    //   accessor: "maxBatteryTempZoneBetween",
    // },
    // {
    //   Header: "Avg Battery Temp (℃)",
    //   accessor: "avgBatteryTempZoneBetween",
    // },
    // {
    //   Header: "Max Battery Current (A)",
    //   accessor: "maxBatteryCurrentZoneBetween",
    // },
    //     ]
    //   },
      ],
      []
    );

    let tableCelStyle = {
      padding:'6px',
      borderRight:`.5px solid ${theme.palette.divider}`,
    }
    let tableBodyCelStyle = {
      padding:'6px',
      borderRight:`.5px solid ${theme.palette.divider}`,
      background:theme.palette.background.paper,
    }

    //Expand 
    const renderSubRowCompoent = useCallback(({row}) => {
      return (
        <Box>
            <TableContainer sx={{marginLeft:'80px'}}>
                <Table
                  sx={{ overflowX: "scroll", borderCollapse: "separate"}}
                >
                    <StyledTableHead sx={{background:'#e0e0e0'}}>
                        <tr>
                            <td style={tableCelStyle}>Total Duraion</td>
                            <td style={tableCelStyle}>Running Time (hh:mm)</td>
                            <td style={tableCelStyle}>Start Odometer ({distanceUnit})</td>
                            <td style={tableCelStyle}>End Odometer ({distanceUnit})</td>
                            <td style={tableCelStyle}>Distance Traveled ({distanceUnit})</td>
                            <td style={tableCelStyle}>Max Speed ({speedUnit}ph)</td>
                            <td style={tableCelStyle}>Average Speed ({speedUnit}ph)</td>
                            <td style={tableCelStyle}>Energy Consumed (kWh)</td>
                            <td style={tableCelStyle}>Entry SOC (%)</td>
                            <td style={tableCelStyle}>Exit SOC (%)</td>
                            <td style={tableCelStyle}>Charging Mins</td>
                            <td style={tableCelStyle}>Energy Gained (kWh)</td>
                            <td style={tableCelStyle}>Battery Temperature Rise (℃)</td>
                            <td style={tableCelStyle}>Max Battery Temp (℃)</td>
                            <td style={tableCelStyle}>Avg Battery Temp (℃)</td>
                            <td style={tableCelStyle}>Max Battery Current (A)</td>
                        </tr>
                    </StyledTableHead>
                    <TableBody className="nestedBody">
                        <tr>
                            <td style={tableBodyCelStyle}>{row.original.actualDurationZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.moveMinsZoneBetween}</td>
                            <td  style={tableBodyCelStyle}>{row.original.startCanOdometerZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.endCanOdometerZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.canDistanceTraveledZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.maxCanSpeedZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.avgCanSpeedZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.energyConsumedZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.startSOCZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.endSOCZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.chargingSummaryMinsZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.gainedSocSummaryMinsZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.riseInTempZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.maxBatteryTempZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.avgBatteryTempZoneBetween}</td>
                            <td style={tableBodyCelStyle}>{row.original.maxBatteryCurrentZoneBetween}</td>
                        </tr>
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
      )
    },[]) 


  
  return (
    <>
      <DataTable
      columns={columns}
      data={responseData}
      styles={{ maxWidth: "calc(100% - 65% )", overflowX:'hidden'}}
      numberOfStickyColumns={1}
      onChangeXcl={onChangeXcl}
      includeExportButton={false}
      renderSubRowCompoent={renderSubRowCompoent}
      />
      {
        <Snackbar open={alert.show}
        anchorOrigin={{ vertical:'top', horizontal:'center' }}
        >
          <Alert
            severity={alert.code === 0 ? 'success' : 'error'}
          >
            <Typography>{alert.message}</Typography>
           
          </Alert>
        </Snackbar>
      }
    </>
  );
};

export default CollapseRouteTripHistory;
