import { Backdrop, Box, Button, Dialog, DialogContent, Fab, Icon, Paper, Stack, Typography, alpha, useTheme } from "@mui/material";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { SoftButton, StyledPaperShadow } from "../../theme/StyledEle";
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from "@react-google-maps/api";
import { useSelector } from "react-redux";
import { Dark, Day } from "../../theme/gmapTheme";
import { GOOGLE_MAP_KEY } from "../../constants/appConstant";
import { GridLoader } from "react-spinners";
import { useShareLocationMutation } from "../../store/slice/TestRideSlice/TestRideSlice";
import { useLocation, useSearchParams } from "react-router-dom";
import { FaPlugCircleBolt, FaRegBell, FaRoad } from "react-icons/fa6";
import { RiEmotionSadLine } from "react-icons/ri";
import { StyledInfoWindow } from "../NewTracking/Treeview/TreeviewStyledEle";
import { FaParking } from "react-icons/fa";
import { MdSignalWifiBad } from "react-icons/md";
import { MovingBike, OfflineBike, IdleBike, StopBike, ChargingBike,  MovingAuto, OfflineAuto, IdleAuto, StopAuto, ChargingAuto, MovingTruck, OfflineTruck, IdleTruck, StopTruck, ChargingTruck, ChargingGifIcon, StopCar, ChargingCar, MovingCar, OfflineCar, MovingScvm, OfflineScvm, IdleScvm, StopScvm, ChargingScvm, MovingTractorm, OfflineTractorm, IdleTractorm, StopTractorm, ChargingTractorm, IdleCar  } from '../Constans';
import { BsStoplightsFill } from "react-icons/bs";


function ShareLocation() {
  const [mapzoom, setMapzoom] = useState(5);
  const [map, setMap] = React.useState(null);
  const [mapType, setMapType] = useState("roadmap");
  const [defaultCenter, setDefaultCenter] = useState({
    lat: 25.9124,
    lng: 74.7873,
  });
  const [libraries] = useState(["drawing", "places", "geometry"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
    //version:'weekly'
  });


  //currentMarker
  const currentMarkerRef = useRef();
    //map style
    const mapContainerStyle = {
      height: "100%",
      position: "relative",
      width: "100%",
    };
  
    const onLoad = useCallback(
      function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(defaultCenter);
        map.fitBounds(bounds);
        setMap(map);
        setMapzoom(14);
      },
      []
    );

    
    const options = {
      zoom: mapzoom,
      center: defaultCenter,
      minZoom: 5,
      //maxZoom:24,
      fullscreenControl: false,
      streetViewControl: false,
      zoomControl: false,
      mapTypeControl: false,
      disableDefaultUI: false,
      featureType: "poi.business",
      stylers: [{ visibility: "off" }],
      featureType: "transit",
      elementType: "labels.icon",
      stylers: [{ visibility: "off" }],
      featureType: "poi",
      stylers: [{ visibility: "off" }],
      tilt: 47.5,
      heading: 320,
    };




    // let icon = {
    //     url:MovingTruck,
    //     scale:1,
    //     fillOpacity: 1,
    // }

    const location = useLocation();
    const theme = useTheme();
    const [vehicleData, setVehicleData] = useState(null);
    const [errorCode, setErrorCode] = useState(null);
    const [errorMsg, setErrorMsg] = useState(null);
    const [show, setShow] = useState(true);
    let [vehicle, setVehicle] = useState(null);

    ///Slice
    const [shareLocation, {data, isLoading, isSuccess}] = useShareLocationMutation();
  

    //get VehicleDetail
    const getVehicleDetail = async() => {
        setMapzoom(5);
        let query = location.search;
        const params = new URLSearchParams(query)
        let payload = {
            trackApiKey:params.get('token'),
        }
        const vehicleDetails = await shareLocation(payload);
        setErrorCode(vehicleDetails?.data?.resultCode);
        setVehicleData(vehicleDetails?.data?.data);
        if(vehicleDetails?.data?.resultCode === 0){
            if(vehicleData?.latitude === null){
                setErrorCode(1);
                setErrorMsg('Vehicle not found');
                setMapzoom(5);
            }
        }else{
          setErrorCode(1);
          setErrorMsg('Link Expired');
          setMapzoom(5);
        }
        //setDefaultCenter({lat:parseFloat(vehicleDetails.data.data.latitude), lng:parseFloat(vehicleDetails.data.data.longitude)});
        //map.panTo({lat:parseFloat(vehicleDetails.data.data.latitude), lng:parseFloat(vehicleDetails.data.data.longitude)});
      
    }
    
    const getVehicleType = (VehicleType) => {
      switch(VehicleType){
        case 'TwoWheeler':
          setVehicle(2);
        break;
        case 'ThreeWheeler':
          setVehicle(3);
      break;
        case 'Truck':
          setVehicle(4);
        break;
        case 'Car':
          setVehicle(5);
        break;
        case 'Scv':
          setVehicle(6);
        break;
        case 'Tractor':
          setVehicle(7);
        break;
        case 'Auto':
          setVehicle(3);
        break;
        default:
          setVehicle(2);
      }
    }

    useEffect(() => {
      if(vehicleData !== null && vehicleData?.latitude !== null && vehicleData?.latitude !==""){
        setDefaultCenter({lat:parseFloat(vehicleData?.latitude), lng:parseFloat(vehicleData?.longitude)});
        //map.panTo({lat:parseFloat(vehicleData?.latitude), lng:parseFloat(vehicleData?.longitude)});
        getVehicleType(vehicleData?.vehType);
      }
    },[vehicleData])


    useEffect(() => {
        if(location.search !== ''){
            getVehicleDetail();
        }
    },[location.search])


    const BackgroundStyle = {
      background: 
      vehicleData?.vehStatus === 'Stop' || vehicleData?.vehStatus === '0' ? alpha(theme.palette.error.main, .9) : 
      vehicleData?.vehStatus === 'Idle' || vehicleData?.vehStatus === '2'? alpha(theme.palette.info.main, .9) : 
      vehicleData?.vehStatus === 'Offline' || vehicleData?.vehStatus === '4'? alpha(theme.palette.grey[700], .9) : 
      vehicleData?.vehStatus === 'Moving' || vehicleData?.vehStatus === '1'? alpha(theme.palette.success.main, .9) : alpha(theme.palette.grey[700], .9)
    }

    //Infowindow Close Event
    const onClose = () => {
      //setSelectMarker(null);
    }

  const maps = useMemo(() => {
    return isLoaded ? (
      <GoogleMap
        options={{
          mapTypeId: mapType,
          ...options,
        }}
        zoom={mapzoom}
        mapContainerStyle={mapContainerStyle}
        onLoad={onLoad}
      >
    {
      vehicleData !== null && vehicleData?.latitude !== null && vehicleData?.latitude !=="" &&
        <Marker
          id={vehicleData.vehId}
          position={{lat:parseFloat(vehicleData?.latitude), lng:parseFloat(vehicleData?.longitude)}}
          ref={currentMarkerRef}
          clickable
                      //icons based on vehicle type
                      icon={
                        vehicle === 2 ? (vehicleData?.vehStatus==="0" || vehicleData?.vehStatus==="Stop") ? StopBike : (vehicleData?.vehStatus==="2" || vehicleData?.vehStatus==="Idle") ? IdleBike : (vehicleData?.vehStatus===1 || vehicleData?.vehStatus==="Moving") ? MovingBike : (vehicleData?.vehStatus==="4" || vehicleData?.vehStatus==="Offline") ? OfflineBike : OfflineBike : 
            
                        vehicle === 3 ? (vehicleData?.vehStatus==="0" || vehicleData?.vehStatus==="Stop") ? StopAuto : (vehicleData?.vehStatus==="2" || vehicleData?.vehStatus==="Idle") ? IdleAuto : (vehicleData?.vehStatus===1 || vehicleData?.vehStatus==="Moving") ? MovingAuto : (vehicleData?.vehStatus==="4" || vehicleData?.vehStatus==="Offline") ? OfflineAuto : OfflineAuto :
            
                        vehicle === 4 ? (vehicleData?.vehStatus==="0" || vehicleData?.vehStatus==="Stop") ? StopTruck : (vehicleData?.vehStatus==="2" || vehicleData?.vehStatus==="Idle") ? IdleTruck : (vehicleData?.vehStatus===1 || vehicleData?.vehStatus==="Moving") ? MovingTruck : (vehicleData?.vehStatus==="4" || vehicleData?.vehStatus==="Offline") ? OfflineTruck : OfflineTruck :
            
                        vehicle === 5 ? (vehicleData?.vehStatus==="0" || vehicleData?.vehStatus==="Stop") ? StopCar : (vehicleData?.vehStatus==="2" || vehicleData?.vehStatus==="Idle") ? IdleCar : (vehicleData?.vehStatus===1 || vehicleData?.vehStatus==="Moving") ? MovingCar : (vehicleData?.vehStatus==="4" || vehicleData?.vehStatus==="Offline") ? OfflineCar : OfflineCar :
            
                        vehicle === 6 ? (vehicleData?.vehStatus==="0" || vehicleData?.vehStatus==="Stop") ? StopScvm : (vehicleData?.vehStatus==="2" || vehicleData?.vehStatus==="Idle") ? IdleScvm : (vehicleData?.vehStatus===1 || vehicleData?.vehStatus==="Moving") ? MovingScvm : (vehicleData?.vehStatus==="4" || vehicleData?.vehStatus==="Offline") ? OfflineScvm : OfflineScvm : 
            
                        vehicle === 7 ? (vehicleData?.vehStatus==="0" || vehicleData?.vehStatus==="Stop") ? StopTractorm : (vehicleData?.vehStatus==="2" || vehicleData?.vehStatus==="Idle") ? IdleTractorm : (vehicleData?.vehStatus===1 || vehicleData?.vehStatus==="Moving") ? MovingTractorm : (vehicleData?.vehStatus==="4" || vehicleData?.vehStatus==="Offline") ? OfflineTractorm : OfflineTractorm ? '' : '' : ''
                        }

          onClick={() => setShow(true)}
      />
    }
    
    {
      vehicleData !== null && vehicleData?.latitude !== null && show &&
      <InfoWindow
      position={{lat:parseFloat(vehicleData?.latitude), lng:parseFloat(vehicleData?.longitude)}}
      options={{pixelOffset: new window.google.maps.Size(0,-80)}}
      onCloseClick={() => setShow(false)}
   >
       <StyledInfoWindow sx={{width:'100% !important'}}>
             <Box 
                 className='infowindowHHeader'
                 sx={{...BackgroundStyle, width:'100%'}}
             >
                        <Stack spacing={2} direction={'row'} alignItems={'baseline'}>
                          <Box>
                            <Typography variant='caption'>Vehicle Name </Typography>
                            <Typography variant='subtitle2'>{vehicleData?.vehName}</Typography>
                          </Box>
                          <Fab disableTouchRipple size='small' 
                          sx={BackgroundStyle} 
                          >
                             <Icon>
                             {
                              vehicleData?.vehStatus === 'Stop' || vehicleData?.vehStatus === '0' ? <FaParking />: 
                              vehicleData?.vehStatus === 'Idle' || vehicleData?.vehStatus === '2'? <BsStoplightsFill/> : 
                              vehicleData?.vehStatus === 'Offline' || vehicleData?.vehStatus === '4'? <MdSignalWifiBad /> : 
                              vehicleData?.vehStatus === 'Moving' || vehicleData?.vehStatus === '1'? <FaRoad /> : <FaParking />
                            }
                             </Icon>
                          </Fab>
                      </Stack>
             </Box>
             <Box sx={{padding:'5px 15px', width:'100%'}}>
                      <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{width:'100%'}}>
                          <Box sx={{width:'100%'}}> 
                              <Stack direction={'column'} spacing={.5}>
                                  <Box>
                                    <Typography variant='caption'>Last Connected</Typography>
                                    <Typography variant='subtitle2'>{vehicleData?.vehConnectedTime}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant='caption'>Vehicle Status</Typography>
                                    <Typography variant='subtitle2'>{vehicleData?.vehStatus}</Typography>
                                  </Box>
                                  <Box>
                                    <Typography variant='caption'>Address</Typography>
                                      <Typography variant='subtitle2'>{vehicleData?.vehAddress}</Typography>
                                  </Box>
                              </Stack>
                          </Box>
                      </Stack>
                  </Box>
       </StyledInfoWindow>
   </InfoWindow>
    }

   


    </GoogleMap>
    ) : (
      <Backdrop>
            <GridLoader />
      </Backdrop>
    );
  }, [isLoaded, vehicleData, defaultCenter,show]);

  //Expired Component
  const Expired = () => {
    return(
        <Paper sx={{background:theme.palette.background.paper, padding:'10px 15px',}}>
            <Icon 
            sx={{
                width:'50px', 
                height:'50px', 
                background:alpha(theme.palette.error.main,.1),
                display:'flex',
                alignItems:'center',
                justifyContent:'center',
                margin:'auto',
                color:theme.palette.error.main,
                borderRadius:'50px', 
                fontSize:'32px'
                }}>
                <RiEmotionSadLine />
            </Icon>
            <Typography variant="h6" sx={{textAlign:'center', mt:1}}>
                {errorMsg}
            </Typography>
            {/* <Button sx={{margin:'auto', display:'flex', mt:1}} variant="outlined" color="error" onClick={getVehicleDetail}></Button> */}
        </Paper>
    )
  }



  return (
  <Box sx={{width:"100vw",height:"100vh"}}>
    {/* <Stack direction={'row'} justifyContent={'flex-end'} sx={{width:'100%', position:'fixed', zIndex:99, p:2}}>
      <SoftButton variant="contained" className="primary" >Refresh</SoftButton>
    </Stack> */}
        {maps}
    <Dialog open={errorCode !== 0 ? true : false} maxWidth={'xs'} fullWidth 
        PaperProps={{
            sx:{
                boxShadow:'0px 0px 8px #d5d5d5',
                border:'.5px solid #ddd',
                background:'transparent',
                borderRadius:'15px',
            }
        }}
        hideBackdrop
    >
        {<Expired />}
    </Dialog>
  </Box>

);
}

export default ShareLocation;
