import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Icon,
  IconButton,
  InputBase,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useState } from "react";
import { HiOutlineCog } from "react-icons/hi2";
import { CiSearch } from "react-icons/ci";
import { useSelector } from "react-redux";
import { StyledSidebarListBtn, StyledSwitch } from "../../../theme/StyledEle";
import { event } from "jquery";
import { MdClose } from "react-icons/md";
import { searchKeyWord } from "../../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";
function TreeviewControls(props) {
  const theme = useTheme();
  const { searchVehicle, searchKey, autoRef, autoRefresh, vehicleTrails, geoZone, activeFeature, handleFeatureChange, showTrails, handleTrails, ClearSeachText, alerts, showPoly, handlePoly } = props;


  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let orgType = userData.userOrgType;


  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleSettings = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  //search keyword
  const isSearchKeyword = useSelector(
    (state) => state.controls.treeview.searchKeyWord
  );


  return (
    <Box sx={{ borderBottom: `.2px solid ${theme.palette.divider}` }}>
      <Paper elevation={0} sx={{ px: 0.5, py: 0.5 }}>
        <Stack direction={"row"} divider={<Divider />}>
          <IconButton disabled>
            <CiSearch />
          </IconButton>
          <Stack direction={"row"} sx={{ width: '100%' }} alignItems={'center'}>
            <InputBase
              placeholder="Search vehicle"
              sx={{ width: "100%" }}
              value={isSearchKeyword}
              onChange={(event) => searchVehicle(event)}
            />
            {
              isSearchKeyword !== null && isSearchKeyword.length > 0 &&
              <IconButton size="small" onClick={() => ClearSeachText()}>
                <MdClose />
              </IconButton>
            }

          </Stack>

          <IconButton
            onClick={handleSettings}
            id="map_settings"
            aria-controls={open ? "basic-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <HiOutlineCog />
          </IconButton>
        </Stack>
      </Paper>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: "20px",
              left: "-6px",
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
            "& .MuiList-root": {
              padding: "0px",
            },
          },
        }}
        transformOrigin={{ horizontal: "left", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <ListItem dense={true} sx={{ px: 0, py: 0 }}>
          <FormControlLabel
            sx={{ m: 0, ml: '15px' }}
            label={<Typography variant="body2" color="textSecondary" sx={{ "&:hover": { color: "black" } }}>Auto Refresh 3 mins</Typography>}
            control={<Checkbox
              edge='start'
              inputProps={{ 'aria-labelledby': 'settings', 'aria-label': 'controlled' }}
              size='small'
              onChange={(event) => handleFeatureChange(event.target.checked, 0)}
              checked={activeFeature === 0 ? true : false} />}
          />
        </ListItem>

        {
          vehicleTrails !== null &&
          <ListItem dense={true} sx={{ px: 0, py: 0 }}>
            <FormControlLabel
              sx={{ m: 0, ml: '15px' }}
              label={<Typography variant="body2" color="textSecondary" sx={{ "&:hover": { color: "black" } }}>Show Geo Zone</Typography>}
              control={<Checkbox
                edge='start'
                inputProps={{ 'aria-labelledby': 'geoZone', 'aria-label': 'controlled' }}
                size='small'
                onChange={(event) => handleFeatureChange(event.target.checked, 1)}
                checked={activeFeature === 1 ? true : false} />}
            />
          </ListItem>

        }
        {
          vehicleTrails !== null &&
          <ListItem dense={true} sx={{ px: 0, py: 0 }}>
            <FormControlLabel
              sx={{ m: 0, ml: '15px' }}
              label={<Typography variant="body2" color="textSecondary" sx={{ "&:hover": { color: "black" } }}>Show Routes</Typography>}
              control={<Checkbox
                edge='start'
                inputProps={{ 'aria-labelledby': 'routeTrip', 'aria-label': 'controlled' }}
                size='small'
                onChange={(event) => handleFeatureChange(event.target.checked, 2)}
                checked={activeFeature === 2 ? true : false} />}
            />
          </ListItem>
        }
        {
          vehicleTrails !== null &&
          <ListItem dense={true} sx={{ px: 0, py: 0 }}>
            <FormControlLabel
              sx={{ m: 0, ml: '15px' }}
              label={<Typography variant="body2" color="textSecondary" sx={{ "&:hover": { color: "black" } }}>Show Alerts</Typography>}
              control={<Checkbox
                edge='start'
                inputProps={{ 'aria-labelledby': 'alerts', 'aria-label': 'controlled' }}
                size='small'
                onChange={(event) => handleFeatureChange(event.target.checked, 3)}
                checked={activeFeature === 3 ? true : false} />}
            />
          </ListItem>
        }
        <Divider />
        {
          vehicleTrails !== null &&
          <ListItem dense={true} sx={{ px: 1, py: 1 }}>
            <FormControlLabel
              sx={{ m: 0, ml: '4px' }}
              label={<Typography variant="body2" color="textSecondary" sx={{ "&:hover": { color: "black" } }}>Show Waypoints</Typography>}
              control={<StyledSwitch
                onChange={(event) => handleTrails(!showTrails)}
                checked={showTrails}
                sx={{ mr: 1 }}
              />}
            />
          </ListItem>
        }
        {
          vehicleTrails !== null &&
          <ListItem dense={true} sx={{ px: 1, py: 1 }}>
            <FormControlLabel
              sx={{ m: 0, ml: '4px' }}
              label={<Typography variant="body2" color="textSecondary" sx={{ "&:hover": { color: "black" } }}>Show Polyline</Typography>}
              control={<StyledSwitch
                onChange={(event) => handlePoly(!showPoly)}
                checked={showPoly}
                sx={{ mr: 1 }}
              />}
            />
          </ListItem>
        }
      </Menu>
    </Box>
  );
}

export default TreeviewControls;
