import { CardContent, CardHeader, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  useAuditLogMutation,
  useOrgDeleteMutation,
  useOrgListMutation
} from "../../store/slice/ApiSlice";
import { snackService } from "../../store/slice/ControlsSlice";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import StyledModel from "../../Utils/StyledModel";
import AdminDropdown from "../AdminDropdown/AdminDropdown";
import { DataTable } from "../shared/data_table/DataTable";

const ListOrganization = ({ onViewOrg, onEditOrg, menuUser }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;
  let userorgType = userData.userOrgType;
  let orgType = userData.userOrgType;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);
  const [deleteItem, setDeleteItem] = useState(null);

  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [loading, setLoading] = useState("");

  const columns = useMemo(
    () => [
      {
        Header: "Org Type",
        accessor: "orgTypeName",
      },
      {
        Header: "Region",
        accessor: "region",
        show: false
      },
      {
        Header: "Dealer",
        accessor: "dealerName",
        show:false
      },
      {
        Header: "Customer",
        accessor: "customerName",
        show:orgType !== 3 && true
      },
      // {
      //   Header: "Organization Name",
      //   accessor: "orgName",
      // },
      // {
      //   Header: "User Name",
      //   accessor: "userName",
      // },
      {
        Header: "Email",
        accessor: "orgBizEmail",
      },
      {
        Header: "State",
        accessor: "stateName",
      },
      {
        Header: "City",
        accessor: "cityName",
      },
      {
        Header: "Phone Number",
        accessor: "orgPhoneNumber",
      },
      {
        Header: "ZipCode",
        accessor: "orgZip",
      },
      {
        Header: "Created Date ",
        accessor: "orgCreatedDate",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
      },
    ],
    []
  );
  let initialState1 = {};
  var arr = [];  
  if (orgType === 3) {
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 5 || orgType===2){
    arr.push("region");
    arr.push("dealerName");
    initialState1.hiddenColumns = arr;
  }else if(orgType === 1){
    arr.push("regionName");
    arr.push("dealerName");
    arr.push("customerName");
    arr.push("fleetName");
    initialState1.hiddenColumns = arr;
  }

  // Get Data from New Dropdown
  const [getGridViewForOrg, { data: organizationdata, isLoading, isSuccess }] =
    useOrgListMutation();
  let commonPayload = {};
  const getData = (data) => {

    commonPayload.orgId = orgId;
    commonPayload.orgType = roleId === 1 ? data.orgnizationType : data.orgType;
    commonPayload.searchText = "";
    commonPayload.columnName = "CREATED_DATE";
    commonPayload.orderDir = "desc";
    commonPayload.orgfleet = data.fleet;
    commonPayload.custrec = roleId === 1 ? 1 : data.customer;
    commonPayload.status = 1;
    commonPayload.reslId = roleId === 1 ? data.orgnization : resellerId;
    commonPayload.fleetParentId = data.fleet;
    commonPayload.selectOrgId = orgId;
    commonPayload.dealerId = roleId === 1 ? 0 : data.dealer;
    commonPayload.regionId = roleId === 1 ? 0 : data.region;
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;

    commonPayload.customerId = data.customer;
    commonPayload.regionorgType = data.orgType;
    commonPayload.roleId = roleId;
    commonPayload.selectedOrgId = -1;
    commonPayload.seltdResellerId = resellerId;
    commonPayload.seltdLastOrgId = -1;
    commonPayload.seltdOrgTypeId = data.orgType;

    //Service Call
    setPayload(commonPayload);
    getGridViewForOrg(commonPayload);


    auditPayload.message = "Admin > Organization > List Organization > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.userOrganizationType+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);

 
  };
  useEffect(async () => {
    auditPayload.message = "Admin > Organization > List Organization";
    auditPayload.action = "View";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  }, []);

  //Search Text
  const handleSearch = async (searchText) => {
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.size = 25;
    payload.searchText = searchText;
    if (payload.searchText.length >= 3) {
      getGridViewForOrg(payload);
    } else if (payload.searchText.length === 0) {
      getGridViewForOrg(payload);
    }
  
    
};
   //Page Change
   const pageChange = (newPage, rowsPerPage) => {

    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getGridViewForOrg(payload);
  };
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (organizationdata.data !== null) {
        setResponseData(organizationdata.data.content);
        setTotalElements(organizationdata.data.totalElements);
        setTotalPages(organizationdata.data.totalPages);
      }
      var dataStr = {};
      dataStr.edit = false;
      dataStr.delete = false;
      dataStr.view = false;
      dataStr.excel = false;
      dataStr.pdf = false;
      var strval;
      strval = menuUser.split(",");
      for (var i = 0; i < strval.length; i++) {
        if (parseInt(strval[i]) === 4) {
          dataStr.edit = true;
        }
        if (parseInt(strval[i]) === 2) {
          dataStr.view = true;
        }
        if (parseInt(strval[i]) === 3) {
          dataStr.delete = true;
        }
        if (parseInt(strval[i]) === 6) {
          dataStr.pdf = true;
        }
        if (parseInt(strval[i]) === 5) {
          dataStr.excel = true;
        }
      }
      setCommonFilter(dataStr);
    }
  }, [isSuccess]);


  const onView = async (handleOrgView) => {
    onViewOrg(handleOrgView);

    auditPayload.message = "Admin > Organization > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const onEdit = (itemEdited) => {
    onEditOrg(itemEdited);

    auditPayload.message = "Admin > Organization > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "Organization";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const [
    delteItem,
    {
      data: deleteData,
      isLoading: isDeleteLoading,
      isSuccess: isDeleteSuccess,
    },
  ] = useOrgDeleteMutation();
  const deleteItems = () => {
    let payload = {
      orgId: deleteItem.id,
      orgType:deleteItem.orgType
    };
    delteItem(payload);
  };
  const dispatch = useDispatch();
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );

      getGridViewForOrg(payload);
    }
  }, [isDeleteSuccess]);

  const onDelete = async (itemDeleted) => {
    setDeleteItem(itemDeleted);
  };

  const onChangeXcl = async () => {

    payload.timeZoneValue = "+05:30";

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "organization/organizationListXLS.csv?resellerId=" +
        payload.reslId +
        "&reslId=" +
        payload.reslId +
        "&orgId=" +
        payload.orgId +
        "&searchText=" +
        payload.searchText +
        "&columnName=" +
        payload.columnName +
        "&orderDir=" +
        payload.orderDir +
        "&orgfleet=" +
        payload.orgfleet +
        "&custrec=" +
        payload.custrec +
        "&status=" +
        payload.status +
        "&timeZoneValue=" +
        payload.timeZoneValue +
        "&selectedOrgId=" +
        payload.selectedOrgId +
        "&fleetParentId=" +
        payload.fleetParentId +
        "&dealerId=" +
        payload.dealerId +
        "&customerId=" +
        payload.customerId +
        "&regionorgType=" +
        payload.regionorgType +
        "&roleId=" +
        payload.roleId +
        "&regionId=" +
        payload.regionId +
        "&orgType=" +
        payload.orgType;
      window.open(url);

      auditPayload.message = "Admin > Organization > List > Export >Excel";
      auditPayload.action = "Download";
      auditPayload.featureName = "Organization";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
    } catch (error) {}
  };

  return (
    <Box>
      <AdminDropdown
        getData={getData}
        regionDrop={true}
        dealerDrop={true}
        customerDrop={true}
        orgDrop={true}
        hideOrg={false}
      />
      <Box>

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Organization List</StyledTableTitle>}
          />
          <CardContent sx={{ py: 0 }}>
            <DataTable
               initialState={initialState1}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              // onChangePdf={onChangePDF}
              handleChange={handleSearch}
              onDelete={onDelete}
              onView={onView}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
              commonFilter={commonFilter}
              onEdit={onEdit}
            />
          </CardContent>
        </StyledCardShadow>
        {deleteItem !== null && (
          <StyledModel data={deleteItem} deleteItem={deleteItems}>
            <Box sx={{ px: 2 }}>
              <Typography sx={{ textAlign: "center", my: 2 }}>
                Org Name: {deleteItem.orgName}
              </Typography>
            </Box>
          </StyledModel>
        )}
      </Box>

    </Box>
  );
};

export default ListOrganization;
