import React, { useRef, useState, useEffect } from 'react'
import ReactEcharts from "echarts-for-react"
function Line() {
    const lineRef = useRef();
    const [data, setData] = useState([])
    let now = new Date(1997, 9, 9);
    let oneDay = 24 * 3600 * 1000;
    let value = Math.random() * 1000;
    
    function randomData() {
        now = new Date(+now + oneDay);
        value = value + Math.random() * 21 - 10;
        return {
          name: now.toString(),
          value: [
            [now.getFullYear(), now.getMonth() + 1, now.getDate()].join('/'),
            Math.round(value)
          ]
        };
      }

      const datas = [
        {
            "name": "Fri Oct 10 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/10",
                762
            ]
        },
        {
            "name": "Sat Oct 11 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/11",
                768
            ]
        },
        {
            "name": "Sun Oct 12 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/12",
                762
            ]
        },
        {
            "name": "Mon Oct 13 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/13",
                764
            ]
        },
        {
            "name": "Tue Oct 14 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/14",
                757
            ]
        },
        {
            "name": "Wed Oct 15 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/15",
                750
            ]
        },
        {
            "name": "Thu Oct 16 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/16",
                748
            ]
        },
        {
            "name": "Fri Oct 17 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/17",
                743
            ]
        },
        {
            "name": "Sat Oct 18 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/18",
                739
            ]
        },
        {
            "name": "Sun Oct 19 1997 00:00:00 GMT+0530 (India Standard Time)",
            "value": [
                "1997/10/19",
                742
            ]
        }
    ]
    var arr = [];
    const options = {
        tooltip: {
          trigger: 'axis',
          formatter: function (params) {
            params = params[0];
            var date = new Date(params.name);
            return (
              date.getDate() +
              '/' +
              (date.getMonth() + 1) +
              '/' +
              date.getFullYear() +
              ' : ' +
              params.value[1]
            );
          },
          axisPointer: {
            animation: false
          }
        },
        xAxis: {
          type: 'time',
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          splitLine: {
            show: false
          }
        },
        series: [
          {
            name: 'Fake Data',
            type: 'line',
            showSymbol: false,
            data: arr
          }
        ]
      };



    //states
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        for (var i = 0; i < 10; i++) {
            const timeData = randomData();
            setData(oldArray => [...oldArray, timeData])
        }

        setTimeout(() => {
            setLoading(false);
        },3000)
      },[])

   
      useEffect(() => {
        if(lineRef.current !== undefined){
            setInterval(function () {
                for (var i = 0; i < 1; i++) {
                    const timeData = randomData();
                    //setData.push(push);
                    //console.log('timeData', timeData);
                    //arr.shift();
                    //setData([timeData]);
                    arr.push(timeData)
                    // lineRef.current.getEchartsInstance().setOption({
                    //     series: [
                    //       {
                    //         data: arr
                    //       }
                    //     ]
                    //   })
                    
                    
                }
                // console.log('timeData', arr);
            }, 1000);
        }
      },[lineRef.current]);
   

  return (
        <ReactEcharts
            ref={lineRef}
            option={options}
            style={{ width: "100%", height: `300px` }}
            renderer={'svg'}
            //onChartReady={onChartReadyCallback}
            //onEvents={onClickEvent}
            showLoading={loading}
            lazyUpdate={true}
            notMerge={true}
            shouldSetOption={true}
        /> 
  )
}

export default Line