import React, {useEffect, useState } from 'react'
import { TollIcon } from '../Constans';
import { Circle, InfoWindow, Marker } from '@react-google-maps/api';
import { Box, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import { AiOutlineMessage } from 'react-icons/ai';
import { chatBotSingleVehicleData } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";

function Tolls(props) {
 
    const { data, center } = props;
    const theme = useTheme()
    const [infoData, setInfoData] = useState(null);
    const dispatch = useDispatch();
    const getMarkerData = (markers) => {
        let markerObj = {};
        markerObj.lat = parseFloat(markers?.tollLocation?.split(',')[0]);
        markerObj.lng = parseFloat(markers?.tollLocation?.split(',')[1]);
        markerObj.name = markers?.tollName;
        markerObj.tollinfo = markers?.tollInfo;
        setInfoData(markerObj);
    }

    const circleOptions = {
      fillColor:theme.palette.secondary.main,
      fillOpacity:.2,
      strokeWeight:1.2,
      strokeColor:theme.palette.secondary.main,
    }
    const handleGptClick=(value,name)=>{
      var payload={
        "mapSignal":value,
        "location":name
      }
      dispatch(chatBotSingleVehicleData(payload));
    }

  return (
    <>
        {/* {
          <Circle
              radius={50000}
              center={{lat:parseFloat(center?.lat), lng:parseFloat(center?.lng)}}
              options={circleOptions}
          />
        } */}
        {
            data !== undefined && data.length > 0 && data.map((item, index) => {
            let location = {};
            location.lat = parseFloat(item.latitude);
            location.lng = parseFloat(item.longitude);
            return(
            <Marker
                key={index}
                position={location}
                onClick={() => getMarkerData(item)}
                icon={{
                url:TollIcon,
                scaledSize: new window.google.maps.Size(35, 35), 
                }}
            />
            ) 
            })
        }
         
    {
       infoData !== null && 
       <InfoWindow
          position={{lat:infoData.lat, lng:infoData.lng}}
          onCloseClick={() => setInfoData(null)}
          options={{pixelOffset: new window.google.maps.Size(0,-70)}}   
       >
          <Paper sx={{padding:'8px', minWidth:'250px', width:'100%', maxWidth:'250px'}}>
              <Stack>
              <Grid container spacing={1}>
                      <Grid item lg={12}>
                        <Box>
                          <Typography variant='caption' color={'text.disabled'}>Toll Name</Typography>
                          <Typography variant='body2'>{infoData.name}</Typography>
                        </Box>
                      </Grid>
              </Grid>
              <Grid container spacing={1}>
              <Grid item lg={12}>
              <Box>
                <Typography variant='caption' color={'text.disabled'}>Charges</Typography>
                {null != infoData?.tollinfo ?
                  (Object.keys(infoData?.tollinfo)?.map((key) => (
                    <Typography variant='body2' key={key}>{`${key}: ${infoData?.tollinfo?.[key]}`}</Typography>
              ))):<Typography variant='body2' >NA</Typography>}
            </Box>
            </Grid>
            </Grid>
            <Grid container sx={{marginTop:"5px",marginBottom: "-8px !important"}}>
              <Grid item lg={11}>
              <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box
                className="addresslink"
                sx={{ display: "flex", alignItems: "center" }}
              >
               <a
                  href={`https://www.google.com/maps/search/?api=1&query=${infoData.lat+","+infoData.lng}`}
                  target="_blank"
                  id={"tolllatlngId"}
                >
                 <Typography variant='caption' >View map</Typography>
                </a>
              </Box>
            </Box>
              </Grid>
              <Grid item lg={1}>
              <Typography variant='body2'><div style={{ fontSize: '16px', color: '#4CAF50' }}>
                <AiOutlineMessage  onClick={(event) => handleGptClick("Tolls",infoData.name)} />
              </div></Typography>
              </Grid>
          </Grid>    
              </Stack>
          </Paper>
       </InfoWindow>
    }
    </>
  )
}

export default Tolls