import { Paper } from '@material-ui/core';
import { alpha, Avatar, Badge, Fab, Tab, Tabs, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { dropVehicles, isLoadingState } from '../../store/slice/ControlsSlice';
import { useGetVehicleByResellerNewMutation } from '../../store/slice/TrackingSlice/TrackingSlice';
import { VehicleStatusBar } from '../../theme/StyledEle';
import { MdKeyboardArrowDown } from "react-icons/md";

import { AccidentTruck, AllAutos, AllRickshaw, AllScooters, AllTrucks, BreakdownTruck, ChargingAuto, ChargingBike, ChargingCar, ChargingRick, ChargingScvm, ChargingTractorm, ChargingTruck, MovingAuto, MovingBike, MovingCar, MovingRick, MovingScvm, MovingTractorm, MovingTruck, OfflineAuto, OfflineBike, OfflineCar, OfflineRick, OfflineScvm, OfflineTractorm, OfflineTruck, StopAuto, StopBike, StopCar, StopRick, StopScvm, StopTractorm, StopTruck,IdleBike,IdleAuto,IdleTruck,IdleScvm,IdleTractorm,IdleCar,IdleRick, CarAll } from '../Constans';
function CountBar(props) {
  const { data, showCategory, mapRefetch ,imgData, reCenter} = props;
  const theme = useTheme();
  let dispatch = useDispatch();
  let treeViewRoll = useSelector((state) => state.controls.treeview)
  const vehicleTrails = useSelector((state) => state.controls.vehicleTrails);
  const [show, setShow] = useState(true);
  //get localstorage data

  const userData = JSON.parse(localStorage.getItem('userData'));
  let VehicleType =  userData.vehicleType;
  let userOrgType = userData.userOrgType;
  let [vehicle, setVehicle] = useState(8);

  const getVehicleType = () => {
    switch(VehicleType){
      case 'TwoWheeler':
        setVehicle(2);
      break;
      case 'ThreeWheeler':
        setVehicle(3);
      break;
      case 'Truck':
        setVehicle(4);
      break;
      case 'Car':
        setVehicle(5);
      break;
      case 'Scv':
        setVehicle(6);
      break;
      case 'Tractor':
        setVehicle(7);
      break;
      case 'ERick':
        setVehicle(8);
      break;
      default:
        setVehicle(2);
    }
  }

  useEffect(() => {
    if(VehicleType){
      getVehicleType();
    }
  },[userData.resellerId])

  

  const [status, setStatus] = useState('none');
  //handleStatusChange
  const handleStatusChange = (event, newStatus) => {
    setStatus(newStatus);
    props.closeTreeViewfn(newStatus);
    mapRefetch();
  }
  
  

  useEffect(() => {
    if(!showCategory){
      setStatus('none');
    }
  },[showCategory])

  const [ getVehicleByResellerNew, { data: getVehicleData, isLoading: getVehicleLoading, isSuccess: getVehicleSuccess, } ] = useGetVehicleByResellerNewMutation();


  const getFilterStatusVehicle = async (commonPayload) => {
      const filterVehicle = await getVehicleByResellerNew(commonPayload);
      if(filterVehicle.data !== null && filterVehicle.data !== undefined){
        if(filterVehicle.data.vehicles.length > 0){
          dispatch(dropVehicles(filterVehicle.data));
        }else{
          dispatch(dropVehicles(null));
        }
      }else{
        dispatch(dropVehicles(null));
      }
  }

  useEffect(() => {
    dispatch(isLoadingState(getVehicleLoading))
  },[getVehicleLoading])

  // get show vehicle based on vehicle status
  const [vehicleStatus, setVehicleStatus] = useState('All');
  const showVehicleStatus = async (status) => {
    setVehicleStatus(status);
    //reCenter();
    let commonPayload = {
      resellerId: userData.resellerId,
      regionId: 0,
      dealerId: 0,
      customerId: 0,
      orgId: userData.resellerId,
      checkboxStatus: false,
      page: 0,
      size: 1000,
      region: 0,
      vehModel: "All",
      searchText: "",
      zoneId: 0,
      status:status
    };
    if(userData.userOrgType === 2){
      commonPayload.orgId = userData.orgId;
      if(treeViewRoll.role === 'region'){
        commonPayload.dealerId = 0;
        commonPayload.customerId = 0;
        commonPayload.regionId = treeViewRoll.id;
      }else if(treeViewRoll.role === 'dealer'){
        commonPayload.dealerId = treeViewRoll.id;
        commonPayload.customerId = 0;
        commonPayload.regionId = 0;
      }else if(treeViewRoll.role === 'customer'){
        commonPayload.dealerId = 0;
        commonPayload.customerId = treeViewRoll.id;
        commonPayload.regionId = 0;
      }else if(treeViewRoll.role === 'reseller'){
        commonPayload.dealerId = 0;
        commonPayload.customerId = 0;
        commonPayload.regionId = 0;
      }
      getFilterStatusVehicle(commonPayload);
    }else if(userOrgType === 5){
      commonPayload.orgId = userData.orgId;
      if(treeViewRoll.role === 'customer'){
        commonPayload.dealerId = userData.orgId;
        commonPayload.customerId = treeViewRoll.id;
        commonPayload.regionId = 0;
      }
      getFilterStatusVehicle(commonPayload);
    }else if(userOrgType === 3){
      commonPayload.customerId = userData.orgId ;
      getFilterStatusVehicle(commonPayload);
    }
  }  
  return (
  //vehicleTrails === null &&  userData.resellerId !== 110224 &&
  
    vehicleTrails === null && 
    <VehicleStatusBar show={show}>
       
        <Fab size='small' sx={{background:'#fff', boxShadow:"0px 2px 5px rgba(0,0,0,0.2)", position:'absolute', top:'-18px', width:'25px', height:'25px', minHeight:'25px', left:'50%', transform:`translateX(-50%) rotate(${show ? '0' : '180'}deg)`, }} onClick={() => setShow(!show)}><MdKeyboardArrowDown /></Fab>
      
       
        <Paper elevation={0} className='vehicle-status-root' sx={{position:'relative'}}>
          
              {
                vehicleTrails === null &&
              <Tabs value={vehicleStatus} TabIndicatorProps={{
                sx:{
                  display:'none'
                }
              }}
              sx={{
                '.Mui-selected':{
                  background:alpha(theme.palette.secondary.main, 0.18),
                  borderRadius:'10px',
                }
              }}
              >
                  <Tab label={'All'} value={'All'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('All')} icon={ <Badge
                        badgeContent={data?.data?.totalCount}
                        color='info'
                        variant='square'
                        max={999999}
                        showZero={true}
                        
                      >
                      <Avatar variant="square"
                        src={
                          vehicle === 2 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllScooters) :
                      vehicle === 3 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllAutos) :
                        vehicle === 4 ? (imgData?.all !=="-" && imgData?.all !== null ? `https://${imgData?.all}` : AllTrucks) :
                          vehicle === 5 ? (imgData?.all  !=="-" && imgData?.all !== null ? `https://${imgData?.all}` : CarAll) :
                            vehicle === 6 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : MovingScvm) :
                              vehicle === 7 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : MovingTractorm) :
                              vehicle === 8 ? (imgData?.all !=="-" && imgData?.all !== null  ? `https://${imgData?.all}` : AllRickshaw) :
                              AllScooters
                        }
                      />
                    </Badge>
                  } />


                <Tab label={'Moving'} value={'Moving'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Moving')} icon={ <Badge
                      badgeContent={data?.data?.moveCount}
                      color='success'
                      variant='square'
                      max={999999}
                      showZero={true}
                      
                    >
                      <Avatar variant="square"
                        src={
                          vehicle === 2 ? ((imgData?.moving !=="-") && imgData?.moving !== null ? `https://${imgData?.moving}` : MovingBike) :
                            vehicle === 3 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingAuto) :
                              vehicle === 4 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTruck) :
                                vehicle === 5 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingCar) :
                                  vehicle === 6 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingScvm) :
                                    vehicle === 7 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingTractorm) :
                                    vehicle === 8 ? ((imgData?.moving !=="-") && imgData?.moving !== null  ? `https://${imgData?.moving}` : MovingRick) :
                                      MovingBike
                        }
                      />
                    </Badge>
                  } />

                  <Tab label={'Stop'} value={'Stop'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Stop')} icon={ <Badge
                      badgeContent={data?.data?.stopCount}
                      color='error'
                      max={999999}
                      showZero={true}
                      
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? ((imgData?.stop !=="-") && imgData?.stop !== null? `https://${imgData?.stop}` : StopBike) :
                        vehicle === 3 ? ((imgData?.stop !=="-") && imgData?.stop !== null ? `https://${imgData?.stop}` : StopAuto) :
                          vehicle === 4 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopTruck) :
                            vehicle === 5 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopCar) :
                              vehicle === 6 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopScvm) :
                                vehicle === 7 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopTractorm) :
                                vehicle === 8 ? ((imgData?.stop !=="-") && imgData?.stop !== null  ? `https://${imgData?.stop}` : StopRick) :
                                StopBike
                    }
                  />
                    </Badge>
                  } />

                  <Tab label={'Idle'} value={'Idle'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Idle')} icon={ <Badge
                      badgeContent={data?.data?.idleCount}
                      color='info'
                      max={999999}
                      showZero={true}
                      
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.idle !=="-" && imgData?.idle !== null ? `https://${imgData?.idle}` : IdleBike) :
                        vehicle === 3 ? (imgData?.idle !=="-" && imgData?.idle !== null ? `https://${imgData?.idle}` : IdleAuto) :
                          vehicle === 4 ? (imgData?.idle !=="-" && imgData?.idle !== null  ? `https://${imgData?.idle}` : IdleTruck) :
                            vehicle === 5 ? (imgData?.idle !=="-" && imgData?.idle !== null && imgData?.idle !== undefined ? `https://${imgData?.idle}` : IdleCar) :
                              vehicle === 6 ? (imgData?.idle !=="-" && imgData?.idle !== null  ? `https://${imgData?.idle}` : IdleScvm) :
                                vehicle === 7 ? (imgData?.idle !=="-" && imgData?.idle !== null  ? `https://${imgData?.idle}` : IdleTractorm) :
                                vehicle === 8 ? (imgData?.idle !=="-" && imgData?.idle !== null  ? `https://${imgData?.idle}` : IdleRick) :
                                IdleBike
                    }
                  />
                    </Badge>
                  } />


                  <Tab label={'Offline'} value={'Offline'} sx={{overflow:'visible'}} onClick={() => showVehicleStatus('Offline')} icon={ 
                  <Badge
                      badgeContent={data?.data?.offlineCount}
                      color='info'
                      max={999999}
                      showZero={true}
                      
                      sx={{'& .MuiBadge-badge':{background:theme.palette.grey[600], color:theme.palette.primary.contrastText}}}
                    >
                  <Avatar variant="square"
                    src={
                      vehicle === 2 ? (imgData?.offline !=="-" && imgData?.offline !== null ? `https://${imgData?.offline}` : OfflineBike) :
                        vehicle === 3 ? (imgData?.offline !=="-"  && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineAuto) :
                          vehicle === 4 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineTruck) :
                            vehicle === 5 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineCar) :
                              vehicle === 6 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineScvm) :
                                vehicle === 7 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineTractorm) :
                                vehicle === 8 ? (imgData?.offline !=="-" && imgData?.offline !== null  ? `https://${imgData?.offline}` : OfflineRick) :
                                OfflineBike
                    }
                  />
                    </Badge>
                  } />


              </Tabs>
              }
            
        </Paper>
    </VehicleStatusBar>
  )
}

export default CountBar
