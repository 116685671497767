import {
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Box,
  Chip,
  Divider,
  Grid,
  Icon,
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
  Dialog,
  DialogContent,
  Accordion
} from "@mui/material";
import { alpha } from "@material-ui/core/styles/colorManipulator";

import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  SoftButton,
  StyledAccordion,
  StyledAccordionRoute,
  StyledFormControl,
  StyledFormControlLabel,
  StyledInputBase,
  StyledListItem,
  StyledListItemAcord,
  StyledPaperShadow,
  StyledTextField,
  StyledTextFieldRoute,
  StyledTooltip,
  StyledFormButtonsRow
} from "../../theme/StyledEle";
import CreateDropDown from "./CreateDropDown";
import { DeleteOutline } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { FiDelete } from "react-icons/fi";
import {
  GoogleMap,
  useJsApiLoader,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { GOOGLE_MAP_KEY } from "../../constants/appConstant";
import { useDispatch, useSelector } from "react-redux";
import { Dark, Day } from "../../theme/gmapTheme";
import { GoInfo } from "react-icons/go";
import GeoZoneMarkers from "./GeoZoneMarkers";
import { snackService, routeTripDuration, snackServiceRoute } from "../../store/slice/ControlsSlice";
import {
  useGetEditRouteTripMutation,
  useSaveAssignRouteTripMutation,
  useSaveRouteMutation,
} from "../../store/slice/RouteTripSlice/RouteTripSlice";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useForm } from "react-hook-form";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { MdOutlineInfo } from "react-icons/md";
import { BiDownArrowCircle } from 'react-icons/bi';
import CreateLeafletRouteTrip from "../route/CreateLeafletRouteTrip"

function CreateRouteTrip({ routeToEdit, onCancel }) {
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    getValues,
    formState: { errors },
  } = useForm({
  });

  let theme = useTheme();
  const isSuccess = useSelector((state) => state.controls.alert);
  const isSuccessRoute = useSelector((state) => state.controls.alertRoute);
  const dispatch = useDispatch();
  const gmode = useSelector((state) => state.controls.customizer.mapMode);
  const routeTripDurtn = useSelector(
    (state) => state.controls.routeTripDuration
  );
  const [map, setMap] = React.useState(null);
  const [defaultCenter, setDefaultCenter] = useState({ lat: 52.92214338014607, lng: -1.4703258589163344 });
  const [mapzoom, setMapzoom] = useState(5);
  const [mapType, setMapType] = useState("roadmap");
  const [libraries] = useState(["drawing", "places", "geometry"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: GOOGLE_MAP_KEY,
    libraries,
    //version:'weekly'
    nonce:"map"
  });

  //Delete Popup
  const [deleteie, setDeleteIe] = useState(false);
  const [deleteItem, setDeleteItem] = useState(null);
  const [auditLog] = useAuditLogMutation();
   const [globalMap,setGlobalMap]= useState(1);
  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let mapTypeData = userData.mapType;

  useEffect(()=>{
    setGlobalMap(mapTypeData)
  },[mapTypeData])

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };


  const onDelete = (itemDeleted) => {
    setDeleteItem(itemDeleted);
    setDeleteIe(true);
  };

  const deleteClose = () => {
    setDeleteIe(false);
  }
  const deleteItems = () => {
    setDeleteIe(false);
    updateRoutes();
  };


  //map style
  const mapContainerStyle = {
    height: "100%",
    position: "relative",
    width: "100%",
  };

  React.useEffect(() => {
    if (isSuccessRoute?.isSuccess) {
      setTimeout(() => dispatch(snackServiceRoute([null, null, null])), 2000);
    }
  }, [isSuccessRoute?.isSuccess]);

  const onLoad = useCallback(
    function callback(map) {
      const bounds = new window.google.maps.LatLngBounds(defaultCenter);
      map.fitBounds(bounds);
      setMap(map);
      setMapzoom(5);
      //setZoomLevel(map.getZoom());
    },
    [gmode]
  );

  const options = {
    zoom: mapzoom,
    center: defaultCenter,
    minZoom: 2,
    maxZoom: 18,
    fullscreenControl: false,
    streetViewControl: false,
    zoomControl: false,
    mapTypeControl: false,
    disableDefaultUI: true,
    featureType: "poi.business",
    stylers: [{ visibility: "off" }],
    featureType: "transit",
    elementType: "labels.icon",
    stylers: [{ visibility: "off" }],
    featureType: "poi",
    stylers: [{ visibility: "off" }],
    tilt: 47.5,
    heading: 320,
  };

  const [vehicleList, setVehicleList] = useState(null);
  const [geoZoneList, setGeoZoneList] = useState(null);
  //get Vehicle Data

  //Vehicle onChange
  const [vehicleValue, setVehicleValue] = React.useState([]);
  const [inputVehicleValue, setInputVehicleValue] = React.useState("");

  //GeoZone onChange
  const [geoZoneValue, setGeoZoneValue] = React.useState([]);
  const [inputGeozoneValue, setInputGeozoneValue] = React.useState("");
  const [selectedSave, setSelectedSave] = React.useState(false);

  const getVehicleData = useCallback((data) => {
    if (data !== null && data !== undefined) {
      setVehicleList(null);
      if (!routeToEdit) {
        setVehicleValue([]);
      }
      setVehicleList(data.data);
    } else {
      if (!routeToEdit) {
        setVehicleList([]);
        setVehicleValue([]);
      }
    }
  }, []);

  //Get GeoZone List
  const getGeoZoneList = useCallback((data, customerId) => {
    setGeoZoneList(null);
    if (!routeToEdit) {
      setGeoZoneValue([]);
    }
    if (data !== null && customerId !== null) {
      setGeoZoneList(data.data);
      setCustomerId(customerId);
    } else {
      setGeoZoneList([]);
      setCustomerId(null);
    }
  }, []);

  const [waypointList, setWaypointList] = useState([]);

  const waypointLists = () => {
    setWaypointList([]);

    geoZoneValue.forEach((key, index) => {
      let waypointsObj = {
        location: {},
      };
      waypointsObj.location.id = key.name;
      waypointsObj.location.lat = parseFloat(key.latitude);
      waypointsObj.location.lng = parseFloat(key.longitude);
      
      if(key.zoneType==="Polygons" || key.geoZoneType==="Polygons"){
        let distination1=JSON.parse(key.cordinates);
        waypointsObj.location.lat= parseFloat(distination1[0]?.lat);
        waypointsObj.location.lng=parseFloat(distination1[0]?.lng);
      }
      waypoints.push(waypointsObj);
      setWaypointList(waypoints);
    })
  }
  useEffect(() => {
    if (waypointList.length > 1) {
      directionService();
    }
  }, [waypointList])

  useEffect(() => {
    if (geoZoneValue.length >= 2) {
      let fLat = parseFloat(geoZoneValue[0].latitude);
      let fLng = parseFloat(geoZoneValue[0].longitude);
      let lLat = parseFloat(geoZoneValue.slice(-1)[0].latitude);
      let lLng = parseFloat(geoZoneValue.slice(-1)[0].longitude);
      if(geoZoneValue[0].zoneType==="Polygons" || geoZoneValue[0].geoZoneType==="Polygons"){
        let distination1=JSON.parse(geoZoneValue[0].cordinates);
        fLat= parseFloat(distination1[0]?.lat);
        fLng=parseFloat(distination1[0]?.lng);
      }
      if(geoZoneValue.slice(-1)[0].zoneType==="Polygons" || geoZoneValue.slice(-1)[0].geoZoneType==="Polygons"){
        let distination1=JSON.parse(geoZoneValue.slice(-1)[0].cordinates);
        lLat= parseFloat(distination1[0]?.lat);
        lLng=parseFloat(distination1[0]?.lng);
      }

      waypointLists();
      getDistance(fLat, lLat, fLng, lLng);
    } else {
      setRouteData(null);
      setTotalDistance(0);
    }
  }, [geoZoneValue]);


  //Handle Zone Select
  const handleZoneSelect = (event, newValue) => {
    if(newValue.length<26){
      setGeoZoneValue(newValue);
    }
    
  };

  //Payload
  const [routeNo, setRouteNo] = useState(0);
  const [routeName, setRouteName] = useState(null);
  const [customerId, setCustomerId] = useState(null);
  const [totalDistance, setTotalDistance] = useState(0);
  const [preLocation, setPreLocation] = useState(null);
  const [edit, setEdit] = useState(null);
  const [dataRouteTripRunnig, setDataRouteTripRunnig] = useState({ routeTripRunnig: true });
  const [startGeoZoneValue,setStartGeoZoneValue] =useState([]);
  const [changedRoute,setChangedRoute] =useState(false);

  var vehicleNos = [];
  var vehicleIds = [];
  var getZoneIds = [];
  var waypoints = [];
  var distance = [];

  //ToString Conversion
  const vehicleListConvert = () => {
    //To String Convert
    vehicleValue.forEach(function (key) {
      vehicleNos.push(key.number);
      vehicleIds.push(key.id);
    });

  };
  //Covert0
  const GeoZoneConvert = () => {
    geoZoneValue.forEach((key, index) => {
      let zoneType="";
      if(key.geoZoneType!==undefined && key.geoZoneType!==null  && key.geoZoneType!==""){
        zoneType=key.geoZoneType;
      }else{
        zoneType=key.zoneType;
      }
      let zoneObj = {};
      zoneObj.id = key.id;
      zoneObj.geoZoneId = key.id;
      zoneObj.geoZoneName = key.name;
      zoneObj.geoZoneLatLong = key.cordinates;
      zoneObj.geoZoneType=zoneType;
      zoneObj.radius = key.radius;
      zoneObj.address = key.address;
      zoneObj.sequenceNo = index + 1;
      zoneObj.routeName = routeName;
      zoneObj.routeId = 0;
      zoneObj.geoZoneType=zoneType;
      zoneObj.duration = getValues("duration_" + key.id);
      zoneObj.plannedDuration = getValues("betweendurtn_" + key.id);
      zoneObj.zoneCategory = (key?.zoneCategory !== "" && key?.zoneCategory !== undefined && key?.zoneCategory !== "null" && key?.zoneCategory !== null) ? key?.zoneCategory : "General";
      //set Distance
      let filterRoute = routeData !== null && routeData !== undefined && routeData?.routes[0]?.legs?.filter((item, index) => {
        return (item?.start_address !== item?.end_address);
      });
      if (index !== 0) {
        let indexno = index - 1;
        zoneObj.distance = filterRoute !== undefined && parseFloat(filterRoute[indexno]?.distance.text.split(' ')[0]);
      } else {
        zoneObj.distance = 0;
      }
      getZoneIds.push(zoneObj);

    });

  };


  //Calculate Distance
  const getDistance = (lat1, lat2, lon1, lon2) => {
    lon1 = (lon1 * Math.PI) / 180;
    lon2 = (lon2 * Math.PI) / 180;
    lat1 = (lat1 * Math.PI) / 180;
    lat2 = (lat2 * Math.PI) / 180;

    let dlon = lon2 - lon1;
    let dlat = lat2 - lat1;

    let a =
      Math.pow(Math.sin(dlat / 2), 2) +
      Math.cos(lat1) * Math.cos(lat2) * Math.pow(Math.sin(dlon / 2), 2);
    let c = 2 * Math.asin(Math.sqrt(a));

    let r = 6371;
    let distance = c * r;
    // setTotalDistance(distance.toFixed(0));
    return distance.toFixed(0);
  };

  var startlocation=geoZoneValue[0]?.cordinates;
  var destination=geoZoneValue.slice(-1)[0]?.cordinates;
    /*
    if(geoZoneValue[0]?.zoneType==="Polygons"){
      let distination1=JSON.parse(geoZoneValue[0]?.cordinates);
      startlocation=distination1[0]?.lat+","+distination1[0]?.lng;
    }
    if(geoZoneValue.slice(-1)[0]?.zoneType==="Polygons"){
      let distination1=JSON.parse(geoZoneValue.slice(-1)[0]?.cordinates);
      destination=distination1[0]?.lat+","+distination1[0]?.lng;
    }
    */

  //Route Payload
  let routePayload = {
    routeId: routeNo,
    routeName: routeName,
    orgId: customerId,
    routeTripDTO: {
      routeId: routeNo,
      routeName: routeName,
      startLoc: geoZoneValue[0]?.address, //address
      endLoc: geoZoneValue.slice(-1)[0]?.address, //address
      startLatLong: startlocation,
      endLatLong: destination,
      startGeozone: geoZoneValue[0]?.name, //geo zone name
      endGeozone: geoZoneValue.slice(-1)[0]?.name, //geo zone name
      orgId: customerId,
      totalDistance: 0,
      geoZones: getZoneIds,
      routeChange:false
    },
  };

  const [
    saveRoute,
    {
      data: saveRouteData,
      isLoading: saveRouteLoading,
      isSuccess: saveRouteSuccess,
      isError: saveRouteError,
    },
  ] = useSaveRouteMutation();
  const [
    updateRoute,
    {
      data: updateRouteData,
      isLoading: updateRouteLoading,
      isSuccess: updateRouteSuccess,
      isError: updateRouteError,
    },
  ] = useSaveAssignRouteTripMutation();
  const [routeData, setRouteData] = useState(null);

  const saveRoutes = useCallback(async () => {
    if (geoZoneValue.length === 0) {
      dispatch(snackService(["Please Select GeoZone", 1, true]));
    } else {
      vehicleListConvert();
      GeoZoneConvert();
      routePayload.routeTripDTO.vehiclesNo = vehicleNos.toString();
      routePayload.routeTripDTO.vehiclesId = vehicleIds.toString();
      routePayload.routeTripDTO.totalDistance = totalDistance;
      routePayload.routeTripDTO.routeChange = false;
      var alertcheck = false;
      geoZoneValue.forEach((key) => {
        if (getValues("duration_" + key.id) === "" || parseInt(getValues("duration_" + key.id)) < 1 || parseInt(getValues("duration_" + key.id)) > 9999) {
          alertcheck = true;
        }
      });
      if (alertcheck === false) {
        const saveRouteResponce = await saveRoute(routePayload);
        auditPayload.message = "Reports > Route Trips > Create Route > Submit";
        auditPayload.action = "Submit";
        auditPayload.featureName = "Route";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.userName = userName;
        auditLog(auditPayload);
        dispatch(snackService([saveRouteResponce.data.resultMessage, saveRouteResponce.data.resultCode, true]));
        if (saveRouteResponce.data.resultCode === 0) {
          onCancel()
        }
      } else {
        dispatch(snackServiceRoute(['Enter numbers between 0 to 9999', 1, true]));
      }
    }
  }, [geoZoneValue, routeData]);

  //Calculate Route
  const directionService = async () => {
    const getDirections = new window.google.maps.DirectionsService();
    var startlocation=geoZoneValue[0].cordinates;
    var distination=geoZoneValue.slice(-1)[0].cordinates;
    if(geoZoneValue[0].zoneType==="Polygons" || geoZoneValue[0].geoZoneType==="Polygons"){
      let distination1=JSON.parse(geoZoneValue[0].cordinates);
      startlocation=distination1[0]?.lat+","+distination1[0]?.lng;
    }

    if(geoZoneValue.slice(-1)[0].zoneType==="Polygons" || geoZoneValue.slice(-1)[0].geoZoneType==="Polygons"){
      let distination1=JSON.parse(geoZoneValue.slice(-1)[0].cordinates);
      distination=distination1[0]?.lat+","+distination1[0]?.lng;
    }
    const result = await getDirections.route({
      origin: startlocation,
      destination: distination,
      travelMode: window.google.maps.TravelMode.DRIVING,
      waypoints: waypointList,
    });

    let filterRoute = result?.routes[0]?.legs?.filter((item, index) => item.start_address !== item.end_address);
    let totalDist = 0;
    for (let i = 0; i < filterRoute.length; i++) {
      totalDist += filterRoute[i].distance.value;
    }
    setTotalDistance(totalDist / 1000);
    setRouteData(result);
    if (!routeToEdit) {
      GeoZoneConvert();
    }
  };





  useEffect(() => {
    //setPreLocation
    geoZoneValue.forEach((key, index) => {
      let loc = {};
      loc.lat = parseFloat(key.latitude);
      loc.lng = parseFloat(key.longitude);
      setPreLocation(loc);
      if (geoZoneValue >= 2) {
        //getDistance(loc.lat,);
      }
    });
  }, [geoZoneValue]);

  // Edit Service call
  const [
    getEditRoute,
    {
      data: getEditRouteData,
      isLoading: getEditRouteLoading,
      isSuccess: getEditSuccess,
    },
  ] = useGetEditRouteTripMutation();
  
  //Edit call
  useEffect(async () => {
    if (routeToEdit) {
      var payload = {};
      payload.routeId = routeToEdit.routeId;
      let response = await getEditRoute(payload);
      setEdit({ regionId: response.data.data[0].regionId, dealerId: response.data.data[0].dealerId, customerId: response.data.data[0].customerId });
      setRouteName(response.data.data[0].routeName);
      setTotalDistance(response.data.data[0].totalDistance);
      setGeoZoneValue(response.data.data);
      var arry=[];
      for(let i=0;i<response.data.data.length;i++){
        arry.push(response.data.data[i].id);
      }
      setStartGeoZoneValue(arry);
      setRouteNo(routeToEdit.routeId);

      var arry = [];
      var data1 = response.data.data[0].vehicleId.split(",");
      var data = response.data.data[0].vehicleNo.split(",");
      for (var i = 0; i < data.length; i++) {
        let obj = {};
        obj.number = data[i];
        obj.id = data1[i];
        arry.push(obj);
      }
      //waypointLists();
      setVehicleValue(arry);
    }
  }, []);

  useEffect(() => {
    if (routeToEdit && geoZoneValue.length > 0) {
      GeoZoneConvert();
    }
  }, [geoZoneValue])

  const updateRoutesNew = () => {
    var arry=[];
    for(let i=0;i<geoZoneValue.length;i++){
      arry.push(geoZoneValue[i].id);
    }
    if(JSON.stringify(arry)===JSON.stringify(startGeoZoneValue)){
      setChangedRoute(true);
     }else{
      setChangedRoute(false);
    }
    setSelectedSave(false);
    setDeleteIe(true);
  }

  //Update Function
  const updateRoutes = async () => {
    vehicleListConvert();
    GeoZoneConvert();
    //setChangedRoute(true);
    
    routePayload.routeTripDTO.vehiclesNo = vehicleNos.toString();
    routePayload.routeTripDTO.vehiclesId = vehicleIds.toString();
    routePayload.routeTripDTO.totalDistance = totalDistance;
    routePayload.routeTripDTO.routeChange = changedRoute===true?false:true;
    
    var alertcheck = false;
    geoZoneValue.forEach((key, index) => {
      if (getValues("duration_" + key.id) === "" || parseInt(getValues("duration_" + key.id)) < 1 || parseInt(getValues("duration_" + key.id)) > 9999) {
        alertcheck = true;
      }
    });
    if (alertcheck === false) {
      onCancel();
      const updateRouteResponce = await updateRoute(routePayload);
      auditPayload.message = "Reports > Route Trips > Create Route > Update";
      auditPayload.action = "Update";
      auditPayload.featureName = "Route";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditPayload.userName = userName;
      auditLog(auditPayload);
      dispatch(
        snackService([
          updateRouteResponce.data.resultMessage,
          updateRouteResponce.data.resultCode,
          true,
        ])
      );
    } else {
      dispatch(snackServiceRoute(['Enter numbers between 0 to 9999', 1, true]));
    }
  };
  const googleMaps = useMemo(() => {
    return isLoaded ? (
      <GoogleMap
        options={{
          mapTypeId: mapType,
          ...options,
          ...(gmode !== 1 ? Dark : Day),
        }}
        zoom={mapzoom}
        mapContainerStyle={mapContainerStyle}
        onLoad={onLoad}
      //onUnmount={onUnmount}
      >
        {/* Geo Zone Markers */}
        {geoZoneValue.length >= 2 && geoZoneValue !== null && (
          <GeoZoneMarkers data={geoZoneValue} />
        )}
        {routeData !== null && <DirectionsRenderer directions={routeData} />}
      </GoogleMap>
    ) : (
      "Loading..."
    );
  }, [isLoaded, routeData, geoZoneValue]);

  const leafletMaps = useMemo(() => {
      return (
        <>
       
        {isLoaded ? (
          <CreateLeafletRouteTrip geoZoneValue={geoZoneValue}  waypointList={waypointList}/>
        ) : (
          'Loading'
        )}
      </>
      )
  
  }, [isLoaded, routeData, geoZoneValue]);

  let mapSetting;

if (globalMap === 1) {
  mapSetting = googleMaps;
} else if (globalMap === 2) {
  mapSetting = leafletMaps;
} else {
  mapSetting = googleMaps;
}

  const onDragComplete = (result) => {
    if (!result.destination) return;
    const arr = [...geoZoneValue];
    //Changing the position of Array element
    let removedItem = arr.splice(result.source.index, 1)[0];
    arr.splice(result.destination.index, 0, removedItem);
    arr.forEach((key, index) => {
      setValue("betweendurtn_" + key.id, 1);
    });
    setGeoZoneValue(arr);
  };
  const deleteSelected = (result) => {
    var ar = [...geoZoneValue];
    console.log(routeToEdit?.clickedval);
    if(routeToEdit!==null && routeToEdit?.clickedval !== "view"){
      ar = ar.filter(item => (item.id !== result.id));
      setGeoZoneValue(ar);
      setValue("duration_" + result.id, 1);
      setValue("betweendurtn_" + result.id, 1);
    }else{
      if(routeToEdit===null && routeToEdit?.clickedval===undefined){
        ar = ar.filter(item => (item.id !== result.id));
        setGeoZoneValue(ar);
        setValue("duration_" + result.id, 1);
        setValue("betweendurtn_" + result.id, 1);
      } 
    } 
      
  }

  const handleChangeDuration = (val, id) => {
    if (parseInt(val) < 1 || parseInt(val) > 9999) {
      dispatch(snackServiceRoute(['Enter numbers between 0 to 9999', 1, true]));
      setValue("duration_" + id, "");
    }
  }

  
  const handleChangeBtwnDuration = (val, id) => {
    if (parseInt(val) < 1 || parseInt(val) > 9999) {
      dispatch(snackServiceRoute(['Enter numbers between 0 to 9999', 1, true]));
      setValue("betweendurtn_" + id, "");
    }
  }

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item lg={7.5}>
          <StyledPaperShadow
            sx={{ height: "100%", minHeight: "calc(100vh - 20vh)" }}
          >
            {mapSetting}
          </StyledPaperShadow>
        </Grid>

        <Grid item lg={4.5}>
          <StyledAccordion defaultExpanded>
            <AccordionSummary>
              <Typography variant="subtitle2" color={"primary"}>
                Route Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ px: 2, pb: 2 }}>
                <Stack rowGap={1}>
                  <StyledFormControl>
                    <StyledFormControlLabel required>
                      Route Name
                    </StyledFormControlLabel>
                    <StyledTextField
                      size="small"
                      placeholder="Enter Route Name"
                      value={routeName}
                      onChange={(event) => setRouteName(event.target.value)}
                      disabled={routeToEdit !== null && routeToEdit?.clickedval === "view" ? true : false}
                    />
                  </StyledFormControl>

                  {/* Dropdowns */}
                  <CreateDropDown
                    getVehicleData={getVehicleData}
                    getGeoZoneList={getGeoZoneList}
                    dropdownValues={edit} // While Editing time
                    isEdit={routeToEdit}
                    showDropdownDisable={(routeToEdit !== null && routeToEdit?.clickedval === "view") ? true : false}
                  />
                  <StyledFormControl fullWidth>
                    <StyledFormControlLabel required>
                      Select Vehicle
                    </StyledFormControlLabel>
                    <Autocomplete
                      disableCloseOnSelect
                      disableClearable
                      multiple={true}
                      fullWidth
                      size="small"
                      value={vehicleValue}
                      onChange={(event, newValue) => {
                        setVehicleValue(newValue);
                      }}
                      inputValue={inputVehicleValue}
                      onInputChange={(event, newInputValue) => {
                        setInputVehicleValue(newInputValue);
                      }}
                      id="Select vehicle"
                      options={vehicleList !== null ? vehicleList : []}
                      getOptionLabel={(option) => option.number}
                      renderInput={(params) => <TextField {...params} />}
                      disabled={routeToEdit !== null && routeToEdit.clickedval === "view" ? true : false}
                    />
                  </StyledFormControl>
                  <StyledFormControl>
                    <StyledFormControlLabel required>
                      Select Geo Zone
                    </StyledFormControlLabel>
                    <Autocomplete
                      disableCloseOnSelect
                      disableClearable
                      multiple={true}
                      fullWidth
                      size="small"
                      value={geoZoneValue}
                      onChange={handleZoneSelect}
                      inputValue={inputGeozoneValue}
                      onInputChange={(event, newInputValue) => {
                        if(geoZoneValue.length<26){
                          setInputGeozoneValue(newInputValue);
                        }                         
                       }}
                      id="Select geoZone"
                      options={geoZoneList !== null && geoZoneList}
                      getOptionLabel={(option) => `${option.name}`}
                      renderInput={(params) => <TextField {...params} />}
                      //disabled={routeToEdit !== null && routeToEdit.clickedval === "view" || routeToEdit !== null && routeToEdit.clickedval === "edit" ?true:false}
                      disabled={routeToEdit !== null && routeToEdit.clickedval === "view" ? true : false}

                    />
                  </StyledFormControl>
                </Stack>
              </Box>
            </AccordionDetails>
          </StyledAccordion>
          <Box sx={{ my: 2 }} />
          <StyledAccordion defaultExpanded>
            <AccordionSummary>
              <Typography variant="subtitle2" color={"primary"}>
                Geo Zone Info
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box sx={{ px: 2, pb: 2 }}>
                <Stack
                  direction={"row"}
                  justifyContent={"space-between"}
                  alignItems={"end"}
                >
                  <Box>
                    <Typography variant="body2">Selected Geo Zone</Typography>
                    <Typography>
                      {geoZoneValue !== null ? geoZoneValue.length : "0"} /25
                    </Typography>
                  </Box>
                  <Box>
                    <Typography variant="body2">Distance</Typography>
                    <Typography>{totalDistance.toFixed(1)} mi</Typography>
                  </Box>
                  <Stack direction={"row"} spacing={2}>
                    {routeToEdit !== null &&
                      routeToEdit.clickedval !== "view" ? (
                      <SoftButton
                        variant="contained"
                        className="success"
                        size="small"
                        onClick={updateRoutesNew}
                      >
                        Update
                      </SoftButton>
                    ) : (
                      routeToEdit === null && (
                        <SoftButton
                          variant="contained"
                          className="success"
                          size="small"
                          onClick={saveRoutes}
                        >
                          Save
                        </SoftButton>
                      )
                    )}

                    <SoftButton
                      variant="contained"
                      className="error"
                      size="small"
                      onClick={() => onCancel()}
                    >
                      Cancel
                    </SoftButton>
                  </Stack>
                </Stack>
                <form>
                  <List sx={{ mt: 1 }} >
                    <div>
                      <StyledListItem
                        className={"zoneButtonRoot"}
                        secondaryAction={
                          <Stack
                            direction={"row"}
                            alignItems={"center"}
                            justifyContent={"center"}
                          >
                            <Typography variant="subtitle2" sx={{ mr: 1 }} color={"primary"}>
                              Action
                            </Typography>
                          </Stack>
                        }
                        sx={{
                          background: theme.palette.grey[50],
                          pl: 1,
                          my: 1,
                        }}
                      >
                        <ListItemText
                          sx={{ ml: 2 }}
                          primary={<Typography variant="subtitle2" color={"primary"}>
                            Geo Zone
                          </Typography>}
                        // + " (" +(item?.zoneCategory===null || item?.zoneCategory==="")?"General":item?.zoneCategory+")
                        />
                        {/* <Typography variant="subtitle2" color={"primary"} sx={{ mr: 12.5 }} >
                          Duration
                        </Typography> */}
                      </StyledListItem>
                    </div>

                    <DragDropContext onDragEnd={onDragComplete} >
                      <Droppable droppableId="drag-drop-list" >
                        {(provided, snapshot) => (
                          <div
                            className="drag-drop-list-container"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {geoZoneValue !== null && geoZoneValue.map((item, index) => (
                              <Draggable key={item?.name} draggableId={item?.name} index={index} isDragDisabled={routeToEdit !== null && routeToEdit.clickedval === "view" ? true : false}>
                                {(provided) => (
                                  <div
                                    className="item-card"
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >                                   
                            {/* <StyledAccordion defaultExpanded> */}
                            <StyledAccordion defaultExpanded >
                            <AccordionSummary sx={{marginTop:"0px !important",padding:"0px !important"}}>
                                   <StyledListItemAcord
                                      className={"zoneButtonRoot"}
                                      secondaryAction={
                                        <Stack
                                          direction={"row"}
                                          alignItems={"left"}
                                          justifyContent={"left"}
                                        >
                                          <StyledTooltip
                                            title={item?.address}
                                            placement="left"
                                            arrow
                                          >
                                            <Icon
                                              color="info"
                                              size='small'
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <MdOutlineInfo />
                                            </Icon>
                                          </StyledTooltip>
                                          <StyledTooltip
                                            title={"Delete"}
                                            placement="right"
                                            sx={{ pl: 1 }}
                                            arrow
                                          >
                                            <Icon
                                              color="error"
                                              size='small'
                                              sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                zIndex:"10px"
                                              }}
                                            >
                                              <DeleteOutline onClick={() => { deleteSelected(item) }} />
                                            </Icon>
                                          </StyledTooltip>
                                        </Stack>
                                      }
                                      sx={{
                                        background: theme.palette.grey[50],
                                        pl: 1,
                                        my: 0,
                                      }}
                                    >
                                      <ListItemIcon sx={{ ml: 1 }}>
                                        <Chip
                                          label={index + 1}
                                          color="warning"
                                          size="small"
                                        />
                                      </ListItemIcon>
                                      <ListItemText
                                        sx={{ ml: 1.2 }}
                                        primary={`${item.name + " "}(${(item?.zoneCategory !== "" && item?.zoneCategory !== undefined && item?.zoneCategory !== "null" && item?.zoneCategory !== null) ? item?.zoneCategory : "General"})`}
                                      />
                                     
                                    </StyledListItemAcord> 
                    </AccordionSummary>
                    <AccordionDetails sx={{background:'',boarderColor:"1px solid black" }}>
                        <Grid container sx={{paddingBottom:"1px",paddingLeft:"10px"}}>
                        <Grid item xs={7} >
                        <Typography variant="body1">With in duration</Typography> 
                        </Grid> 
                        <Grid item xs={5} >
                        <Typography variant="body1">Between duration</Typography>                          
                        </Grid>
                        <Grid item xs={7}>
                        <TextField sx={{ mr: 0 }}
                                        size="small"
                                        {...register("duration_" + item.id,)}
                                        type='number' //ad this line                        
                                        inputProps={{ readOnly: (routeToEdit !== null && routeToEdit.clickedval === "view") ? true : false }}
                                        placeholder="Enter Duration"
                                        //disabled={(routeToEdit !== null && routeToEdit?.clickedval === "view") ? true : false}
                                        onChange={(e) => handleChangeDuration(e.target.value, item?.id)}
                                        defaultValue={(item?.duration !== null && item?.duration !== undefined) ? item?.duration : "1"}
                                        style={{ width: 100,backgroundColor: (routeToEdit !== null && routeToEdit.clickedval === "view") ?'#EBEBEB':'#FFFFFF'}} //assign the width as your requirement
                                      />
                          </Grid>
                          <Grid item xs={5}>
                        <TextField sx={{ mr: 0 }}
                                        size="small"
                                        {...register("betweendurtn_" + item.id,)}
                                        type='number' //ad this line                        
                                        inputProps={{ readOnly: (routeToEdit !== null && routeToEdit.clickedval === "view") ? true : false }}
                                        placeholder="Between Duration"
                                        //disabled={(routeToEdit !== null && routeToEdit?.clickedval === "view") ? true : false}
                                        onChange={(e) => handleChangeBtwnDuration(e.target.value, item?.id)}
                                        defaultValue={(item?.plannedDuration !== null && item?.plannedDuration !== undefined) ? item?.plannedDuration : "1"}
                                        style={{ width: 100,backgroundColor: (routeToEdit !== null && routeToEdit.clickedval === "view") ?'#EBEBEB':'#FFFFFF'}} 
                                      />
                          </Grid>
                        </Grid>
                        </AccordionDetails>
                     </StyledAccordion>

                        {/* </StyledAccordion> */}
                                  </div>
                                )}
                              </Draggable>
                            ))}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>

                  </List>
                </form>
              </Box>
            </AccordionDetails>
          </StyledAccordion>
        </Grid>
      </Grid>
      {isSuccessRoute?.isSuccess && (
        <StyledSnackBar message={isSuccessRoute.message} status={isSuccessRoute.status} />
      )}

{/* maxWidth={"xs"} fullWidth={false} */}
      <Dialog open={deleteie} onClose={deleteClose}       
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "370px",  // Set your width here
          },
        },
      }}
      >

        <DialogContent sx={{ px: 0, py: 2 }}>
          {
            <>
              <Typography sx={{ textAlign: "center" }} variant="h6">
                Update
              </Typography>
              <Box sx={{ px: 2 }}>
                <Typography sx={{ textAlign: "center", my: 1.7 }}>
                {changedRoute===true?"Are you sure you want to update route?":"Current trip will be closed for the running vehicles"}
                </Typography>
              </Box>
              <StyledFormButtonsRow direction="row" sx={{ mt: 2.5}} spacing={2}>
                <SoftButton
                  size="small"
                  color="error"
                  className="error"
                  onClick={deleteClose}
                >
                  Cancel
                </SoftButton>
                <SoftButton
                  size="small"
                  className="success"
                  color="success"
                  type="submit"
                  onClick={deleteItems}
                >
                  Confirm
                </SoftButton>
              </StyledFormButtonsRow>
            </>
          }
        </DialogContent>
      </Dialog>

    </Box>
  );
}

export default CreateRouteTrip;
