import { useState, useEffect, useMemo, useCallback } from "react";
import { useDeleteServicePlanMutation, useServicePlanListMutation } from "../../store/slice/ApiSlice";
import { DataTable } from "../shared/data_table/NestedTable";
import { alpha, Box, Chip, Dialog, DialogContent, Divider, Grid, Icon, IconButton, Stack, Table, TableBody, TableContainer, Tooltip, Typography, useTheme } from "@mui/material";
import { SoftButton, StyledFormButtonsRow, StyledFormControl } from "../../theme/StyledEle";
import { AiOutlineDelete } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";
import { StyledTableHead } from "../shared/data_table/StyledEle";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch } from "react-redux";
import StyledModel from "../../Utils/StyledModel";
import Transition from "rsuite/esm/Animation/Transition";
import DrawerModel from "../../Utils/DrawerModel";
import CreateServiceForm from "./CreateServiceForm";
import { useNavigate } from "react-router-dom";
import EditServices from "./EditServices";
import FormDialog from "../../Utils/FormDialog";

const SubListServicePlans = ({ vehicleId, editDeletePayload, reloadPage }) => {
  const theme = useTheme();
  const [
    getServicePlanList, { data: servicePlanData, isLoading: getServicePlanLoading, isSuccess: getServicePlanSuccess },] = useServicePlanListMutation();

    const [
      delteItem,
      {
        data: deleteData,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
      },
    ] = useDeleteServicePlanMutation();

  const [responseData, setResponseData] = useState([]);
  const [commonFilter, setCommonFilter] = useState([]);
  const [deleteItem, setDeleteItem] = useState(null);
  const buttonItems = ['EDIT', 'DELETE'];
  const dispatch = useDispatch();
  const [payload, setPayload] = useState(null);
  const [edit, setEdit] = useState(null);
  const navigate = useNavigate();
  const [editData , setEditData] = useState(null);

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let roleId = userData.roleId;
  let orgType = userData.userOrgType;
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  const columns = useMemo(() =>
    [
      {
        Header: "Service Type",
        accessor: "serviceTypeLabel",
      },
      {
        Header: "Next Service Date",
        accessor: "serviceDate",
      },
      {
        Header: "Next Service Distance",
        accessor: "nextServiceDistance",
      },
      {
        Header: "Recurrence Duration",
        accessor: "recurrenceDurationLabel",
      },
     
      {
        Header: "Recurrence Distance",
        accessor: "recurrenceDistance",
      },
      
      {
        Header: "Status",
        accessor: "serviceStatus",
        Cell:({row}) => {
          if(row.original.serviceStatus !== '-' ){
            return <Typography  variant="body2"
              color={row.original.serviceStatus ==='Due for service' ? 'primary':row.original.serviceStatus ==='Completed'? 'success.main':'error.main'}
              sx={{ padding: "0px 3px",fontSize: "12px",fontStyle: "normal", fontWeight: "none" }}>
               {row.original.serviceStatus}</Typography>
                       }else{
              return row.original.serviceStatus
          }
        }
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
        width: 120,
      },



    ], []);

    const onDelete = async (itemDeleted) => {
      console.log(itemDeleted);
      setDeleteItem(itemDeleted);
    };

  const onSubmitForm = async () => {
    var dataStr = {};
    //dataStr.edit = orgType != 3 ?true: false;
    dataStr.edit = true;
    dataStr.delete = true;
    setCommonFilter(dataStr);
    var request = {};
    request.vehicleId = vehicleId.vehicleId;
    request.page = 0;
    request.size = 25;
    request.serviceType = -1;
    request.recurrenceType = -1;
    request.search = "";
    request.sortBy = "desc";
    setPayload(request);
    const responseData = await getServicePlanList(request);
    setResponseData(responseData.data.data.content);
  };

  const deleteItems = () => {
    let payload = {
      id: deleteItem.id,
    };
    delteItem(payload);
    auditPayload.message = "Admin > ServicePlan > ServicePlan List > Delete";
    auditPayload.action = "Delete";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

   // auditLog(auditPayload);
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );
      reloadPage(editDeletePayload);
    }
  }, [isDeleteSuccess]);


  useEffect(async () => {
    await onSubmitForm()
  }, [vehicleId]);

  const onEdit = (itemEdited) => {
    console.log(itemEdited);
    setEditData(itemEdited);
    navigate('#serviceEdit');

    // <DrawerModel position={'right'} width={'35vw'} title={'Edit Service Plan'}>
    // <CreateServiceForm reload={onSubmitForm} itemEdit={itemEdited} isEdit = {true}/>
    // </DrawerModel>
  };
  const handleClick = (v, e) => {
    console.log('click', v, e)
  }

  let tableCelStyle = {
    padding:'6px',
    borderRight:`.5px solid ${theme.palette.divider}`,
  }
  let tableBodyCelStyle = {
    padding:'6px',
    borderRight:`.5px solid ${theme.palette.divider}`,
    background:theme.palette.background.paper,
  }

  //Expand 
  const renderSubRowCompoent = useCallback(({row}) => {
    return (
      <Box>
          <TableContainer sx={{marginLeft:'80px'}}>
              <Table
                sx={{ overflowX: "scroll", borderCollapse: "separate"}}
              >
                  <StyledTableHead sx={{background:'#e0e0e0'}}>
                      <tr>
                          <td style={tableCelStyle}>Service Type</td>
                          <td style={tableCelStyle}>Service Date</td>
                          <td style={tableCelStyle}>Recurrence Distance</td>
                          <td style={tableCelStyle}>Next Service Distance</td>
                          <td style={tableCelStyle}>Recurrence Duration</td>
                          <td style={tableCelStyle}>Action</td>
                      </tr>
                  </StyledTableHead>
                  <TableBody className="nestedBody">
                      <tr>
                          <td style={tableBodyCelStyle}>{row.original.serviceType}</td>
                          <td style={tableBodyCelStyle}>{row.original.serviceDate}</td>
                          <td  style={tableBodyCelStyle}>{row.original.recurrenceDistance}</td>
                          <td style={tableBodyCelStyle}>{row.original.nextServiceDistance}</td>
                          <td style={tableBodyCelStyle}>{row.original.recurrenceDurationLabel}</td>
                          <td style={tableBodyCelStyle}>{row.original.buttonEditDelete}</td>

                      </tr>
                  </TableBody>
              </Table>
          </TableContainer>
      </Box>
    )
  },[]) 

  return (
    <>
    <DataTable
      columns={columns}
      data={responseData}
      styles={{ maxWidth: "100vw" }}
      numberOfStickyColumns={2}
      commonFilter={commonFilter}
      onEdit={onEdit}
      onDelete={onDelete}
      includeExportButton = {false}
      renderSubRowCompoent={renderSubRowCompoent}
    />

  


    {deleteItem !== null && (
         <Dialog  open = {true} maxWidth={"sm"} fullWidth={true} >
         <DialogContent sx={{ px: 0, py: 2 }}>
             <Icon
               sx={{
                 fontSize: "50px",
                 height: "auto",
                 margin: "auto",
                 display: "flex",
               }}
               color="error"
             >
               <AiOutlineDelete />
             </Icon>
           
           <Typography sx={{ textAlign: "center" }} variant="h6">
             Are you sure want to delete?
        </Typography>
        <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
          <SoftButton
            size="small"
            color="error"
            className="error"
            onClick={() => setDeleteItem(null)}
          >
            Cancel
          </SoftButton>
          <SoftButton
            size="small"
            className="success"
            color="success"
            type="submit"
            onClick={() => deleteItems()}
          >
          Confirm
          </SoftButton>
        </StyledFormButtonsRow>
      </DialogContent>
    </Dialog>
      )}
      <FormDialog title={'Edit Service Plan'} maxWidth={'60%'} size={false} >
      <EditServices editData = {editData} isEdit={true} editDeletePayload = {editDeletePayload} reloadPage = {reloadPage}/>
    </FormDialog>
      
    </>
    // <Box>
    //   {responseData.map((row, i) => {
    //     return (
    //       <>
    //         <Grid container rowSpacing={2} sx={{ p: .6 }}>
    //           <Grid item lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>

    //             <Stack
    //               direction={"row"}
    //               spacing={0.5}
    //               sx={{ textAlign: "center", alignItems: "center" }}
    //             >
    //               {buttonItems.map((v, i) => {
    //                 return (

    //                   <Tooltip
    //                     title={v.toLowerCase() === "edit"
    //                       ? "Edit"
    //                       : "Delete"
    //                     }
    //                     arrow
    //                   >
    //                     <IconButton
    //                       size="small"
    //                       sx={{
    //                         display:
    //                           v.toLowerCase() === "edit" &&
    //                             commonFilter.edit === true
    //                             ? "flex"
    //                             : v.toLowerCase() === "delete" &&
    //                               commonFilter.delete === true
    //                               ? "flex"
    //                               : "none",
    //                         background:
    //                           v.toLowerCase() === "edit"
    //                             ? alpha(
    //                               theme.palette.info.main,
    //                               0.1
    //                             )
    //                             : alpha(
    //                               theme.palette.error.main,
    //                               0.1
    //                             ),
    //                         color:
    //                           v.toLowerCase() === "edit"
    //                             ? alpha(
    //                               theme.palette.info.main,
    //                               1
    //                             )
    //                             : alpha(
    //                               theme.palette.error.main,
    //                               1
    //                             ),
    //                         borderRadius: "5px",
    //                       }}
    //                       onClick={(e) => handleClick(v, e)}
    //                     >
    //                       {v.toLowerCase() === "edit" &&
    //                         commonFilter.edit === true && (
    //                           <TbEdit />
    //                         )}
    //                       {v.toLowerCase() === "delete" &&
    //                         commonFilter.delete === true && (
    //                           <AiOutlineDelete />
    //                         )}
    //                     </IconButton>

    //                   </Tooltip>
    //                 )
    //               })}
    //             </Stack>

    //           </Grid>
    //           <Grid item lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
    //             <Typography variant='body2'>{row.serviceType}</Typography>
    //           </Grid>
    //           <Grid item lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
    //             <Typography variant='body2'>{row.serviceDate}</Typography>
    //           </Grid>
    //           <Grid item lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
    //             <Typography variant='body2'>{row.recurrenceDistance}</Typography>
    //           </Grid>
    //           <Grid item lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
    //             <Typography variant='body2'>{row.nextServiceDistance}</Typography>
    //           </Grid>
    //           <Grid item lg={2} sx={{ display: 'flex', justifyContent: 'center' }}>
    //             <Typography variant='body2'>{row.recurrenceDurationLabel}</Typography>
    //           </Grid>
    //         </Grid>
    //         <Divider sx={{ borderBottomWidth: 2 }} />
    //       </>
    //     )

    //   })}

    // </Box>


  );
};


export default SubListServicePlans;