import { Backdrop, Box, Chip, Divider, Grid, IconButton, Menu, Paper, Slider, Stack, Tooltip, Typography, alpha, useTheme } from '@mui/material'
import { GoogleMap, InfoWindow, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { FaPause, FaPlay, FaStop } from 'react-icons/fa6'
import { GridLoader } from 'react-spinners'
import endMarker from '../../assets/images/playback/End_Marker.svg'
import startMarker from '../../assets/images/playback/Start_Marker.svg'
import { GOOGLE_MAP_KEY } from '../../constants/appConstant'
import { usePlaybackMutation } from '../../store/slice/ApiSlice'
import { SoftButton, StyledPageLayout, StyledToggleButton, StyledToggleButtonGroup } from '../../theme/StyledEle'
import FilterDropdown from '../NewDropdown/FilterDropdown'
import { Breadcrumb } from '../shared/Breadcrumbs'
//PLay Icons
import Scrollbars from 'react-custom-scrollbars-2'
import { FaMinus, FaPlus } from 'react-icons/fa'
import { HiFolderDownload } from "react-icons/hi"
import { IoMdClose } from "react-icons/io"
import { MdSummarize } from "react-icons/md"
import { useAuditLogMutation } from "../../store/slice/ApiSlice"
import MapOptions from '../../Utils/MapOptions'
import LeafletPlayback from "./LeafletPlayback"
import MovingCard from './MovingCard'
import { auto, bikePath, car, truck } from './resource'
import Summary from './Summary'
import SummaryPdf from './SummaryPdf'


//import { StartFlag } from '../

function TryPlayback() {

    //Theme
    const theme = useTheme();

    //Google Map
    const [defaultCenter, setDefaultCenter] = useState({ lat: 52.92214338014607, lng: -1.4703258589163344 });
    const [libraries] = useState(["drawing", "places", "geometry"]);
    const [mapzoom, setMapzoom] = useState(5);
    const [maxZoom, setMaxZoom] = useState(6);
    const [openDialog, setOpenDailag] = useState(false)
    const [openpopup, setOpenpPopup] = useState(false)
    const [globalMap, setGlobalMap] = useState(1);
    const [leafletMarker, setLeafletMarker] = useState([])
    const { isLoaded } = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries,
        nonce: "map"
    });
    const [map, setMap] = useState(null);
    //Map Onload
    const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(defaultCenter);
        map.fitBounds(bounds);
        setMap(map);
        setMapzoom(5);
    }, [])


    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    //Google Map Options
    const [mapType, setMapType] = useState("roadmap");
    const options = {
        zoom: mapzoom,
        center: defaultCenter,
        minZoom: 2,
        maxZoom: maxZoom,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
        featureType: "poi",
        stylers: [{ visibility: "off" }],
        tilt: 47.5,
        heading: 320,
    }
    const mapContainerStyle = {
        height: 'inherit',
        position: 'relative',
        pointerEvents: 'inherit',
        height: '100%'
    }

    /* --------------------------------------------------------------------- */
    // onsubmit form data
    const [getPlaybackData, { data: playbackData, isLoading: playbackLoading, isSuccess: plackbackSuccess }] = usePlaybackMutation();
    const [auditLog] = useAuditLogMutation();

    let commonPayload = {}
    const [payload, setPayload] = useState(null);
    const onSubmitForm = (data) => {
        setCurrentLocation({ target: 1, lat: null, lng: null, distance: 0 });
        commonPayload.truckId = data.vehicleId;
        commonPayload.orgId = data.fleetId;
        commonPayload.startDate = data.fromDate;
        commonPayload.endDate = data.toDate;
        commonPayload.startOdometer = 0;
        commonPayload.endOdometer = 0;
        //Service Call
        getPlaybackData(commonPayload);
        setPayload(data);
        setTrailsData([]);
        setPlayState(false);
        setAnimPath([]);
        setPlay(false);


        //Audit Log
        auditPayload.message = "Tracking > PlayBack";
        auditPayload.action = "View";
        auditPayload.featureName = "Playback";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.orgId = orgId;
        auditPayload.userId = userId;

        auditLog(auditPayload);


    }

    //Play Data
    let playPath = [];
    let [markerData, setMarkerData] = useState([]);
    let [pathData, setPathData] = useState([]);
    let [rotation, setRotation] = useState(90);
    let [animPath, setAnimPath] = useState([]);
    var speed = 120; // km/h
    var delay = 100;
    let movingMarkerRef = useRef();
    let timerRef = useRef();
    let [play, setPlay] = useState(false);
    let [playState, setPlayState] = useState(false);
    let [speedValue, setSpeedValue] = useState(500000);
    let [newSpeed, setNewSpeed] = useState(50000);

    let [currentLocation, setCurrentLocation] = useState({ target: 1, lat: null, lng: null, distance: 0 });
    const handleCloseDialog = () => {
        setOpenDailag(false)
    }
    const handleOpenDialog = () => {
        setOpenDailag(!openDialog)
    }
    const handleClosePopup = () => {
        setOpenpPopup(false)
    }
    const handleOpenPopup = () => {
        setOpenpPopup(true)
    }


    //Playback Trip data
    const [tripData, setTripData] = useState(null);
    const [playbackDatas, setPlaybackDatas] = useState([]);
    const [trailsData, setTrailsData] = useState([]);
    const [newTrailDatas, setnewTrailDatas] = useState(trailsData);
    const [categoryOrgId, setCategoryOrgId] = useState(null);
    const [rawData, setRawData] = useState(null);
    //Handle Days
    let [days, setDays] = useState(1);
    useEffect(() => {
        setnewTrailDatas(trailsData);
    }, [trailsData]);
    //Playback Data
    useEffect(() => {
        if (plackbackSuccess) {
            if (playbackData?.playBackList.length > 0 && playbackData !== null && playbackData !== undefined) {
                setPlaybackDatas(playbackData?.playBackList[0]);
                setTripData(playbackData);
                setRawData(playbackData);
                handleDays(1, 1);
                //setData
                setMaxZoom(22);
                setMapzoom(12);
                setDefaultCenter(playPath[0]);
                movingMarkerRef?.current?.marker.setPosition(playPath[0]);
            } else {
                setMaxZoom(5);
                setMapzoom(5);
                setDefaultCenter({ lat: 52.92214338014607, lng: -1.4703258589163344 });
                map?.panTo({ lat: 52.92214338014607, lng: -1.4703258589163344 })
            }
        } else {
            setMaxZoom(5);
            setMapzoom(5);
            setDefaultCenter({ lat: 52.92214338014607, lng: -1.4703258589163344 });
            map?.panTo({ lat: 52.92214338014607, lng: -1.4703258589163344 })
        }
        auditPayload.message = "Tracking > PlayBack";
        auditPayload.action = "View";
        auditPayload.featureName = "Playback";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditPayload.orgId = orgId;
        auditPayload.userId = userId;

        auditLog(auditPayload);

    }, [plackbackSuccess])

    //Play & Pause
    const playAnimation = () => {
        setPlay(!play);
    }

    useEffect(() => {
        if (play) {
            if (markerData.length <= 0) {
                handleDays(0, 0);
            }
            if (globalMap === 1) {
                animateMarker(movingMarkerRef.current, speed, true);
            }
            else if (globalMap === 2) {
                leafletAnimateMarker(speed, true);
            } else {
                animateMarker(movingMarkerRef.current, speed, true);
            }
            setDefaultCenter({ lat: movingMarkerRef.current?.marker.position.lat(), lng: movingMarkerRef.current?.marker.position.lng() });
            setPlayState(true);
        } else {
            clearTimeout(timerRef.current);
        }
    }, [play])


    const userData = JSON.parse(localStorage.getItem('userData'));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;
    let mapTypeData = userData.mapType;

    useEffect(() => {
        setGlobalMap(mapTypeData)
    }, [mapTypeData])

    let auditPayload = {
        //Audit Common Payload
        orgId: orgId,
        userId: userId, //User ID
        orgName: orgName, //Org Name
        userName: userName,
    };
    let VehicleType = userData.vehicleType;
    let [vehicle, setVehicle] = useState(null);

    const getVehicleType = () => {
        switch (VehicleType) {
            case 'TwoWheeler':
                setVehicle(2);
                break;
            case 'ThreeWheeler':
                setVehicle(3);
                break;
            case 'Truck':
                setVehicle(4);
                break;
            case 'Car':
                setVehicle(5);
                break;
            default:
                setVehicle(2);
        }
    }


    useEffect(() => {
        if (VehicleType) {
            getVehicleType();
        }
        setCategoryOrgId(orgId)
    }, [])

    useEffect(() => {
        if (map !== null && map !== undefined) {
            setTimeout(() => {
                reCenter()
            }, 1000)
        }
    }, [map])

    const reCenter = () => {
        map?.setZoom(6);
        map?.panTo(defaultCenter);
        // if(!!navigator.geolocation) {
        //     navigator.geolocation.getCurrentPosition((position) => {
        //        setDefaultCenter({lat:parseFloat(position?.coords?.latitude), lng:parseFloat(position?.coords?.longitude)})
        //         map?.panTo({lat:parseFloat(position?.coords?.latitude), lng:parseFloat(position?.coords?.longitude)});
        //         map?.setZoom(3);
        //     })
        // }
    }

    const playicon = {
        path: vehicle === 2 ? bikePath : vehicle === 3 ? auto : vehicle === 4 ? truck : vehicle === 5 && car,
        fillColor: 'green',
        strokeWeight: .2,
        strokeColor: '#fff',
        rotation: rotation,
        scale: 1.3,

    }

    useEffect(() => {
        if (playbackData && playbackData.playBackList && playbackData.playBackList[0]?.playBackList[0]) {
            const firstItem = playbackData.playBackList[0].playBackList[0];
            const [lat, lng] = firstItem.latlng.split(',').map(coord => parseFloat(coord));
            setLeafletMarker([lat, lng]);
        }
    }, [playbackData]);

    useEffect(() => {
        if (map !== null && map !== undefined && markerData.length > 0) {
            let point1 = { lat: markerData[0][0], lng: markerData[0][1] }
            let point2 = { lat: markerData[2][0], lng: markerData[2][1] }
            var angle = window.google.maps.geometry.spherical.computeHeading(point1, point2);
            setRotation(angle);
        }
    }, [map, markerData])

    let linerArray = [];
    //Marker Animation
    const animateMarker = (marker, km_h, state) => {
        var target = currentLocation.target;
        var pathAnim = [];
        var km_h = km_h || 50;
        setMapzoom(14);
        setMaxZoom(14);
        //setTrailsData([...trailsData, playbackData.playBackList[0].playBackList[0]]);
        function goToPoint() {
            let part = [];
            part.push(playbackData.playBackList[0].playBackList[target]);
            if (part[0] !== undefined) {
                linerArray = [...part, ...linerArray];
                setTrailsData(linerArray);
                var lat = marker?.marker?.position.lat();
                var lng = marker?.marker?.position.lng();
                var step = (km_h * 1000 * delay) / speedValue; // in meters
                var dest = new window.google.maps.LatLng(markerData[target][0], markerData[target][1]);
                var distance = window.google.maps.geometry.spherical.computeDistanceBetween(dest, marker.marker.position); // in meters
                setCurrentLocation({ target: target, distance: distance, lat, lng })
                let point1 = { lat: markerData[target - 1][0], lng: markerData[target - 1][1] }
                let point2 = { lat: markerData[target][0], lng: markerData[target][1] }
                var angle = window.google.maps.geometry.spherical.computeHeading(point1, point2);
                setRotation(angle);
                var numStep = distance / step;
                var i = 0;
                var deltaLat = (parseFloat(markerData[target][0]) - lat) / numStep;
                var deltaLng = (parseFloat(markerData[target][1]) - lng) / numStep;
                function moveMarker() {
                    lat += deltaLat;
                    lng += deltaLng;
                    i += step;
                    setCurrentLocation({ lat: lat, lng: lng, target, distance })
                    if (i < distance) {
                        let pathObj = { lat: lat, lng: lng }
                        pathAnim.push(pathObj);
                        setAnimPath([...animPath, ...pathAnim])
                        marker.marker.setPosition(new window.google.maps.LatLng(lat, lng));
                        map.panTo({ lat: lat, lng: lng });
                        timerRef.current = setTimeout(moveMarker, delay);
                    }
                    else {
                        target++;
                        timerRef.current = setTimeout(goToPoint, delay);
                    }
                }
                moveMarker();
            } else {
                setPlay(false);
                clearTimeout(timerRef.current);
            }
        }
        goToPoint();

    }

    //Leaflet animation
    const leafletAnimateMarker = (km_h, state) => {
        var target = currentLocation.target;
        var pathAnim = [];
        var km_h = km_h || 50;

        function goToPoint() {
            let part = [];
            part.push(playbackData.playBackList[0].playBackList[target]);
            if (part[0] !== undefined) {
                linerArray = [...part, ...linerArray];
                setTrailsData(linerArray);
                var lat = leafletMarker[0];
                var lng = leafletMarker[1];
                var step = (km_h * 1000 * delay) / speedValue;
                var dest = new window.google.maps.LatLng(markerData[target][0], markerData[target][1]);
                var distance = window.google.maps.geometry.spherical.computeDistanceBetween(dest, new window.google.maps.LatLng(leafletMarker[0], leafletMarker[1]));
                setCurrentLocation({ target: target, distance: distance, lat, lng })
                let point1 = { lat: markerData[target - 1][0], lng: markerData[target - 1][1] }
                let point2 = { lat: markerData[target][0], lng: markerData[target][1] }
                var angle = window.google.maps.geometry.spherical.computeHeading(point1, point2);
                setRotation(angle);
                var numStep = distance / step;
                var i = 0;
                var deltaLat = (parseFloat(markerData[target][0]) - lat) / numStep;
                var deltaLng = (parseFloat(markerData[target][1]) - lng) / numStep;
                function moveMarker() {
                    lat += deltaLat;
                    lng += deltaLng;
                    i += step;
                    setCurrentLocation({ lat: lat, lng: lng, target, distance })
                    if (i < distance) {
                        let pathObj = { lat: lat, lng: lng }
                        pathAnim.push(pathObj);
                        setAnimPath([...animPath, ...pathAnim])
                        leafletMarker[0] = lat;
                        leafletMarker[1] = lng;
                        timerRef.current = setTimeout(moveMarker, delay);
                    }
                    else {
                        target++;
                        timerRef.current = setTimeout(goToPoint, delay);
                    }
                }
                moveMarker();
            } else {
                setPlay(false);
                clearTimeout(timerRef.current);
            }
        }
        goToPoint();
    }
    //Stop Animation
    const stopAnimation = () => {
        onSubmitForm(payload);
        clearTimeout(timerRef.current);
        setCurrentLocation({ target: 1, lat: null, lng: null, distance: 0 });


    }

    //onSpeed
    const onSpeed = (e, value) => {
        setNewSpeed(value);
        setSpeedValue(500000 - value - 150000);
        setPlay(false);
        setTimeout(() => {
            setPlay(true);
        }, 100)
    }

    //Add Speed
    const speedAdd = () => {
        setNewSpeed(newSpeed + 2500);
        setSpeedValue(500000 - 2500 - 150000);
        setPlay(false);
        setTimeout(() => {
            setPlay(true);
        }, 0)
    }

    //subtract Speed
    const speedminus = () => {
        setNewSpeed(newSpeed - 2500);
        setSpeedValue(500000 - 2500 - 150000);
    }


    //Dashed Line
    const dashedLine = {
        path: "M 0,-1 0,1",
        strokeOpacity: 1,
        scale: 1.2,
    };


    //Download PDF

    const [openPdf, setOpenPdf] = useState(false);
    const closePDF = () => {
        setOpenPdf(false);
    }
    const downloadPDF = () => {
        setOpenPdf(true);
    };



    const onChangeXcl = async () => {
        try {
            var url =
                process.env.REACT_APP_API_BASE_URL +
                //"ManualCanDataReport/vehicledataXLS.xls?startDate=" +
                "ManualCanDataReport/categoryVehicledataForMontra.csv?startDate=" +
                payload.fromDate +
                "&endDate=" +
                payload.toDate +
                "&orderDir=" +
                'desc' +
                "&orgId=" +
                categoryOrgId +
                "&status=" + "9" +
                // vehicleTrails?.commonData[0]?.propVehicleStatus +
                "&resellerId=" + userData.resellerId +
                "&vehicleId=" +
                rawData?.summaryData[0]?.vehicleId;
            window.open(url);
            window.focus();
            let auditPayload = {};
            auditPayload.message = 'MapView > VehicleSummary > CSV';
            auditPayload.action = 'View';
            auditPayload.featureName = 'VehicleSummary';
            auditPayload.customerName = 'All';
            auditPayload.fleetName = 'All';
            auditPayload.result = 'Success';
            auditPayload.access = 'Portal';
            auditPayload.severity = 'INFO';

            auditLog(auditPayload);
            fetch(url, {
                headers: {
                    Authorization:
                        "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
                },
            }) // FETCH BLOB FROM IT
                .then((response) => {
                    response.blob();
                })
                .then((blob) => { })
                .catch((err) => { });

        } catch (error) { }
    };

    //   const onChangeXcl = async () => {
    //     try {

    //      var url =
    //      process.env.REACT_APP_API_BASE_URL +
    //      //"ManualCanDataReport/vehicledataXLS.xls?startDate=" +
    //      "ManualCanDataReport/categoryVehicledataForMontra.csv?startDate=" +
    //      moment(rawData.tripStartDate).format('DD-MM-YYYY HH:mm:00') +
    //      "&endDate=" +
    //      moment(rawData.tripEndDate).format('DD-MM-YYYY HH:mm:59')  +
    //      "&orderDir=" +
    //      'desc' +
    //      "&orgId=" +
    //      categoryOrgId +
    //      "&status="+"9"+
    //     // vehicleTrails?.commonData[0]?.propVehicleStatus +
    //      "&resellerId=" +userData.resellerId+
    //      "&vehicleId=" +
    //      rawData?.summaryData[0]?.vehicleId;
    //      window.open(url);
    //      let auditPayload={};
    //      auditPayload.message = 'MapView > VehicleSummary > CSV';
    //      auditPayload.action = 'View';
    //      auditPayload.featureName = 'VehicleSummary';
    //      auditPayload.customerName = 'All';
    //      auditPayload.fleetName = 'All';
    //      auditPayload.result = 'Success';
    //      auditPayload.access = 'Portal';
    //      auditPayload.severity = 'INFO';

    //      auditLog(auditPayload);


    //       fetch(url, {
    //         headers: {
    //           Authorization:
    //             "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
    //         },
    //       }) // FETCH BLOB FROM IT
    //         .then((response) => {
    //           response.blob();
    //         })
    //         .then((blob) => {})
    //         .catch((err) => {});

    //     } catch (error) {}
    //   };

    //Handle Days
    const handleDays = (event, selectDay) => {
        if (selectDay !== null) {
            setDays(selectDay);
            if (selectDay === 1) {
                setTripData(playbackData?.playBackList[0]);
                setPlaybackDatas(playbackData?.playBackList[0].playBackList);
                let play = playbackData?.playBackList[0].playBackList;
                pathGenrate(play);
            } else if (selectDay === 2) {
                setTripData(playbackData?.playBackList[1]);
                setPlaybackDatas(playbackData?.playBackList[1].playBackList);
                let play = playbackData?.playBackList[1].playBackList;
                pathGenrate(play);
            } else {
                if (playbackData?.playBackList.length > 1) {
                    setTripData(playbackData);
                    setPlaybackDatas([...playbackData?.playBackList[0].playBackList, ...playbackData?.playBackList[1].playBackList]);
                    let play = [...playbackData?.playBackList[0].playBackList, ...playbackData?.playBackList[1].playBackList];
                    pathGenrate(play)
                } else {
                    setTripData(playbackData);
                    setPlaybackDatas(playbackData?.playBackList[0].playBackList);
                    let play = playbackData?.playBackList[0].playBackList;
                    pathGenrate(play)
                }
            }
        }

    }

    const pathGenrate = (pathList) => {
        let array = [];
        pathList.forEach((key, index) => {
            let latlng = key.latlng.split(',')
            let playPathItem = {};
            playPathItem.lat = parseFloat(latlng[0]);
            playPathItem.lng = parseFloat(latlng[1]);
            playPath.push(playPathItem);
            array.push(Object.values(playPathItem));
        });
        setMarkerData([...array]);
        setPathData([...playPath]);
    }

    const handleMapZoom = () => {
        setMapzoom(map?.zoom);
    }


    const [movingTooltip, setMovingToolTip] = useState(true)
    const googleMap = useMemo(() => {

        return (
            isLoaded ?
                <GoogleMap
                    options={{
                        mapTypeId: mapType,
                        ...options,
                    }}
                    mapContainerStyle={mapContainerStyle}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    zoom={mapzoom}
                    onZoomChanged={handleMapZoom}
                >
                    {
                        //moving Marker
                        playbackData !== null && playbackData !== undefined &&
                        <Marker
                            position={pathData[0]}
                            ref={movingMarkerRef}
                            clickable
                            onClick={() => setMovingToolTip(!movingTooltip)}
                            options={{
                                icon: {
                                    ...playicon,
                                    anchor: new window.google.maps.Point(10, 32),
                                    fillOpacity: playState ? 1 : 0,
                                },
                                title: 'Start'
                            }}
                        >
                            {
                                movingTooltip && trailsData.length > 0 && playbackData !== null && playbackData !== undefined &&
                                <InfoWindow
                                    position={pathData[0]}
                                    onCloseClick={() => setMovingToolTip(false)}
                                >
                                    <Box>
                                        {
                                            trailsData.length > 0 &&
                                            <Paper sx={{ width: '280px', p: 1.5 }}>
                                                <Stack direction={'row'} justifyContent={'space-between'}>
                                                    <Typography variant='body2'>{trailsData[0]?.startDate}</Typography>
                                                    <Chip size='small' color={trailsData[0]?.vehicleStatus === '0' ? 'error' : trailsData[0]?.vehicleStatus === '1' ? 'success' : trailsData[0]?.vehicleStatus === '2' ? 'info' : 'default'} label={trailsData[0]?.vehicleStatus === '0' ? 'Stop' : trailsData[0]?.vehicleStatus === '1' ? 'Moving' : trailsData[0]?.vehicleStatus === '2' ? 'Idle' : 'default'} />
                                                </Stack>
                                                <Stack direction={'row'} justifyContent={'space-between'}>
                                                    {/* <Box>
                                                        <Typography variant='caption'>SOC (%)</Typography>
                                                        <Typography variant='body2'>{trailsData[0]?.soc}</Typography>
                                                    </Box>  */}
                                                    <Box>
                                                        <Typography variant='caption'>Odometer</Typography>
                                                        <Typography variant='body2'>{trailsData[0]?.odometer}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant='caption'>Speed</Typography>
                                                        <Typography variant='body2'>{trailsData[0]?.speed}</Typography>
                                                    </Box>
                                                </Stack>
                                            </Paper>
                                        }

                                    </Box>
                                </InfoWindow>
                            }

                        </Marker>
                    }
                    {
                        //End Marker
                        playbackData !== null && playbackData !== undefined && !playState &&
                        <Marker
                            position={pathData[0]}
                            options={{
                                icon: {
                                    url: startMarker,
                                    scale: 1.5
                                },
                            }}
                            zIndex={9999}
                        />
                    }
                    {
                        //End Marker
                        playbackData !== null && playbackData !== undefined && !playState &&
                        <Marker
                            position={pathData.slice(-1)[0]}
                            options={{
                                icon: {
                                    url: endMarker,
                                    scale: 1.5
                                },
                            }}
                        />
                    }
                    {
                        /* Solid Line */
                        playbackData !== null && playbackData !== undefined &&
                        <Polyline
                            path={playState ? animPath : pathData}
                            options={{
                                strokeColor: '#28282b',
                                strokeWeight: 3.5,
                                geodesic: true,
                                strokeOpacity: .9,
                            }}
                        />
                    }

                    {
                        /* Dashed Line */
                        playbackData !== null && playbackData !== undefined &&
                        <Polyline
                            path={playState ? animPath : pathData}
                            options={{
                                strokeColor: '#f8f8f8',
                                strokeWeight: .1,
                                geodesic: true,
                                strokeOpacity: 1,
                                zIndex: 999,
                                icons: [
                                    {
                                        icon: dashedLine,
                                        offset: "0",
                                        repeat: '20px'
                                    }
                                ]
                            }}
                        />
                    }
                </GoogleMap>
                :
                <Backdrop open={true} sx={{ position: 'absolute', background: alpha(theme.palette.background.paper, .6), zIndex: 999 }}>
                    <GridLoader color={theme.palette.secondary.main} />
                </Backdrop>
        )

    }, [isLoaded, mapzoom, mapType, playbackData, pathData, markerData, playState, animPath, trailsData, rotation])

    const LeafletMap = useMemo(() => {
        return (
            <>
                <Backdrop
                    // open={true}
                    sx={{
                        position: "absolute",
                        background: alpha(theme.palette.background.paper, 0.6),
                        zIndex: 999,
                    }}
                >
                    <GridLoader color={theme.palette.secondary.main} />
                </Backdrop>
                {isLoaded ? (
                    <LeafletPlayback playbackData={playbackData} play={play} markerData={markerData} trailsData={trailsData} animPath={animPath} pathData={pathData} rotation={rotation} mapzoom={mapzoom} mapType={mapType} />
                ) : (
                    'Loading'
                )}
            </>
        );
    }, [isLoaded, mapzoom, mapType, playbackData, pathData, markerData, playState, animPath, trailsData, rotation])


    let mapSetting;

    if (globalMap === 1) {
        mapSetting = googleMap;
    } else if (globalMap === 2) {
        mapSetting = LeafletMap;
    } else {
        mapSetting = googleMap;
    }


    const handleZoom = (value, action) => {
        if (action === 'zoom') {
            setMapzoom(value);
        } else {
            setMapType(value);
        }
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const openSummary = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };





    return (
        <StyledPageLayout sx={{ p: 0, width: "100%", height: '100%', overflow: 'hidden' }}>
            <Backdrop open={playbackLoading} sx={{ position: 'absolute', background: alpha(theme.palette.background.paper, .6), zIndex: 999 }}>
                <GridLoader color={theme.palette.secondary.main} />
            </Backdrop>
            <Box sx={{ p: 2 }}>
                <Breadcrumb mainDiv="Tracking" subDiv="Playback" />
                <FilterDropdown getData={onSubmitForm} status={false} isShowAll={false} allowDays={1} onload={false} last7={false} time={true} offToday={true} allowedMonth={3} />
            </Box>
            <Box sx={{ height: '80%', overflowY: 'hidden' }}>
                <Grid container sx={{ height: '80vh', overflowY: 'hidden' }}>
                    {
                        playbackData !== null && playbackData !== undefined &&
                        <Grid item lg={2} sx={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
                            <Paper elevation={0} sx={{ p: 1.2, height: '100%', overflowY: 'hidden' }}>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-start'}>
                                    <Stack direction={'row'} alignItems={'center'}>
                                        <StyledToggleButtonGroup
                                            onChange={handleDays}
                                            value={days}
                                            size='small'
                                            exclusive
                                        >
                                            <StyledToggleButton sx={{ py: '5px !important' }} value={0}>All</StyledToggleButton>
                                            {
                                                rawData?.playBackList !== undefined && rawData?.playBackList?.map((item, index) =>

                                                    <StyledToggleButton value={index + 1} sx={{ py: '5px !important' }}>
                                                        <Tooltip title='Date'>
                                                            <Typography variant='body1'>{index + 1}</Typography>
                                                        </Tooltip>

                                                    </StyledToggleButton>
                                                )
                                            }
                                        </StyledToggleButtonGroup>
                                    </Stack>
                                    <Tooltip title='Summary' arrow>
                                        <IconButton color='success' sx={{ borderRadius: "5px", border: "1px solid #dbdbdb" }} disabled={tripData === null} onClick={openSummary} >
                                            <MdSummarize fontSize="small" />
                                        </IconButton>
                                    </Tooltip>
                                </Stack>
                                <Box sx={{ height: '94%', overflow: 'hidden' }}>
                                    <Scrollbars
                                        autoHeight
                                        autoHeightMax={'96%'}
                                        style={{ height: '100%', overflowX: 'hidden' }}
                                        autoHide={true}
                                        autoHideDuration={1000}
                                        hideTracksWhenNotNeeded={false}
                                    >
                                        <MovingCard data={playbackDatas} tripData={tripData} trailsData={trailsData} rawData={rawData} selectDays={handleDays} selectedDay={days} />
                                    </Scrollbars>
                                </Box>
                            </Paper>
                        </Grid>
                    }

                    <Grid item lg={playbackData !== null && playbackData !== undefined ? 10 : 12} sx={{ position: 'relative', overflow: 'hidden', height: '100%' }}>
                        {mapSetting}

                        {playbackData !== null && playbackData !== undefined &&
                            <Stack direction={'row'} alignItems={'center'} spacing={1} sx={{ background: theme.palette.background.paper, position: 'absolute', top: '25px', left: '50%', zIndex: '9', px: 1, pointerEvents: 'all', transform: 'translateX(-50%)' }}>
                                <Tooltip title={play ? 'Pause' : 'Play'}>
                                    <IconButton onClick={playAnimation} size='small' >{play ? <FaPause /> : <FaPlay />} </IconButton>
                                </Tooltip>
                                <Tooltip title={'Stop'}>
                                    <IconButton onClick={stopAnimation} size='small'><FaStop /></IconButton>
                                </Tooltip>
                                <Tooltip title={'Minus'}>
                                    <IconButton size='small' onClick={speedminus} disabled={newSpeed === 10000} ><FaMinus /></IconButton>
                                </Tooltip>
                                <Slider
                                    sx={{
                                        width: "160px",
                                        margin: "0px 0px 0px 20px !important",
                                        "& .MuiSlider-track": {
                                            color: theme.palette.action.disabledBackground,
                                        },
                                        "& .MuiSlider-thumb": {
                                            color: theme.palette.primary.main,
                                        },
                                        "& .MuiSlider-rail": { color: "#ddd" },
                                    }}
                                    value={newSpeed}
                                    aria-label="Default"
                                    //key={`slider-${speedValue}`}
                                    step={10}
                                    max={200000}
                                    min={10000}
                                    valueLabelDisplay="off"
                                    onChange={onSpeed}
                                //disabled={playPath.length <= 0 }
                                />

                                <Tooltip title={'Plus'}>
                                    <IconButton size='small' onClick={speedAdd} disabled={newSpeed === 200000}><FaPlus /></IconButton>
                                </Tooltip>
                                {/* <IconButton> <FaForward /></IconButton>
        <IconButton> <FaBackward /></IconButton> */}
                            </Stack>
                        }
                        <MapOptions map={map} zoomValue={mapzoom} mapLayer={mapType} handleZoom={handleZoom} mapTypeData={mapTypeData} />
                    </Grid>
                </Grid>
                <Menu
                    id="playback_summary"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                        'aria-labelledby': 'playbackSummary-button',
                    }}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    PaperProps={{
                        sx: {
                            overflow: 'hidden',
                            '& .MuiList-root': {
                                padding: 0
                            }
                        },
                    }}
                >

                    <Box sx={{ width: '320px', height: '350px', overflowY: 'hidden' }}>
                        <Stack direction={'row'} sx={{ p: 1 }} alignItems={'center'} justifyContent={'space-between'}>
                            <Typography variant='body2'>Summary</Typography>
                            {
                                resellerId === 110224 &&
                                <Stack direction={'row'} spacing={1}>
                                    <Tooltip title='Excel' sx={{ zIndex: 3000 }}>
                                        <SoftButton
                                            className="success-soft"
                                            startIcon={<HiFolderDownload />}
                                            id="export-button"
                                            aria-haspopup="true"
                                            onClick={onChangeXcl}
                                            disableElevation
                                            color="primary"
                                            size="small"
                                        >
                                            CSV
                                        </SoftButton>
                                        {/* <SoftButton className='success-soft' color='success' size='small' onClick={onChangeXcl}><BsFileEarmarkExcel /></SoftButton> */}
                                    </Tooltip>
                                    <Tooltip title='PDF' sx={{ zIndex: 3000 }}>
                                        <SoftButton
                                            className="error-soft"
                                            startIcon={<HiFolderDownload />}
                                            id="export-button"
                                            aria-haspopup="true"
                                            onClick={downloadPDF}
                                            disableElevation
                                            color="secondary"
                                            size="small"
                                        >
                                            PDF
                                        </SoftButton>
                                        {/* <SoftButton className='error-soft' color='error' size='small' onClick={downloadPDF}><BsFileEarmarkPdf /></SoftButton> */}
                                    </Tooltip>
                                </Stack>
                            }
                            <Tooltip title='Close' arrow>
                            <IconButton color="error" onClick={() => setAnchorEl(null)}>
                                <IoMdClose fontSize={'small'}/>
                            </IconButton>
                            </Tooltip>
                        </Stack>
                        <Divider />
                        <SummaryPdf tripData={tripData} rawData={rawData} openPdf={openPdf} closePDF={closePDF} />
                        <Summary data={rawData} />
                    </Box>
                </Menu>
            </Box>
        </StyledPageLayout>
    )
}

export default TryPlayback