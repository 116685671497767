import { Box, Button, CardMedia, Menu, MenuItem, Stack } from '@mui/material'
import React, { useEffect, useState } from 'react'
import i18next from 'i18next';
function LanguageSwitcher() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    let lngCode = localStorage.getItem("i18nextLng");
    const lang = [
        {
          id:1,
          code:'en',
          name:'English',
          flagcode:'GB'
        },
        {
          id:2,
          code:'tr',
          name:'Turkish',
          flagcode:'TR'
        }
      ]
    const [selectLng, setSelectLng] = React.useState(lngCode);
    const [value, setValue] = useState(lang[0]);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLng = (item, index) =>{
        i18next.changeLanguage(item.code)
        //window.localStorage.setItem('i18nextLng', item.code)
        setValue(item);
        //window.location.reload();
        handleClose();
    }



    useEffect(() => {
        if(lngCode !== undefined){
            const setLng = lang.find((item, index) => item.flagcode.toLowerCase() === lngCode);
            setSelectLng(setLng?.flagcode?.toLowerCase());
            setValue(setLng);
        }
        
    },[])




  
  return (
    <Box>
        {
            value !== 0 && <Button onClick={handleClick} startIcon={
                <CardMedia component={'img'} sx={{width:'30px', height:'auto'}} src={`https://flagcdn.com/w20/${value?.flagcode !== undefined ? value?.flagcode?.toLowerCase() : 'gb'}.png`}/>
                }>{value?.name !== undefined ? value?.name : 'English'}</Button>
        }
        
        <Menu
        id="language-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
            'aria-labelledby': 'language-button',
        }}
        >
        {
            lang.map((item, index) => 
            <MenuItem onClick={() => handleLng(item, index)} selected={lngCode === item?.code?.toLowerCase() ? true : false} key={index}>
                <Stack direction={'row'} spacing={3}>
                    <CardMedia component={'img'} sx={{width:'30px', height:'auto', mr:1}} src={`https://flagcdn.com/w20/${item.flagcode.toLowerCase()}.png`}/>
                    {item.name}
                </Stack>
            </MenuItem>)
        }
        
        </Menu>
    </Box>
  )
}

export default LanguageSwitcher