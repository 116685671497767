import { InfoWindow, Marker, Polyline } from "@react-google-maps/api"
import { createRef, useEffect, useRef, useState } from "react"
import { ChargingDotIcon, GreenFirst, GreenLast, IdleDotIcon, MovingDotIcon, OfflineDotIcon, StopDotIcon } from "../Constans";
import { Box, ClickAwayListener, Divider, Fab, Icon, Skeleton, Stack, Typography, alpha, useTheme } from "@mui/material";
//import InfowindowTrailsMarker from "./NewTreeView/Infowindow";
import { StyledInfoWindow } from "./Treeview/TreeviewStyledEle";
import { FaParking, FaRoad } from "react-icons/fa";
import { FaPlugCircleBolt } from "react-icons/fa6";
import { MdSignalWifiBad } from "react-icons/md";
import reportServices from "../../services/reportServices";
import Nosignal from '../../assets/images/StatusGif/offlineImage.png'
import { BsStoplightsFill } from "react-icons/bs";
import { MovingBike, OfflineBike, IdleBike, StopBike, ChargingBike, MovingAuto, OfflineAuto, IdleAuto, StopAuto, ChargingAuto, MovingTruck, OfflineTruck, IdleTruck, StopTruck, ChargingTruck, ChargingGifIcon, StopCar, ChargingCar, MovingCar, OfflineCar, MovingScvm, OfflineScvm, IdleScvm, StopScvm, ChargingScvm, MovingTractorm, OfflineTractorm, IdleTractorm, StopTractorm, ChargingTractorm, StopRick, ChargingRick, MovingRick, OfflineRick, IdleRick, IdleCar } from '../Constans';

import { useSelector } from "react-redux";
import moment from "moment";

//Start Marker
export const StartMarker = (props) => {
    //const startMarkerRef = useRef();
    const { data } = props;
    //Start Maker icon
    let icon = {
        url: Nosignal,
        scale: 1,
        fillOpacity: 1,
    }
    return (
        data !== null &&
        <Marker
            id={data?.lng}
            position={{ lat: data?.lat, lng: data?.lng }}
            clickable
            markerData={''}
            icon={icon}
        />
    )
}

//Offline Marker
export const OfflineMarker = (props) => {
    //const { position } = props;
    //Start Maker icon
    let icon = {
        url: Nosignal,
        scale: 1,
        fillOpacity: 1,
    }
    return (
        <Marker
            //id={data?.lng}
            position={{ lat: 52.92214338014607, lng: -1.4703258589163344 }}
            clickable
            markerData={''}
            icon={icon}
        />
    )
}



//End Marker
export const EndMarker = (props) => {
    const startMarkerRef = useRef();
    const { data } = props;

    //Start Maker icon
    let icon = {
        url: GreenLast,
        scale: 1,
        fillOpacity: 1,
    }
    return (
        data !== null &&
        <Marker
            id={data[0]?.lng}
            position={{ lat: data[0]?.lat, lng: data[0]?.lng }}
            ref={startMarkerRef}
            clickable
            markerData={''}
            icon={icon}
        />
    )
}

//Polyline
export const TrailsPolyline = (props) => {
    const { data } = props;
    //Polyline Options
    const options = {
        strokeColor: '#006400',
        strokeWeight: 1.2,
        geodesic: true,
        icons: [{
            icon: { path: window.google.maps.SymbolPath.FORWARD_OPEN_ARROW },
            offset: '50%',
            repeat: '100px'
        }]
    }
    return (
        <Polyline
            path={data}
            options={options}
        />
    )
}

//Dot Markers
export const TrailsMarkersDot = (props) => {
    const { data, lastMoving, firstMoving, autoRefresh, activeFeature } = props;
    const TrailsMakerRef = data.map(() => createRef());
    const [markerData, setMarkerData] = useState(null);
    const theme = useTheme();
    const userData = JSON.parse(localStorage.getItem('userData'));
    let distanceUnit = userData.distanceDisplayUnit;
    let speedUnit = userData.speedDisplayUnit ;
    const date = useSelector((state) => state.controls.treeview.date);
    const iconsType = {
        Stop: {
            icon: StopDotIcon
        },
        Moving: {
            icon: MovingDotIcon
        },
        Charging: {
            icon: ChargingDotIcon
        },
        Offline: {
            icon: OfflineDotIcon
        },
        Idle: {
            icon: IdleDotIcon
        }
    }
    //Start Maker icon
    let Lasticon = {
        url: GreenLast,
        scale: 2,
        fillOpacity: 1,
        //anchor: new window.google.maps.Point(0, .02),
    }
    let Firsticon = {
        url: GreenFirst,
        scale: 2,
        fillOpacity: 1,
        //anchor: new window.google.maps.Point(0, .02),
    }
    //Trails Marker Data
    const getMakerData = (makerData) => {
        // console.log('makerData',makerData)
        // handleMapClose(makerData)
        if (makerData.current !== null && makerData.current !== undefined) {
            setMarkerData(makerData.current.props.dmarkerData);
        } else {
            setMarkerData(makerData);
        }
    }

    //Close Marker
    const onClose = () => {
        setMarkerData(null);
    }

    const BackgroundStyle = {
        background:
            (markerData !== null && markerData?.vehicleStatus === 'Stop') || (markerData !== null && markerData?.vehicleStatus) === '0' ? alpha(theme.palette.error.main, .9) :
                (markerData !== null && markerData?.vehicleStatus === 'Idle') || (markerData !== null && markerData?.vehicleStatus) === '2' ? alpha(theme.palette.info.main, .9) :
                    (markerData !== null && markerData?.vehicleStatus === 'Offline') || (markerData !== null && markerData?.vehicleStatus) === '4' ? alpha(theme.palette.grey[700], .9) :
                        (markerData !== null && markerData?.vehicleStatus === 'Moving') || (markerData !== null && markerData?.vehicleStatus) === '1' ? alpha(theme.palette.success.main, .9) : alpha(theme.palette.grey[700], .9)
    }

    let [address, setAddress] = useState(null);

    useEffect(() => {
        if (markerData !== null) {
            getAddress();
        }
    }, [markerData]);

    const getAddress = () => {
        setAddress(null);
        var requestN = {};
        requestN.latLng = markerData?.lat + "," + markerData?.lng;
        return reportServices.getGeocodeAddress(requestN)
            .then((resN) => {
                setAddress(resN.data);
            })
            .catch((err1) => {
                console.log(err1);
            });
    }

    useEffect(() => {
        if (autoRefresh) {
            setAddress(null);
            setMarkerData(null);
        } else if (!autoRefresh && data !== null) {
            if (data.length > 0) {
                getMakerData(data.slice(-1)[0]);
            }
        }


    }, [autoRefresh, data])

    useEffect(() => {
        if (activeFeature === 0) {
            onClose();
        }
    }, [activeFeature])

    let VehicleType = userData.vehicleType;
    let [vehicle, setVehicle] = useState(null);
    const getVehicleType = () => {
        switch (VehicleType) {
            case 'TwoWheeler':
                setVehicle(2);
                break;
            case 'ThreeWheeler':
                setVehicle(3);
                break;
            case 'Truck':
                setVehicle(4);
                break;
            case 'Car':
                setVehicle(5);
                break;
            case 'Scv':
                setVehicle(6);
                break;
            case 'Tractor':
                setVehicle(7);
                break;
            case 'ERick':
                setVehicle(8);
                break;
            case 'Bus':
                setVehicle(9);
                break;
            default:
                setVehicle(2);
        }
    }

    //Initially get Vehicle Type
    useEffect(() => {
        if (VehicleType) {
            getVehicleType();
        }
    }, [])

    // useEffect(() => {
    //     if(data === null ){
    //         handleMapClose();
    //     }

    //   }, []);

    //   const handleMapClose = (markerdata) =>{
    //     console.log('markerData',markerdata.current)
    //     document.body.addEventListener('click', () => {
    //         if(markerdata.current === null || markerdata.current === undefined ){
    //             // setMarkerData(null); 
    //             console.log('inside!',markerdata.current)
    //         }
    //     });
    //   }

    return (
        <>
            {
                data !== null &&
                data.map((markerItem, index) => {

                    let dataimg =
                        vehicle === 2 ? (markerItem.vehicleStatus === "0" || markerItem.vehicleStatus === "Stop") ? StopBike : (markerItem.vehicleStatus === "2" || markerItem.vehicleStatus === "Idle") ? IdleBike : (markerItem.vehicleStatus === 1 || markerItem.vehicleStatus === "Moving") ? MovingBike : (markerItem.vehicleStatus === "4" || markerItem.vehicleStatus === "Offline") ? OfflineBike : OfflineBike :

                            vehicle === 3 ? (markerItem.vehicleStatus === "0" || markerItem.vehicleStatus === "Stop") ? StopAuto : (markerItem.vehicleStatus === "2" || markerItem.vehicleStatus === "Idle") ? IdleAuto : (markerItem.vehicleStatus === 1 || markerItem.vehicleStatus === "Moving") ? MovingAuto : (markerItem.vehicleStatus === "4" || markerItem.vehicleStatus === "Offline") ? OfflineAuto : OfflineAuto :

                                vehicle === 4 ? (markerItem.vehicleStatus === "0" || markerItem.vehicleStatus === "Stop") ? StopTruck : (markerItem.vehicleStatus === "2" || markerItem.vehicleStatus === "Idle") ? IdleTruck : (markerItem.vehicleStatus === 1 || markerItem.vehicleStatus === "Moving") ? MovingTruck : (markerItem.vehicleStatus === "4" || markerItem.vehicleStatus === "Offline") ? OfflineTruck : OfflineTruck :

                                    vehicle === 5 ? (markerItem.vehicleStatus === "0" || markerItem.vehicleStatus === "Stop") ? StopCar : (markerItem.vehicleStatus === "2" || markerItem.vehicleStatus === "Idle") ? IdleCar : (markerItem.vehicleStatus === 1 || markerItem.vehicleStatus === "Moving") ? MovingCar : (markerItem.vehicleStatus === "4" || markerItem.vehicleStatus === "Offline") ? OfflineCar : OfflineCar :

                                        vehicle === 6 ? (markerItem.vehicleStatus === "0" || markerItem.vehicleStatus === "Stop") ? StopScvm : (markerItem.vehicleStatus === "2" || markerItem.vehicleStatus === "Idle") ? IdleScvm : (markerItem.vehicleStatus === 1 || markerItem.vehicleStatus === "Moving") ? MovingScvm : (markerItem.vehicleStatus === "4" || markerItem.vehicleStatus === "Offline") ? OfflineScvm : OfflineScvm :

                                            vehicle === 7 ? (markerItem.vehicleStatus === "0" || markerItem.vehicleStatus === "Stop") ? StopTractorm : (markerItem.vehicleStatus === "2" || markerItem.vehicleStatus === "Idle") ? IdleTractorm : (markerItem.vehicleStatus === 1 || markerItem.vehicleStatus === "Moving") ? MovingTractorm : (markerItem.vehicleStatus === "4" || markerItem.vehicleStatus === "Offline") ? OfflineTractorm : OfflineTractorm ? '' : '' :
                                                vehicle === 8 ? (markerItem.vehicleStatus === "0" || markerItem.vehicleStatus === "Stop") ? StopRick : (markerItem.vehicleStatus === "2" || markerItem.vehicleStatus === "Idle") ? IdleRick : (markerItem.vehicleStatus === 1 || markerItem.vehicleStatus === "Moving") ? MovingRick : (markerItem.vehicleStatus === "4" || markerItem.vehicleStatus === "Offline") ? OfflineRick : OfflineRick ? '' : '' : ''

                    return (
                        <>

                            <Marker
                                key={index}
                                id={data[0]?.lng}
                                position={{ lat: markerItem.lat, lng: markerItem?.lng }}
                                ref={TrailsMakerRef[index]}
                                clickable
                                dmarkerData={markerItem}
                                icon={
                                    firstMoving?.lat === markerItem.lat && firstMoving?.lng === markerItem.lng ? Firsticon :
                                        lastMoving[0]?.lat === markerItem.lat && lastMoving[0]?.lng === markerItem.lng ? Lasticon :
                                            iconsType[markerItem.vehicleStatus]?.icon
                                }
                                onClick={() => getMakerData(TrailsMakerRef[index])}
                            />
                            {
                                data.length - 1 === index && date[0] === moment().format('YYYY-MM-DD 00:00:00') && date[1] === moment().format('YYYY-MM-DD 23:59:59') &&
                                <Marker
                                    key={data[0]?.lng}
                                    id={data[0]?.lng}
                                    position={{ lat: markerItem.lat, lng: markerItem?.lng }}
                                    zIndex={9999}
                                    options={{
                                        //anchorPoint: new window.google.maps.Point(0, 0),
                                        icon: {
                                            url: dataimg,
                                            anchor: new window.google.maps.Point(25, 32),
                                            scaledSize: new window.google.maps.Size(50, 50),
                                        }
                                    }}
                                    onClick={() => getMakerData(TrailsMakerRef[index])}
                                    ref={TrailsMakerRef[index]}
                                    clickable
                                    dmarkerData={markerItem}
                                />
                            }

                        </>
                    )
                })
            }
            {
                markerData !== null &&
                <InfoWindow
                    position={{ lat: markerData.lat, lng: markerData.lng }}
                    options={{ pixelOffset: new window.google.maps.Size(0, -60) }}
                    onCloseClick={onClose}
                >
                    {/* <ClickAwayListener
                        onClickAway={onClose}
                    > */}
                        <StyledInfoWindow>
                            <Box
                                className='infowindowHHeader'
                                sx={{ ...BackgroundStyle, width: '100%' }}
                            >
                                <Stack spacing={2} direction={'row'} divider={<Divider />} alignItems={'baseline'}>
                                    <Box>
                                        <Typography variant='caption'>Vehicle No </Typography>
                                        <Typography variant='subtitle2'>{markerData?.name}</Typography>
                                    </Box>
                                    <Fab disableTouchRipple size='small'
                                        sx={BackgroundStyle}
                                    >
                                        <Icon>
                                            {
                                                markerData?.vehicleStatus === 'Stop' || markerData?.vehicleStatus === '0' ? <FaParking /> :
                                                    markerData?.vehicleStatus === 'Charging' || markerData?.vehicleStatus === '6' ? <FaPlugCircleBolt /> :
                                                        markerData?.vehicleStatus === 'Offline' || markerData?.vehicleStatus === '4' ? <MdSignalWifiBad /> :
                                                            markerData?.vehicleStatus === 'Moving' || markerData?.vehicleStatus === '1' ? <FaRoad /> : <FaParking />
                                            }
                                        </Icon>
                                    </Fab>
                                </Stack>
                            </Box>
                            <Box sx={{ padding: '5px 15px', width: '100%' }}>
                                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} sx={{ width: '100%' }}>
                                    <Box sx={{ width: '100%' }}>
                                        <Stack direction={'column'} spacing={.5}>
                                            <Box>
                                                <Typography variant='caption'>Date</Typography>
                                                <Typography variant='subtitle2'>{markerData?.dispDate}</Typography>
                                            </Box>
                                            <Box>
                                                <Typography variant='caption'>Vehicle Status</Typography>
                                                <Typography variant='subtitle2'>{markerData?.vehicleStatus}</Typography>
                                            </Box>
                                            <Box>
                                                <Stack direction={'row'} justifyContent={'space-between'}>
                                                    <Box>
                                                        <Typography variant='caption'>Odometer ({distanceUnit})</Typography>
                                                        <Typography variant='subtitle2'>{markerData?.odometer}</Typography>
                                                    </Box>
                                                    <Box>
                                                        <Typography variant='caption'>Speed ({speedUnit}ph)</Typography>
                                                        <Typography variant='subtitle2'>{markerData?.speed}</Typography>
                                                    </Box>
                                                </Stack>
                                            </Box>
                                            <Box>
                                                <Typography variant='caption'>Address</Typography>
                                                {
                                                    address !== null ?
                                                        <Typography variant='subtitle2'> {address} </Typography> :
                                                        <Skeleton variant='subtitle2' />
                                                }
                                            </Box>
                                        </Stack>
                                    </Box>
                                </Stack>
                            </Box>

                        </StyledInfoWindow>
                    {/* </ClickAwayListener> */}
                </InfoWindow>

            }
        </>


    )
}
