import {
  Alert,
  Box,
  Tabs,
  Typography,
  useTheme
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import {
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTabsGroup
} from "../../theme/StyledEle";
import { Dashboards } from "../Constans";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { Breadcrumb } from "../shared/Breadcrumbs";
import Edashboard from "../echarts/Edashbord.js";
import TripDetailsDashboard from "./TripDetailsDashboard.js";
import ChordDiagram from "../../Utils/ChordDiagram.js";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box >{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};


export const NewDashboard = () => {
  const [showDashboard, setShowDashboard] = useState("-1");
  const [showCalendar, setShowCalendar] = useState(false);
  const [value, setValue] = React.useState(39);
  const [showMonth, setShowMonth] = useState(false);


  const globalJson1 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalUserName = "";
  var globalOrgName = "";
  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson?.resellerId;
    globalOrgName = globalJson.orgName;
    globalUserName = globalJson.userName;
  }


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId,
    orgName: globalOrgName,
    userName: globalUserName,
  };

  useEffect(() => {
    if (showDashboard === "-1") {
      setShowCalendar(false);
    }
  }, [showDashboard]);

  const handleChange = async (event, newValue) => {
    setValue(newValue);
    if (newValue === 40) {
      setShowCalendar(false);
      setShowMonth(false)
      auditPayload.message = "Dashboard > Dashboard >Last Connect";
    } else if (newValue === 41) {
      setShowCalendar(true);
      setShowMonth(false)
      auditPayload.message = "Dashboard > Dashboard > Vehicle Summary";
    }else if (newValue === 42) {
      setShowCalendar(false);
      setShowMonth(false)
      auditPayload.message = "Dashboard > Dashboard > Trip Details";
    }else if (newValue === 43) {
      setShowCalendar(true);
      setShowMonth(true)
      auditPayload.message = "Dashboard > Dashboard > Chord Diagram";
    } else{
      setShowCalendar(false);
    }
    // else if (newValue === 37) {
    //   setShowMonth(true)
    //   setShowCalendar(true);
    //   auditPayload.message = "Dashboard > Dashboard > Vehicle Rating";
    // }
    auditPayload.action = "View";
    auditPayload.featureName = "Dashboard";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;
    auditLog(auditPayload);
  };


  let userData = JSON.parse(localStorage.getItem("userData"));
  let RoleId = userData?.roleId;
  let orgType = userData?.userOrgType;
  let resellerId = userData?.resellerId
  let orgId = userData?.orgId
  //onsubmit
  let [formData, setFormData] = useState(null);
  const [date, setDate] = useState({ start: null, end: null });
  let [ZohoUrl, setZohoUrl] = useState(null);
  let [lastConnectUrl, setLastConnectUrl] = useState(null);
  let [vehicleSumUrl, setVehicleSumUrl] = useState(null);
  let [vehicleRatingUrl, setVehicleRatingUrl] = useState(null);
  let [vehicle, setVehicle] = useState(null);
  let iframeReload = useRef();
  //Hide & Show Tabs
  const tabsOpt = useSelector((state) => state.controls.customizer.currentPage.buttonOpt);
  const onSubmitForm = (data) => {
    setFormData(data);
    setDate({ start: data?.fromDate, end: data?.toDate });
    if (iframeReload?.current?.src !== undefined && iframeReload?.current !== null) {
      iframeReload.current.src = iframeReload?.current?.src;
    }

  };

  //dashboard urls
  let [Zoho, setZoho] = useState(null);
  let [lastConnet, setLastConnect] = useState(null);
  let [vehicleSummaryd, setVehicleSummaryd] = useState(null);
  let [vehicleRating, setVehicleRating] = useState(null);
  const findDashboard = () => {
    let findUrls = Dashboards?.filter((item) => item?.resellerId === userData?.resellerId);
    setZoho(findUrls[0]?.dashboardName[0].url);
    setLastConnect(findUrls[0]?.dashboardName[1].url);
    setVehicleSummaryd(findUrls[0]?.dashboardName[2].url);
    setVehicleRating(findUrls[0]?.dashboardName[3].url)
  };

  useEffect(() => {
    findDashboard();
  }, []);



  useEffect(() => {
    var customerId = "";
    var fleetId = "";
    var zohocustomerId = "";
    var zohofleetId = "";
    var vehicleId = "";
    let startTime = moment(date.start, "YYYY-MM-DD HH:mm:ss").valueOf();
    let endTime = moment(date.end, "YYYY-MM-DD HH:mm:ss").valueOf();
    let start = moment(date.start, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
    let end = moment(date.end, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD");
    let todayStartTime = moment().startOf('day').valueOf();
    let todayEndTime = moment(new Date(), "YYYY-MM-DD HH:mm:ss").valueOf();
    let selectedPeriod = "";

    if (formData !== null) {

      if (formData?.customerId !== "-1" && orgType !== 1) {
        zohocustomerId = '"CUSTOMER_ID"=' + formData?.customerId + "and";
        customerId = formData?.customerId;
      } else {
        customerId = "";
      }

      if (formData?.fleetId !== "-1") {
        zohofleetId = '"org_id"=' + formData?.fleetId + "and";
        fleetId = formData?.fleetId;
      } else {
        fleetId = "";
      }
      if (formData?.vehicleId !== "-1") {
        vehicleId = formData?.vehicleId;
        setVehicle(formData?.vehicleId);
      } else {
        vehicleId = "";
        setVehicle(formData?.vehicleId);
        //setValue(39);
      }
      if (formData?.selectedPriod !== "-1") {
        selectedPeriod = formData?.selectedPriod;
      } else {
        selectedPeriod = "";
      }
    }
    // setZohoUrl(
    //   Zoho +
    //   "&from=" +
    //   startTime +
    //   "&to=" +
    //   endTime +
    //   "&var-customer_id=" +
    //   customerId +
    //   "&var-org_id=" +
    //   fleetId +
    //   "&var-selectPeriod=" +
    //   selectedPeriod +
    //   "&var-Start_date=" +
    //   start +
    //   "&var-End_date=" +
    //   end +
    //   "&var-Date=" +
    //   start +
    //   "&kiosk"
    // );
    setZohoUrl(`https://analytics.zoho.in/open-view/353263000002037807?ZOHO_CRITERIA=` +
      zohocustomerId +
      zohofleetId +
    '"RESELLER_ID"=' +
    userData?.resellerId +
    "");

    if (showDashboard !== '-1' && showDashboard !== null) {
      setLastConnectUrl(
        lastConnet +
        "&var-vehicle_id=" +
        vehicleId +
        "&var-Start_date=" +
        moment().format("YYYY-MM-DD") +
        "&var-End_date=" +
        moment().format("YYYY-MM-DD") +
        "&from=" +
        todayStartTime +
        "&to=" +
        todayEndTime +
        "&kiosk"
      );

      let SummaryUrl = selectedPeriod !== 1 && selectedPeriod !== 0 && selectedPeriod !== 9 ? vehicleSummaryd : 'https://analytics.assetmatics.live:3000/d/ddtz7f494ui9sc/obd-vehicle-details?orgId=1'
      setVehicleSumUrl(
        SummaryUrl +
        "&from=" +
        startTime +
        "&to=" +
        endTime +
        "&var-customer_id=" +
        customerId +
        "&var-org_id=" +
        fleetId +
        "&var-selectPeriod=" +
        selectedPeriod +
        "&var-vehicle_id=" +
        vehicleId +
        "&var-Start_date=" +
        start +
        "&var-End_date=" +
        end +
        "&var-Date=" +
        start +
        "&kiosk"
      );
      setVehicleRatingUrl(
        vehicleRating +
        "&var-vehicle_id=" +
        vehicleId +
        "&var-Start_date=" +
        start +
        "&var-End_date=" +
        end +
        "&var-reseller_id=" +
        orgId +
        "&kiosk"
      );
    }
  }, [formData, showDashboard, date]);
  useEffect(() => {
    if (vehicle !== "-1" && vehicle !== null) {
      setShowDashboard(vehicle);
      if (tabsOpt !== null) {
        setValue(tabsOpt[1])
        setShowCalendar(false);
      }
    } else {
      setShowDashboard("-1");
      setValue(39);
    }
  }, [vehicle]);

  const theme = useTheme();

  const [iframeLoaded, setIframeLoaded] = useState(false);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setIframeLoaded(true);
    }, 3000);

    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Dashboard" subDiv="Dashboard"></Breadcrumb>
      <FilterDropdown
        getData={onSubmitForm}
        last7={showDashboard !== '-1' && showDashboard !== null ? false : true}
        status={false}
        allowDays={showDashboard !== '-1' && showDashboard !== null ? 1 : 7}
        allowToday={true}
        showCalendar={showCalendar}
        allowedMonth={3}
        isShowAll={false}
        currentMonth={showDashboard !== '-1' && showDashboard !== null ? false : true}
        thisMonth={showDashboard !== '-1' && showDashboard !== null ? false : true}
        customDate={true}
        offToday={true}
        time={false}
        monthPicker={showMonth ? true : false}
        dashTab={value}
      />
      {showDashboard === "-1" ? (
        <Box>
          {/* Tabs */}
          <StyledTabBox>
            <StyledTabsGroup
              value={value}
              onChange={handleChange}
              textColor="secondary"
              indicatorColor="secondary"
              aria-label="secondary tabs example"
            >
               {/* <StyledTab value={42} label="New Dashboard" />  */}
               {RoleId === 17 &&<StyledTab value={43} label="Chord Diagram" />}
              <StyledTab value={39} label="Dashboard" />


            </StyledTabsGroup>
          </StyledTabBox>
          {/* Tabs Panel */}

          {
            tabsOpt !== null &&
            <CustomTabPanel value={value} index={39}>
              <Box sx={{ height: "100vh" }}>
                <iframe
                  className="iframe"
                  src={ZohoUrl}
                  width="100%"
                  height="98%"
                  ref={iframeReload}
                ></iframe>
              </Box>
            </CustomTabPanel>
          }         
                    <CustomTabPanel value={value} index={43}>
            <Box sx={{ height: "100vh" }}>
              <ChordDiagram data={formData} />
            </Box>
          </CustomTabPanel> 
            {/* <CustomTabPanel value={value} index={39}>
              <Box sx={{ height: "100vh" }}>
                <Edashboard payload={formData}/>
              </Box>
            </CustomTabPanel> */}
 
        </Box>
      ) : (
        <Box>
          <Box>
            {/* Tabs */}
            {
              tabsOpt !== null &&
              <StyledTabBox>
                {/* <Tabs 
              value={value}
              onChange={handleChange}
              indicatorColor="secondary"
              >
                {
                  DashboardTabs?.map((item) => {
                    if(tabsOpt?.includes(item.valaue)){
                      return( <StyledTab value={item.valaue} label={item.label}/> )
                    } 
                  })
                }
  
              </Tabs> */}

                <Tabs
                  value={value}
                  onChange={handleChange}
                  textColor="secondary"
                  indicatorColor="secondary"
                  aria-label="secondary tabs example"
                >
                  <StyledTab value={40} label="Last Connected" />
                  <StyledTab value={41} label="Vehicle Summary" />
                  {RoleId === 17 && <StyledTab value={42} label="Trip Details" /> }
                  {RoleId === 17 && <StyledTab value={43} label="Chord Diagram" />}
                  {/* <StyledTab value={37} label="Vehicle Rating" /> */}
                </Tabs>
              </StyledTabBox>
            }

            {/* Tabs Panel */}
            {
              tabsOpt !== null &&
              <CustomTabPanel value={value} index={40}>
                <Box sx={{ height: "100vh" }}>
                  <iframe
                    id="Contentframe"
                    className="iframe"
                    src={lastConnectUrl}
                    width="100%"
                    height="98%"
                    ref={iframeReload}
                  ></iframe>
                </Box>
              </CustomTabPanel>

            }
            {
              tabsOpt !== null &&
              <CustomTabPanel value={value} index={41}>
                <Box sx={{ height: "100vh" }}>
                  <iframe
                    id="Contentframe"
                    className="iframe"
                    src={vehicleSumUrl}
                    width="100%"
                    height="98%"
                    ref={iframeReload}
                  ></iframe>
                </Box>
              </CustomTabPanel>

            }
            {
              tabsOpt !== null &&
              <CustomTabPanel value={value} index={37}>
                <Box sx={{ height: "100vh" }}>
                  <iframe
                    id="Contentframe"
                    className="iframe"
                    src={vehicleRatingUrl}
                    width="100%"
                    height="98%"
                    ref={iframeReload}
                  ></iframe>
                </Box>
              </CustomTabPanel>

            }
                        <CustomTabPanel value={value} index={43}>
              <Box sx={{ height: "100vh" }}>
                <ChordDiagram data={formData} />
              </Box>
            </CustomTabPanel>
                      <CustomTabPanel value={value} index={42}>
              <Box sx={{ height: "100vh" }}>
              <TripDetailsDashboard  vehicleId={formData?.vehicleId} resellerId={resellerId} vehicleNo={formData?.vehicleName}/>
              </Box>
            </CustomTabPanel>
            {
              tabsOpt === null && <Box sx={{ height: '100%', background: '#fff', padding: '25px', mt: 4 }}>
                <Alert severity="error">
                  <Typography>Access Denied! please contact your admin.</Typography>
                </Alert>
              </Box>
            }
          </Box>
        </Box>
      )}
    </StyledPageLayout>
  );
};

export default NewDashboard;
