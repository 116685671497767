import React, { useEffect } from "react";
import ReportServices from "../../services/reportServices";
import { Box, Typography, useTheme } from "@mui/material";

const GetTimeLineAddress = (props) => {
  const theme = useTheme();
  const { row, columnName } = props;
  const refAddres = React.useRef(row);

  const getAddress = async (columnName) => {
    if (refAddres.current.innerText === "") {
      var request = {};
      request.latLng = columnName;
      const responseData = (
        await ReportServices.getGeocodeAddress(request)
      ).data;
      refAddres.current.innerText = responseData;
      if (responseData !== "") {
        refAddres.current.style.display = "block";
        refAddres.current.style.fontWeight = "400";
        refAddres.current.style.fontSize = "10px";
      }
    }
  };
  useEffect(() => {
    getAddress(columnName);
  }, [])

  return (
    <Box
      className="addresslink"
      id={columnName}
      sx={{ display: "flex", alignItems: "center" }}
    >
      <Typography
        component={'a'}
        href={`https://www.google.com/maps/search/?api=1&query=${columnName}`}
        target="_blank"
        className="address"
        ref={refAddres}
        id={columnName}
        key={columnName}
        variant='body2'
        sx={{
          color: theme.palette.primary.main, fontWeight: '500', '&:hover': {
            textDecoration: 'underline', color: theme.palette.primary.main
          }
        }}
      ></Typography>
    </Box>
  );
}


export default GetTimeLineAddress;