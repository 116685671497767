import React, { useEffect, useState } from "react";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import {
  useAdminOtherDropdownsMutation,
  useGetDropdownListMutation,
  useGetRouetListMutation,
  useGetVehicleDropdownListMutation,
  useSimPlanListMutation,
  useVendorDropDownListMutation,
} from "../../store/slice/ApiSlice";
import { useLayoutEffect } from "react";
import {
  DeviceStatusData,
  createDateData,
  customerAssignedData,
  firmwareData,
  lastConnectData,
  orgTypedata,
  organizationListData,
  actionLicenseData,
  status,
} from "./MockData";
import DatepickerDropdown from "./DatepickerDropdown";
import moment from "moment";
import SingleInput from "../NewDropdown/SingleInput";
import { useLocation } from "react-router-dom";

function AdminDropdown(props) {
  const {
    getData,
    simDrop = false,
    vehicleDrop = false,
    deviceDrop = false,
    orgDrop = false,
    userDrop = false,
    createdate = false,
    regionDrop = false,
    dealerDrop = false,
    customerDrop = false,
    fleetDrop = false,
    vehicleDropD = false,
    hideOrg = true,
    vendorDrop = false,
    time=false,
    allowDays,
    allowedMonth,
    createDateStatus=true
  } = props;
  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;

  //Is show all
  const [allOption, setAllOption] = useState(true);

  // All service -------------------------------------------------------------------
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();
  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();
  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();
  const [
    getVehicleList,
    { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess },
  ] = useGetVehicleDropdownListMutation();
  const [
    getDeviceModel,
    {
      data: deviceModelData,
      isLoading: deviceModelLoading,
      isSuccess: deviceModelSuccess,
    },
  ] = useAdminOtherDropdownsMutation();
  const [
    getDeviceStatus,
    {
      data: deviceData,
      isLoading: deviceStatusloading,
      isSuccess: deviceStatusSuccess,
    },
  ] = useAdminOtherDropdownsMutation();
  const [
    getSimPlanData,
    { data: simplanData, isLoading: simplanLoading, isSuccess: simplanSuccess },
  ] = useSimPlanListMutation();
  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();

  const [getOrgnizatioList, { data: organizationListData }] =
    useGetDropdownListMutation();
  const [getOrgList, { data: orgListData, isSuccess: orgListSuccess }] =
    useGetDropdownListMutation();

  const [
    getVendorList,
    { data: vendordata, isLoading: vendorLoading, isSuccess: vendorSuccess },
  ] = useGetDropdownListMutation();

 

  // const [getVehicleSearch, {data:searchVehicles, isLoading:searchVehicleLoading, isSuccess:searchVehicleSuccess}] = useGetVehicleDropdownListMutation();

  //All States ---------------------------------------------------------------------
  const [region, setRegion] = useState(null);
  const [dealer, setDealer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fleet, setFleet] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [orgType, setOrgType] = useState("-1");
  const [orgnizationType, setOrgnizationType] = useState("1");
  const [orgnization, setOrgnization] = useState(null);
  const [statusval, setStatusval] = useState("1");
  const [imei, setImei] = useState(null);
  const [customerAssigned, setCustomerAssigned] = useState("-1");
  const [createdDate, setCreatedDate] = useState("0");
  const [deviceStatus, setDeviceStatus] = useState("0");
  const [deviceModel, setDeviceModel] = useState("-1");
  const [lastConneted, setLastConneted] = useState("0");
  const [simStatus, setSimStatus] = useState("-1");
  const [firmware, setFirmware] = useState("All");
  const [lotNumber, setLotNumber] = useState("");
  const [vendor, setVendor] = useState(null);
  const [actionType, setActionType] = useState("0");

  const [regionName, setRegionName] = useState("All");
  const [dealerName, setDealerName] = useState("All");
  const [customerName, setCustomerName] = useState("All");
  const [fleetName, setFleetName] = useState("All");
  const [vehicleName, setVehicleName] = useState("All");
  const [userOrganizationType, setUserOrganizationType] = useState("All");
  const [simStatusName, setSimStatusName] = useState("All");
  const [selectedPeriod, setSelectedPeriod] = useState("All");
  const [deviceStatusName,setDeviceStatusName] = useState("All");
  const [deviceModelName, setDeviceModelName] = useState("All");
  const [lastConnectedName,setLastConnectedName] = useState("All");
  const [custAssignName, setCustAssignName] = useState("All");

  // All State API Responce List
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);
  const [imeiList, setImeiList] = useState(null);
  const [vendorList, setVendorList] = useState(null);
  const [OrganizationList, setOrganizationList] = useState(null);
  //Other Dropdown list
  let [orgTypeList, setOrgTypeList] = useState({ content: orgTypedata });
  let [statusList, setStatusList] = useState({ content: status });
  let [actionTypeList, setActionTypeList] = useState({ content: actionLicenseData });
  const [customerAssignedList, setCustomerAssignedList] = useState({
    content: customerAssignedData,
  });
  const [createdDateList, setCreatedDateList] = useState({
    content: createDateData,
  });
  const [deviceStatusList, setdeviceStatusList] = useState({
    content: DeviceStatusData,
  });
  const [deviceModelList, setDeviceModelList] = useState(null);
  const [lastConnectlist, setLastConnectList] = useState({
    content: lastConnectData,
  });
  const [firmwarelist, setFirmwareList] = useState({ content: firmwareData });
  const [simplanList, setSimplanList] = useState(null);
  //If search and get vehicle
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  //Onload set
  const [load, setLoad] = useState(false);

  //Date Pickers
  const [fromDate, setFromDate] = useState(
    moment().format("YYYY-MM-DD 00:00:00")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD hh:mm:ss"));
  const [activeLabel, setActiveLabel] = useState("All");
  const [label, setLabel] = useState("All");
  const GetDate = (fromDate, toDate, name) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setLabel(name);
  };

  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [search, setSearch] = useState(false);

  //Login Implemented
  //Based Login Implemented
  const adminDropdown = () => {
    switch (userOrgType) {
      case 2:
        getCustomer(orgId);
        break;
      case 5:
        getCustomer(orgId);
        break;
      case 3:
        getCustomer("0");
        setCustomer(orgId);
        break;
      case 8:
        getOrgnization();
        break;
      case 1:
        getvehicle(orgId);
        break;
    }
  };
  let CommonPayload = {
    resellerId: userOrgType === 8 ? orgnization:resellerId,
  }
  let vendorPayload = {
    id: orgId,
    value: "vendor",
    orgType: userOrgType,
    searchText: "",
  };

  useEffect(() => {
    if (vendorDrop === true) {
      getVendorList(vendorPayload);
    } else {
      adminDropdown();
    }
  }, []);



  //Payloads ---------------------------------------------------------------------
  //Common Payloads

  //deviceStatus Payload
  let deviceStatusPayload = {
    resellerId: resellerId,
    value: "deviceStatus",
  };

  //deviceStatus Payload
  let simplanPayload = {
    providerId: "100",
  };

  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };

//deviceState Payload
let deviceStatePayload = {
  resellerId: resellerId,
  value:"deviceModel"
}

  //Load Region ------------------------------------------------------------------
  useEffect(() => {
    if (allOption) {
      setRegion("-1");
    }
    if (userOrgType !== 3 && userOrgType !== 5) {

      getDeviceModel(deviceStatePayload);
      getDeviceStatus(deviceStatusPayload);
      getSimPlanData();
    }
  }, []);

  useLayoutEffect(() => {
    if (regionSuccess) {
      setRegionList(regionData?.data);
    }
  }, [regionSuccess]);


  /// Load Org
  const getOrgnization = async () => {
    let orgPaylad = {
      value: "reseller",
      orgType: "2",
      resellerId: 0,
      dealerId: 0,
      roleId: 1,
      id: 0,
      searchText: "",
    };
    const orgList = await getOrgList(orgPaylad);
    setOrganizationList(orgList.data.data);
    setOrgnization(orgList.data.data.content[0].key);
    hanldeOrganization(orgList.data.data.content[0].key);
  }


  //Handle Get Dealer ------------------------------------------------------------
  const getDealer = (regionId) => {
    setRegion(regionId);
    if (regionId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.regionId = regionId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);
      //Set Org Type
      orgRemoves([1, 4]);
    } else {
      setDealer("-1");
      setDealerList(null);
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
      setImei("-1");
      setImeiList(null);

      if (pathname === "user") {
        orgRemoves([4]);
      } else {
        orgRemoves([1, 4]);
      }
      setOrgType("-1");
    }
  };

  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
      if (search) {
        getCustomer(selectedVehicle[0]?.dealerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setDealer("-1");
    }
  }, [dealerSuccess]);

  //Handle Get Customer -----------------------------------------------------------
  const getCustomer = async(dealerId) => {
    setDealer(dealerId);
    if (dealerId !== "-1") {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = 0;
      CommonPayload.searchText = "";
      getCustomerList(CommonPayload);
      //Set Org Type
      orgRemoves([3]);
    } else {
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
      setImei("-1");
      setImeiList(null);
      //Set Org Type
      orgRemoves([3]);
    }
  };

  useLayoutEffect(() => {
    if (customerSuccess) {
      if (userOrgType === 3) {
        let customerName = customerdata?.data.content.filter(
          (id) => parseInt(id.key) === orgId
        );
        setCustomerList({ content: customerName });
        setCustomer(customerName[0].key);
        getFleet(customerName[0].key);
      } else {
        setCustomerList(customerdata?.data);
      }
      if (search) {
        getFleet(selectedVehicle[0]?.customerId);
      }
    }
    if (allOption && selectedVehicle === null && userOrgType !== 3) {
      setCustomer("-1");
    }
    
  }, [customerSuccess]);

  //Handle Get Fleet -------------------------------------------------------------
  const getFleet = (customerId) => {
    setCustomer(customerId);
    if (customerId !== "-1") {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      CommonPayload.customerId = customerId;
      CommonPayload.searchText = "";
      getFleetList(CommonPayload);

      if (pathname === "user") {
        orgRemoves([ 1, 3]);
      } else {
        orgRemoves([ 1, 3]);
      }
    } else {
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
      setImei("-1");
      setImeiList(null);
      if (pathname === "user") {
        orgRemoves([ 3]);
      } else {
        orgRemoves([ 1, 3]);
      }
    
    }
  };
  useLayoutEffect(() => {
    if (fleetSuccess) {
      setFleetList(fleetdata?.data);
      if (search) {
        getvehicle(selectedVehicle[0]?.fleetId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setFleet("-1");
    }
  }, [fleetSuccess]);

  let vehilePayload = {
    value: "orgId",
   
    searchText: "",
  };

  //Handle Get Dealer -----------------------------------------------------------
  const getvehicle = (fleetId) => {
    setFleet(fleetId);
    if (fleetId !== "-1") {
      vehilePayload.paramValue = fleetId;
      if(search){
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      getVehicleList(vehilePayload);
    
        orgRemoves([ 0,1,2,3]);
        setOrgType("1")
      
    } else {
      setVehicle("-1");
      setVehicleList(null);
  
        orgRemoves([ 1,3]);
        setOrgType("-1")
     
    }
    //getVehicleList
  };
  const getselectVendor = (fleetId) => {
    setFleet(fleetId);
  };

  useLayoutEffect(() => {
    if (vehicleSuccess) {
      setVehicleList(vehicledata?.data);
      setImeiList(vehicledata?.data);
      if (search) {
        setSearch(false);
      }
    }
    if (allOption && selectedVehicle === null) {
      setVehicle("-1");
      setImei("-1");
      if(userOrgType !== 8){
        setLoad(true);
      }
    }
  }, [vehicleSuccess]);

  //Handle Select Vehicle --------------------------------------------------------
  const selectVehicle = (vehicleId) => {
    setVehicle(vehicleId);
    //Find IEMI Id based on vehicle selection
    if (search && vehicleId !== "-1" && vehicleId !== undefined) {
      const SelctedVehicleData = searchVehicles?.data?.content.filter(
        (item) => item.key === vehicleId
      );
      if (allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
        setVehicle(SelctedVehicleData[0]?.key);
        setImei(SelctedVehicleData[0]?.refVal);
      }
      setSelectedVehicle(SelctedVehicleData);

      //getVehicle(SelctedVehicleData[0].fleetId);
      // setSearchText({ value: null });
    }

    if (vehicleId !== "-1") {
      let SelectVehicleIemi = vehicledata?.data.content.find(
        (item, index) => item.key === vehicleId
      );
      setImei(SelectVehicleIemi.refVal);
    } else {
      setImei("-1");
    }
  };

  //Handle IEMI Select
  const selectIemi = (iemiId) => {
    setImei(iemiId);
    if (iemiId !== "-1") {
      let getVehicleId = vehicledata?.data.content.find(
        (item, index) => item.refVal === iemiId
      );
      setVehicle(getVehicleId.key);
    } else {
      setVehicle("-1");
    }
  };

      //vendor success
      useLayoutEffect(() => {
        if (vendorSuccess) {
          if (vendordata.data !== null) {
          setVendorList(vendordata?.data);
          selectVendor(userOrgType === 7 ? vendordata.data.content[0].key : "-1");
          }
        }
      }, [vendorSuccess]);
    
      const selectVendor = (vendor) =>{
        setVendor(vendor)
        //setLoad(true);
      }


     





  //Org type --------------------------------------------------------------------- handleStatus
  const haldeOrgType = (orgTypeId) => {
    setOrgType(orgTypeId);
  };

  //ActionType
  const handleActionType = (actionId) => {
    setActionType(actionId);
  };

  const orgRemoves = (removeItems) => {
    let orgData = orgTypedata.filter((item, idx) => {
      return !removeItems.includes(idx);
    });
    setOrgTypeList({ content: orgData });
  };

  //handleCustomerAssign------------------------------------------------------------
  const handleCustomerAssign = (assignId) => {
    setCustomerAssigned(assignId);
  };

  //Device Status
  const handleDeviceStatus = (deviceStatusId) => {
    setDeviceStatus(deviceStatusId);
  };
  //Device Status
  const handlevendor = (vendorId) => {
    setVendor(vendorId);
  };

  //handleCreateDate ----------------------------------------------------------------
  const handleCreateDate = (createDateValue) => {
    setCreatedDate(createDateValue);
    switch (createDateValue) {
      case "0":
        setActiveLabel("All");
        break;
      case "1":
        setActiveLabel("today");
        break;
      case "2":
        setActiveLabel("yesterday");
        break;
      case "3":
        setActiveLabel("today");
        break;
      case "4":
        setActiveLabel("custom");
        break;
      default:
        setActiveLabel("All");
        break;
    }
  };

  //Device Model ---------------------------------------------------------------------
  const handleDeviceModel = (deviceModelId) => {
    setDeviceModel(deviceModelId);
  };

  useEffect(() => {
    if (deviceModelSuccess) {
      setDeviceModelList(deviceModelData?.data);
    }
  }, [deviceModelSuccess]);

  ///Simplan list Data ----------------------------------------------------------------
  const handleSimStatus = (simPlanId) => {
    setSimStatus(simPlanId);
  };
  useEffect(() => {
    if (simplanSuccess) {
      setSimplanList({ content: simplanData });
    }
  }, [simplanSuccess]);

  //handleLastConnect ---------------------------------------------------------------
  const handleLastConnect = (lastConnectId) => {
    setLastConneted(lastConnectId);
  };

  //Firmware ---------------------------------------------------------------
  const handleFirmware = (firmwareId) => {
    setFirmware(firmwareId);
  };

  //Lot Number ---------------------------------------------------------------
  const handleLotNumber = (lotNumber) => {
    setLotNumber(lotNumber);
  };


  //Onsubmit Data
  const onSubmit = () => {
    let formdata = {
      region: 0,
      dealer: 0,
      customer: customer,
      fleet: fleet,
      vehicle: vehicle,
      imei: imei,
      orgType: orgType,
      customerAssigned: customerAssigned,
      createDate: createdDate,
      fromDate: fromDate,
      toDate: label === 'Today' ? moment().format('yyyy-MM-DD HH:mm:ss') : toDate,
      deviceStatus: deviceStatus,
      DeviceModel: deviceModel,
      lastconnnect: lastConneted,
      simStatus: simStatus,
      firmware: firmware,
      lotNummber: lotNumber,
      orgnizationType: orgnizationType,
      orgnization: orgnization,
      vendor: vendor,
      regionName:regionName,
      dealerName:dealerName,
      customerName:customerName,
      fleetName:fleetName,
      vehicleName:vehicleName,
      userOrganizationType,userOrganizationType,
      selectedPeriod:selectedPeriod,
      simStatusName:simStatusName,
      deviceStatusName:deviceStatusName,
      deviceModelName:deviceModelName,
      lastConnectedName:lastConnectedName,
      custAssignName:custAssignName,
      actionId:actionType,
    };
    getData(formdata);
  };
  useEffect(() => {
    if (load) {
      onSubmit();
    }
  }, [load]);

  useEffect(() => {
    if (!hideOrg) {
      const orgList = orgTypedata.filter((id) => id.key !== "2");
      setOrgTypeList({ content: orgList });
    }
  }, []);


  //Search--------------------------------------------------------------------------
  // vehicleSearch
  const vehicleSearch = (searchText) => {
    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: searchText,
      page: "0",
      size: "10",
      orgType: userOrgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (searchText !== '') {
      getVehicleSearch(vehicleSeatchPayload);
    } else {
      setSelectedVehicle(null);
      setVehicleList(vehicledata?.data);
    }
  };
  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles.data);
      }
    }
  }, [searchVehicleSuccess]);



  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);
    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };
  const ResellerSearch = (searchText) => {
    CommonPayload.value = "reseller";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.resellerId = 0;
    CommonPayload.orgType = "2";
    CommonPayload.roleId = 1;
    CommonPayload.id = 0;
    if (orgnization !== "-1") {
      getOrgList(CommonPayload);
    }
  };
  //search
  const dropDownSearch = (searchText, name) => {
    if (name === "Vehicle") {
      // setSearchText({ value: searchText });
      // setSearchText({ name: name });
      setSearch(true);
      vehicleSearch(searchText);
    } else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    } else if (name === "Organization") {
      ResellerSearch(searchText);
    }

  };

  const location = useLocation();
  let pathname = location.pathname.split("/")[1];

  //Supre Admin Dropdowns
  const [orgTypeSShow, setOrgTypeSShow] = useState(false); //Super Admin Only
  const [orgSShow, setOrgSShow] = useState(false); //Super Admin Only
  const [statusShow, setStatusShow] = useState(false); //Super Admin Only
  const [regionShow, setRegionShow] = useState(false);
  const [dealerShow, setDealerShow] = useState(false);
  const [customerShow, setCustomerShow] = useState(false);
  const [venderShow, setVenderShow] = useState(false);
  const [fleetShow, setFleetShow] = useState(false);
  const [vehicleShow, setVehicleShow] = useState(false);
  const [orgTypeShow, setOrgTypeShow] = useState(false);
  const [imeiShow, setImeiShow] = useState(false);
  const [customerAShow, setCustomerAShow] = useState(false);
  const [simStatusShow, setSimStatusShow] = useState(false);
  const [deviceStatusShow, setDeviceStatusShow] = useState(false);
  const [deviceModelShow, setDeviceModelShow] = useState(false);
  const [createDateShow, setCreateDateShow] = useState(false);
  const [lastConnnectShow, setLastConnnectShow] = useState(false);
  const [firmwareShow, setFirmwareShow] = useState(false);
  const [lotNumerShow, setLotNumerShow] = useState(false);
  const [actionShow, setActionShow] = useState(false);
  // const [vendorShow,  setvendorShow] = useState(false);



  //onload
  useEffect(() => {
    if (userOrgType === 8) {
      //Org List
      let payload = {
        value: "orgType",
        resellerId: 0,
        dealerId: 0,
        searchText: "",
      };
      getOrgnizatioList(payload);
     
     
      if (pathname === "user") {
        setOrgTypeSShow(true);
        setOrgSShow(true);
        setStatusShow(false);
      } else if (pathname === "organization") {
        setOrgTypeSShow(true);
        setOrgSShow(true);
        setStatusShow(true);
      } else if (pathname === "vehicle") {
        setOrgSShow(true);
        setCustomerShow(true);
        setCustomerAShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setImeiShow(true);
        setCreateDateShow(true);
      } else if (pathname === "device") {
        setOrgSShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setDeviceStatusShow(true);
        setDeviceModelShow(true);
        setLastConnnectShow(true);
        setCreateDateShow(true);
      } else if (pathname === "sim") {
        setOrgSShow(true);
      } else if (pathname === "orgSettings") {
        setOrgSShow(true);
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(true);
        setFleetShow(true);
      }
    } else if (userOrgType === 2) {
      if (pathname === "user") {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(true);
        setFleetShow(true);
        setOrgTypeShow(true);
      } else if (pathname === "organization") {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(true);
        setFleetShow(true);
        setOrgTypeShow(true);
      } else if (pathname === "vehicle") {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(true);
        setCustomerAShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setImeiShow(true);
        setCreateDateShow(true);
      } else if (pathname === "device") {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setDeviceStatusShow(true);
        setDeviceModelShow(true);
        setLastConnnectShow(true);
        setCreateDateShow(true);
        setLotNumerShow(true);
      } else if (pathname === "sim") {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setSimStatusShow(true);
        setCreateDateShow(true);
      }else if (pathname === "licenseReport") {
        setLotNumerShow(true);
        setCreateDateShow(true);
        setActionShow(true);
      }
    } else if (userOrgType === 8) {
      setVenderShow(true);
    }else if (userOrgType === 5){
      if (pathname === "organization") {
        setCustomerShow(true);
        setOrgTypeShow(true);
      }else if (pathname === "vehicle") {
        setCustomerShow(true);
        setCustomerAShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setImeiShow(true);
        setCreateDateShow(true);
      } 
    }else if (userOrgType === 3){
      if (pathname === "organization") {
        setOrgTypeShow(true);
      }else if (pathname === "vehicle") {
        setCustomerAShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setImeiShow(true);
        setCreateDateShow(true);
      }
      else if (pathname === "user") {
        setCreateDateShow(true);
      }else if(pathname === "device"){
        setCreateDateShow(true);
      }
    }else if (userOrgType === 1){
      if (pathname === "user") {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setOrgTypeShow(false);
      } else if (pathname === "organization") {
        setRegionShow(false);
        setDealerShow(false);
        setCustomerShow(false);
        setOrgTypeShow(false);
      } else if (pathname === "vehicle") {
        setVehicleShow(true);
        setImeiShow(true);
        setCreateDateShow(true);
      } else if (pathname === "device") {
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setDeviceStatusShow(true);
        setDeviceModelShow(true);
        setLastConnnectShow(true);
        setCreateDateShow(true);
        setLotNumerShow(true);
      } else if (pathname === "sim") {
        setRegionShow(true);
        setDealerShow(true);
        setCustomerShow(true);
        setFleetShow(true);
        setVehicleShow(true);
        setSimStatusShow(true);
        setCreateDateShow(true);
      }
    }
    
  }, []);

  const getInnerText = (value, label) =>{
    if(label === "Region"){
      setRegionName(value);
    }else if(label === "Dealer"){
      setDealerName(value);
    }else if(label === "Customer"){
      setCustomerName(value);
    } else if(label === "Fleet"){
      setFleetName(value);
    }else if(label === "Vehicle"){
      setVehicleName(value);
    }else if(label === "Organization Type"){
      setUserOrganizationType(value);
    }else if(label === "Sim status"){
      setSimStatusName(value);
    }else if(label === "Created Date"){
      setSelectedPeriod(value);
    }else if(label === "Device status"){
      setDeviceStatusName(value);
    }else if(label === "Device Model"){
      setDeviceModelName(value);
    }else if(label === "Last Connected"){
      setLastConnectedName(value);
    }else if(label === "Customer Assigned"){
      setCustAssignName(value);
    }
  }



  //Organzation
  //Org type ---------------------------------------------------------------------
  const haldeOrganizationType = (orgTypeId) => {
    setOrgnizationType(orgTypeId);
    //If Fleet Select
    if (orgTypeId === "1" || orgTypeId === "8") {
      setCustomerShow(true);
      setFleetShow(true);
      setDealerShow(false);
    } else if (orgTypeId === "3") {
      setDealerShow(false);
      setCustomerShow(true);
      setFleetShow(false);
    } else if (orgTypeId === "5") {
      setCustomerShow(false);
      setFleetShow(false);
      setDealerShow(true);
    } else if (orgTypeId === "2") {
      setDealerShow(false);
      setCustomerShow(false);
      setFleetShow(false);
    } else {
      setDealerShow(false);
      setCustomerShow(false);
      setFleetShow(false);
    }
  };

  //Org type ---------------------------------------------------------------------
  const hanldeOrganization = (orgId) => {
    setOrgnization(orgId);
    if(userOrgType===8 && RoleId===1){
      var request = {};
      request.value = "customer";
      request.dealerId = 0;
      request.searchText = "";
      request.resellerId = orgId;
      getCustomerList(request);
      setLoad(true);
    }
  };


  //Org type ---------------------------------------------------------------------
  const handleStatus = (statusId) => {
    setStatusval(statusId);
  };

  useEffect(() => {
    if (pathname === "user") {
      orgRemoves([3]);
    } else {
      orgRemoves([1,3]);
    }
  }, [pathname]);

  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
      {orgTypeSShow && (
        <SingleDropdown
          label={"Organization Type"}
          listItems={organizationListData?.data}
          handleChange={haldeOrganizationType}
          value={orgnizationType}
          isSearch={false}
          showAll={false}
          getInnerText={getInnerText}
        />
      )}

      {orgSShow && (
        <SingleDropdown
          label={"Organization"}
          listItems={OrganizationList}
          handleChange={hanldeOrganization}
          value={orgnization}
          isSearch={dropDownSearch}
          showAll={false}
        />
      )}

      {regionShow && (
        <SingleDropdown
          label={"Region"}
          listItems={regionList}
          handleChange={getDealer}
          value={region}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {dealerShow && (
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerList}
          handleChange={getCustomer}
          value={dealer}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {customerShow && (
        <SingleDropdown
          label={"Customer"}
          listItems={customerList}
          handleChange={getFleet}
          value={customer}
          isSearch={dropDownSearch}
          showAll={RoleId !== 9 ? allOption : false}
          getInnerText={getInnerText}
        />
      )}

      {fleetShow && (
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetList}
          handleChange={getvehicle}
          value={fleet}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {vehicleShow && (
        <SingleDropdown
          label={"Vehicle"}
          listItems={vehicleList}
          handleChange={selectVehicle}
          value={vehicle}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {statusShow && (
        <SingleDropdown
          label={"Status"}
          listItems={statusList}
          handleChange={handleStatus}
          value={statusval}
          //isSearch={dropDownSearch}
          showAll={false}
        />
      )}

      {orgTypeShow && (
        <SingleDropdown
          label={"Organization Type"}
          listItems={orgTypeList}
          handleChange={haldeOrgType}
          value={orgType}
          isSearch={false}
          showAll={false}
          hideOrg={hideOrg}
          getInnerText={getInnerText}
        />
      )}

{actionShow && (
        <SingleDropdown
          label={"Action"}
          listItems={actionTypeList}
          handleChange={handleActionType}
          value={actionType}
          isSearch={false}
        />
      )}

      {imeiShow && (
        <SingleDropdown
          label={"IMEI"}
          listItems={imeiList}
          handleChange={selectIemi}
          value={imei}
          isSearch={false}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {customerAShow && (
        <SingleDropdown
          label={"Customer Assigned"}
          listItems={customerAssignedList}
          handleChange={handleCustomerAssign}
          value={customerAssigned}
          isSearch={false}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {simStatusShow && (
        <SingleDropdown
          label={"Sim status"}
          listItems={simplanList}
          handleChange={handleSimStatus}
          value={simStatus}
          customAllValue={"-1"}
          isSearch={false}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {deviceStatusShow && (
        <SingleDropdown
          label={"Device status"}
          listItems={deviceData?.data}
          handleChange={handleDeviceStatus}
          value={deviceStatus}
          customAllValue={"0"}
          isSearch={false}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {deviceModelShow && (
        <SingleDropdown
          label={"Device Model"}
          listItems={deviceModelList}
          handleChange={handleDeviceModel}
          value={deviceModel}
          isSearch={false}
          showAll={allOption}
        />
      )}
      {createDateShow && (
        <SingleDropdown
          label={"Created Date"}
          listItems={createdDateList}
          handleChange={handleCreateDate}
          value={createdDate}
          isSearch={false}
          showAll={false}
          getInnerText={getInnerText}
        />
      )}

      {createdate && createdDate !== "0" && (
        <DatepickerDropdown getDate={GetDate} activeLabel={activeLabel} time={time} allowDays={allowDays}  createDateValue={createdDate} createDateStatus={createDateStatus} allowedMonth={allowedMonth}/>
      )}

      {lastConnnectShow && (
        <SingleDropdown
          label={"Last Connected"}
          listItems={lastConnectlist}
          handleChange={handleLastConnect}
          customAllValue={"0"}
          value={lastConneted}
          isSearch={false}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {firmwareShow && (
        <SingleDropdown
          label={"Firmware Version"}
          listItems={firmwarelist}
          handleChange={handleFirmware}
          customAllValue={"All"}
          value={firmware}
          isSearch={false}
          showAll={allOption}
        />
      )}

      {lotNumerShow && (
        <SingleInput
          label={"Lot Number"}
          handleChange={handleLotNumber}
          // customAllValue={"All"}
          // value={firmware}
          // isSearch={false}
          // showAll={allOption}
        />
      )}
      {vendorDrop && (
        <SingleDropdown
          label={"Vendor"}
          listItems={vendorList}
          handleChange={handlevendor}
          value={vendor}
          isSearch={dropDownSearch}
          showAll={userOrgType === 7 ? false : allOption}
        />
      )}

      <StyledFiterButton
        type="button"
        variant="contained"
        size="small"
        color="success"
        onClick={onSubmit}
        sx={{ marginTop: "25px" }}
      >
        Submit
      </StyledFiterButton>
    </StyledPaperShadow>
  );
}

export default AdminDropdown;
