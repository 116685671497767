import Service from "./service";

const GeozoneService = { 
  existsCheckGeoZone: function (data) {
    return Service.post("/GeoFence/chkIsGeoExist", data, null);
  },
  existsEditCheckGeoZone: function (data) {
    return Service.post("/GeoFence/chkIsGeoExistForEdit", data, null);
  },
  saveGeoZoneDetails: function (data) {
    return Service.post("/GeoFence/saveGeoZone", data, null);
  },
  saveAssignGeoZoneDetails: function (data) {
    return Service.post("/GeoFence/assignGeoZoneVehicleMap", data, null);
  },
  loadAllGeoZone: function (resource) {
    return Service.post("/GeoFence/loadAllGeoZone", resource, null);
  },
  getAllGeoZoneForOrg:function (resource) {
    return Service.post("/GeoFence/getAllGeoZoneForOrg", resource, null);
  },
  getAllGeoZoneForVehicle:function (resource) {
    return Service.post("/GeoFence/getAllGeoZoneForVehicle", resource, null);
  },
  deleteOrgById: (orgId) => {
    return Service.post("/organization/deleteOrganization", orgId, null);
  },
  editOrgById: (orgId) => {
    return Service.post("/organization/getOrganization", { orgId });
  },
  updateOrgDetails: function (data) {
    return Service.post("/organization/updateOrganization", data, null);
  },  
 
  getOrganizationHistory: function (resource) {
    return Service.post('/organization/listOrganizationHistory',resource,null)
  },
  getOrganization: (orgId) => {
    return Service.post('organization/getOrganization', orgId)
  },
  updateOrganization: (orgId) => {
    return Service.post('/organization/updateOrganization', orgId,null)
  },
  validateGeozone: function (data) {
    return Service.post('/GeoFence/validateExcel',
      data,null,
      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },
  uploadGeozone: function (data) {
    return Service.post('/GeoFence/processExcel',
      data,null,
      { "Content-Type": "multipart/form-data", accept: "*/*" }
    );
  },

  /*
  getOrganizationListXLS:function(request){
  
    return Service.get('/organization/organizationListXLS',{request})  
  },
  getOrganizationListpdf:function(request){
  
    return Service.get('/organization/organizationListPDF',{request})  
  },
  chkOrgName: function (data) {
    return Service.post("/organization/chkOrgName",data);
  },
  
  chkOrgEmail: function (data) {
    return Service.post("/organization/chkOrgEmail", data);
  },
  chkUserName: function (data) {
    return Service.post("/organization/chkUserName", data);
  },
  */

};

export default GeozoneService;
