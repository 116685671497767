import { isEqual, parse } from "date-fns";
import { isFuture, isPast, subDays } from "date-fns";
import { isAfter, isBefore, isToday } from "date-fns";
import moment from "moment"
import { useState } from "react"
import { DatePicker } from "rsuite"

export const SingleDatePicker = (props) => {
    const { defaultdate = new Date(moment().subtract(1, 'days')),  allowDays=90, onChangeDate} = props;
    const [date, setDate] = useState(defaultdate);
    const { afterToday, allowedMaxDays, combine } = DatePicker;
    const handleDate = (date) => {
        setDate(date);
        onChangeDate(date)
       
    }
    return(
        <>
            <DatePicker 
                size="sm"
                cleanable={false}
                value={date}
                defaultValue={date}
                shouldDisableDate={date => {
                    let PastDays = subDays(new Date(), allowDays)
                    let isPast = isBefore(date, new Date(PastDays));
                    return isFuture(date, new Date()) || isToday(date, new Date()) || isPast
                }}
                onOk={handleDate}
            />
        </>
    )
}