import {
  Box,
  CardMedia,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Icon,
  IconButton,
  Slide,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineDelete } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import { SoftButton, StyledFormButtonsRow } from "../theme/StyledEle";
import { useLocation, useNavigate } from "react-router-dom";


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function StyledModel(props) {
  const { whiteList = false } = props;
  const { movetoVendor = false } = props;
  const { otp = false } = props;
  const { moveToReseller = false } = props;
  const {resendActivation = false} = props;


  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  //const {open} = props;
  const [open, setOpen] = useState(true);
  const [children, setChildren] = useState(undefined);
  useEffect(() => {
    if (props.children !== undefined && location.hash !== "") {
      setOpen(true);
      setChildren(props.children);
    }
  }, [props.children]);

  const modelClose = () => {
    setOpen(false);
    setChildren(undefined);
    navigate(location.pathname);
  };

  return (
    <Dialog open={open} maxWidth={"sm"} fullWidth={true} TransitionComponent={Transition}>
      <DialogContent sx={{ px: 0, py: 2 }}>
        {!whiteList && !movetoVendor && !otp && !moveToReseller && !resendActivation && (
          <Icon
            sx={{
              fontSize: "50px",
              height: "auto",
              margin: "auto",
              display: "flex",
            }}
            color="error"
          >
            <AiOutlineDelete />
          </Icon>
        )}
        <Typography sx={{ textAlign: "center" }} variant="h6">
          {whiteList
            ? "SMS Whitlisting"
            : movetoVendor
            ? "Move to Vendor"
            : moveToReseller
            ? "Move to Reseller"
            : otp
            ? "Enter verification code"
            : resendActivation
            ? "Resend Activation"
            :"Are you sure want to delete?"}
        </Typography>
        {props.children}
        <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
          <SoftButton
            size="small"
            color="error"
            className="error"
            onClick={modelClose}
          >
            Cancel
          </SoftButton>
          <SoftButton
            size="small"
            className="success"
            color="success"
            type="submit"
            onClick={props.deleteItem}
          >
            {whiteList || movetoVendor || otp || moveToReseller ? "Submit" : "Confirm"}
          </SoftButton>
        </StyledFormButtonsRow>
      </DialogContent>
    </Dialog>
  );
}

export default StyledModel;
