import React, { useEffect, useLayoutEffect, useState } from "react";
import { StyledFiterButton, StyledPaperShadow } from "../../theme/StyledEle";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import {
  searchByData,
  actionData,
  alertModeData,
} from "../AdminDropdown/MockData";
import DatepickerDropdown from "../AdminDropdown/DatepickerDropdown";
import moment from "moment";
import {
  useGetDropdownListMutation,
  useGetVehicleDropdownListMutation,
} from "../../store/slice/ApiSlice";

function LogsDropdown(props) {
  const {
    getData,
    airtelDrop = false,
    regionDrop = false,
    dealerDrop = false,
    customerDrop = false,
    fleetDrop = false,
    vehicleDrop = false,
    sentDrop = false,
    allowedMonth = 3,
    allowDays = 1,
  } = props;

  //user data form local storage
  const userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let RoleId = userData.roleId;
  let userOrgType = userData.userOrgType;

  //Is show all
  const [allOption, setAllOption] = useState(true);

  // All service -------------------------------------------------------------------
  const [
    getRegionList,
    { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess },
  ] = useGetDropdownListMutation();
  const [
    getDealerList,
    { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess },
  ] = useGetDropdownListMutation();
  const [
    getCustomerList,
    {
      data: customerdata,
      isLoading: customerLoading,
      isSuccess: customerSuccess,
    },
  ] = useGetDropdownListMutation();
  const [
    getFleetList,
    { data: fleetdata, isLoading: fleetLoading, isSuccess: fleetSuccess },
  ] = useGetDropdownListMutation();
  const [
    getVehicleList,
    { data: vehicledata, isLoading: vehicleLoading, isSuccess: vehicleSuccess },
  ] = useGetVehicleDropdownListMutation();
  const [
    getVehicleSearch,
    {
      data: searchVehicles,
      isLoading: searchVehicleLoading,
      isSuccess: searchVehicleSuccess,
    },
  ] = useGetVehicleDropdownListMutation();
  //All States ---------------------------------------------------------------------
  const [actionStatus, setActionStatus] = useState("all");
  const [searchStatus, setSearchStatus] = useState("0");
  const [region, setRegion] = useState(null);
  const [dealer, setDealer] = useState(null);
  const [customer, setCustomer] = useState(null);
  const [fleet, setFleet] = useState(null);
  const [vehicle, setVehicle] = useState(null);
  const [alertMode, setAlertMode] = useState("0");

  // All State API Responce List
  const [regionList, setRegionList] = useState(null);
  const [dealerList, setDealerList] = useState(null);
  const [customerList, setCustomerList] = useState(null);
  const [fleetList, setFleetList] = useState(null);
  const [vehicleList, setVehicleList] = useState(null);


  const [regionName, setRegionName] = useState("All");
  const [dealerName, setDealerName] = useState("All");
  const [customerName, setCustomerName] = useState("All");
  const [fleetName, setFleetName] = useState("All");
  const [vehicleName, setVehicleName] = useState("All");
  const [alertModeName, setAlertModeName] = useState("All")

  //Other Dropdown list
  const [actionList, setActionList] = useState({ content: actionData });
  const [searchList, setSearchList] = useState({ content: searchByData });
  const [alertModeList, setAlertModeList] = useState({
    content: alertModeData,
  });

  //If search and get vehicle
  const [selectedVehicle, setSelectedVehicle] = useState(null);

  //Onload set
  const [load, setLoad] = useState(false);

  //Date Pickers
  const [fromDate, setFromDate] = useState(
    moment().format("YYYY-MM-DD 00:00:00")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD hh:mm:ss"));
  const [activeLabel, setActiveLabel] = useState("today");
  const [label, setLabel] = useState("Today");
  const GetDate = (fromDate, toDate, name) => {
    setFromDate(fromDate);
    setToDate(toDate);
    setLabel(name);
  };

  const [searchText, setSearchText] = useState({ value: null, name: null });
  const [search, setSearch] = useState(false);

  //Login Implemented
  //Based Login Implemented
  const logsDropdown = () => {
    switch (RoleId) {
      case 6:
       // getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 17:
       // getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 20:
       // getRegionList(regionPayload);
        getCustomer(orgId);
        break;
      case 9:
        getCustomer("0");
        setCustomer(orgId);
    }
  };
  useEffect(() => {
    logsDropdown();
  }, []);

  //Payloads ---------------------------------------------------------------------
  //Common Payloads
  let CommonPayload = {
    resellerId: resellerId,
  };
  //Region List Loaded
  let regionPayload = {
    value: "region",
    searchText: "",
  };

  //Load Region ------------------------------------------------------------------
  useEffect(() => {
    if (allOption) {
      setRegion("-1");
    }
  }, []);

  useLayoutEffect(() => {
    if (regionSuccess) {
      setRegionList(regionData?.data);
    }
  }, [regionSuccess]);

  //Handle Get Dealer ------------------------------------------------------------
  const getDealer = (regionId) => {
    setRegion(regionId);
    if (regionId !== "-1") {
      CommonPayload.value = "dealer";
      CommonPayload.regionId = regionId;
      CommonPayload.dealerId = "0";
      CommonPayload.searchText = "";
      getDealerList(CommonPayload);
    } else {
      setDealer("-1");
      setDealerList(null);
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
    }
  };

  useLayoutEffect(() => {
    if (dealerSuccess) {
      setDealerList(dealerdata?.data);
      if (search) {
        getCustomer(selectedVehicle[0]?.dealerId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setDealer("-1");
    }
  }, [dealerSuccess]);

  //Handle Get Customer -----------------------------------------------------------
  const getCustomer = (dealerId) => {
    setDealer(dealerId);
    if (dealerId !== "-1") {
      CommonPayload.value = "customer";
      CommonPayload.dealerId = 0;
      CommonPayload.searchText = "";
      getCustomerList(CommonPayload);
    } else {
      
      setCustomer("-1");
      setCustomerList(null);
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
    }
  };

  useLayoutEffect(() => {
    if (customerSuccess) {
      if (RoleId === 9) {
        let customerName = customerdata?.data.content.filter(
          (id) => parseInt(id.key) === orgId
        );
        setCustomerList({ content: customerName });
        setCustomer(customerName[0].key);
        getFleet(customerName[0].key);
      } else {
        setCustomerList(customerdata?.data);
      }
      if (search) {
        getFleet(selectedVehicle[0]?.customerId);
      }
    }
    if (allOption && selectedVehicle === null && RoleId !== 9) {
      setCustomer("-1");
    }
  }, [customerSuccess]);



  //Handle Get Fleet -------------------------------------------------------------
  const getFleet = (customerId) => {
    setCustomer(customerId);
    if (customerId !== "-1") {
      CommonPayload.value = "fleet";
      CommonPayload.dealerId = "0";
      CommonPayload.customerId = customerId;
      CommonPayload.searchText = "";
      getFleetList(CommonPayload);
    } else {
      setFleet("-1");
      setFleetList(null);
      setVehicle("-1");
      setVehicleList(null);
    }
  };
  useLayoutEffect(() => {
    if (fleetSuccess) {
      setFleetList(fleetdata?.data);
      if (search) {
        getvehicle(selectedVehicle[0]?.fleetId);
      }
    }
    if (allOption && selectedVehicle === null) {
      setFleet("-1");
    }
  }, [fleetSuccess]);

      
  let vehilePayload = {
    value: "orgId",
    searchText: ""
  };

  //Handle Get Dealer -----------------------------------------------------------
  const getvehicle = (fleetId) => {
    setFleet(fleetId);
    if (fleetId !== "-1") {
      vehilePayload.paramValue = fleetId;
      if(search){
        vehilePayload.headerValue = vehicle;
        vehilePayload.param = 'search';
      }
      getVehicleList(vehilePayload);
    } else {
      setVehicle("-1");
      setVehicleList(null);
    }
    //getVehicleList
  };
  useLayoutEffect(() => {
    if (vehicleSuccess) {
      setVehicleList(vehicledata?.data);

      if (search) {
        setSearch(false);
      }
    }
    if (allOption && selectedVehicle === null) {
      setVehicle("-1");

      setLoad(true);
    }
  }, [vehicleSuccess]);

  //Handle Select Vehicle --------------------------------------------------------
  const selectVehicle = (vehicleId) => {
    setVehicle(vehicleId);
    //Find IEMI Id based on vehicle selection
    if (search && vehicleId !== "-1") {
      const SelctedVehicleData = searchVehicles?.data?.content.filter(
        (item) => item.key === vehicleId
      );
      if (allOption && search) {
        setRegion(SelctedVehicleData[0]?.regionId);
        getDealer(SelctedVehicleData[0]?.regionId);
        setVehicle(SelctedVehicleData[0]?.key);
      }

      setSelectedVehicle(SelctedVehicleData);

      //getVehicle(SelctedVehicleData[0].fleetId);
      setSearchText({ value: null });
    }
  };

  //handleActionList ---------------------------------------------------------------
  const handleActionList = (actionList) => {
    setActionStatus(actionList);
  };

  //handleSearchList ---------------------------------------------------------------
  const handleSearchList = (searchList) => {
    setSearchStatus(searchList);
  };

  //handleAlertModeList ---------------------------------------------------------------
  const handleAlertModeList = (alertList) => {
    setAlertMode(alertList);
  };


  useLayoutEffect(() => {
    setLoad(true);
    switch (userOrgType) {
      case 1:
        getvehicle(orgId);
        setFleet(orgId);
      break
    }
  }, []);


  const getInnerText = (value, label) =>{
    if(label === "Region"){
      setRegionName(value);
    }else if(label === "Dealer"){
      setDealerName(value);
    }else if(label === "Customer"){
      setCustomerName(value);
    } else if(label === "Fleet"){
      setFleetName(value);
    }else if(label === "Vehicle"){
      setVehicleName(value);
    }else if(label === "Alert Mode"){
      setAlertModeName(value);
    }
  }

  //Onsubmit Data
  const onSubmit = () => {
    let formdata = {
      fromDate: fromDate,
      toDate: label === 'Today' ? moment().format('yyyy-MM-DD HH:mm:ss') : toDate,
      actionStatus: actionStatus,
      searchStatus: searchStatus,
      region: userOrgType === 3 || userOrgType === 5 || userOrgType === 1 ? 0: 0,
      dealer: userOrgType === 1 ? 0 : 0,
      customer: userOrgType === 1 ? 0 : customer,
      fleet: userOrgType === 1 ? orgId : fleet,
      vehicle: vehicle,
      alertMode: alertMode,
      regionName:regionName,
      dealerName:dealerName,
      customerName:customerName,
      fleetName,fleetName,
      vehicleName:vehicleName,
      alertModeName:alertModeName,
    };
    getData(formdata);
  };

  useEffect(() => {
    if (load) {
      onSubmit();
    }
  }, [load]);

  //Search--------------------------------------------------------------------------

  // vehicleSearch

  const vehicleSearch = (searchText) => {
    let vehicleSeatchPayload = {
      resellerId: resellerId,
      searchText: searchText,
      page: "0",
      size: "10",
      orgType: userOrgType,
      dealerId: orgId,
      vehicleAllFilter: true,
      imeiSearch: "",
    };
    if (search) {
      getVehicleSearch(vehicleSeatchPayload);
    } else {
      setSelectedVehicle(null);
      setVehicleList(vehicledata?.data);
    }
  };
  useLayoutEffect(() => {
    if (searchVehicleSuccess) {
      if (searchVehicles.data !== null) {
        setVehicleList(searchVehicles.data);
      }
    }
  }, [searchVehicleSuccess]);

  const regionSearch = (searchText) => {
    CommonPayload.value = "region";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = "-1";
    getRegionList(CommonPayload);
  };
  const dealerSearch = (searchText) => {
    CommonPayload.value = "dealer";
    CommonPayload.searchText = searchText;
    CommonPayload.regionId = region;
    if (region !== "-1") {
      getDealerList(CommonPayload);
    }
  };
  const customerSearch = (searchText) => {
    CommonPayload.value = "customer";
    CommonPayload.searchText = searchText;
    CommonPayload.dealerId = dealer;
    if (dealer !== "-1") {
      getCustomerList(CommonPayload);
    }
  };
  const FleetSearch = (searchText) => {
    CommonPayload.value = "fleet";
    CommonPayload.dealerId = "0";
    CommonPayload.searchText = searchText;
    CommonPayload.customerId = customer;
    if (customer !== "-1") {
      getFleetList(CommonPayload);
    }
  };

  //search
  const dropDownSearch = (searchText, name) => {
    if (name === "Vehicle") {
      setSearchText({ value: searchText });
      setSearchText({ name: name });
      setSearch(true);
      vehicleSearch(searchText);
    } else if (name === "Region") {
      regionSearch(searchText);
    } else if (name === "Dealer") {
      dealerSearch(searchText);
    } else if (name === "Customer") {
      customerSearch(searchText);
    } else if (name === "Fleet") {
      FleetSearch(searchText);
    }
  };

  return (
    <StyledPaperShadow
      elevation={0}
      sx={{ display: "inline-block", width: "100%" }}
    >
      {regionDrop && RoleId !== 9 && userOrgType !== 1 && (
        <SingleDropdown
          label={"Region"}
          listItems={regionList}
          handleChange={getDealer}
          value={region}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}
      {dealerDrop && RoleId !== 9 && userOrgType !== 1 && (
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerList}
          handleChange={getCustomer}
          value={dealer}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}

      {(customerDrop || RoleId === 9) && userOrgType !== 1 &&(
        <SingleDropdown
          label={"Customer"}
          listItems={customerList}
          handleChange={getFleet}
          value={customer}
          isSearch={dropDownSearch}
          showAll={RoleId !== 9 ? allOption : false}
          getInnerText={getInnerText}
        />
      )}

      {(fleetDrop || RoleId === 9) && userOrgType !== 1 && (
        <SingleDropdown
          label={"Fleet"}
          listItems={fleetList}
          handleChange={getvehicle}
          value={fleet}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}
      {(vehicleDrop || RoleId === 9) && (
        <SingleDropdown
          label={"Vehicle"}
          listItems={vehicleList}
          handleChange={selectVehicle}
          value={vehicle}
          isSearch={dropDownSearch}
          showAll={allOption}
          getInnerText={getInnerText}
        />
      )}
      {/*Sent Log Dropdowns Start */}
      {sentDrop && (
        <SingleDropdown
          label={"Alert Mode"}
          listItems={alertModeList}
          handleChange={handleAlertModeList}
          value={alertMode}
          isSearch={false}
          showAll={allOption}
          customAllValue={"0"}
          getInnerText={getInnerText}
        />
      )}
      {/*Sent Log Dropdowns Ends */}
      {/*Airtel Log Dropdowns Start */}
      {airtelDrop && (
        <SingleDropdown
          label={"Action"}
          listItems={actionList}
          handleChange={handleActionList}
          value={actionStatus}
          isSearch={false}
          showAll={allOption}
          customAllValue={"all"}
        />
      )}
      {airtelDrop && (
        <SingleDropdown
          label={"Search By"}
          listItems={searchList}
          handleChange={handleSearchList}
          value={searchStatus}
          isSearch={false}
          showAll={allOption}
          customAllValue={"0"}
        />
      )}
      
      {/*Airtel Log Dropdowns Ends */}
      {(sentDrop || searchStatus === "1") && (
        <DatepickerDropdown
          getDate={GetDate}
          activeLabel={activeLabel}
          showAll={false}
          allowDays={allowDays}
          allowedMonth={allowedMonth}
          createDateStatus={true}
          createDateValue={1}
        />
      )}

      <StyledFiterButton
        type="button"
        variant="contained"
        size="small"
        color="success"
        onClick={onSubmit}
        sx={{ marginTop: "25px" }}
      >
        Submit
      </StyledFiterButton>
    </StyledPaperShadow>
  );
}

export default LogsDropdown;
