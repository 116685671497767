import { dashboardApi } from "../ApiSlice";

const vehicleSummarySlice = dashboardApi.injectEndpoints({
    endpoints: builder => ({
        healthAlerts: builder.mutation({
            query: (body) => ({
                url: "vehicle/healthCheck",
                method: "POST",
                body: body,
              }),
        }),
        Alerts: builder.mutation({
            query: (body) => ({
                url: "Alerts/healthAlerts",
                method: "POST",
                body: body,
              }),
        }),
        vehicleTrips: builder.mutation({
            query: (body) => ({
                url: "vehicle/vehicleTripSummary",
                method: "POST",
                body: body,
              }),
        }),
        getAddress: builder.mutation({
            query: (body) => ({
                url: "/GeoFence/getGeocodeAddress",
                method: "POST",
                body: body,
              }),
        })
    })
})

export const { useHealthAlertsMutation, useVehicleTripsMutation, useGetAddressMutation, useAlertsMutation } = vehicleSummarySlice