import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  Stack
} from "@mui/material";
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from "@mui/material/FormLabel";
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from 'react';
import dropdownServices from '../../services/dropdownServices';
import { useGetDistanceTypeMutation, useSaveDistanceTypeMutation } from '../../store/slice/ApiSlice';
import {
  StyledFilterFormControl,
  StyledPaperShadow
} from "../../theme/StyledEle";
import StyledSnackBar from "../../Utils/StyledSnackBar";

const DistanceType = (props) => {
  const { tabValue } = props;
  const [resellers, setResellers] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedReseller, setSelectedReseller] = useState("");
  const [selectedCustomer, setSelectedCustomer] = useState("");
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [open, setOpen] = useState(false);

  const [saveDistanceType, { isLoading: saveMapTypeLoading, isSuccess: saveMapTypeSucccess }] = useSaveDistanceTypeMutation();
  const [getDistanceType, { data: getDistanceTypeData, isLoading: getDistanceTypeLoading, isSuccess: getDistanceTypeSuccess }] = useGetDistanceTypeMutation();

  const [distance, setDistance] = useState(1);

  const handleSaveButtonClick = () => {
        const payload = {
      id: selectedCustomer!="" ? selectedCustomer : selectedReseller ,
      distanceType: distance,
    };
    console.log(payload);
    saveDistanceType(payload);
     setDataStatusConfirm("Success");
     setOpen(true);
     setTimeout(() => {
       setOpen(false);
     }, 3000);

  }


  useEffect(() => {
    if (selectedReseller) {
      const payload = {
        id: selectedReseller,
      };
      getDistanceType(payload);
    }
 
  }, [selectedReseller]);


  useEffect(() => {
      if (selectedCustomer) {
      const payload = {
        id: selectedCustomer,
      };
      getDistanceType(payload);
    }
  }, [selectedCustomer]);

  useEffect(() => {
    if (getDistanceTypeData && getDistanceTypeData.data) {
      setDistance( getDistanceTypeData.data.mapType);
        }
  }, [getDistanceTypeData]);

  const fetchData = async () => {
    const resellerPayload = {
      value: "reseller",
      orgType: "2",
      resellerId: 1,
      dealerId: 0,
      roleId: 1,
      id: 1,
      searchText: "",
    };

    try {
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(resellerPayload);
      setResellers(orgTypeResponseNew.data?.content);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const customerData = async (selectedReseller) => {
    const customerPayload = {
      value: "customer",
      orgType: "2",
      resellerId:selectedReseller,
      dealerId: 0,
      roleId: 1,
      id: 1,
      searchText: "",
    };

    
    try {
      const { data: orgTypeResponseNew } = await dropdownServices.getResellers(customerPayload);
      setCustomers(orgTypeResponseNew.data?.content);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  

  useEffect(() => {
    fetchData();
  }, []);


  const handleChange = (event, newDistance) => {
    setDistance(newDistance);
  };

  const handleResellerChange = (event) => {
    setSelectedReseller(event.target.value);
    customerData(event.target.value);
  };

  const handleCustomerChange = (event) => {
    setSelectedCustomer(event.target.value);
     };

  

  const TabPanel = (props) => {
    const { children, value, index, ...other } = props;
    return (
      <div role="tabpanel" hidden={value !== index} {...other}>
        {value === index && <Box>{children}</Box>}
      </div>
    );
  };

  return (
    <Grid item lg={4}>
      <TabPanel value={tabValue} key={6} index={6} className='tabsPanel'>
        <StyledPaperShadow sx={{ display: 'block' }}>
          <Typography gutterBottom sx={{ mb: 1 }}>Distance Settings</Typography>
          <Box>
            <Grid container spacing={1}>
              <Grid item lg={12}>
                <StyledFilterFormControl size='small' sx={{ minWidth: '80%' }}>
                  <FormLabel required>Select Reseller</FormLabel>

                  <Select
                    value={selectedReseller}
                    onChange={handleResellerChange}
                    displayEmpty
                    fullWidth
                    sx={{ width: "300px" }}
                  >
                    <MenuItem value="" disabled>
                      Select a Reseller
                    </MenuItem>
                    {resellers.map((reseller) => (
                      <MenuItem key={reseller.key} value={reseller.key}>
                        {reseller.value}
                      </MenuItem>
                    ))}
                  </Select>


                  <FormLabel required>Select Customer</FormLabel>

                    <Select
                      value={selectedCustomer}
                      onChange={handleCustomerChange}
                      displayEmpty
                      fullWidth
                      sx={{ width: "300px" }}
                    >
                      <MenuItem value="" >
                        Select
                      </MenuItem>
                      {customers.map((reseller) => (
                        <MenuItem key={reseller.key} value={reseller.key}>
                          {reseller.value}
                        </MenuItem>
                      ))}
                    </Select>

                </StyledFilterFormControl>
              </Grid>

              <Grid item lg={12}>
                <Box>
                <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Select Distance Type </FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        value={distance}
        onChange={handleChange}
      >
        <FormControlLabel value="1" control={<Radio />} label="KM" />
        <FormControlLabel value="2" control={<Radio />} label="Mile" />
        </RadioGroup>
    </FormControl>
                </Box>
              </Grid>
              <Grid item lg={12}>
                <Stack direction='row' sx={{ mt: 2, display: "flex", justifyContent: 'flex-end' }}>
                  <Button
                    variant='contained'
                    size='small'
                    color='primary'
                    onClick={handleSaveButtonClick}
                    disabled={!selectedReseller || distance === null}
                  >
                    Update
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Box>
        </StyledPaperShadow>
      </TabPanel>
      {open && (
        <StyledSnackBar message={"Distance settings successfully"} open={true} status={dataStatusConfirm} />
      )}
    </Grid>
  );
};

export default DistanceType;
