import React, { useCallback, useEffect, useState } from 'react'
import SingleDropdown from '../NewDropdown/SingleDropdown';
import { Stack, useTheme } from '@mui/material';
import { useGetDropdownListMutation } from '../../store/slice/ApiSlice';
import { useGetGeoZoneByCustomerMutation, useGetVehicleByCustomerMutation } from '../../store/slice/RouteTripSlice/RouteTripSlice';

function CreateDropDown(props) {
    const { getVehicleData, getGeoZoneList,showDropdownDisable, dropdownValues, isEdit } = props
    const userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let RoleId = userData.roleId;
    let userOrgType = userData.userOrgType;
    const theme = useTheme();
    //State
    const [region, setRegion] = useState(null);
    const [dealer, setDealer] = useState(null);
    const [customer, setCustomer] = useState(null);
    const [fleet, setFleet] = useState(null);   
    const [vehicle, setVehicle] = useState(null);
    const [regionList, setRegionList] = useState(null);
    const [dealerList, setDealerList] = useState(null);
    const [customerList, setCustomerList] = useState(null);
    const [fleetList, setFleetList] = useState(null);
    const [vehicleList, setVehicleList] = useState(null);
    const [searchText, setSearchText] = useState({ value: null, name: null });
    const [search, setSearch] = useState(false);

    //Silce Mutation

    //Get Region List 
    const [ getRegionList, { data: regionData, isLoading: regionLoading, isSuccess: regionSuccess } ] = useGetDropdownListMutation();
    const [ getDealerList, { data: dealerdata, isLoading: dealerLoading, isSuccess: dealerSuccess } ] = useGetDropdownListMutation();
    const [ getCustomerList, { data: customerdata, isLoading: customerLoading, isSuccess: customerSuccess}] = useGetDropdownListMutation();
    const [ getVehicleListByCustomer, { data: vehicleData, isLoading: vehicleLoading, isSuccess: vehicleSuccess}] = useGetVehicleByCustomerMutation();
    const [ getGeoZoneByCustomer, { data: geoZoneData, isLoading: geoZoneLoading, isSuccess: geoZoneSuccess}] = useGetGeoZoneByCustomerMutation();
    
    let CommonPayload = {
      resellerId: resellerId,
    };

    let regionPayload = {
      value: "region",
      searchText: "",
    };
    // const getRegion = useCallback(async() => {
    //   const regionResponse =  await getRegionList(regionPayload);
    //   setRegionList(regionResponse.data.data);
    //   if(regionResponse.data.data !== null && regionResponse.data.data !== undefined){
    //       if(isEdit === null){
    //         // getDealer(regionResponse.data.data.content[0].key); 
    //       }
    //   }
    // },[])
    useEffect(() => {
      getCustomer();
    },[]);

    useEffect(() => {
      if(dropdownValues !== null){
        setTimeout(() => {
          if(isEdit !== null){
            // setRegion(dropdownValues.regionId);
            // getDealer(dropdownValues.regionId); 
            // setDealer(dropdownValues.dealerId)
            setCustomer(dropdownValues.customerId);
            getVehicle(dropdownValues.customerId);
          }
        },500)
      }
    },[dropdownValues])

  // const getDealer = async (regionId) => {
  //   if(isEdit === null){
  //     setRegion(regionId);
  //   }
  //   if(regionId !== '-1'){
  //     CommonPayload.value = "dealer";
  //     CommonPayload.regionId = regionId;
  //     CommonPayload.dealerId = "0";
  //     CommonPayload.searchText = "";
  //     const DealerResponse = await getDealerList(CommonPayload);
  //     setDealerList(DealerResponse.data.data);
  //     if(DealerResponse.data.data !== null && DealerResponse.data.data !== undefined){
  //       if(DealerResponse.data.data.content[0]?.key !== undefined){
  //           if(isEdit === null){
  //             getCustomer(DealerResponse.data.data.content[0]?.key);
  //           }else{
  //             getCustomer(dropdownValues.dealerId);
  //           }
  //       }else{
  //         setDealer(null);
  //         setCustomer(null);
  //         setCustomerList(null);
  //         getGeoZoneList(null, null);
  //         getVehicleData(null);
  //         setVehicleList(null);
  //       }
  //     }
  //   }
  // }

  const getCustomer = async () =>{
    // if(dropdownValues === null){
    //   setDealer(dealerId);
    // }
    // if(dealerId !== '-1'){
      CommonPayload.value = "customer";
      CommonPayload.resellerId = resellerId;
      CommonPayload.dealerId = 0;
      CommonPayload.searchText = "";
      const CustomerResponse = await getCustomerList(CommonPayload);
      if(CustomerResponse.data.data !== null && CustomerResponse.data.data !== undefined){
        if(CustomerResponse.data.data.content[0]?.key !== undefined){
            if(isEdit === null){
              getVehicle(CustomerResponse.data.data.content[0]?.key);
            }
            setCustomerList(CustomerResponse.data);
        }else{
          setCustomer(null);
          setCustomerList(null);
          setVehicleList(null);
          getGeoZoneList(null, null);
        }
      }
    // }else{
    //   setCustomer(null);
    // }
  }

  const getVehicle = useCallback(async(customerId) =>{
    setCustomer(customerId);
    if(customerId !== '-1'){
      CommonPayload.customerId = customerId;
      CommonPayload.searchText = "";
      const vehicleResponse = await getVehicleListByCustomer(CommonPayload);
      //Get GeoZone

      const GeoZOneResponse = await getGeoZoneByCustomer({'orgId':customerId});
      if(GeoZOneResponse.data.data !== null && GeoZOneResponse.data.data !== undefined){
        getGeoZoneList(GeoZOneResponse.data, customerId);
      }
      setVehicleList(vehicleResponse.data.data);
      getVehicleData(vehicleResponse.data);
    }
  },[])



  // const regionSearch = async (searchText) => {
  //   regionPayload.value = "region";
  //   regionPayload.searchText = searchText;
  //   const regionResponse =  await getRegionList(regionPayload);
  //   setRegionList(regionResponse.data.data);
  // };

  // const dealerSearch = async (searchText) => {
  //   regionPayload.value = "dealer";
  //   regionPayload.searchText = searchText;
  //   regionPayload.regionId = region;
  //   regionPayload.resellerId = resellerId;
  //   regionPayload.dealerId = "0";
  //   const dealerResponse =  await getDealerList(regionPayload);
  //   setDealerList(dealerResponse.data.data);
  // };
  
  const customerSearch = async (searchText) => {
    regionPayload.value = "customer";
    regionPayload.searchText = searchText;
    regionPayload.regionId = region;
    regionPayload.resellerId = resellerId;
    regionPayload.dealerId = dealer;
    const customerResponse =  await getCustomerList(regionPayload);
    setCustomerList(customerResponse.data);
  };


    //search
    const dropDownSearch = (searchText, name) => {
      setSearchText({ value: searchText, name: name });
      setSearch(true);
      // if (name === "Region") {
      //   regionSearch(searchText);
      // } 
      // else if (name === "Dealer") {
      //   dealerSearch(searchText);
      // } 
       if (name === "Customer") {
        customerSearch(searchText);
      }
    };

    useEffect(() => {
      if(dealerSuccess && searchText.name === 'Region'){
          setSearchText({value:null, name:null});
          setSearch(false)
          // regionSearch('');
      }
    },[dealerSuccess])

    useEffect(() => {
      if(customerSuccess && searchText.name === 'Dealer'){
          setSearchText({value:null, name:null});
          setSearch(false)
          // dealerSearch('');
      }
    },[customerSuccess])

    useEffect(() => {
      if(vehicleSuccess && searchText.name === 'Customer'){
          setSearchText({value:null, name:null});
          setSearch(false)
          customerSearch('');
      }
    },[vehicleSuccess])


  return (
    <Stack direction={{xl:'row', lg:'column', md:'column', sm:'column'}}>
        {/* Region */}
        {/* <SingleDropdown
          label={"Region"}
          listItems={regionData !== null && regionData?.data}
          handleChange={getDealer}
          value={region}
          isSearch={dropDownSearch}
          showAll={false}
          fullwidth={true}
          disabled={showDropdownDisable}
        />
        <SingleDropdown
          label={"Dealer"}
          listItems={dealerdata !== null && dealerdata !== undefined && dealerdata?.data}
          handleChange={getCustomer}
          value={dealer}
          fullwidth={true}
          isSearch={dropDownSearch}
          showAll={false}
          disabled={showDropdownDisable}
        /> */}
        <SingleDropdown
          label={"Customer"}
          listItems={customerList !== null && customerList !== undefined && customerList?.data}
          handleChange={getVehicle}
          value={customer}
          fullwidth={true}
          isSearch={dropDownSearch}
          showAll={false}
          disabled={showDropdownDisable}
        />
    </Stack>
  )
}

export default CreateDropDown