import React, { useState, useEffect, useMemo, useRef, createRef } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import { CardHeader, CardContent, Button } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import { useTheme } from "@mui/styles";
import MouseOverPopover from "../shared/PopOver";
import ReportNewDropDown from "../shared/ReportsDropDownNew";
import logServices from "../../services/logService";
import { ButtonAlign } from "../shared/button/ButtonAlign";
import {
  StyledCardShadow,
  Exportoptions,
  StyledPageLayout,
  StyledTableTitle,
} from "../../theme/StyledEle";
import addDays from "date-fns/addDays";
import { format } from "date-fns";
import {
  useAuditLogMutation,
  useStopPageMutation,
} from "../../store/slice/ApiSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import GetLocation from "../Location";



const Stoppage = () => {

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const text =
  `This report shows various instances when the vehicle was in stoppage beyond the threshold time. Stop is a status when the ignition is off and vehicle is not moving.Stoppage is reported if the vehicle was running continuously for more than 30 minutes and then in stop status beyond the threshold time.`;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  const theme = useTheme();

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > Stoppage";
    auditPayload.action = "View";
    auditPayload.featureName = "Stoppage";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = React.useMemo(() => {
    const commonColumns = [
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
        width:120,
      },
      // {
      //   Header: "Customer Name",
      //   accessor: "customerName",
      //   width:120,
      // },
      // {
      //   Header: "Zone Category",
      //   accessor: "zoneCategory",
      // },
      {
        Header: "GPS Location",
        accessor: "gpsLocation",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.gpsLocation !== "" &&   row.original.gpsLocation !== "NA" ?
          <GetLocation row={row} columnName={row.original.gpsLocation}/> : '-'
          )
        }
      },
      {
        Header: "Start Time",
        accessor: "startTime",
        width:120,
      },
      {
        Header: "End Time",
        accessor: "endTime",
        width:120,
      },
      {
        Header: "Duration (hh:mm)",
        accessor: "stopminsinhrs",
        width:120,
      },
     
    ];
  
    if (resellerId === 110224) {
      const additionalColumns = [
        {
          Header: "SOC Difference (%)",
          accessor: "socConsumed",
          width:120,
        },
        {
          Header: "Engery Consumed (kWh)",
          accessor: "energyConsumedMontra",
          width:120,
        }
      ];
      commonColumns.splice(2,0,       
        {
          Header: "GeoZone",
          accessor: "zoneName",
          width:120,
        }
      );
      return [...commonColumns, ...additionalColumns];
    }
  
    return commonColumns;
  }, [resellerId]);

  let initialState1 = {};
  var arr = [];

  if (resellerId !== 110224) {
 
    arr.push("customerName");
    arr.push("zoneCategory");
    initialState1.hiddenColumns = arr;
  }

  // Get Data from New Dropdown
  const [getOverSpeed, { data: oversppedData, isLoading, isSuccess }] =
    useStopPageMutation();
  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    //Service Call
    setPayload(commonPayload);
    getOverSpeed(commonPayload);

    auditPayload.message = "Reports > Stoppage > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Stoppage";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };
  //Export PDF & Excel
  const [payload, setPayload] = useState(null);
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "StoppageReport/stoppageReportXLS.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&size=" +
        payload.size +
        "&page=" +
        payload.page +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&sortBy=" +
        payload.sortBy +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > Stop Page > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "Stop Page";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
  const onChangePDF = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "StoppageReport/stoppageReportPDF.pdf?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&size=" +
        payload.size +
        "&page=" +
        payload.page +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&sortBy=" +
        payload.sortBy +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId;
      window.open(url);

      auditPayload.message = "Reports > Stop Page > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "Stop Page";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    } catch (error) {}
  };
  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    setNewPage(0);
    setRowsPerPage(25);
    payload.page = 0;
    payload.rowsPerPage = 25;
    if (payload.search.length >= 3) {
      getOverSpeed(payload);
    } else if (payload.search.length === 0) {
      getOverSpeed(payload);
    }
  };


  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.page = 0;
      getOverSpeed(payload);
    }else{
      setNewPage(newPage);
      payload.page = newPage;
      getOverSpeed(payload);
    }
  };


  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (oversppedData.data !== null) {
        setResponseData(oversppedData.data.content);
        setTotalElements(oversppedData.data.totalElements);
        setTotalPages(oversppedData.data.totalPages);
      }
    }
  }, [isSuccess]);

  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="Stoppage"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"Stoppage"} />
      <Box>
        <FilterDropdown getData={onSubmitForm} status={false} allowDays = {31} 
        time = {true} allowedMonth = {3} last6Month={false} last31={false} thisMonth ={true} currentMonth ={true} getDataLoading={isLoading}/>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={true}
              columns={columns}
              includeSearchBox={true}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePDF}
              handleChange={handleSearch}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={isLoading}
              initialState={initialState1}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default Stoppage;
