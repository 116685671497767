import { Alert, Box, Collapse, Divider, Fab, FormLabel, Grid, Icon, Pagination, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { StyledFormControl, StyledTimeline } from '../../theme/StyledEle';
import { useGetUserMutation } from '../../store/slice/ApiSlice';
import InnerTab from '../../Utils/InnerTab';
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { useGetUserActivityMutation } from '../../store/slice/AdminSlice/AdminSlice';
import { MdOutlineCheck, MdAdd, MdClose  } from "react-icons/md";
import moment from 'moment';
import { ScaleLoader, ScaleLoder } from 'react-spinners'

function ViewUser({data}) {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
    const theme = useTheme();
    const [ getUserDetails, { data: getUserData, isLoading: userDataLoading, isSuccess: userDetSuccess }] = useGetUserMutation();
    const [ getUserActivity, { data: activityData, isLoading: activityLoading, isSuccess: activitySuccess }] = useGetUserActivityMutation();
    const [userViewData, setUserViewData] = useState(null);
    const [userActivity, setUserActivity] = useState(null);
    const [plan, setPlan] = useState(null);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(5);

    let activityPayload = {
        "userId": data?.id,
        "page": page,
        "size": length,
        "orgId": orgId
    }

    const getActivity = async () => {
        const activity = await getUserActivity(activityPayload);
        setUserActivity(activity.data.dataObj);
    }

    useEffect(async() => {
        if(data !== null && data !== undefined){
            //const user = await getUserDetails({id:data.id});
            setUserViewData(data);
            getActivity();
        }
    },[])

    //Tabs
    const tabList = [
        {
            id:1,
            name:'Details',
            icon:'',
            show:true,
        },
        {
            id:2,
            name:'Activity',
            icon:'',
            show:true,
        }
    ]
    const [tabValue, setTabValue] = useState(1);
    const getTabValue = (value) => {
        setTabValue(value);
    }


    //Call  
    const pageChange = (event, value) => {
        setPage(value - 1);
        let page = value;
        activityPayload.page = page - 1;
        getActivity();
    }


  return (
        userViewData !== null && userViewData !== undefined && 
        <Box sx={{height:'100%', maxHeight:'75vh', overflowY:'auto'}}>
        <Box>
            <InnerTab tabList={tabList} getTabValue={getTabValue}/>
        </Box>
            {
                tabValue === 1 && 
                <Box sx={{p:2, background:theme.palette.background.paper}}>
            <Grid container spacing={2}>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>First Name</FormLabel>
                <Typography>{userViewData.firstName !== undefined && userViewData.firstName !== '' ? userViewData.firstName : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Last Name</FormLabel>
                <Typography>{userViewData.lastName !== null && userViewData.lastName !== '' ? userViewData.lastName : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>User Name</FormLabel>
                <Typography>{userViewData.userName !== null && userViewData.userName !== '' ? userViewData.userName : '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Mobile</FormLabel>
                <Typography>{userViewData.mobile !== null && userViewData.mobile !== '' ? userViewData.mobile: '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Email Id</FormLabel>
                <Typography>{userViewData.email !== null && userViewData.email !== '' ? userViewData.email: '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>Mobile</FormLabel>
                <Typography>{userViewData.mobile !== null && userViewData.mobile !== '' ? userViewData.mobile: '-'}</Typography>
            </StyledFormControl>
        </Grid>
        <Grid item lg={4}>
            <StyledFormControl>
                <FormLabel>User Role</FormLabel>
                <Typography>{userViewData.userRole !== null && userViewData.userRole !== '' ? userViewData.userRole: '-'}</Typography>
            </StyledFormControl>
        </Grid>
    </Grid>
                </Box>
            }
            {
                tabValue === 2 && 
                <Box sx={{background:theme.palette.background.paper}}>
                    <Box sx={{background:theme.palette.background.paper, px:2, py:1, position:'sticky', top:'0px', zIndex:9, borderBottom:`.5px solid ${theme.palette.divider}`}}>
                        <Stack direction={'row'} justifyContent={'space-between'}>
                            <Typography variant='body2' color={'text.secondary'}>User - <Typography sx={{fontWeight:600}} component={'span'}>{userViewData.userName}</Typography></Typography>
                            <Pagination count={activityData !== undefined ? Math.round(activityData?.count / length) === 0 ?  1 : Math.round(activityData?.count / length)  : 0} variant='outlined' shape='rounded' page={page + 1} sx={{position:'sticky', top:'0px'}} size='small' onChange={pageChange}/>
                        </Stack>
                    </Box>
                    <Box>
                        <Collapse in={activityLoading}>
                            <Alert severity='success' icon={false} variant='filled' sx={{borderRadius:'0px', justifyContent:'center'}}>
                                <ScaleLoader color="#fff" height={15}/>
                            </Alert>
                        </Collapse>
                    </Box>
                    <StyledTimeline>
                        {
                           userActivity !== null && userActivity.length > 0 ? userActivity.map((item) => {
                            return(
                                <>
                                <TimelineItem>
                                <TimelineOppositeContent>
                                    <Typography variant='body2'>{moment(item.key, 'DD-MM-YYYY').format('Do-MMM-YYYY')}</Typography>
                                </TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                        <Stack sx={{mt:2}}>
                                            {
                                                item?.obj?.map((item) => {
                                                    let color = (item.action.toLowerCase() === "create" || item.action.toLowerCase() === "ticket creation") ? theme.palette.success.main : item.action.toLowerCase() === "update" || item.action === null || item.action === "null" ? theme.palette.info.main : theme.palette.error.main
                                                    let icon = (item.action.toLowerCase() === "create" || item.action.toLowerCase() === "ticket creation") ? <MdAdd /> : item.action.toLowerCase() === "update" || item.action === null || item.action === "null" ? <MdOutlineCheck />
                                                    : <MdClose />
                                                    return(
                                                        <Stack direction={'column'}>
                                                            <Icon sx={{background:color}}>{icon}</Icon>
                                                            <Stack direction={'column'}>
                                                                <Typography variant='body1' gutterBottom>{item.action === "null" || item.action === null?'update':item.action.toLowerCase()}</Typography>
                                                                <Typography variant='caption'>{item.createdDate}</Typography>
                                                            </Stack>
                                                            <Typography variant='body2'>{item.description}</Typography>
                                                            <Divider sx={{my:1}}/>
                                                        </Stack>
                                                    )
                                                })
                                            }
                                        </Stack>
                                        
                                </TimelineContent>
                                </TimelineItem>
                                </>
                            )
                           }) : <Box sx={{px:3, py:1, background:theme.palette.grey[100]}}>
                                    <Typography variant='subtitle1'>No Activity</Typography>
                                </Box>
                        }
                    </StyledTimeline>
                </Box>
            }
        </Box>
  )
}

export default ViewUser