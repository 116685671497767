import Intro from "./Intro"
import { GetNumber, IntroOptions, ReportList, ReportListDetail, DateSelectList, BatteryHealth, VehicleTrip, CurrentFault, CurrentAlert, MainList, BatteryList, MotorList, BatteryDetailList, MotorDetailList, MotorHealthList, Chatgpt, ClearAirZoneList, TollsList, HighbridgeList, LowbridgeList } from "./Reports"

const Widgets = (props) => {
    const widgets = [
        //intro
        {
            widgetName:'intro',
            widgetFunc: (props) => <Intro {...props}/>
        },
        {
            widgetName:'IntroOptions',
            widgetFunc: (props) => <IntroOptions {...props}/>
        },
        {
            widgetName:'reportData',
            widgetFunc:(props) => <GetNumber {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'mainList',
            widgetFunc:(props) => <MainList {...props}/>
        },
        {
            widgetName:'reportList',
            widgetFunc:(props) => <ReportList {...props}/>
        },
        {
            widgetName:'reportListDetail',
            widgetFunc:(props) => <ReportListDetail {...props}/>,
            mapStateToProps:['gridData']
        },
        {
            widgetName:'chatgpt',
            widgetFunc:(props) => <Chatgpt {...props}/>,
            mapStateToProps:['gridData']
        },        
        {
            widgetName:'clearAirZoneList',
            widgetFunc:(props) => <ClearAirZoneList {...props}/>
        },        
        {
            widgetName:'tollsList',
            widgetFunc:(props) => <TollsList {...props}/>
        },        
        {
            widgetName:'highbridgeList',
            widgetFunc:(props) => <HighbridgeList {...props}/>
        },        
        {
            widgetName:'lowbridgeList',
            widgetFunc:(props) => <LowbridgeList {...props}/>
        }
    ]
    return widgets
}

export default Widgets