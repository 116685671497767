import React, { useEffect, useMemo, useState } from "react";
import { ExpandTable } from "../shared/data_table/ExpandTable";

import { Box, CardContent, CardHeader, Dialog, DialogContent, Icon, Typography } from "@mui/material";
import { SoftButton, StyledCardShadow, StyledFormButtonsRow, StyledTableTitle } from "../../theme/StyledEle";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { useDeleteRouteMutation, useGetManageRouteListMutation } from "../../store/slice/RouteTripSlice/RouteTripSlice";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { snackService } from "../../store/slice/ControlsSlice";
import StyledModel from "../../Utils/StyledModel";
import { AiOutlineDelete } from "react-icons/ai";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";

const ListManageTrip = ({ onEditRoute, onViewRoute }) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [commonFilter, setCommonFilter] = useState({
    edit: false,
    view: false,
    delete: false,
  });
  const navigate = useNavigate();
  const dispatch = useDispatch();
  
  const[deleteItem,setDeleteItem] = useState(null);





  const columns = useMemo(
    () => [
      // {
      //   Header: "",
      //   accessor: "expandIcon",
      //   width:50,
      //   disableResizing: true,
      // },
      {
        Header: "Route Name",
        accessor: "routeName",
        width:'320'
      },
      {
        Header: "Start Geozone",
        accessor: "startGeozone",
      },
      {
        Header: "End Geozone",
        accessor: "endGeozone",
      },
      {
        Header: "Distance (mi)",
        accessor: "totalDistance",
      },
      {
        Header: "Vehicle(s)",
        accessor: "vehiclesNo",
        width:420
      },
      {
        Header: "Created Date",
        accessor: "createdDate",
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
        width:90

      },
    ],
    []
  );


  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, 
    orgName: orgName, 
    userName: userName,
  };

  const [
    getManageTripList,
    {
      data: manageTripData,
      isLoading: getManageTripDataLoading,
      isSuccess: getManageTripSuccess,
    },
  ] = useGetManageRouteListMutation();
  let commonPayload = {};
  let [payload, setPayload] = useState(null);
  // Onload and Submit
  const getData = (data) => {
    if (JSON.parse(localStorage.getItem("menuList")) !== null) {
      for (
        let k = 0;
        k < JSON.parse(localStorage.getItem("menuList")).length;
        k++
      ) {
        if (JSON.parse(localStorage.getItem("menuList"))[k].sub.length > 0) {
          for (
            let j = 0;
            j < JSON.parse(localStorage.getItem("menuList"))[k].sub.length;
            j++
          ) {
            if (
              JSON.parse(localStorage.getItem("menuList"))[k].sub[j].route ===
              "route"
            ) {
              var menuUser = JSON.parse(localStorage.getItem("menuList"))[k]
                .sub[j].button;

              var dataStr = {};
              dataStr.edit = false;
              dataStr.delete = false;
              dataStr.view = false;
              var strval;
              strval = menuUser.split(",");
              for (var i = 0; i < strval.length; i++) {
                if (parseInt(strval[i]) === 4) {
                  dataStr.edit = true;
                }
                if (parseInt(strval[i]) === 2) {
                  dataStr.view = true;
                }
                if (parseInt(strval[i]) === 3) {
                  dataStr.delete = true;
                }
              }

              setCommonFilter(dataStr);
              commonPayload.orgId = data.customerId;
              commonPayload.page = newPage;
              commonPayload.size = rowsPerPage;
              commonPayload.routeId = data.routeId;
              commonPayload.searchText = "";
              getManageTripList(commonPayload);
              setPayload(commonPayload);

              auditPayload.message = "Reports > Route Trips > Manage Route > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.routeName+" > "+"Submit";
              auditPayload.action = "Submit";
              auditPayload.featureName = "Route Trips";
              auditPayload.customerName = "All";
              auditPayload.fleetName = "All";
              auditPayload.result = "Success";
              auditPayload.access = "Portal";
              auditPayload.severity = "INFO";
              auditPayload.userName = userName;
              auditLog(auditPayload);
            }
          }
        }
      }
    }
  };
  useEffect(() => {
    if (getManageTripSuccess) {
      setResponseData([]);
      if (manageTripData.data !== null) {
        setResponseData(manageTripData.data.content);
        setTotalElements(manageTripData.data.totalElements);
        setTotalPages(manageTripData.data.totalPages);
      }
    }
  }, [getManageTripSuccess]);

  const pageChange = (newPage, rowsPerPage) => {
    setNewPage(newPage);
    setRowsPerPage(rowsPerPage);
    payload.page = newPage;
    payload.size = rowsPerPage;
    getManageTripList(payload);
  };
  const handleSearch = (searchText) => {
    setNewPage(0);
    setRowsPerPage(25);
    payload.search = searchText;
    payload.page = 0;
    payload.size = 25;
    if (payload.search.length >= 3) {
      getManageTripList(payload);
    } else if (payload.search.length === 0) {
      getManageTripList(payload);
    }
  };

  //OnEdit call
  const onEdit = (itemEdit) => {
    onEditRoute(itemEdit);
  };

  //OnView call
  const onView = (itemView) => {
    onViewRoute(itemView);
  };

//Delete call
const [
  delteItem,
  {
    data: deleteData,
    isLoading: isDeleteLoading,
    isSuccess: isDeleteSuccess,
  },
] = useDeleteRouteMutation();

  //Delete Popup
  const [deleteie, setDeleteIe] = useState(false);
  const [deleteMsg, setDeleteMsg] = useState('Please unassign the vehicles');
  //OnDelete call
  const onDelete = (itemDeleted) => {
    setDeleteItem(itemDeleted);
    setDeleteIe(true);
  };

  const deleteClose = () => {
    setDeleteIe(false);
  }

  const deleteItems = () => {
    let payload = {
      routeId: deleteItem.routeId,
    };
    delteItem(payload);
  };
  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );
      getManageTripList(payload);
    }
  }, [isDeleteSuccess]);

  return (
    <>
        <FilterDropdown
          getData={getData}
          status={false}
          isShowAll={false}
          showRoute={true}
          value={1}
          showCalendar={false}
        />
      <Box>
      <StyledCardShadow sx={{ my: 2 }}>
        <CardHeader title={<StyledTableTitle>Vehicle List</StyledTableTitle>} />
        <CardContent sx={{ py: 0 }}>
          <ExpandTable
            isLoading={getManageTripDataLoading}
            includeSearchBox={false}
            handleChange={handleSearch}
            commonFilter={commonFilter}
            columns={columns}
            data={responseData}
            styles={{ maxWidth: "100vw" }}
            page={newPage}
            rowsPerPage={rowsPerPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            onEdit={onEdit}
            onView={onView}
            includeExportButton={false}
            onDelete={onDelete}
          />
        </CardContent>
      </StyledCardShadow>

      <Dialog open={deleteie} onClose={deleteClose} maxWidth={"sm"} fullWidth={true}>
       
        <DialogContent sx={{ px: 0, py: 2 }}>
        {
          deleteItem !== null && deleteItem?.vehiclesNo !== "-" ?
          deleteItem?.vehiclesNo !== ""?
          <>
          <Icon
            sx={{
            fontSize: "50px",
            height: "auto",
            margin: "auto",
            display: "flex",
            }}
            color="error"
            >
            <AiOutlineDelete />
            </Icon>
            <Typography sx={{ textAlign: "center" }} variant="h6">
              {deleteMsg}
            </Typography>
            <StyledFormButtonsRow  nsRow direction="row" sx={{ mt: 2}} spacing={2}>
              <SoftButton
              size="small"
              color="error"
              className="error"
              onClick={deleteClose}
            >
              Close
            </SoftButton>
            </StyledFormButtonsRow>
          
          </>: <>
            <Icon
            sx={{
            fontSize: "50px",
            height: "auto",
            margin: "auto",
            display: "flex",
            }}
            color="error"
            >
            <AiOutlineDelete />
            </Icon>
            <Typography sx={{ textAlign: "center" }} variant="h6">
                Are you sure want to delete?
            </Typography>
              <Box sx={{ px: 2 }}>
              <Typography sx={{ textAlign: "center", my: 2 }}>
                Route Name: {deleteItem?.routeName}
              </Typography>
            </Box>
            <StyledFormButtonsRow direction="row" sx={{ mt: 2}} spacing={2}>
          <SoftButton
            size="small"
            color="error"
            className="error"
            onClick={deleteClose}
          >
            Cancel
          </SoftButton>
          <SoftButton
            size="small"
            className="success"
            color="success"
            type="submit"
            onClick={deleteItems}
          >
            Confirm
          </SoftButton>
        </StyledFormButtonsRow>
        </>
          
           : 
          <>
            <Icon
            sx={{
            fontSize: "50px",
            height: "auto",
            margin: "auto",
            display: "flex",
            }}
            color="error"
            >
            <AiOutlineDelete />
            </Icon>
            <Typography sx={{ textAlign: "center" }} variant="h6">
                Are you sure want to delete?
            </Typography>
              <Box sx={{ px: 2 }}>
              <Typography sx={{ textAlign: "center", my: 2 }}>
                Route Name: {deleteItem?.routeName}
              </Typography>
            </Box>
            <StyledFormButtonsRow direction="row" sx={{ mt: 2}} spacing={2}>
          <SoftButton
            size="small"
            color="error"
            className="error"
            onClick={deleteClose}
          >
            Cancel
          </SoftButton>
          <SoftButton
            size="small"
            className="success"
            color="success"
            type="submit"
            onClick={deleteItems}
          >
            Confirm
          </SoftButton>
        </StyledFormButtonsRow>
        </>
  }
        </DialogContent>
      </Dialog>
      </Box>
    </>
  );
};

export default ListManageTrip;
