import { Box } from '@mui/material'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import React, { forwardRef, useCallback, useMemo, useState } from 'react'
import { GOOGLE_MAP_KEY } from '../constants/appConstant';

const GoogleMapUtil = ({children, mapDepend, center={ lat: 52.92214338014607, lng: -1.4703258589163344 }, zoom=5, mapRef}) => {
    const mapContainerStyle = {
        height:'inherit',
        position:'relative',
        pointerEvents:'inherit',
        height:'100%'
        }
        const [libraries] = useState(["drawing", "places", "geometry"]);
        const {isLoaded} = useJsApiLoader({
            id: "google-map-script",
            googleMapsApiKey: GOOGLE_MAP_KEY,
            libraries,
            nonce:"map"
        })
        const [map, setMap] = useState(null);
        const [mapzoom, setMapzoom] = useState(zoom);
        const [maxZoom, setMaxZoom] = useState(5);
        const [mapType, setMapType] = useState("roadmap");
        const [defaultCenter, setDefaultCenter] = useState(center);
        //Map Onload
        const onLoad = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(defaultCenter);
        map.fitBounds(bounds);
        setMap(map);
        mapRef.current = map;
        setMapzoom(5);
        },[])
       
        const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
        }, []);

        const centerChanged = () => {
            setMaxZoom(22)
        }

        const handleMapZoom = () => {
            setMaxZoom(22)
            setMapzoom(map?.zoom);
        }

        const options = {
            zoom:mapzoom,
            center:center,
            minZoom:2,
            maxZoom:maxZoom,
            fullscreenControl: false,
            streetViewControl: false,
            zoomControl: false,
            mapTypeControl: false,
            disableDefaultUI: true,
            featureType: "poi.business",
            stylers: [{ visibility: "off" }],
            featureType: "transit",
            elementType: "labels.icon",
            stylers: [{ visibility: "off" }],
            featureType: "poi",
            stylers: [{ visibility: "off" }],
            tilt:47.5,
            heading: 320,
            }

       

    const GoogleMemo = useMemo(() => {
        return(
            isLoaded ? <GoogleMap
            mapContainerStyle={mapContainerStyle}
            options={{
                mapTypeId: mapType,
                ...options,
            }}
            onLoad={onLoad}
            onUnmount={onUnmount}
            zoom={mapzoom}
            onZoomChanged={handleMapZoom}
            onCenterChanged={centerChanged}
        >
            {children}
        </GoogleMap> : 'Loading...'
        )
    },[isLoaded, ...mapDepend, center, zoom])

  return (
    <Box sx={{height:'100%'}}>
       {GoogleMemo}
    </Box>
  )
}

export default GoogleMapUtil