import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Typography,
  Alert,
  Grid,
  FormLabel,
  Stack,
  InputAdornment,
  Icon,
  IconButton,
  Tooltip,
  LinearProgress,
  Snackbar,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { AUTH_LOGIN } from "../../constants/actionType";
import loginService from "../../services/loginService.js";
import { saveToken } from "../../Utils";
import service from "../../services/service";
import userServices from "../../services/userServices";
import Captcha from "captcha-image";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useTheme } from "@mui/styles";
import ScaleLoader from "react-spinners/ScaleLoader";
import menuListService from "../../services/menuListService";
import { useGetLogoApiMutation } from "../../store/slice/ApiSlice";
import { StyledFormControlLabel, StyledTextField } from "../../theme/StyledEle";
import { AiFillEye, AiFillEyeInvisible, AiOutlineReload } from "react-icons/ai";
import StyledSnackBar from "../../Utils/StyledSnackBar.js";
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";
export const Loginform = ({ showForgot }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    register,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    mode: "all",
    defaultValues: {
      name: null,
      password: null,
      captcha: null,
    },
  });
  const [validation, setValidation] = useState("");
  const [msg, setMsg] = useState();
  const [error, setError] = useState(false);
  const [elementData, setElementData] = useState();
  const [circularOpen, setCircularOpen] = React.useState(false);
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(true);
  const [captcha, setCaptcha] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [userLoaction,setUserLocation] = useState("");
//User Location 

const getUserLocation = () =>{

  // Check if geolocation is supported by the browser
  if ("geolocation" in navigator) {
    // Prompt user for permission to access their location
    navigator.geolocation.getCurrentPosition(
      // Success callback function
    
      function(position) {
        // Get the user's latitude and longitude coordinates
        const lat = position.coords.latitude;
        const lng = position.coords.longitude;
        setUserLocation(`${lat},${lng}`)
        // Update the map with the user's new location
      },
      // Error callback function
      function(error) {
        // Handle errors, e.g. user denied location sharing permissions
        console.error("Error getting user location:", error);
      }
    );
  } else {
    // Geolocation is not supported by the browser
    console.warn("Geolocation is not supported by this browser.");
  }
  }

  useEffect(()=>{
    let location = window.location.hostname;
    // console.log(location.split("."));
    getUserLocation()
  
  },[location.pathname])

  //Login Start
  const onSubmit = (data) => {
    setCircularOpen(true);
    window.localStorage.removeItem("menuList");
    window.localStorage.removeItem("userData");
    window.localStorage.removeItem("isRefreshToken");
    window.localStorage.removeItem("jwToken");
    window.localStorage.removeItem("expjwToken");
    window.localStorage.removeItem("rshjwToken");

    
    let userName = data.userName.trim();
    let payload = {
      'userName':userName,
      'password':data.password,
      'captcha':data,captcha,
      'name':null,
      'latLong':userLoaction,
    }
    let loginPayload = {
      'latLong':userLoaction,
    }

    
    service
      .post("/authenticate", payload)
      .then((response) => {
        // console.log(response);
        if (response.status === 200) {
          var userInfo = response.data;
          saveToken(userInfo.accessToken, userInfo.refreshToken);
          loginService
            .loginUserData(loginPayload)
            .then((res) => {
              // console.log(res);
              localStorage.setItem("userData", JSON.stringify(res.data.data));
              var request1 = {},
                region2 = {};
              request1.id = 0;
              request1.orgId = res.data.data.orgId;
              request1.orgType = res.data.data.userOrgType;
              request1.roleId = res.data.data.roleId;
              request1.page = 0;
              request1.size = 100;
              request1.value = "region";
              request1.searchText = "";
              request1.vehModel = "1";
              region2.region = 1;
              menuListService.getMenuList(request1, region2).then((res) => {
                //setMenuList(MENUS);
                window.localStorage.removeItem("menuList");
                window.localStorage.setItem("menuList", null);
                window.localStorage.setItem(
                  "menuList",
                  JSON.stringify(res.data.menus)
                );
                let location = window.location.hostname.split('.')[0];
                var request2 = {};
              request2.userName=userName;
              request2.url="common";
              // request2.url=location
              //get Reseller List
              loginService.getResellerList(request2).then((resp1) => {
             
                window.localStorage.removeItem("resellerList");
                window.localStorage.setItem("resellerList", null);
                window.localStorage.setItem(
                  "resellerList",
                  JSON.stringify(resp1.data.data)
                );
                  
                setCircularOpen(false);
                // navigate("/mapviews")
                navigate(res.data.menus[0]?.sub[0]?.route !== undefined ? `/${res.data.menus[0]?.sub[0]?.route}` : `/${res.data.menus[1]?.sub[1]?.route}`);
              });
              
              //GetLogoService
              //Get Logo API Via
              if (null !== localStorage.getItem("userData")) {
                setValidation("");
                setError(false);
                setMsg("Login Success");
                //setTimeout(() => , 1000);
              }

              return res;

              
            })
            .catch((err) => {
              if (localStorage.getItem("userData") !== null) {
                localStorage.removeItem("userData");
                //navigate("/");
              }
            });
          })
            // .catch((err) => {
            //   if (localStorage.getItem("userData") !== null) {
            //     localStorage.removeItem("userData");
            //     //navigate("/");
            //   }
            // });
        } else {
          setCircularOpen(false);
        }
      })
      //
      .catch((err) => {
        setMsg('Something went wrong');
        setCircularOpen(false);
        setValidation(err);
        setMsg(err.response.data.resultMessage);
        setError(true);
        setTimeout(() => setError(false), 2000)
        setMsg(err.response.data.resultMessage);
        setCircularOpen(false);
      });
    dispatch({ type: AUTH_LOGIN, payload: data });
  };

  function createMarkup(source) {
    return { __html: source };
  }

  function MyCaptcha() {
    const captchaImage = new Captcha(
      "20px Noto Sans SC",
      "center",
      "middle",
      100,
      37,
      "transparent",
      "black",
      4
    ).createImage();

    return <div dangerouslySetInnerHTML={createMarkup(captchaImage)} />;
  }

  useEffect(() => {
    setElementData(returnData(<MyCaptcha />));
  }, []);

  const returnData = () => {
    setCaptcha(true);
    return <MyCaptcha />;
  };

  const reloadcaptcha = () => {
    setElementData(returnData(<MyCaptcha />));
  };

  const submitButtonRef = useRef();

  const getCaptcha = (e) => {
    if (document.images.item(1).getAttribute("data-key") === e.target.value) {
      setCaptcha(false);
    } else {
      setCaptcha(true);
    }
  };

  

  useEffect(() => {
    if (captcha !== null) {
      submitButtonRef.current.focus();
    }
  }, [captcha]);

  const showpassword = () => {
    setShowPassword(!showPassword);
  };


  return (
    <Box>
      {circularOpen && (
        <Box
          sx={{ position: "absolute", width: "100%", top: "0px", left: "0px" }}
        >
          <LinearProgress
            sx={{
              "&": {
                background: "#fff",
              },
              "& .MuiLinearProgress-bar": {
                background:
                  "linear-gradient(90deg, #cc5021 20%, #a50156 33%, #ffb900 70% )",
              },
            }}
          />
        </Box>
      )}

      <Box sx={{ mb: 5 }}>
        <Typography variant="h5">{t(`sign_in`)}</Typography>
        <Typography variant="body1">{t(`access_Portal`)}</Typography>
      </Box>
      {error === true && (
        <Snackbar open={error} anchorOrigin={{ vertical:'top', horizontal:'center' }}>
           <Alert
            severity={error ? "error" : "success"}
          >
            {msg}
        </Alert>
        </Snackbar>
       
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <Stack direction="column" spacing={2}>
          <StyledFormControlLabel>
            <FormLabel>{t(`username`)}</FormLabel>
            <StyledTextField
              size="small"
              id="userName"
              type="text"
              fullWidth
              error={errors.userName && true}
              {...register("userName", {
                required: {
                  value: true,
                  message: "Required Field ",
                },
              })}
              inputProps={{
                autoComplete:'username'
              }}
              onChange={(e) => setValue("userName", e.target.value)}
              helperText={errors.userName && errors.userName.message}
            />
          </StyledFormControlLabel>
          <StyledFormControlLabel>
            <FormLabel>{t(`password`)}</FormLabel>
            <StyledTextField
              
              size="small"
              error={errors.password && true}
              {...register("password", {
                required: {
                  value: true,
                  message: "Required Field ",
                },
              })}
              onChange={(e) => setValue("password", e.target.value)}
              helperText={errors.password && errors.password.message}
              type={showPassword ? "text" : "password"}
              id="password"
              fullWidth
              inputProps={{
                  autoComplete:'current-password'
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={showpassword} size="small">
                      {showPassword ? <AiFillEyeInvisible /> : <AiFillEye />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </StyledFormControlLabel>
          <StyledFormControlLabel id="setid" variant="outlined">
            <FormLabel>{t(`captcha`)}</FormLabel>
            <StyledTextField
              className="captcha_img"
              fullWidth
              size="small"
              id="captcha"
              type="text"
              name="captcha"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {elementData}
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start">
                    <Tooltip title="Reload Captcha" arrow>
                      <IconButton onClick={reloadcaptcha} size="small">
                        <AiOutlineReload />
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
              error={errors.captcha && true}
              {...register("captcha", {
                required: {
                  value: true,
                  message: "Required Field ",
                },
                validate: (fieldValue) => {
                  return (
                    fieldValue ===
                      document.images.item(1).getAttribute("data-key") ||
                    "Invalid Captcha"
                  );
                },
              })}
              onChange={(e) => getCaptcha(e)}
              helperText={errors.captcha && errors.captcha.message}
            />
          </StyledFormControlLabel>
        </Stack>
        <FormControlLabel
          control={<Checkbox value="remember" color="primary" size="small" />}
          label={t(`remember_me`)}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ my: 1 }}
          color="primary"
          disabled={captcha}
          ref={submitButtonRef}
        >
          {circularOpen ? t(`loading`) : t(`login`)}
        </Button>
        {/* <ScaleLoader color="#fff" />  */}
        <Button onClick={() => showForgot(true)} color="primary" fullWidth>
        {t(`forgat_password`)}
        </Button>
      </Box>
    </Box>
  );
};

export const Forgotpassword = ({ showForgot }) => {

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });

  const [dialogContent, setDialogContent] = useState({});
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [open, setOpen] = useState(false);
const[successPop,setSuccessPop] = useState(false);
const[forgetPop,setForgetPop] = useState(true);


  //Login End

  //Fotgot Password Start
  let url = window.location.origin;

 
 

  const onSubmit = async (data) => {
    var request = {};
    request.userName = data.userName;
    request.path = url;
    request.email = data.userName

    const responseData1 = (await userServices.getForgetPassword(request)).data;
    var obj1 = {};
    obj1.dialogText = responseData1 === "/fpwdsuccessHyundai"?"Mail sent successfully":"Mail sent Failed";
    setDialogContent(obj1);
    setDataStatusConfirm(responseData1 === "/fpwdsuccessHyundai"?"Success":"Failed");
    setOpen(true);
    setTimeout(() => {
      setOpen(false);
    }, 2000);
    if(responseData1 === "/fpwdsuccessHyundai"){
      setSuccessPop(true)
      setForgetPop(false)
    }else{
      setSuccessPop(false)
      setForgetPop(true)

    }
  };

  return (
    <>
    {(successPop === false && forgetPop === true) &&
    <Box>
      <Box sx={{ mb: 5 }}>
        <Typography variant="h5">Forgot Password</Typography>
        <Typography variant="body1">Change password here!</Typography>
      </Box>
      <Alert icon={false} color="info">
        To reset your password, enter your user name. Password reset details
        will be sent to your registered Email.
      </Alert>
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit)}
        sx={{ width: "100%" }}
      >
        <TextField
          size="medium"
          margin="normal"
          // id="userName"
          label="Username"
          type="text"
          fullWidth
          error={errors.userName && true}
          {...register("userName", {
            required: {
              value: true,
              message: "Required Field ",
            },
          })}
          onChange={(e) => setValue("userName", e.target.value)}
          helperText={errors.userName && errors.userName.message}
        />
        <Button
          type="submit"
          variant="contained"
          fullWidth
          sx={{ my: 1 }}
          color="primary"
        >
          Change Password
        </Button>
        <Button color="primary" fullWidth onClick={showForgot}>
          Back to Login
        </Button>
      </Box>
      
    </Box>}
    {(successPop === true && forgetPop === false) &&
  <Box
  sx={{ width: "100%" }}
  >
  <Typography component="h5" variant="h5" sx={{ mt: 1 }}>
    Your Change Password link has been sent successfully to your entered email address.
  </Typography>
  <Button color="primary" fullWidth onClick={showForgot}>
          Back to Login
        </Button>
</Box>  }
    {open && (
        <StyledSnackBar
          open={true}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
};
