import { createSlice } from "@reduxjs/toolkit";
import React from "react";

const initialState = {
    customizer: {
        mode: 'light',
        themeView: 'default',
        fontFamily: 'Noto Sans SC',
        themeCustom: true,
        themePallete: null,
        header: {
            theme: 'default',
            primary: null,
            secondary: null,
        },
        sidebar: {
            theme: 'default',
            primary: null,
            secondary: null,
            backgroundImg: 'none'
        },
        mapMode: 1,
        treeViewSettings: '0',
        mini: true,
        popupIsHide: false,
        userImg: null,
        currentPage: {
            buttonOpt: null,
        }
    },
    refreshBtn: {
        mapRef: false,
        vehicleRef: false,
    },
    authToken: null,
    refToken: null,
    isloading: false,
    siteIdentity: {
        title: 'Telematics Portal',
        tagLine: 'Telematics Portal',
        descriptions: 'Telematics Portal',
        weburl: '',
        logoURL: '',
        favIconURL: '',
    },
    dropVehicles: null,
    vehicleCount: null,
    vehicleTrails: null,
    vehicleSummary: false,
    vehicleCategoryList: null,
    chargingMarkers: null,
    stopPageMarkers: null,
    tripsList: [],
    selectedVehicle: null,
    singleVehicleData: null,
    chatBotSingleVehicle: null,
    hoverVehicle: null,
    selectedType: {
        id: null,
        type: null
    },
    //if click treeview dealer and customer get id for get vehicles
    treeview: {
        date: null,
        trailsTime: 1,
        selectedTrailsMarkerData: null,
        searchKeyWord: null,
        searchCategoryKeyWord: null,
        id: null,
        role: null
    },
    alert: {
        message: '',
        status: '',
        isSuccess: '',
    },
    alertRoute: {
        message: '',
        status: '',
        isSuccess: '',
    },
    routeTrip: null,
    routeTripDuration: null,
    mapOptions: {
        map: 'here',
        mapTypeId: 1,
    },
    breadcrumb: {
        pages: [],
        popup: false,
        mesage: null
    }
}


export const Controls = createSlice({
    name: 'SiteControls',
    initialState,
    reducers: {
        getAuth: (state, payload) => {
            state.authToken = payload.payload[0];
            state.refToken = payload.payload[1];
        },
        logout: (state) => {
            window.localStorage.removeItem("rshjwToken");
            window.localStorage.removeItem("jwToken");
            window.localStorage.removeItem("menuList");
            window.localStorage.removeItem("expjwToken");
            window.localStorage.removeItem("userData");
            return initialState;
        },
        toggleMode: (state, payload) => {
            payload.payload === 'light' ? state.customizer.mode = 'dark' : state.customizer.mode = 'light';
            payload.payload === 'light' ? state.customizer.mapMode = 2 : state.customizer.mapMode = 1;
        },
        toggleMini: (state, payload) => {
            payload.payload ? state.customizer.mini = false : state.customizer.mini = true;
        },
        toggleTheme: (state, payload) => {
            state.customizer.themeView = payload.payload
        },
        setTheme: (state, payload) => {
            state.customizer.themePallete = payload.payload
        },
        toggleFont: (state, payload) => {
            state.customizer.fontFamily = payload.payload
        },
        toggleHederStyle: (state, payload) => {

            state.customizer.header.theme = payload.payload[0]
            state.customizer.header.primary = payload.payload[1]
            state.customizer.header.secondary = payload.payload[2]
        },
        toggleSidebarStyle: (state, payload) => {

            state.customizer.sidebar.theme = payload.payload[0]
            state.customizer.sidebar.primary = payload.payload[1]
            state.customizer.sidebar.secondary = payload.payload[2]
            state.customizer.sidebar.backgroundImg = payload.payload[3]
        },
        toggleTreeView: (state, payload) => {
            state.customizer.treeViewSettings = payload.payload
        },
        chanageSiteIdentity: (state, payload) => {
            state.siteIdentity.logoURL = payload.payload?.logoUrl
            state.siteIdentity.favIconURL = payload.payload?.favIcon
            state.siteIdentity.title = payload.payload?.title
            state.siteIdentity.tagLine = payload.payload?.tagLine
        },
        //Popup hide
        togglePopup: (state, payload) => {
            payload.payload === true ? state.customizer.popupIsHide = true : state.customizer.popupIsHide = false;
        },
        //dropVehicles
        dropVehicles: (state, payload) => {
            state.dropVehicles = payload.payload
        },
        //vehicle Count Null
        vehicleCountSlice: (state, payload) => {
            state.vehicleCount = payload.payload
        },
        //selected vehicle
        selectedVehicleData: (state, payload) => {
            state.selectedVehicle = payload.payload
        },
        //selected vehicle trails Data
        selectedVehicleTrailsData: (state, payload) => {
            state.vehicleTrails = payload.payload
        },
        selectedVehicleCategoryListData: (state, payload) => {
            state.vehicleCategoryList = payload.payload
        },
        hoverVehicle: (state, payload) => {
            state.hoverVehicle = payload.payload
        },
        //treeViewDate
        treviewDate: (state, payload) => {
            state.treeview.date = payload.payload;
        },
        //Trails Time
        trailsTime: (state, payload) => {
            state.treeview.trailsTime = payload.payload;
        },
        //Trails Custom date diff
        customDateDiff: (state, payload) => {
            state.treeview.customDateDiff = payload.payload;
        },
        //treeview
        treeViewRollId: (state, payload) => {
            state.treeview.id = payload.payload[0];
            state.treeview.role = payload.payload[1]
        },
        //Trails MarkerData
        selectedTrailsMarkerData: (state, payload) => {
            state.treeview.selectedTrailsMarkerData = payload.payload;
        },
        //Treeview Serch searchKeyWord
        searchKeyWord: (state, payload) => {
            state.treeview.searchKeyWord = payload.payload;
        },
        //Treeview Serch searchKeyWord
        searchCategoryKeyWord: (state, payload) => {
            state.treeview.searchCategoryKeyWord = payload.payload;
        },
        //isLoading
        isLoadingState: (state, payload) => {
            state.isloading = payload.payload
        },
        //isSuccess
        snackService: (state, payload) => {
            state.alert.message = payload.payload[0];
            state.alert.status = payload.payload[1];
            state.alert.isSuccess = payload.payload[2];
        },
        snackServiceRoute: (state, payload) => {
            state.alertRoute.message = payload.payload[0];
            state.alertRoute.status = payload.payload[1];
            state.alertRoute.isSuccess = payload.payload[2];
        },
        singleVehicle: (state, payload) => {
            state.singleVehicleData = payload.payload;
        },
        chatBotSingleVehicleData: (state, payload) => {
            state.chatBotSingleVehicle = payload.payload;
        },
        refresh: (state, payload) => {
            state.refreshBtn.mapRef = payload.payload[0];
            state.refreshBtn.vehicleRef = payload.payload[1];
        },
        profileImg: (state, payload) => {
            state.customizer.userImg = payload.payload
        },
        routeTrips: (state, payload) => {
            state.routeTrip = payload.payload
        },
        routeTripDuration: (state, payload) => {
            state.routeTripDuration = payload.payload
        },
        currentPage: (state, payload) => {
            if (payload.payload !== "") {
                let stringArray = payload.payload.split(',');
                let numberArray = [];
                for (let i = 0; stringArray.length > i; i++) {
                    numberArray.push(parseInt(stringArray[i]));
                }
                state.customizer.currentPage.buttonOpt = numberArray.sort();
            } else {
                state.customizer.currentPage.buttonOpt = null;
            }
        },
        vehicleSummaryWindow: (state, payload) => {
            state.vehicleSummary = payload.payload;
        },
        selectedType: (state, payload) => {
            state.selectedType.id = payload.payload[1];
            state.selectedType.type = payload.payload[0];
        },
        ChargingMarkers: (state, payload) => {
            if (payload.payload !== null && payload.payload !== undefined) {
                state.chargingMarkers = payload.payload[0]
            } else {
                state.chargingMarkers = null
            }
        },
        StopPageMarkers: (state, payload) => {
            if (payload.payload !== null && payload.payload !== undefined) {
                state.stopPageMarkers = payload.payload[0]
            } else {
                state.stopPageMarkers = null
            }
        },
        //Other Makrer
        getOtherMarkers: (state, payload) => {
            if (payload.payload[0] !== null && payload.payload[0] !== undefined) {
                state.otherMarkers.charging = payload.payload[0]
            } else {
                state.otherMarkers.charging = null
            }
            if (payload.payload[1] !== null && payload.payload[1] !== undefined) {
                state.otherMarkers.stopPage = payload.payload[1]
            } else {
                state.otherMarkers.stopPage = null
            }
        },
        //update trips
        tripsList: (state, payload) => {
            if (payload.payload[1] === 'add') {
                state.tripsList = [...state.tripsList, payload.payload[0]]
            } else {
                state.tripsList = state.tripsList.filter(item => item.id !== payload.payload[0])
            }

        },
        breadcrumb: (state, payload) => {
            state.breadcrumb.pages = payload.payload[0];
            state.breadcrumb.popup = payload.payload[1];
            state.breadcrumb.mesage = payload.payload[2];
        },
    }
})

export const { toggleMode, toggleTheme, toggleFont, toggleHederStyle, toggleSidebarStyle, toggleTreeView, chanageSiteIdentity, toggleMini, togglePopup, getAuth, dropVehicles, vehicleCountSlice, isLoadingState, selectedVehicleData, hoverVehicle, selectedVehicleTrailsData, selectedVehicleCategoryListData, treviewDate, trailsTime, selectedTrailsMarkerData, searchKeyWord, searchCategoryKeyWord, snackService, snackServiceRoute, singleVehicle, refresh, logout, profileImg, routeTrips, currentPage, mapOptions, vehicleSummaryWindow, selectedType, getOtherMarkers, tripsList, ChargingMarkers, StopPageMarkers, setTheme, treeViewRollId, breadcrumb, chatBotSingleVehicleData,customDateDiff } = Controls.actions;
export default Controls.reducer