import { Box, Button, CircularProgress, Dialog, DialogContent, DialogTitle, Divider, FormControl, FormControlLabel, IconButton, InputBase, Radio, RadioGroup, Slide, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { MdClose, MdOutlineContentCopy } from "react-icons/md";
import { useVehicleShareMutation } from '../store/slice/TrackingSlice/TrackingSlice';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="down" ref={ref} {...props} />;
});

function VehicleShare({showShare, closeShare}) {
    let location = window.location;
    const selectedVehicle = useSelector( (state) => state.controls.selectedVehicle);
    const vehicleName = useSelector( (state) => state.controls.vehicleTrails);
    const [hours, setHours] = useState(24);
    const [msg, setMsg] = useState("Copy Link");

    let userData = JSON.parse(localStorage.getItem("userData"));
    let resellerId = userData.resellerId;
    let orgId = userData.orgId;
    let userId = userData.id;
    let orgName = userData.orgName;
    let userName = userData.userName;


    const [ getShare, {data:shareVehicle, isLoading:vehicleShareLoading, isSuccess:vehicleShareSuccess}  ] = useVehicleShareMutation();

    const copyText = (text) => {
        handleShare();
      };

      const reset = setTimeout(() => {
        setMsg("Copy Link");
        return () => clearTimeout(reset);
      }, 1000);

    const handleShare = async () => {
        let payload = {
            "vehicleId":selectedVehicle,
            "userId":userId,
            "duration":hours
        }

        const shareLink = await getShare(payload);
        if(shareLink.data !== null && shareLink.data !== undefined && selectedVehicle !== undefined){
            if(shareLink.data.result === 'success'){
                navigator.clipboard.writeText(shareLink.data.generateURL);
                setMsg("Copied");
            }else{
                setMsg("Copy Faild");
            }
        }else{
            setMsg("Copy Faild");
        }
        reset()
    }

  return (
    <Dialog open={showShare} maxWidth={'xs'} fullWidth TransitionComponent={Transition} keepMounted transitionDuration={500}>
        <DialogContent sx={{px:3, py:2}}>
            <Stack spacing={1}>
                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography variant='h6' gutterBottom>Share Vehicle</Typography>
                    <IconButton size='small' onClick={closeShare}><MdClose /></IconButton>
                </Stack>
                <Box> 
                    <Stack direction={'row'} justifyContent={'space-between'}>
                        <Box>
                            <Typography variant='body2'>Vehicle Name</Typography>
                            <Typography variant='body1'>{vehicleName?.commonData[0]?.vehicleName}</Typography>
                        </Box>
                        <Box>
                            <Typography variant='body2'>Sharing Time</Typography>
                            <FormControl>
                                <RadioGroup name='sharingTime' defaultValue={hours} row onChange={(event) => setHours(event.target.value)}>
                                    <FormControlLabel value={24} control={<Radio size='small'/>} label={'24 hours'}/>
                                    <FormControlLabel value={25} control={<Radio size='small'/>} label={'End of Day'}/>
                                </RadioGroup>
                            </FormControl>
                        </Box>
                    </Stack>
                </Box>
                <Box> 
                    <Stack direction={'row'} sx={{border:'.5px solid #ddd', p:.5, borderRadius:'10px'}}>
                        <InputBase 
                            sx={{flex:1, px:1}}
                            disabled
                            value={`${location.origin}/#/publictrack/Tracking`}
                        />
                        <Button
                            variant='contained'
                            color='secondary'
                            size='small'
                            onClick={copyText}
                            endIcon={
                                vehicleShareLoading ? <CircularProgress color="inherit" size={15}/> : <MdOutlineContentCopy /> 
                            }
                        >
                            {msg}
                        </Button>
                    </Stack>
                </Box>
            </Stack>
        </DialogContent>
    </Dialog>
  )
}

export default VehicleShare