import { useState, useEffect, useMemo } from "react";
import { useAuditLogMutation, useDeleteServicePlanMutation, useServicePlanHistoryMutation, useServicePlanListMutation, useUpcomingServicePlansMutation, useUpdateServicePlanMutation } from "../../store/slice/ApiSlice";
import { useGeoZoneGridListViewMutation } from "../../store/slice/GeozoneSlice/GeozoneSlice";
import { SoftButton, StyledFormButtonsRow, StyledFormControl, StyledFormControlLabel, StyledPageLayout, StyledTableTitle } from "../../theme/StyledEle";
import MouseOverPopover from "../shared/PopOver";
import { Breadcrumb } from "../shared/Breadcrumbs";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import { DataTable } from "../shared/data_table/DataTable";
import { CardContent, Chip, Dialog, DialogContent, FormHelperText, Icon, IconButton, Typography, useTheme,Stack, CardHeader, CardActions } from "@mui/material";
import { StyledCardShadow,StyledTextField } from "../../theme/StyledEle";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { FiPlus } from "react-icons/fi";
import DrawerModel from "../../Utils/DrawerModel";
import CreateServiceForm from "./CreateServiceForm";
import { ExpandTable } from "../shared/data_table/ExpandTable";
import { alpha } from "@material-ui/core";
import EditServices from "./EditServices";
import FormDialog from "../../Utils/FormDialog";
import StyledModel from "../../Utils/StyledModel";
import { snackService } from "../../store/slice/ControlsSlice";
import { AiOutlineDelete } from "react-icons/ai";
import { IoMdClose } from "react-icons/io";
import dayjs from "dayjs";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useForm } from "react-hook-form";
import { PulseLoader } from "react-spinners";

const ListServicePlans = ({onViewOrg, menuUser }) => {

  const [
    getServicePlanList,{ data: servicePlanData, isLoading: getServicePlanLoading, isSuccess: getServicePlanSuccess },] = useServicePlanHistoryMutation();

    const [
      delteItem,
      {
        data: deleteData,
        isLoading: isDeleteLoading,
        isSuccess: isDeleteSuccess,
      },
    ] = useDeleteServicePlanMutation();

    const [
      updateService, {data: updateServiceData, isLoading: updateServicePlanLoading, isSuccess: updateServicPlanSuccess},] = useUpdateServicePlanMutation();
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);
  const [auditLog] = useAuditLogMutation();
  const [deleteItem, setDeleteItem] = useState(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const [commonFilter, setCommonFilter] = useState([]);
  const [selectRows, setSeletedRows] = useState(null);
  const [selectRowId, setSelectRowId] = useState(null);
  const [editData , setEditData] = useState(null);
  const [isPopup , setIsPopup] = useState(false);
  const [clickedval, setClickedval] = useState(false);
  const [date, setDate] = useState(moment(new Date()).format("YYYY-MM-DD"));

  const location = useLocation();

  let auditPayload = {
    orgId: orgId,
    userId: userId,
    orgName: orgName,
    userName: userName,
  }

  useEffect(async () => {
    auditPayload.message = "Service Plan > List Service Plan > GridView";
    auditPayload.action = "View";
    auditPayload.featureName = "Service Plan Grid";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  useEffect(() => {
    if (updateServicPlanSuccess) {
      dispatch(
        snackService([
          updateServiceData?.resultMessage,
          updateServiceData?.resultText,
          updateServicPlanSuccess,
        ])
      );
      if (updateServiceData?.resultText !== "Failed") {
        setIsPopup(false);
      }
      getServicePlanList(payload);
    }
  },[updateServicPlanSuccess])

  const columns = useMemo(() =>
    [
      {
        Header: "Vehicle No",
        accessor: "vehicleNo",
        width: 120,
      },
      {
        Header: "Service Type",
        accessor: "serviceTypeLabel",
      },
      {
        Header: "Recurrence Type",
        accessor: "recurrenceTypeLabel",
      },
      {
        Header: "Next Service Date",
        accessor: "serviceDate",
      },
      {
        Header: "Recurrence Duration",
        accessor: "recurrenceDurationLabel",
      },
      {
        Header: "Recurrence Distance",
        accessor: "recurrenceDistance",
      },
      {
        Header: "Next Service Distance",
        accessor: "nextServiceDistance",
      },
      {
        Header: "Service Completion Date",
        accessor: "serviceCompletionDate",
      },
      {
        Header: "Status",
        accessor: "serviceStatus",
        Cell:({row}) => {
          if(row.original.serviceStatus !== '-' ){
              return <Typography  variant="body2"
              color={row.original.serviceStatus ==='Due for service' ? 'primary':row.original.serviceStatus ==='Completed'? 'success.main':'error.main'}
              sx={{ padding: "0px 3px",fontSize: "12px",fontStyle: "normal", fontWeight: "none" }}>
               {row.original.serviceStatus}</Typography>
          }else{
              return row.original.serviceStatus
          }
        }
      },
      {
        Header: "Action",
        accessor: "buttonEditDelete",
        width: 140,
      },

      // {
      //   Header: "Action",
      //   accessor: "buttonEditDelete",
      //   width: 120,
      // },

    ], [resellerId]);

    const {
      register,
      setValue,
      watch,
      reset,
      formState: { errors },
      handleSubmit
    } = useForm({
      mode: "all",
    });

  let commonPayload = {};
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.fleetId = data.fleetId;
    if (data.customerId == -1) {
      commonPayload.orgId = resellerId;
    }
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.sortBy = "desc";
    commonPayload.page = newPage;
    commonPayload.size = rowsPerPage;
    commonPayload.serviceType = -1;
    commonPayload.recurrenceType = -1;
    commonPayload.status = data.servicePlanStatus;

    //Service Call
    setPayload(commonPayload);
    getServicePlanList(commonPayload);

    auditPayload.message = "Service Plan > Upcoming service Plans > " + data.regionName + " > " + data.dealerName + " > " + data.customerName + " > " + data.fleetName + " > " + data.vehicleName + " > " + data.selectedPeriodName + "(" + data.fromDate + "-" + data.toDate + ")" + " > " + "Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    //auditLog(auditPayload);
  };


  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "servicePlanner/servicePlansListXls.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&orgId=" +
        payload.orgId +
        "&fleetId=" +
        payload.fleetId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId +
        "&serviceType="+
        payload.serviceType +
        "&recurrenceType="+
        payload.recurrenceType+
        "&status="+
        payload.status+
        "&search="+
        payload.search;
      window.open(url);

      auditPayload.message = "Reports > Service Plan > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "OverSpeed";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };

  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getServicePlanList(payload);
    } else if (payload.search.length === 0) {
      payload.page = 0;
      payload.rowsPerPage = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getServicePlanList(payload);

    }
  };

  const handleCompletePopup = (data) => {
    console.log(data)
    setEditData(data);
    setIsPopup(true);
    navigate('#completeService');
    reset({'nextServiceDistance': ''});
  }

  const handleDateChange = (newValue) => {
    setDate(moment(newValue).format("YYYY-MM-DD"));
  };
 
  const handleServiceCompletion = (data,editData) => {
    var updatePayload = {};
    updatePayload.createdBy = userId;
    updatePayload.action = "Update";
    updatePayload.id = editData.id;
    updatePayload.orgId = editData.orgId;
    updatePayload.customerId = editData.customerId;
    updatePayload.fleetId = editData.fleetId;
    updatePayload.vehicleId  = editData.vehicleId;
    updatePayload.vehicleNo = editData.vehicleNo;
    updatePayload.serviceType = editData.serviceType;
    updatePayload.recurrenceType = editData.recurrenceType;
    updatePayload.reminderDurationList = editData.reminderList;
    updatePayload.resellerId = resellerId;

    if (editData.recurrenceDuration > 0) {
     updatePayload.recurrenceDuration = editData.recurrenceDuration;
    }

    if(editData.recurrenceDistance != "NA") {
     updatePayload.recurrenceDistance = editData.recurrenceDistance;
     updatePayload.prevServiceDistance = watch('nextServiceDistance');
    }
    if (updatePayload.serviceDate != "NA"){
     updatePayload.serviceDate = date;
    }
    updateService(updatePayload);
    
 }

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.size = rowsPerPage;
    if (totalElements < rowsPerPage * newPage) {
      setNewPage(0)
      payload.page = 0;
      getServicePlanList(payload);
    } else {
      setNewPage(newPage);
      payload.page = newPage;
      getServicePlanList(payload);
    }
  };

  useEffect(() => {
    if (isDeleteSuccess) {
      setDeleteItem(null);
      dispatch(
        snackService([
          deleteData?.resultMessage,
          deleteData?.resultText,
          isDeleteSuccess,
        ])
      );
      getServicePlanList(payload);
    }
  }, [isDeleteSuccess]);


  useEffect(() => {
    if (getServicePlanSuccess) {

      if (servicePlanData.data !== null) {

        setResponseData(servicePlanData.data.content);
        setTotalElements(servicePlanData.data.totalElements);
        setTotalPages(servicePlanData.data.totalPages);

        var dataStr = {};
        dataStr.edit = false;
        dataStr.delete = false;
        dataStr.view = false;
        dataStr.excel = false;
        dataStr.pdf = false;
        var strval;
        strval = menuUser.split(",");
        for (var i = 0; i < strval.length; i++) {
          if (parseInt(strval[i]) === 4) {
            dataStr.edit = true;
          }
          if (parseInt(strval[i]) === 3) {
            dataStr.delete = true;
          }
          if (parseInt(strval[i]) === 6) {
            dataStr.pdf = true;
          }
          if (parseInt(strval[i]) === 5) {
            dataStr.excel = true;
          }
          if (parseInt(strval[i]) === 2) {
            dataStr.view = false;
          }
          dataStr.edit = true;
          dataStr.view = false;
        }
        dataStr.edit = true;
        dataStr.view = true;
        dataStr.delete = true;
        dataStr.completeService = true;

        setCommonFilter(dataStr);
      }
    }

  }, [getServicePlanSuccess]);

  const onEdit = (itemEdited) => {
    // onEditService(itemEdited);

    
      console.log(itemEdited);
      setEditData(itemEdited);
      navigate('#serviceEdit');
  
      // <DrawerModel position={'right'} width={'35vw'} title={'Edit Service Plan'}>
      // <CreateServiceForm reload={onSubmitForm} itemEdit={itemEdited} isEdit = {true}/>
      // </DrawerModel>


    auditPayload.message = "Admin > ServicePlan > List > Edit";
    auditPayload.action = "Edit";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  const onDelete = async (itemDeleted) => {
    setDeleteItem(itemDeleted);
  };

  const onServiceComplete = async (itemDeleted) => {
    // setDeleteItem(itemDeleted);
  };


  const onView = async (handleOrgView) => {
     onViewOrg(handleOrgView);

    auditPayload.message = "Admin > ServicePlan > List > View";
    auditPayload.action = "View";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

    auditLog(auditPayload);
  };

  const getRow = (selectRow, rowId) => {
    setSelectRowId(rowId);
    setSeletedRows(selectRow);
  };

  const deleteItems = () => {
    let payload = {
      id: deleteItem.id,
    };
    delteItem(payload);
    auditPayload.message = "Admin > ServicePlan > ServicePlan List > Delete";
    auditPayload.action = "Delete";
    auditPayload.featureName = "ServicePlan";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;

   // auditLog(auditPayload);
  };

  const onClose = () => {
    reset({'nextServiceDistance': ''});
    setIsPopup(false)
  }

  const onSubmit = (data) => {
    console.log('data', data);
  }

  return (
    <>
      <FilterDropdown
        getData={onSubmitForm}
        status={false}
        allowDays={7}
        allowToday={true}
        last6Month={true}
        showCalendar={false}
        serviceShow = {true}

      />
      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'end' }}>
        <SoftButton variant='contained' color='success' size='small' className='success-soft' startIcon={<FiPlus />} onClick={() => navigate('#create')}>
          Create Service Plan
        </SoftButton>
      </Box>
      {/* Create Service Plan */}
      <DrawerModel position={'right'} width={'35vw'} title={'Create Service Plan'}>
        <CreateServiceForm reload={onSubmitForm} payload={payload}/>
      </DrawerModel>
      <Box>
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
          <DataTable
              onEdit={onEdit}
              onDelete={onDelete}
              onServiceComplete = {handleCompletePopup}
              columns={columns}
              onView={onView}
              data={responseData}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={2}
              page={newPage}
              exportWithAll = {true}
              includeExportButton = {true}
              onChangeXcl={onChangeXcl}
              includeSearchBox={false}
              handleChange={handleSearch}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
              isLoading={getServicePlanLoading}
              commonFilter={commonFilter}
              selectRow={getRow}
              
            />
          </CardContent>
        </StyledCardShadow>
        {/* <Dialog open={isPopup} onClose={false} maxWidth={"sm"} fullWidth={true}>
    <DialogContent sx={{ px: 0, py: 2 }}> */}
     {isPopup  && location.hash ==='#completeService' && <FormDialog title={'Complete Service'} padding={false} size="xs">
      <>
     
      <Box component={'form'} onSubmit={handleSubmit( data => handleServiceCompletion(data, editData))}>
      <StyledCardShadow elevation={0} sx={{ mt: 2, my: 3,p:2,ml:4, mr:6}}>
       <Stack direction={'column'} spacing={1.5} >
        <StyledFormControl>
                                <StyledFormControlLabel required>
                                  Date of Service
                                </StyledFormControlLabel>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                  <DesktopDatePicker
                                    inputFormat="yyyy-MM-dd"
                                    maxDate={dayjs().add(0, "day")}
                                    value={date}
                                    onChange={handleDateChange}
                                    renderInput={(params) => (
                                      <StyledTextField
                                        fullWidth
                                        size="small"
                                        {...params}
                                        // inputProps={{
                                        //   ...params.inputProps,
                                        //   placeholder: "Select Date",
                                        // }}
                                        disabled={clickedval}
                                      />
                                    )}
                                  />
                                </LocalizationProvider>
                    </StyledFormControl>
      
             <StyledFormControl>
                                <StyledFormControlLabel required={editData.nextServiceDistance != null && editData.nextServiceDistance != "NA" ?true:false}>
                                   Odometer Reading on Service
                                </StyledFormControlLabel>
                                <StyledTextField
                                   {...register("nextServiceDistance", {
                                    required: {
                                      value: editData.nextServiceDistance != null && editData.nextServiceDistance != "NA"?true:false ,
                                      message: "Please provide the odometer reading on service",
                                    },
                                  })}
                                  size="small"
                                  fullWidth
                                  type="number"
                                  value={watch('nextServiceDistance')}
                                  onChange={(e) => setValue('nextServiceDistance', e.target.value)}
                                  InputProps={{ inputProps: { min: 0 } }}
                                />
                                 <FormHelperText error={errors.nextServiceDistance} sx={{display:"contents"}}>
                                  {errors.nextServiceDistance?.message}
                                </FormHelperText>
                              </StyledFormControl>
                              
                            
                           
                            
</Stack>
                            </StyledCardShadow>
                       
          <StyledFormButtonsRow direction="row" sx={{ mb: 2 }} spacing={2}>
            <SoftButton
              className="error"
              size="small"
              onClick={onClose}
              color="error"
            >
              Cancel
            </SoftButton>
            <SoftButton
              size="small"
              className="success"
              color="success"
              type="submit"
            >
              {updateServicePlanLoading ? (
                <PulseLoader
                  color={theme.palette.success.contrastText}
                  size={6}
                />
              ) : (
                "Complete"
              )}
            </SoftButton>
          </StyledFormButtonsRow>
                
      </Box> 
        </>
        </FormDialog>
        }
      </Box>




     

      {location.hash == '#serviceEdit' && <FormDialog title={'Edit Service Plan'} maxWidth={'60%'} size={false} >
      <EditServices editData = {editData} isEdit={true} editDeletePayload = {payload} reloadPage = {onSubmitForm}/>
      </FormDialog>}

      {deleteItem !== null && (
         <Dialog  open = {true} maxWidth={"sm"} fullWidth={true} >
         <DialogContent sx={{ px: 0, py: 2 }}>
             <Icon
               sx={{
                 fontSize: "50px",
                 height: "auto",
                 margin: "auto",
                 display: "flex",
               }}
               color="error"
             >
               <AiOutlineDelete />
             </Icon>
           
           <Typography sx={{ textAlign: "center" }} variant="h6">
             Are you sure want to delete?
        </Typography>
        <StyledFormButtonsRow direction="row" sx={{ mt: 2 }} spacing={2}>
          <SoftButton
            size="small"
            color="error"
            className="error"
            onClick={() => setDeleteItem(null)}
          >
            Cancel
          </SoftButton>
          <SoftButton
            size="small"
            className="success"
            color="success"
            type="submit"
            onClick={() => deleteItems()}
          >
          Confirm
          </SoftButton>
        </StyledFormButtonsRow>
      </DialogContent>
    </Dialog>
      )}
      
    </>
  );


};


export default ListServicePlans;