import i18n  from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import HttpApi from 'i18next-http-backend'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs:['en', 'tr'],
    fallbackLng: "en",
    detection:{
        order:['localStorage','cookie','htmlTag', 'path', 'subdomain'],
        caches:['localStorage']
    },
    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    backend:{
        loadPath:'/asset/locales/{{lng}}/translation.json'
    },
    react:{useSuspense:false}
  });

export default i18n