import React, { useState } from 'react'
import VehicleList from './VehicleList'
import { StyledPageLayout } from '../../theme/StyledEle';
import VehicleSummary from './VehicleSummary';
import { Breadcrumb } from '../shared/Breadcrumbs';

function NewVehicles() {
    const [tab, setTab] = useState(2);
  return (
    <StyledPageLayout>
    <Breadcrumb
    mainDiv="Vehicles"
    subDiv="Vehicles"
    includePopup={false}
    ></Breadcrumb>
    {
        tab === 1 && <VehicleList />
    }
    {
        tab === 2 && <VehicleSummary />
    }    
    </StyledPageLayout>
  )
}

export default NewVehicles