import { CardContent } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo } from "react";
import { DataTable } from "../shared/data_table/DataTable";

import {
  useAuditLogMutation
} from "../../store/slice/ApiSlice";
import {
  StyledCardShadow
} from "../../theme/StyledEle";
import GetLocation from "../Location";


const DriverAttendance = ({
  data,
  payload,
  isLoadingOut,
  handleSearch,
  pageChange,
  newPage,
  rowsPerPage,
  totalElements
}) => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > DriverSummary";
    auditPayload.action = "View";
    auditPayload.featureName = "DriverSummary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle Number",
        accessor: "vehicleNo",
        width:180,
      },
      {
        Header: "Driver Name",
        accessor: "driverName",
        width:180,
      },
      {
        Header: "Login Time",
        accessor: "punchingTime",
        width:180,
      },

      {
        Header: "Location",
        accessor: "location",
        width:180,
        Cell: ({ row }) => {
          return(
            row.original.location !== "" &&   row.original.location !== "NA" ?
          <GetLocation row={row} columnName={row.original.location}/> : '-'
          )
        }
      },


    ],
    []
  );
 
  const onChangeXcl = async () => {
    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "DriverAttendance/getDriverAttendance.csv?regionId=" +
        payload.regionId +
        "&resellerId=" +
        payload.resellerId +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&dealerId=" +
        payload.dealerId +
        "&orgId=" +
        payload.orgId +
        "&vehicleId=" +
        payload.vehicleId +
        "&customerId=" +
        payload.customerId +
        "&tab=" +
        payload.tab +
        "&driverId=" +
        payload.driverId;
      window.open(url);

      auditPayload.message = "Reports > DriverSummary > XLS";
      auditPayload.action = "View";
      auditPayload.featureName = "DriverSummary";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);

      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});
    } catch (error) {}
  };
 
  return (
    
      <Box>
      
        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              payload={payload}
              exportWithAll={false}
              columns={columns}
              includeSearchBox={false}
              onChangeXcl={onChangeXcl}
              handleChange={handleSearch}
              includeExportButton={true}
              data={data}
              styles={{ maxWidth: "100vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={rowsPerPage}
              isLoading={isLoadingOut}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
  );
};

export default DriverAttendance;
