import { Box } from "@mui/material";
import React, { useState,useEffect } from "react";
import StyledTabs from "../shared/StyledTabs";
import CreateVehicle from "./CreateVehicle";
import ListVehicle from "./ListVehicle";
import RecentActivities from "./RecentActivities";
import { Breadcrumb } from "../shared/Breadcrumbs";
import VinUpload from "./VinMapping";
import { StyledPageLayout } from "../../theme/StyledEle";
import { snackService } from "../../store/slice/ControlsSlice";
import { useDispatch, useSelector } from "react-redux";
import FormDialog from "../../Utils/FormDialog";
import ViewVehicle from "./ViewVehicle";
import DriverAssignment from "./DriverAssignment";
import { useLocation, useNavigate } from "react-router-dom";
import AssignDriver from "../driver/AssignDriver";
import { useDriverListMutation, useDriverVehicleMapMutation, useGetVehicleMutation } from '../../store/slice/ApiSlice';

const Vehicles = () => {
  const location = useLocation();
  const [vehToEdit, setVehToEdit] = useState(null);
  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tempcommonFilter, setTempcommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  //const [createSim, setCreateSim] = React.useState("Create Sim");
  const [tabShow, setTabShow] = React.useState("1");
  const [menuUser, setMenuUser] = useState();
  const [create, setCreate] = useState("Create Vehicle");

  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "vehicle"
              ) {
                setMenuUser(
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                );
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                }
                setCommonFilter(dataStr);
                setTempcommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }
    //setTabShow

    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("8");
      tabshowval = "8";
      displaydefaulttab = 1;
    }
    return displaydefaulttab;
  };
  const [tabValue, setTabValue] = useState(() => callfun());
  const handleTabChange = (event, newValue) => {
    setVehToEdit(null);
    setTabValue(newValue);
    setCreate("Create Vehicle");
  };

  const handleVehEdit = (vehEditedId) => {
    var displaydefaulttab = 2;
    if (tabShow === "6") {
      //displaydefaulttab=3;
      setTabShow("5");
      displaydefaulttab = 0;
    } else if (tabShow === "5") {
      displaydefaulttab = 0;
    } else if (tabShow === "4") {
      displaydefaulttab = 1;
    } else if (tabShow === "1") {
      displaydefaulttab = 2;
    } else if (tabShow === "2") {
      displaydefaulttab = 5;
    } else if (tabShow === "3") {
      displaydefaulttab = 1;
    } else if (tabShow === "8") {
      setTabShow("5");
      displaydefaulttab = 0;
    }
    setTabValue(displaydefaulttab);
    var tempProps = JSON.parse(JSON.stringify(vehEditedId));
    tempProps.clickedval = "edit";
    setVehToEdit(Object.preventExtensions(tempProps));
    setCreate("Edit Vehicle");
  };

  const navigate = useNavigate();
  const [vehicleData, setVehicleData] = useState();
  const handleVehView = (vehEditedId) => {
    navigate('#view');
    setVehicleData(vehEditedId);
  };
  
  const [driverData, setDriverData] = useState([]);
  const [driverList, setDriverList] = useState(); 

  const handleDriver = async (driverAssigned) => {
    navigate('#assign');
    setDriverData(driverAssigned);
  // const resultdata = await getDriverList({id:driverData.id});
  // const finalresult = resultdata.data.data;
  // setDriverList([]);
  // finalresult?.length > 0 && finalresult?.forEach((key, index) => {
  //   let userObj = {
  //     userName:key.userName,
  //     userId:key.userId,
  //   }
   
  //   setDriverList(oldList => [...oldList, userObj])
  // })
 
 

  };
 
  const [getDriverList, { data: getDriverData, isLoading: driverDataLoading, isSuccess: driverDataSuccess }] = useDriverListMutation();
  
    // var displaydefaulttab = 2;
    // if (tabShow === "6") {
    //   //displaydefaulttab=3;
    //   setTabShow("5");
    //   displaydefaulttab = 0;
    // } else if (tabShow === "5") {
    //   displaydefaulttab = 0;
    // } else if (tabShow === "4") {
    //   displaydefaulttab = 1;
    // } else if (tabShow === "1") {
    //   displaydefaulttab = 2;
    // } else if (tabShow === "2") {
    //   displaydefaulttab = 5;
    // } else if (tabShow === "3") {
    //   displaydefaulttab = 1;
    // } else if (tabShow === "8") {
    //   setTabShow("5");
    //   displaydefaulttab = 0;
    // }
    // setTabValue(displaydefaulttab);
    // var tempProps = JSON.parse(JSON.stringify(vehEditedId));
    // tempProps.clickedval = "view";
    // setVehToEdit(Object.preventExtensions(tempProps));
    // setCreate("View Vehicle");

  const handleCancel = () => {
    //setTabValue(3);
    var dataStr = { ...tempcommonFilter };
    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("6");
      tabshowval = "6";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("5");
      tabshowval = "5";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("4");
      tabshowval = "4";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 3;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === true
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 2;
    } else if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === true
    ) {
      setTabShow("8");
      tabshowval = "8";
      displaydefaulttab = 2;
    }
    setTabValue(displaydefaulttab);
    setCreate("Create Vehicle");
  };

  const TABS = [
    {
      label: "VIN Mapping",
      children: <VinUpload onCancel={handleCancel} />,
    },
    {
      label: "Recent Activities",
      children: <RecentActivities />,
    },
    {
      label: create,
      children: <CreateVehicle vehToEdit={vehToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Vehicles",
      children: (
        <ListVehicle
          onEditVeh={handleVehEdit}
          menuUser={menuUser}
          onViewVeh={handleVehView}
          onDriverAssignment={handleDriver}
        />
      ),
    },
  ];

  const TABS4 = [
    {
      label: "Recent Activities",
      children: <RecentActivities />,
    },
    {
      label: create,
      children: <CreateVehicle vehToEdit={vehToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Vehicles",
      children: (
        <ListVehicle
          onEditVeh={handleVehEdit}
          menuUser={menuUser}
          onViewVeh={handleVehView}
          onDriverAssignment={handleDriver}
        />
      ),
    },
  ];

  const TABS5 = [
    {
      label: create,
      children: <CreateVehicle vehToEdit={vehToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Vehicles",
      children: (
        <ListVehicle
          onEditVeh={handleVehEdit}
          menuUser={menuUser}
          onViewVeh={handleVehView}
          onDriverAssignment={handleDriver}
        />
      ),
    },
  ];

  const TABS6 = [
    {
      label: "Vehicles",
      children: (
        <ListVehicle
          onEditVeh={handleVehEdit}
          menuUser={menuUser}
          onViewVeh={handleVehView}
          onDriverAssignment={handleDriver}
        />
      ),
    },
  ];
  const TABS7 = [
    {
      label: create,
      children: <CreateVehicle vehToEdit={vehToEdit} onCancel={handleCancel} />,
    },
  ];

  const TABS3 = [
    {
      label: "VIN Mapping",
      children: <VinUpload onCancel={handleCancel} />,
    },
    {
      label: create,
      children: <CreateVehicle vehToEdit={vehToEdit} onCancel={handleCancel} />,
    },
    {
      label: "Vehicles",
      children: (
        <ListVehicle
          onEditVeh={handleVehEdit}
          menuUser={menuUser}
          onViewVeh={handleVehView}
          onDriverAssignment={handleDriver}
        />
      ),
    },
  ];

  const TABS2 = [
    {
      label: "VIN Mapping",
      children: <VinUpload onCancel={handleCancel} />,
    },
    {
      label: "Recent Activities",
      children: <RecentActivities />,
    },
    {
      label: "Vehicles",
      children: (
        <ListVehicle
          onEditVeh={handleVehEdit}
          menuUser={menuUser}
          onViewVeh={handleVehView}
          onDriverAssignment={handleDriver}
        />
      ),
    },
  ];

  const TABS8 = [
    {
      label: "VIN Mapping",
      children: <VinUpload onCancel={handleCancel} />,
    },
    {
      label: "Vehicles",
      children: (
        <ListVehicle
          onEditVeh={handleVehEdit}
          menuUser={menuUser}
          onViewVeh={handleVehView}
          onDriverAssignment={handleDriver}
        />
      ),
    },
  ];


  const isSuccess = useSelector((state) => state.controls.alert);
  const dispatch = useDispatch();
  useEffect(()=>{
    if(isSuccess.isSuccess){
      setTimeout(() => dispatch(snackService([null, null, null])),6000)
    }
  },[isSuccess.isSuccess])

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="Vehicles"></Breadcrumb>
      {tabShow === "1" && (
        <StyledTabs
          tabs={TABS}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "2" && (
        <StyledTabs
          tabs={TABS2}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "3" && (
        <StyledTabs
          tabs={TABS3}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "4" && (
        <StyledTabs
          tabs={TABS4}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "5" && (
        <StyledTabs
          tabs={TABS5}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "6" && (
        <StyledTabs
          tabs={TABS6}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "8" && (
        <StyledTabs
          tabs={TABS8}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
   {location.hash === '#view' && <FormDialog title={'View Vehicle'} padding={false} size="md">
        <ViewVehicle data={vehicleData}/>
    </FormDialog>}
    {location.hash === '#assign' && <FormDialog title={'Driver Assignment for Vehicle - ' + driverData.number} padding={false} size="md"> 
        <DriverAssignment 
        // driverList={driverList} 
        driverData={driverData}/>
    </FormDialog>}
    </StyledPageLayout>
  );
};

export default Vehicles;
