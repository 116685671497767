import React, { useEffect, useMemo, useState } from "react";
import { Box, CardHeader, CardContent } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { StyledCardShadow, StyledTableTitle } from "../../theme/StyledEle";
import OTADropdown from "./OTADropdown";
import {
  useAuditLogMutation,
  useOtaListMutation,
} from "../../store/slice/ApiSlice";

const OTALog = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
  let logoName = userData.orgLogo;

  const [auditLog] = useAuditLogMutation();

  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };

  const columns = useMemo(
    () => [
      {
        Header: "Vehicle",
        accessor: "vehicleNo",
      },
      {
        Header: "IMEI",
        accessor: "imei",
      },
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "Command",
        accessor: "data",
      },
      {
        Header: "Command Type",
        accessor: "commandType",
      },
      {
        Header: "Queued Date",
        accessor: "queuedDate",
      },
      {
        Header: "Initiated Date",
        accessor: "initiatedDate",
      },
      {
        Header: "Responded Message",
        accessor: "respondedMessage",
      },
      {
        Header: "Responded Date",
        accessor: "respondedDate",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Complete Percent(%)",
        accessor: "completePercent",
      },
      {
        Header: "Mode",
        accessor: "otaMode",
      },
     
    ],
    []
  );

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [payload, setPayload] = useState(null);
  // Get Data from New OTA Dropdown
  const [getOtaList, { data: otaListData, isLoading, isSuccess }] =
    useOtaListMutation();
  let commonPayload = {};
  // Onload and Submit
  const getData = (data) => {
    commonPayload.imei = data.imei;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.customerId = data.customer;
    commonPayload.resellerId = resellerId;
    commonPayload.fleetId = data.fleet;
    commonPayload.otaFilter = data.commandType;
    commonPayload.otaStatus = data.otaStatus;
    commonPayload.uniImei = 0;
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.search = "";
    commonPayload.vehicleLevel = 1;
    commonPayload.dealerId = data.dealer;
    commonPayload.regionId = data.region;
    commonPayload.logoName = logoName;
    //Service Call
    setPayload(commonPayload);
    getOtaList(commonPayload);

    auditPayload.message = "Admin > OTA > OTA Log > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+">"+data.imei+" > "+data.statusName+" > "+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "OTA";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);

  };

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      getOtaList(payload);
    } else if (payload.search.length === 0) {
      getOtaList(payload);
    }
  };
  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.length = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.start = 0;
      getOtaList(payload);
    }else{
      setNewPage(newPage);
      payload.start = newPage;
      getOtaList(payload);
    }
  };
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (otaListData.data !== null) {
        setResponseData(otaListData.data.content);

        setTotalElements(otaListData.data.totalElements);
        setTotalPages(otaListData.data.totalPages);
      }
    }
  }, [isSuccess]);

  useEffect(() => {
    auditPayload.message = "OTA > OTA Log";
    auditPayload.action = "View";
    auditPayload.featureName = "OTALog";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  //XLS
  const onChangexls = async () => {

    try {
      var url =
        process.env.REACT_APP_API_BASE_URL +
        "OTA/otaLogXLS.xls?imei=" +
        payload.imei +
        "&startDate=" +
        payload.startDate +
        "&endDate=" +
        payload.endDate +
        "&customerId=" +
        payload.customerId +
        "&resellerId=" +
        payload.resellerId +
        "&fleetId=" +
        payload.fleetId + 
        "&otaFilter=" + 
        payload.otaFilter + 
        "&uniImei=" + 
        payload.uniImei + 
        "&search=" + 
        payload.search + 
        "&vehicleLevel=" + 
        payload.vehicleLevel + 
        "&dealerId=" + 
        payload.dealerId + 
        "&regionId=" + 
        payload.regionId + 
        "&logoName=" + 
        payload.logoName + 
        "&otaStatus=" + 
        payload.otaStatus;
      window.open(url);
      
      fetch(url, {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(window.localStorage.getItem("jwToken")),
          
        },
      }) // FETCH BLOB FROM IT
        .then((response) => {
          response.blob();
        })
        .then((blob) => {})
        .catch((err) => {});

        auditPayload.message = "OTA > OTA Log > Export > Excel";
        auditPayload.action = "Download";
        auditPayload.featureName = "OTALog";
        auditPayload.customerName = "All";
        auditPayload.fleetName = "All";
        auditPayload.result = "Success";
        auditPayload.access = "Portal";
        auditPayload.severity = "INFO";
        auditLog(auditPayload);

    } catch (error) {}
  };
  return (
    <Box>
      <OTADropdown
        getData={getData}
        regionDrop={false}
        dealerDrop={false}
        customerDrop={true}
        fleetDrop={true}
        vehicleDrop={true}
        imeiDrop={true}
        otaDrop={true}
        onLoadData={true}
        time={true}
        allowedMonth={6}
        allowDays={31}
      />
      <StyledCardShadow elevation={0} sx={{ mt: 2, my: 2 }}>
        <CardHeader title={<StyledTableTitle>OTA Log</StyledTableTitle>} />
        <CardContent>
          <DataTable
            includeSearchBox={true}
            includeExportButton={true}
            columns={columns}
            styles={{ maxWidth: "100vw" }}
            numberOfStickyColumns={1}
            data={responseData}
            isLoading={isLoading}
            page={newPage}
            callbackChangePage={pageChange}
            totalElements={totalElements}
            totalPages={totalPages}
            handleChange={handleSearch}
            rowsPerPage={rowsPerPage}
            onChangeXcl={onChangexls}
          />
        </CardContent>
      </StyledCardShadow>
    </Box>
  );
};
export default OTALog;
