import { Alert, Box, Collapse, Divider, Fab, FormLabel, Grid, Icon, Pagination, Stack, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { StyledFormControl, StyledTimeline } from '../../theme/StyledEle';
import deviceServices from '../../services/deviceService';
import InnerTab from '../../Utils/InnerTab';
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineOppositeContent, TimelineSeparator } from '@mui/lab';
import { MdOutlineCheck, MdAdd, MdClose  } from "react-icons/md";
import moment from 'moment';
import { useLazyGetSimDetailsQuery, } from '../../store/slice/SimSlice/SimSlice';
import { useDeviceAvtivityMutation } from '../../store/slice/DeviceSlice/DeviceSlice';
import { ScaleLoader } from 'react-spinners';

function ServiceViewOneTime({data}) {
let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;
    const theme = useTheme();
    // const [ getDeviceActivity, { data: activityData, isLoading: activityLoading, isSuccess: activitySuccess }] = useDeviceAvtivityMutation();
    const [simdata, setSimData] = useState(null);
    const [deviceActivity, setDeviceActivity] = useState(null);
    const [plan, setPlan] = useState(null);
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(5);
    let count =1;
    let payload = {
        "resellerId": resellerId,
        "userid": userId,
        "orgId": orgId,
        "orgType": 2,
        "type": "Edit",
        "id": data?.id,
        "sessionOrgType": 2,
        "createdBy":userId
    }
    let activityPayload = {
        "deviceId": data?.id,
        "page": page,
        "size": length,
        "searchText": "",
        "type": "",
        "simHistoryId": 0,
        "lastDate": "",
        "orgId": orgId
    }

    // const getActivity = async () => {
    //     const activity = await getDeviceActivity(activityPayload);
    //     setDeviceActivity(activity.data.dataObj);
    // }



    // useEffect(async() => {
    //     if(data !== null && data !== undefined){
    //         //get Sim
    //         //const planLists = (await deviceServices.getlistofPlansList(payload)).data;
    //         //setPlan(planLists.filter(item => item.value === data.listofPlans)[0].title);
    //         //const sim = await getSimDetails(data.id);
    //         //setSimData(sim.data.data);
    //         getActivity();
    //     }
    // },[])


    const pageChange = (event, value) => {
        setPage(value - 1);
        let page = value;
        activityPayload.page = page - 1;
        //getActivity();
    }
    //Tabs
    const tabList = [
        {
            id:1,
            name:'Details',
            icon:'',
            show:true,
        },
        // {
        //     id:2,
        //     name:'Activity',
        //     icon:'',
        //     show:true,
        // }
    ]
    const [tabValue, setTabValue] = useState(1);
    const getTabValue = (value) => {
        setTabValue(value);
    }
  return (
    data !== null && data !== undefined && 
        <Box sx={{height:'100%', maxHeight:'75vh', overflowY:'auto'}}>
        <Box>
            <InnerTab tabList={tabList} getTabValue={getTabValue}/>
        </Box>
        <Box sx={{p:0, background:theme.palette.primary.contrastText}}>
            {
                tabValue === 1 && 
                <Box sx={{mt:2, px:2, pb:2}}>
                <Grid container spacing={2}>
                    <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Vehicle Number</FormLabel>
                            <Typography>{data.vehicleNo !== undefined && data.vehicleNo !== '' ? data.vehicleNo : '-'}</Typography>
                        </StyledFormControl>
                    </Grid>
                    <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Service Type</FormLabel>
                            <Typography>{data.serviceTypeLabel !== null && data.serviceTypeLabel !== '' ? data.serviceTypeLabel : '-'}</Typography>
                        </StyledFormControl>
                    </Grid>
                    <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Recurrence Type</FormLabel>
                            <Typography>{data.recurrenceTypeLabel !== null && data.recurrenceTypeLabel !== '' ? data.recurrenceTypeLabel : '-'}</Typography>
                        </StyledFormControl>
                    </Grid>
                    <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Service Date</FormLabel>
                            <Typography>{data.serviceDate !== null && data.serviceDate !== '' ? data.serviceDate : '-'}</Typography>
                        </StyledFormControl>
                    </Grid>

                    <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Service Status</FormLabel>
                            <Typography>{data.serviceStatus !== null && data.serviceStatus !== '' ? data.serviceStatus : '-'}</Typography>
                        </StyledFormControl>
                    </Grid>
                    <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Service Completion Date</FormLabel>
                            <Typography>{data.serviceCompletionDate !== null && data.serviceCompletionDate !== '' ? data.serviceCompletionDate : '-'}</Typography>
                        </StyledFormControl>
                    </Grid>

                  
                    { data.firstReminderDate !== "NA" && <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Reminder Date {count++}</FormLabel>
                            <Typography>{data.firstReminderDate !== null && data.firstReminderDate !== '' ? data.firstReminderDate : '-'}</Typography>
                        </StyledFormControl>
                    </Grid>}
                    { data.secondReminderDate !== "NA" && <Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Reminder Date {count++}</FormLabel>
                            <Typography>{data.secondReminderDate !== null && data.secondReminderDate !== '' ? data.secondReminderDate : '-'}</Typography>
                        </StyledFormControl>
                    </Grid> }
                    { data.thirdReminderDate !== "NA" &&<Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Reminder Date {count++}</FormLabel>
                            <Typography>{data.thirdReminderDate !== null && data.thirdReminderDate !== '' ? data.thirdReminderDate : '-'}</Typography>
                        </StyledFormControl>
                    </Grid> }
                   { data.fourthReminderDate !== "NA" &&<Grid item lg={4}>
                        <StyledFormControl>
                            <FormLabel>Reminder Date {count++}</FormLabel>
                            <Typography>{data.fourthReminderDate !== null && data.fourthReminderDate !== '' ? data.fourthReminderDate : '-'}</Typography>
                        </StyledFormControl>
                    </Grid> }
                    </Grid>
                </Box>
            }
            {
                tabValue === 2 && 
                // <Box>
                //     <Box sx={{background:theme.palette.background.paper, px:2, py:1, position:'sticky', top:'0px', zIndex:9, borderBottom:`.5px solid ${theme.palette.divider}`}}>
                //         <Stack direction={'row'} justifyContent={'space-between'}>
                //             <Typography variant='body2' color={'text.secondary'}>Device Number - <Typography component={'span'} sx={{fontWeight:'600'}}>{data.imei}</Typography></Typography>
                //             <Pagination count={activityData !== undefined ? Math.round(activityData?.count / length) === 0 ?  1 : Math.round(activityData?.count / length) : 0} variant='outlined' shape='rounded' page={page + 1} sx={{position:'sticky', top:'0px'}} size='small' onChange={pageChange}/>
                //         </Stack>
                //     </Box>
                //     <Box>
                //         <Collapse in={activityLoading}>
                //             <Alert severity='success' icon={false} variant='filled' sx={{borderRadius:'0px', justifyContent:'center'}}>
                //                 <ScaleLoader color="#fff" height={15}/>
                //             </Alert>
                //         </Collapse>
                //     </Box>
                //     <StyledTimeline>
                //         {
                //            deviceActivity !== null && deviceActivity.length > 0 ? deviceActivity.map((item) => {
                //             return(
                //                 <>
                //                 {/* <Box sx={{px:3, py:1, background:theme.palette.grey[100]}}>
                //                     <Typography variant='subtitle1'>{moment(item.key).format('MMMM-YYYY')}</Typography>
                //                 </Box> */}
                //                 <TimelineItem>
                //                 <TimelineOppositeContent>
                //                     <Typography variant='body2'>{moment(item.key).format('Do-MMM-YYYY')}</Typography>
                //                 </TimelineOppositeContent>
                //                 <TimelineSeparator>
                //                     <TimelineDot />
                //                     <TimelineConnector />
                //                 </TimelineSeparator>
                //                 <TimelineContent>
                //                         <Stack sx={{mt:2}}>
                //                             {
                //                                 item?.obj?.map((item) => {
                //                                     let color = (item.action.toLowerCase() === "create" || item.action.toLowerCase() === "ticket creation") ? theme.palette.success.main : item.action.toLowerCase() === "update" || item.action === null || item.action === "null" ? theme.palette.info.main : theme.palette.error.main
                //                                     let icon = (item.action.toLowerCase() === "create" || item.action.toLowerCase() === "ticket creation") ? <MdAdd /> : item.action.toLowerCase() === "update" || item.action === null || item.action === "null" ? <MdOutlineCheck />
                //                                         : <MdClose />
                //                                     return(
                //                                         <Stack direction={'column'}>
                //                                             <Icon sx={{background:color}}>{icon}</Icon>
                //                                             <Stack direction={'column'}>
                //                                                 <Typography variant='body1' gutterBottom sx={{textTransform:'capitalize'}}>{item.action === "null" || item.action === null?'update':item.action.toLowerCase()}</Typography>
                //                                                 <Typography variant='caption'>{item.createdDate}</Typography>
                //                                             </Stack>
                //                                             <Typography variant='body2'>{item.description}</Typography>
                //                                             <Divider sx={{my:1}}/>
                //                                         </Stack>
                //                                     )
                //                                 })
                //                             }
                //                         </Stack>
                                        
                //                 </TimelineContent>
                //                 </TimelineItem>
                //                 </>
                //             )
                //            }) : <Box sx={{px:3, py:1, background:theme.palette.grey[100]}}>
                //                     <Typography variant='subtitle1'>No Recent Activity</Typography>
                //                 </Box>
                //         }
                       

                //     </StyledTimeline>
                // </Box>
                <></>
            }
        </Box>
        </Box>
  )
}

export default ServiceViewOneTime;