import React, { useMemo, useState, useEffect } from "react";
import { Box } from "@mui/system";
import { DataTable } from "../shared/data_table/DataTable";
import ReportServices from "../../services/reportServices";
import {CardContent, Chip } from "@mui/material";
import { Breadcrumb } from "../shared/Breadcrumbs";
import MouseOverPopover from "../shared/PopOver";
import { Button } from "@mui/material";
import {
  StyledCardShadow,
  StyledPageLayout,
} from "../../theme/StyledEle";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";
import { useVehicleDataSCVMutation } from "../../store/slice/ReportSlice/ReportSlice";
import FilterDropdown from "../NewDropdown/FilterDropdown";


const ManualCanDataReportSCV = () => {

  const text = "Note:This Report gets the details of battery in a Fleet based on date selection.";

  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [auditLog]= useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId:orgId,
    userId:userId, //User ID
    orgName:orgName, //Org Name
    userName:userName,
  }

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(async () => {
    auditPayload.message = 'Reports > VehicleData';
    auditPayload.action = 'View';
    auditPayload.featureName = 'VehicleData';
    auditPayload.customerName = 'All';
    auditPayload.fleetName = "All";
    auditPayload.result = 'Success';
    auditPayload.access = 'Portal';
    auditPayload.severity = 'INFO';
    auditLog(auditPayload);
  
  }, []);


  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "createdDate",
      },
      {
        Header: "Vehicle Number",
        accessor: "vehicleno",
      },
      // {
      //   Header: "Vin Number",
      //   accessor: "vinNo",
      // },
      {
        Header: "Vehicle Status",
        accessor: "strgpsMoveStatus",
        Cell:({row}) => {
          // console.log('row', row.original.strgpsMoveStatus);
          if(row.original.strgpsMoveStatus !== '-' ){
              return <Chip label={row.original.strgpsMoveStatus} size="small" color={row.original.strgpsMoveStatus === 'Moving' ? 'success' : row.original.strgpsMoveStatus === 'Charging' ? 'warning' : 'error'}/>
          }else{
              return row.original.strgpsMoveStatus
          }
        }
      },
      {
        Header: "GPS	Location",
        accessor: "location",

        Cell: ({ row, column, cell }) => {
          const refAddres = React.useRef(row);
          var refLink = React.useRef(row);
          var getAddressBtn = React.useRef(row);
          var toggleAddressBtn = React.useRef(row);
          const getAddress = async(e) => {
            if(refAddres.current.innerText === ''){
              var request = {};
              request.latLng = e.target.value;
              // rowid = e.target.id;
              const responseData = (await ReportServices.getGeocodeAddress(request)).data;
              refAddres.current.innerText = responseData;
              if(responseData !== ''){
                refLink.current.style.display = 'none'
                refAddres.current.style.display = 'block'
                toggleAddressBtn.current.style.display = 'block'
                getAddressBtn.current.style.display = 'none'
                toggleAddressBtn.current.innerText='LatLng'
              }
            }
          }
        
          //Address toggle
          const ToggleAdderss = () => {
            if( refAddres.current.style.display === 'block' ){
              refAddres.current.style.display = 'none'
              refLink.current.style.display = 'block'
              toggleAddressBtn.current.innerText='Address'
            }else{
              refAddres.current.style.display = 'block'
              refLink.current.style.display = 'none'
              toggleAddressBtn.current.innerText='LatLng'
            }
          }
          return(
            <Box sx={{display:'flex', alignItems:'center'}} >
            <Box className='addresslink' id={row.original.location} sx={{display:'flex', alignItems:'center'}}>
                <Button onClick={(e) => getAddress(e)} value={row.original.location} ref={getAddressBtn}>Address</Button>
                <Button onClick={(e) => ToggleAdderss(e)} ref={toggleAddressBtn} sx={{display:'none'}}>LatLng</Button>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="latlink"  ref={refLink} id={row.original.location}>{row.original.location}</a>
                <a href={`https://www.google.com/maps/search/?api=1&query=${row.original.location}`} target="_blank" className="address" ref={refAddres} id={row.original.location} key={row.original.location}></a>
            </Box>
        </Box>
          )
        },
      },

      {
        Header: "GPS Odometer (km)",
        accessor: "odometer",
      },
      {
        Header: "GPS Speed (km/h)",
        accessor: "speed",
      },
      {
        Header: "CAN Odometer (km)",
        accessor: "canOdometer",
      },
      {
        Header: "CAN Speed (km)",
        accessor: "canVehicleSpeed",
      },
      {
        Header: "Battery Voltage (V)",
        accessor: "batteryVoltage1",
      },
      {
        Header: "Battery Current (A)",
        accessor: "batteryCurrent",
      },
      {
        Header: "Battery SOC (%)",
        accessor: "batterySoc",
      },
      {
        Header: "Customer SOC (%)",
        accessor: "customerSOC",
      },

      {
        Header: "Battery Monomer Maximum Voltage Cell ID",
        accessor: "maxParallelVoltage",
      },
      {
        Header: "Battery Monomer Maximum Voltage (V)",
        accessor: "maxCellNoVoltage",
      },
    
      {
        Header: "Battery Monomer Minimum Voltage Cell ID",
        accessor: "minParallelVoltage",
      },
      {
        Header: "Battery Monomer Minimum Voltage (V)",
        accessor: "minCellNoVoltage",
      },

      {
        Header: "Battery Monomer Maximum Temperature (ºC)",
        accessor: "maxParallelTemperature",
      },
      {
        Header: "	Battery Monomer Maximum Temperature ID",
        accessor: "maxCellNoTemperature",
      },
      {
        Header: "Battery Monomer Minimum Temperature(ºC)",
        accessor: "minParallelTemperature",
      },

      {
        Header: "Battery Monomer Minimum Temperature ID",
        accessor: "minCellNoTemperature",
      },      
      {
        Header: "Gear Position",
        accessor: "gearPosition",
      },
      // {
      //   Header: "Max Allowable Charging Current (A)",
      //   accessor: "maxAllowChargeCurrent",
       
      // }, {
      //   Header: "Max Allowable Discharging Current(A)",
      //   accessor: "maxAllowDischargeCurrent",
       
      // }, {
      //   Header: "Max Permissible Charging Power (kW)",
      //   accessor: "maxAllowChargePower",
       
      // }, {
      //   Header: "Max Allowable Discharge Power (kW)",
      //   accessor: "maxAllowDischargePower",
       
      // },
     
    ],
    []
  );

  const [ vehicleDataSCV, { data:scvVehicleData, isLoading, isSuccess }] = useVehicleDataSCVMutation();
  const [payload, setPayload] = useState();
  let commonPayload = {
    'resellerId': resellerId,
  }
  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(50);
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.orderDir = 'desc';
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage; 
    commonPayload.status = data.vehicleStatusId;
    commonPayload.search = '';
    setPayload(commonPayload);
    vehicleDataSCV(commonPayload);

    auditPayload.message = "Reports > Vehicle Data > "+data.regionName+" > "+data.dealerName+" > "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.statusName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    auditPayload.action = "Submit";
    auditPayload.featureName = "Vehicle Data Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  }

  useEffect(() => {
    if(isSuccess){
      setResponseData([]);
      if(scvVehicleData.data !== null){
        setResponseData(scvVehicleData.data?.content);
        setTotalElements(scvVehicleData.data?.totalElements);
        setTotalPages(scvVehicleData.data?.totalPages);
      }
    }
  },[isSuccess]);

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    payload.size = 0;
    payload.rowsPerPage = 50;
    setNewPage(0);
    setRowsPerPage(50);
    if(payload.search.length >= 3){
        vehicleDataSCV(payload);
    }else if(payload.search.length === 0){
        vehicleDataSCV(payload);
    }
  };

//Onchange PDF
const onChangePdf = async () => {
  try {
    var url =
      process.env.REACT_APP_API_BASE_URL +
      "ManualCanDataReport/vehicledataPDF.pdf?startDate=" +
      payload.startDate +
      "&endDate=" +
      payload.endDate +
      "&orderDir=" +
      payload.orderDir +
      "&orgId=" +
      payload.orgId +
      "&status=" +
      payload.status +
      "&vehicleId=" +
      payload.vehicleId;
      window.open(url);

      auditPayload.message = "Reports > Vehicle Data > Export > PDF";
      auditPayload.action = "View";
      auditPayload.featureName = "VDR Report";
      auditPayload.customerName = "All";
      auditPayload.fleetName = "All";
      auditPayload.result = "Success";
      auditPayload.access = "Portal";
      auditPayload.severity = "INFO";
      auditLog(auditPayload);
    

  } catch (error) {
    console.log('error', error);
  }
};

//Onchange XLS
const onChangeXcl = async () => {
  try {
      var url =
      process.env.REACT_APP_API_BASE_URL +
      "ManualCanDataReport/vehicleDataReportForKg2.xls?startDate=" +
      payload.startDate +
      "&endDate=" +
      payload.endDate +
      "&orderDir=" +
      payload.orderDir +
      "&orgId=" +
      payload.orgId +
      "&status=" +
      payload.status +
      "&resellerId=" +
      payload.resellerId +
      "&vehicleId=" +
      payload.vehicleId;
// console.log(url);
    window.open(url);
    auditPayload.message = "Reports > Vehicle Data > Export > Excel";
    auditPayload.action = "View";
    auditPayload.featureName = "VDR Report";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);

  } catch (error) {
    console.log('error', error)
  }
};

 //Page Change
 const pageChange = (newPage, rowsPerPage) => {
  setNewPage(newPage);
  setRowsPerPage(rowsPerPage);
  payload.page = newPage;
  payload.size = rowsPerPage;
  payload.start=newPage;
  payload.length=rowsPerPage;
  vehicleDataSCV(payload);
} 

  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Reports" subDiv="Vehicle Data Report" includePopup={true}></Breadcrumb>
      <MouseOverPopover text={text} title={"Vehicle Data Report"} />
      <Box>
        <FilterDropdown getData={onSubmitForm} isVehicleData={true} isShowAll={false} allowDays={0} last7={false}  setToday={true} />

        <StyledCardShadow sx={{ mt: 2 }}>
          <CardContent sx={{ py: 1 }}>
            <DataTable
              isLoading={isLoading}
              includeSearchBox={true}
              handleChange={handleSearch}
              onChangeXcl={onChangeXcl}
              onChangePdf={onChangePdf}
              columns={columns}
              data={responseData}
              styles={{ maxWidth: "89vw" }}
              numberOfStickyColumns={1}
              page={newPage}
              rowsPerPage={rowsPerPage}
              callbackChangePage={pageChange}
              totalElements={totalElements}
              totalPages={totalPages}
            />
          </CardContent>
        </StyledCardShadow>
      </Box>
    </StyledPageLayout>
  );
};

export default ManualCanDataReportSCV;
