import React,{useEffect} from 'react'
import { createClientMessage } from 'react-chatbot-kit';
import { useBotUserMessageMutation,useBotGptUserMessageMutation,useBotGeminiMessageMutation } from '../../../store/slice/botSlice/BotSlice';
import Chip from '@mui/material/Chip';
import { chatBotSingleVehicle,chatBotSingleVehicleData } from "../../../store/slice/ControlsSlice";
import { useSelector } from "react-redux";
import moment from "moment";

function ActionProvider({ createChatBotMessage, setState, children }) {
  let userDataLocal = JSON.parse(localStorage.getItem('userData'));
  let chatbotType = userDataLocal?.chatbotType;
  
  const [ getMessage, { data:getMessageData, isLoading, isSuccess } ] = useBotGptUserMessageMutation();
  const [ getMessageGemini, { data:getMessageGeminiData, isGeminiLoading, isGeminiSuccess } ] = useBotGeminiMessageMutation();

  const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);

  const callCommon=async (mesage,userMessage)=>{
    try{
    userMessage =await createChatBotMessage(mesage);    
    setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));          
        await customMessage(mesage);
      }catch (error) {
        console.error("An error occurred:", error);
    }
  }  
  const clearAirZonesMain= async (option,location) =>{
    
    try{
      switch (selectedVehicle?.option) {
        case 'Classification and CO2 Emission level':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option); 
          break;
        case 'Location and Road type':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
          break; 
        case 'Vehicle type, Charges and Penality':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
        case 'Traffic and Speed limit':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
       default:
          const userMessage =await createChatBotMessage("Clear Air Zone "+location);    
          setState((prev) => ({
                ...prev,
                messages: [...prev.messages, userMessage],
              }));          
          await customMessage("Provide one line description of Clear Air Zone "+location+" in UK","Chatgpt");
       break;    
        }  

      const removeSpaceFn=()=> {
        if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
            window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
          }
        }
      }        
      setTimeout(removeSpaceFn, 50);
      }catch (error) {
          console.error("An error occurred:", error);
      }
     
    } 
    
    const tollsMain= async (option,location) =>{
      try{
        switch (selectedVehicle?.option) {
          case 'Location and Road type':             
            commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
            break; 
          case 'Vehicle type and charges':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
          break;
          case 'Traffic and Speed limit':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
          break;
         default:
            const userMessage =await createChatBotMessage("Toll "+location+" Bridge");    
            setState((prev) => ({
                  ...prev,
                  messages: [...prev.messages, userMessage],
                }));          
            await customMessage("Provide one line description of toll "+location+" in UK","Chatgpt");
           break;    
          }  
  
        const removeSpaceFn=()=> {
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
            }
          }
        }        
        setTimeout(removeSpaceFn, 50);
        }catch (error) {
            console.error("An error occurred:", error);
        }
      
  }

  
  const lowBridgeMain=async (option,location) =>{
    try{
      switch (selectedVehicle?.option) {
        case 'Location and Road type':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
          break; 
        case 'Height and Weight':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
        case 'Traffic and Speed limit':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
       default:
          const userMessage =await createChatBotMessage("Low Bridge "+location);    
          setState((prev) => ({
                ...prev,
                messages: [...prev.messages, userMessage],
              }));          
          await customMessage("Provide one line description of low bridge "+location+" in UK","Chatgpt");
         break;    
        }  

      const removeSpaceFn=()=> {
        if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
            window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
          }
        }
      }        
      setTimeout(removeSpaceFn, 50);
      }catch (error) {
          console.error("An error occurred:", error);
      }    
    }

    const highBridgeMain=async (option,location) =>{
      try{
        switch (selectedVehicle?.option) {
          case 'Location and Road type':             
            commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
            break; 
          case 'Height and Weight':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
          break;
          case 'Traffic and Speed limit':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
          break;
         default:
            const userMessage =await createChatBotMessage("Weigh Bridge "+location);    
            setState((prev) => ({
                  ...prev,
                  messages: [...prev.messages, userMessage],
                }));          
            await customMessage("Provide one line description of weigh bridge "+location+" in UK","Chatgpt");
           break;    
          }  
  
        const removeSpaceFn=()=> {
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
            }
          }
        }        
        setTimeout(removeSpaceFn, 50);
        }catch (error) {
            console.error("An error occurred:", error);
        }    
      }


  const commonFinalData=async (signal,location,option) =>{
    let text=option;
    if(signal==="ClearAirZones"){
      if(option==="Classification and CO2 Emission level"){
        text="Provide short description of Classification and CO2 Emission level at Clear Air Zone "+location+" in UK";
      }
      else if(option==="Location and Road type"){
        text="Provide short description of the location , road type and operating hours at Clear Air Zone "+location+" in UK";
      }
      else if(option==="Vehicle type, Charges and Penality"){
        text="Provide short description of vehicle type,Charges and penalities at Clear Air Zone "+location+" in UK";
      }
      else if(option==="Traffic and Speed limit"){
        text="Provide short description of traffic and speed limit at Clear Air Zone "+location+" in UK";
      }
    }
    else if(signal==="Tolls"){
      if(option==="Location and Road type"){
        text="Provide short description of the location , road type and operating hours of toll "+location+" Bridge in UK";
      }
      else if(option==="Vehicle type and charges"){
        text="Provide short description of vehicle type and charges of toll "+location+" Bridge in UK";
      }
      else if(option==="Traffic and Speed limit"){
        text="Provide short description of traffic and speed limit near toll "+location+" Bridge in UK";
      }
    }
    else if(signal==="Low bridge"){
      if(option==="Location and Road type"){
        text="Provide short description of location and road type of low bridge "+location+" in UK";
      }
      else if(option==="Height and Weight"){
        text="Provide short description of height and weight of low bridge "+location+" in UK";
      }
      else if(option==="Traffic and Speed limit"){
        text="Provide short description of traffic and speed limit near low bridge "+location+" in UK";
      }
    }
    else if(signal==="High bridge"){
      if(option==="Location and Road type"){
        text="Provide short description of the location , road type and operating hours of weigh bridge "+location+" in UK";
      }
      else if(option==="Height and Weight"){
        text="Provide short description of height and weight of weigh bridge "+location+" in UK";
      }
      else if(option==="Traffic and Speed limit"){
        text="Provide short description of traffic and speed limit near weigh bridge "+location+" in UK";
      }
    }
    const userMessage =await createChatBotMessage(option);    
    setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));          
    await customMessage(text,"Chatgpt");
    const removeSpaceFn=()=> {
      if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
        if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
          window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
        }
      }
    }        
    setTimeout(removeSpaceFn, 50);
  }

    useEffect(() => {
      if(selectedVehicle!==null && selectedVehicle?.vehicleNo!==undefined){
        if(selectedVehicle!=null){
          window.document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0].style.visibility = 'hidden';
        }else{
          window.document.getElementsByClassName('react-chatbot-kit-chat-input-form')[0].style.visibility = 'visiable';
        }
      }
      if(selectedVehicle?.mapSignal!==undefined){
      switch (selectedVehicle?.mapSignal) {
        case "ClearAirZones":
          clearAirZonesMain(selectedVehicle?.mapSignal,selectedVehicle?.location);  
          break;
        case "Tolls":
          tollsMain(selectedVehicle?.mapSignal,selectedVehicle?.location);  
        break;
        case 'Low bridge':             
          lowBridgeMain(selectedVehicle?.mapSignal,selectedVehicle?.location);  
            break;  
        case 'High bridge':             
          highBridgeMain(selectedVehicle?.mapSignal,selectedVehicle?.location);  
          break;
        case 'Classification and CO2 Emission level':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option); 
          break;
        case 'Location and Road type':             
          commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
          break; 
        case 'Vehicle type,Charges and Penality':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
        case 'Vehicle type and charges':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
        case 'Height and Weight':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
        case 'Vehicle type and charges':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
        case 'Traffic and Speed limit':             
        commonFinalData(selectedVehicle?.mapSignal,selectedVehicle?.location,selectedVehicle?.option);
        break;
       default:
          
        }  
        }else{     
              if(selectedVehicle?.vehicleNo!==undefined){
                setState((prev) => ({
                  ...prev,
                  messages: [],
                  }));
                setState((prev) => ({
                  ...prev,
                  messages: [...prev.messages,  createChatBotMessage("Vehicle No : "+selectedVehicle?.vehicleNo,{widget: "reportList"})],
                  })); 
              } 
      
        }
      
  },[selectedVehicle])

  const handleClick = (option) => {
    if(selectedVehicle!==null){
        const userMessage = createClientMessage(option+" "+selectedVehicle?.vehicleNo);
        setState((prev) => ({
          ...prev,
          messages: [...prev.messages, userMessage],
        }));
        customMessage(option+" "+selectedVehicle?.vehicleNo);      
    }  
  };

  const introOpt = () => {
    const botMessage = createChatBotMessage('Options', {
      widget:'IntroOptions',
      loading:true,
    })
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const customMes = (message) => {
    const userMessage = createClientMessage(message);
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, userMessage],
    }));
    customMessage(message)
  }

  //get vehicle number from bot
  const getVehicleNumber = () => {
    const botMessage = createChatBotMessage('Enter the vehicle number', {
      delay:500
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  //get getReport
  const getReportData = (number) => {
    const botMessage = createChatBotMessage('Detail here', {
       widget:'reportData',
       delay:1000,
       loading:true,
       payload:{
        "projectId": "chatbot-437905",
        "text": number,
        "sessionId": "22222",
        "languageCode": "en-US",
       }
    });
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, botMessage],
    }));
  }

  const botMessage = (message) => {
      const botMessage = createChatBotMessage(message);
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
      }));
  }
 
  const displayValFn = (option)=>{
       switch(option){
          case "Vehicle detail":
            return "Vehicle details below";
            break;
          case "Battery status":
            return "Battery status details below";
            break;
          case "Battery health":
            return "Battery health details below";
            break;
          case "Battery detail":
            return "Battery details below";
            break;
          case "Last trip details":
            return "Last trip details below";
            break;
          case "Faults":
            return "Current Fault details below";  
            break;
          case "Alerts":
            return "Current Alert details below";
            break;
          case "Chatgpt":
            return "ChatGpt details below";
            break;
          default:
              return "Vehicle details below";
            break;
       }
  }

  const displayRootValFn = (option)=>{
    switch(option){
       case "Chatgpt":
          return "chatgpt";
        break;        
      case "ClearAirZones":
        return "clearAirZoneList";
      case "Tolls":
        return "tollsList";
      case "High bridge":
        return "highbridgeList";
      case "Low bridge":
        return "lowbridgeList";
      default:
        return "chatgpt";
        break;
    }
}

  
  const customMessage = async (message,secOption) => {
    let payload = {
      "prompt": message
     }
    //console.log("option:::"+JSON.stringify(selectedVehicle));
    const responceData = await ((chatbotType===1 && chatbotType!==undefined)?getMessageGemini(payload):getMessage(payload));
    if(responceData?.data !== undefined && responceData?.data !== null){
       const botMessage = createChatBotMessage( "",{
        loading:isLoading,
        // widget:displayRootValFn(secOption!==undefined?"Chatgpt":selectedVehicle?.mapSignal===undefined?"Chatgpt":selectedVehicle?.mapSignal),
     
        widget:displayRootValFn(secOption!==undefined?"Chatgpt":secOption===undefined?"Chatgpt":(selectedVehicle?.mapSignal===undefined?"Chatgpt":selectedVehicle?.mapSignal)),
        data:responceData?.data !== null ? responceData?.data : undefined,
      });
      let obj = {
        mesId:botMessage.id,
        data:responceData?.data !== null ? responceData?.data : undefined
      }
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages, botMessage],
        gridData:responceData?.data !== null ? responceData?.data : undefined
      }));   
      setState((prev) => ({
        ...prev,
        messages: [...prev.messages,  selectedVehicle!==null?createChatBotMessage("",{widget: displayRootValFn(selectedVehicle?.mapSignal===undefined?"Chatgpt":selectedVehicle?.mapSignal)}):""],
        gridData:responceData?.data !== null ? responceData?.data : undefined
        })); 
        

     
      const removeDivFn=()=> {
          if(window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container")[window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container")[window.document.getElementsByClassName("react-chatbot-kit-chat-bot-message-container").length-1].style.display="none";
            }
          }
          if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length>0){
            if(window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].innerHTML===""){
              window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container")[window.document.getElementsByClassName("react-chatbot-kit-user-chat-message-container").length-1].style.display="none";
            }
          }
        }
        const myTimeout = setTimeout(removeDivFn, 100);
       
    }else if(responceData.error !== undefined){
      botMessage(responceData?.error.data);
    }else{
      botMessage('No action');
    }    
  }
  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            customMessage,
          },
        });
      })} 
    </div>
  )
}


export default ActionProvider