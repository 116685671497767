import { Box, Fab, Menu } from '@mui/material'
import React, {useEffect, useRef} from 'react'
import { BsChatSquareDotsFill } from 'react-icons/bs'
import ChatBot from './ChatBot';
import ChatBotGpt from './newChatgpt/ChatBot';
import { chatBotSingleVehicle,chatBotSingleVehicleData } from "../../store/slice/ControlsSlice";
import { useSelector,useDispatch } from "react-redux";

function NeshBot() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    //const [open, setOpen] = React.useState(Boolean(anchorEl));
    const [open, setOpen] = React.useState(false);
    const paperRef = useRef();
    const dispatch = useDispatch();
    //let open = Boolean(anchorEl);
    const handleClick = (event) => {
        setOpen(true);
        if(event.currentTarget!==undefined){
            setAnchorEl(event.currentTarget);
        }else{
            setAnchorEl(event);
        }
    };
    const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
    dispatch(chatBotSingleVehicleData(null));
    if(window.document.getElementsByClassName('MuiBox-root css-mvnvty').length>0){
        window.document.getElementsByClassName('MuiBox-root css-mvnvty')[0].style.visibility = 'visiable';
    }        
    };
    const selectedVehicle = useSelector( (state) => state.controls.chatBotSingleVehicle);
    useEffect(() => {
      
        if(selectedVehicle!==null){
            setOpen(true);
            handleClick(paperRef.current);
        }
    },[selectedVehicle])
  return (
    <>
    <Box sx={{position:'fixed', bottom:'20px', right:'20px', zIndex:99999, '& .MuiModal-root':{pointerEvents:'none'}}}>
        {
            <Fab 
    color='info' 
    onClick={handleClick}
    aria-controls={open ? 'account-menu' : undefined}
    aria-haspopup="true"
    aria-expanded={open ? 'true' : undefined}
    ref={paperRef}
    //style={{visibility:'hidden'}}
    sx={{
        visibility :  selectedVehicle === null ? 'visible' : 'hidden',
        //pointerEvents: selectedVehicle === null ? 1 : 0,
    }}
    >
        <BsChatSquareDotsFill />
    </Fab>
        }
    
    <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        //onClose={handleClose}
        //onClick={handleClose}
        transformOrigin={{ horizontal: 'left', vertical: 'bottom' }}
        anchorOrigin={{ horizontal: 'left', vertical: 'top' }}
        hideBackdrop={true}
        disablePortal={true}
        PaperProps={{
            elevation: 0,
            sx: {
                maxWidth:'350px',
                width:'100%',
                minWidth:'350px',
                minHeight:'430px',
                overflow: 'visible',
                pointerEvents:'all',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 0,
                '&::before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bottom: '-10px',
                    right: '28px',
                    width: 10,
                    height: 10,
                    bgcolor: '#a71274',
                    //bgcolor: 'red',
                    transform: 'translateY(-50%) rotate(45deg)',
                    //zIndex: 0,
                },
                '& .MuiList-root':{
                    maxHeight:'100%',
                    height:'100%',
                    padding:0,
                    margin:0,
                }

            },
          }}
        >
            {
                (selectedVehicle!==null && selectedVehicle?.vehicleNo!==undefined && selectedVehicle?.mapSignal===undefined) && <ChatBot close={handleClose} vehicleNo={selectedVehicle?.vehicleNo}/>
            }
            {
                ((selectedVehicle===null || selectedVehicle?.vehicleNo===undefined) || selectedVehicle?.mapSignal!==undefined) && <ChatBotGpt close={handleClose} vehicleNo={selectedVehicle?.vehicleNo}/>
            }
                 
    </Menu>
    </Box>
    </>
  )
}

export default NeshBot