import { alpha, Box, Card, CardContent, Chip, Divider, Fab, Grid, Icon, Skeleton, Stack, Typography, useTheme } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { FaBell, FaCarBattery, FaTools } from 'react-icons/fa'
import { RiErrorWarningFill } from "react-icons/ri";
import { useAlertsMutation, useHealthAlertsMutation } from '../../store/slice/VehicleSummarySlice/VehicleSummarySlice';
import { FaTemperatureThreeQuarters } from 'react-icons/fa6';
import { TbAirConditioning, TbBatteryAutomotive, TbTemperature } from 'react-icons/tb';


function HealthAlerts({vehicleId, resellerId}) {
    
    //health alerts
    const [ getHealthAlerts, {data:healthAlerts, isLoading:healthAlertsLoading, isSuccess:healthAlertsSuccess} ] = useHealthAlertsMutation();
    const [ getAlerts, {data:alerts, isLoading:alertsLoading, isSuccess:alertsSuccess} ] = useAlertsMutation();
    const [data, setData] = useState(null);
    const [alertsData, setAlertsData] = useState([])
    const getVehicleHealth = async () => {
        let payload = {
            "vehicleId": vehicleId,
            "resellerId": resellerId,
        }
        const getHealthCheck = await getHealthAlerts(payload);
        if(getHealthCheck?.data !== undefined && getHealthCheck.data !== null){
            if(getHealthCheck.data.data !== undefined && getHealthCheck.data.data !== null && getHealthCheck.data.data.length > 0){
                setData(getHealthCheck.data.data[0]);
            }else{
                setData(null)
            }
        }else{
            setData(null)
        }

        const alerts = await getAlerts(payload);
        if(alerts?.data !== undefined && alerts.data !== null){
            if(alerts.data.data !== undefined && alerts.data.data !== null && alerts.data.data.length > 0){
                setAlertsData(alerts.data.data);
            }else{
                setAlertsData([]); 
            }
        }else{
            setAlertsData([]); 
        }
    }



    useEffect(() => {
        getVehicleHealth();
    },[])

    const theme = useTheme();
    const IconCard = (props) => {
        const { label, normalRange, todaysLow, status, time, name, icon, value } = props
        
        return (
            <Card 
            sx={{overflow:'visible', boxShadow:'none !important', borderRadius:'15px !important', border:`.5px solid ${alpha(theme.palette.divider, .09)}`, '&:hover':{boxShadow:'0px 5px 12px rgba(0,0,0,0.1) !important'}}}>
                <Grid container spacing={1}>
                    <Grid item lg={12} sx={{p:2, position:'relative'}}>
                        {
                            healthAlertsLoading ? <Skeleton variant="circular" width={'40px'} height={'40px'} sx={{fontSize:'40px', p:1.2, boxShadow:'0px 2px 12px rgba(0,0,0,0.09) !important', position:'absolute', top:'-15px', left:'25px', width:'40px', height:'40px', borderRadius:'50px 50px 0px 50px', }}/> :
                            <Fab sx={{fontSize:'40px', p:1.2, boxShadow:'0px 2px 12px rgba(0,0,0,0.09) !important', position:'absolute', top:'-15px', left:'25px', width:'40px', height:'40px', borderRadius:'50px 50px 0px 50px', }} color='secondary' size='small'>
                            
                            {icon}
                            </Fab>
                        }
                        
                    </Grid>
                    <Grid item lg={12}>
                        <Box sx={{py:1, px:2}}>
                            <Stack>
                                <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                    {
                                        healthAlertsLoading ? <Skeleton variant="rounded" width={'78%'} height={20}/> : <Typography variant='h6' gutterBottom>{label}</Typography>
                                    }
                                    
                                    {
                                        healthAlertsLoading ? <Skeleton variant="rounded" width={'12%'} height={20}/> : <Chip label={status} size='small' color='success'/>
                                    }

                                </Stack>
                                {
                                    healthAlertsLoading ? <Skeleton variant="text" sx={{ fontSize: '1rem' }}/> : <Typography variant='body2' gutterBottom >{normalRange}</Typography>
                                }
                                {

                                    healthAlertsLoading ? <Skeleton variant="rounded" width={'60%'} height={15}/> : value !== "NA" && <Typography variant='body2' gutterBottom>{todaysLow}</Typography>
                                }
                                
                              
                                
                            </Stack>
                        </Box>
                        {/* <Divider /> */}
                        <Box sx={{background:alpha(theme.palette.secondary.main, .1), py:.5, px:2, borderRadius:'0px 0px 15px 15px'}}>
                            {
                                healthAlertsLoading ? <Skeleton variant="text" sx={{ fontSize: '.8rem' }} /> : <Typography variant='body2' color={'secondary.main'}>{time}</Typography>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Card>
        )
    }

    const renderHTML = (rawHTML) => React.createElement("p", {
        dangerouslySetInnerHTML: { __html: rawHTML },
      });

    const AlertCard = ({data}) => {
        return(
            <Card sx={{overflow:'visible', boxShadow:'none !important', borderRadius:'15px', border:`.5px solid ${alpha(theme.palette.divider, .09)}`}}>
                <Stack direction={'row'} alignItems='center'>
                    <Box sx={{p:1}}>
                        <Icon sx={{background:alpha(theme.palette.error.light, 0.09), p:.8, display:'flex', alignItems:'center', fontSize:'38px', borderRadius:'50px', color:theme.palette.error.light}}>
                            <RiErrorWarningFill />
                        </Icon>
                    </Box>
                    <Box sx={{width:'100%', p:1}}>
                        <Stack direction={'row'} justifyContent='space-between' alignItems='center'>
                            <Typography variant='body1'>{data.alertName}</Typography>
                            <Typography variant='body2' color={'text.disabled'}>{data?.alertTime}</Typography>
                        </Stack>
                        <Typography variant='body2'>
                        {renderHTML(data.message)}
                        </Typography>
                    </Box>
                </Stack>
            </Card>
        )
    }
  return (
    <Box sx={{py:3, px:3}}>
        <Stack rowGap={2}>
        <Box>
            <Typography variant='h6' color={'primary'} sx={{fontWeight:600, mb:5, display:'flex', gap:1, alignItems:'center'}}><FaTools />
             Component Details</Typography>
            <Grid container spacing={2}>
                {
                    data?.batteryStatus !== 'NA' && data?.batteryVal !== "NA" && data?.batteryTime !== "NA" && 
                    <Grid item lg={3}>
                    <IconCard 
                    label={'Battery Status'}
                    normalRange={'Normal range 11v-15v'}
                    todaysLow={`Today's Low: ${data !== null && data?.lowestBatteryVolt !== null ? data?.lowestBatteryVolt : '-'}  at ${data !== null ? data?.lowestBatteryVoltTime : '-'} `}
                    status={data !== null ? data?.batteryStatus : '-'}
                    time={`${data !== null ? data?.batteryVal : '-'} at ${data !== null ? data?.batteryTime : '-'}`}
                    name={'battery'}
                    icon={<TbBatteryAutomotive />}
                    value={data?.lowestBatteryVolt}
                    />
                    </Grid>
                }
                {
                    data?.coolantTempVal !== 'NA' && data?.coolantTime !== "NA" && data?.coolantTempStatus !== "NA" && 
                    <Grid item lg={3}>
                    <IconCard 
                    label={'Coolant Temperature'}
                    normalRange={'Normal range below 96C'}
                    todaysLow={`Today's Low: ${data !== null && data?.lowestCoolanttemp !== null ? data?.lowestCoolanttemp : '-'}  at ${data !== null ? data?.lowestCoolanttime : '-'}`}
                    status={data !== null ? data?.coolantTempStatus : '-'}
                    time={`${data !== null ? data?.coolantTempVal : '-'} at ${data !== null ? data?.coolantTime : '-'}`}
                    name={'coolant'}
                    icon={<TbTemperature />}
                    value={data?.lowestCoolanttemp}
                    />
                    </Grid>
                }
                
                {
                    data?.intakeAirTempVal !== 'NA' && data?.intakeAirTime !== "NA" && data?.intakeAirTempStatus !== "NA" && 
                    <Grid item lg={3}>
                    <IconCard 
                    label={'Intake Air Temperature'}
                    normalRange={'Normal range -30C-70C'}
                    todaysLow={`Today's Low: ${data !== null && data?.lowestintakeAirTemp !== null ? data?.lowestintakeAirTemp : '-'}  at ${data !== null ? data?.lowestintakeAirTime : '-'} `}
                    status={data !== null ? data?.intakeAirTempStatus : '-'}
                    time={`${data !== null ? data?.intakeAirTempVal : '-'} at ${data !== null ? data?.intakeAirTime : '-'}`}
                    name={'airIntake'}
                    icon={<TbAirConditioning />}
                    value={data?.lowestintakeAirTemp}
                    />
                </Grid>
                }
                
            </Grid>
        </Box>
        <Box>
            <Typography variant='h6' color={'primary'} sx={{fontWeight:600, mb:1, display:'flex', gap:1, alignItems:'center'}}><FaBell />
             Alerts</Typography>
            <Stack rowGap={2}>
                <Box>
                    <Grid container spacing={2}>
                        {
                            alertsData.length > 0 && alertsData.map((item, index) =>  
                            <Grid item lg={3}>
                                <Typography gutterBottom>{item?.alertDate}</Typography>
                                <AlertCard data={item}/>
                        </Grid> )
                        }
                       
                    </Grid>
                </Box>
            </Stack>
            
        </Box>
        </Stack>
    </Box>
  )
}

export default HealthAlerts