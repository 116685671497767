import React, { useState, useMemo, useRef, useEffect } from "react";
import Box from "@mui/material/Box";
import {
  Grid,
  Button,
  Typography,
  Link,
  CardHeader,
  CardContent,
  Stack,
  CardMedia,
  Chip,
  Collapse,
} from "@mui/material";
import { Paper } from "@mui/material";
import { DataTable } from "../shared/data_table/DataTable";
import { styled } from "@mui/material/styles";
import GeozoneUpload from "../../xls/geozone/geozoneUpload.xls";
import DraggableDialog from "../shared/DailogDrag";
import { useAuditLogMutation } from "../../store/slice/ApiSlice";


import { FormControl, FormHelperText, Select, MenuItem } from "@mui/material";
import {
  FileUploadBox,
  StyledCardShadow,
  StyledFilterDropDown,
  StyledFilterFormControl,
  StyledFormControlLabel,
  StyledPaperShadow,
  StyledTableTitle,
} from "../../theme/StyledEle";
import uploadcsv from "../../assets/images/uploadcsv.png";
import SingleDropdown from "../NewDropdown/SingleDropdown";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import GeozoneService from "../../services/geozoneService";
import { useValidatezoneMutation } from "../../store/slice/GeozoneSlice/GeozoneSlice";
function GeoZoneUpload(props) {
  
  const {payload} = props;
  const [file, setFile] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [dataTable, setDataTable] = useState(false);
  const [dataStatus, setDataStatus] = useState(false);
  const [dataStatusConfirm, setDataStatusConfirm] = useState(false);
  const [dialogContent, setDialogContent] = useState({});
  const [dialogContentConfirm, setDialogContentConfirm] = useState({});
  const [open, setOpen] = useState(false);
  const [template, setTemplate] = useState(4);
  const [validate, setValidate] = useState(false);
  const [allowUpload, setAllowUpload] = useState(true);
  const globalJson2 =
    localStorage.getItem("userData") !== null
      ? JSON.parse(localStorage.getItem("userData"))
      : null;
  var globaldisplayview = "";
  var globalOrgId = 1;
  var globaluserId = 1;
  var globalRoleId = 0;
  var globalJson = {};
  var globalResellerId = 2;
  var globalOrgType = 2;
  var globalUserName = "";
  var globalOrgName = "";


  if (
    "null" === localStorage.getItem("userData") ||
    null === localStorage.getItem("userData")
  ) {
  } else {
    globalJson =
      localStorage.getItem("userData") !== null
        ? JSON.parse(localStorage.getItem("userData"))
        : 0;
    globalOrgId = globalJson.orgId;
    globalOrgType = globalJson.userOrgType;
    globaluserId = globalJson.id;
    globalRoleId = globalJson.roleId;
    globalResellerId = globalJson.resellerId;
    globaldisplayview = globalJson.displayView;
    globalUserName =  globalJson.userName;
    globalOrgName = globalJson.orgName;
  }

  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: globalOrgId,
    userId: globaluserId, 
    orgName: globalOrgName, 
    userName: globalUserName,
  };

  //Validate Excel

  //Activate Columns
  const activateCols = useMemo(() => [
    {
      Header: "Zone Name",
      accessor: "zoneName",
    },
    {
      Header: "Zone Type",
      accessor: "zoneType",
    },
    {
        Header: "Zone Category",
        accessor: "zoneCategory",
    },
    {
        Header: "Zone Location",
        accessor: "latlng",
    },
    {
        Header: "Radius",
        accessor: "radius",
    },


  ]);
  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadPayload = {
      orgId:payload.orgId,
      vehicleId:payload.vehicleId
    }
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("params", JSON.stringify(uploadPayload));

    const response = await GeozoneService.uploadGeozone(formData);
    var obj1 = {};

    obj1.dialogButtonText = "Upload Status";
    obj1.submitButtonText = "";
    obj1.closeButtonText = "Close";
    obj1.dialogText = "Uploaded Successfully";

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(response.data.result);
    setOpen(true);
   

      if (response.status === 200) {
        setValidate(false);
        setTimeout(() => {
          setOpen(false);
         
        }, 2000);
      }
      // setTimeout(() => onCancel(), 2000);

    auditPayload.message = "Admin > Geozone > Import Geozone Details > Upload";
    auditPayload.action = "Upload";
    auditPayload.featureName = "Geozone";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  };

  const handleValidate = async (e) => {

    if (payload.vehicleId == -1 || payload.vehicleId == null) {
      setAllowUpload(false);
      setDataTable(false);
    }
    else {
      setAllowUpload(true);
    e.preventDefault();
    const validatePayload = {
      orgId:payload.orgId
    }
    const formData = new FormData();
    formData.append("file", file[0]);
    formData.append("params", JSON.stringify(validatePayload));
    //validateXls(payload);
    
    
    const responseData = await GeozoneService.validateGeozone(formData);

    if (
      responseData.data.result !== "invalidtemplate" &&
      responseData.data.result !== "excesscount" &&
      responseData.data.result !== "lowcount" &&
      Object.keys(responseData.data.result).length > 0 && 
      responseData.data.result !== undefined
    ) {
      setResponseData(responseData.data.result);
      setDataTable(true);
      if (responseData.data.isValidateSuccess == true) {
        setValidate(true);
      } else {
        setValidate(false);
      }
    } else {
      setResponseData([]);
      setValidate(false);
    }

       var obj1 = {};

        if (responseData.data.result === "excesscount" || responseData.data.result === "lowcount"){
          obj1.dialogText = "Please upload valid count";
        }
        else if (responseData.data.result === "invalidtemplate") {
          obj1.dialogText = "Please Upload Valid Template";
        }
        else if (responseData.data.isValidateSuccess == false) {
          obj1.dialogText = "Validation Failed";
        }
        else {
           obj1.dialogText = "Validated Successfully";

        }

    setDataStatus(true);
    setDialogContent(obj1);
    setDataStatusConfirm(
      responseData.data.result !== "excesscount" &&
        responseData.data.result !== "invalidtemplate" &&
        responseData.data.result !== "lowcount" &&
        responseData.data.isValidateSuccess == true
        ? "Success"
        : "Failed"
    );
    setOpen(true);

    if (responseData.status === 200) {
      setTimeout(() => {
        setOpen(false);
      }, 2000);
    }
  }
    auditPayload.message = "Admin > Geozone > Import Geozone Details > Validate";
    auditPayload.action = "Validate";
    auditPayload.featureName = "Geozone";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);

  };
  const [isFilePicked, setIsFilePicked] = useState(false);
  const FileRef = useRef();

  const handleOnChange = (e) => {
    setFile([e.target.files[0]]);
    setIsFilePicked(true);
    setValidate(false);
  };

  const handleVehicleChoice = (e) => {
    console.log("on click+> ")
    
    
  };

  const handleTemplateClick = () =>{
    auditPayload.message = "Admin > Geozone > Import Geozone Details > Download Template";
    auditPayload.action = "Download";
    auditPayload.featureName = "Geozone";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  }

  function temp() {
   
        return (
          <Stack direction={"row"} spacing={2} >
          {!allowUpload &&<Typography variant="body2" sx={{ml:2, mt:0.5}} color={'error'}>Please select a vehicle to validate geozone</Typography>}
          <Button
            href={GeozoneUpload}
            variant="contained"
            size="small"
            component={Link}
            onClick={handleTemplateClick}
          >
            Download Geozone Template
          </Button>
          </Stack>
        );
  }

  useEffect(() => {
    temp();
  }, [template]);

  useEffect(async () => {
    auditPayload.message = "Admin > Geozone > Import Geozone Details";
    auditPayload.action = "View";
    auditPayload.featureName = "Geozone";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = globalUserName;

    auditLog(auditPayload);
  }, []);

  return (
    <>
      <Collapse in={true}>
        <StyledCardShadow sx={{ my: 2 }}>
          <CardHeader
            title={<StyledTableTitle>Upload List</StyledTableTitle>}
            action={temp()}
          />
          <form onSubmit={handleSubmit}>
            <CardContent sx={{ py: 0 }}>
              <FileUploadBox>
                <input
                  type="file"
                  accept="application/vnd.ms-excel"
                  onChange={handleOnChange}
                  className="fileupload"
                  ref={FileRef}
                />
                <CardMedia
                  component="img"
                  src={uploadcsv}
                  sx={{ width: "60px", height: "60px", my: 2 }}
                />
                <Typography sx={{ mt: 2 }} variant="h6" color="primary">
                  Select a XLS file to upload
                </Typography>
                {isFilePicked && (
                  <Chip
                    sx={{ ml: 1 }}
                    label={
                      FileRef?.current !== undefined &&
                      FileRef?.current?.files[0]?.name
                    }
                    color="warning"
                  />
                )}
              </FileUploadBox>
              <Stack
                direction="row"
                sx={{ mt: 2 }}
                spacing={2}
                justifyContent="center"
              >
                {file?.length > 0 && (
                  <Button variant="contained" onClick={handleValidate}>
                    {" "}
                    Validate{" "}
                  </Button>
                )}
                {validate && dataTable === true && (
                  <Button variant="contained" color="success" type="submit">
                    {" "}
                    Upload{" "}
                  </Button>
                )}
              </Stack>
            </CardContent>
          </form>
        </StyledCardShadow>
      </Collapse>
      <Box>
        {dataTable === true && file?.length > 0 && (
          <StyledCardShadow>
            <CardContent>
              <DataTable
                includePagination = {false}
                includeExportButton={false}
                includeSearchBox={false}
                columns={activateCols}
                data={responseData}
                styles={{ maxWidth: "50vw" }}
              />
            </CardContent>
          </StyledCardShadow>
        )}
      </Box>
      {open && (
        <StyledSnackBar
          open={open}
          message={dialogContent.dialogText}
          status={dataStatusConfirm}
        />
      )}
    </>
  );
}

export default GeoZoneUpload;
