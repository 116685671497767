import React from 'react'
import Chatbot from 'react-chatbot-kit'
import { BotWrapGpt } from '../../../theme/StyledEle'
import Configwrap from './Config'
import MessageParser from './MessageParser'
import ActionProvider from './ActionProvider'

function ChatBot({close,vehicleNo}) {
    const saveMessage = (message, HTMLString) => {
        localStorage.setItem('chat_messages', JSON.stringify(message))
    }

    const loadMessage = () => {
        const message = JSON.parse(localStorage.getItem('chat_messages'));
        return message;
    }

  return (
    <BotWrapGpt>
        <Chatbot 
            config={Configwrap(close,vehicleNo)}
            messageParser={MessageParser}
            actionProvider={ActionProvider}
            // messageHistory={loadMessage()}
            // saveMessages={saveMessage}
        />
    </BotWrapGpt>
  )
}

export default ChatBot