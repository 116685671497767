import { Box } from "@mui/material";
import React, { useState,useEffect } from "react";
import StyledTabs from "../shared/StyledTabs";
import CreateOrganization from "./CreateOrganization";
import ListOrganization from "./ListOrganization";
import { Breadcrumb } from "../shared/Breadcrumbs";
import RecentActivity from "./RecentActivity";
import { StyledPageLayout } from "../../theme/StyledEle";
import { useDispatch, useSelector } from "react-redux";
import StyledSnackBar from "../../Utils/StyledSnackBar";
import { useNavigate } from "react-router-dom";
import FormDialog from "../../Utils/FormDialog";
import { snackService } from "../../store/slice/ControlsSlice";
import ViewOrganization from "./ViewOrganization";


const Organization = () => {
  const [orgToEdit, setOrgToEdit] = useState(null);
  const [commonFilter, setCommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tempcommonFilter, setTempcommonFilter] = React.useState({
    create: false,
    edit: false,
    view: false,
    delete: false,
    recentActivation: false,
    importfunction: false,
    excel: false,
    pdf: false,
  });
  const [tabShow, setTabShow] = React.useState("1");
  const [menuUser, setMenuUser] = useState();
  const [create, setCreate] = useState("Create Organization");

  const callfun = () => {
    if (
      "null" !== JSON.parse(localStorage.getItem("menuList")) &&
      null !== JSON.parse(localStorage.getItem("menuList"))
    ) {
      if (JSON.parse(localStorage.getItem("menuList")) !== null) {
        for (
          let i = 0;
          i < JSON.parse(localStorage.getItem("menuList")).length;
          i++
        ) {
          if (JSON.parse(localStorage.getItem("menuList"))[i].sub.length > 0) {
            for (
              let j = 0;
              j < JSON.parse(localStorage.getItem("menuList"))[i].sub.length;
              j++
            ) {
              if (
                JSON.parse(localStorage.getItem("menuList"))[i].sub[j].route ===
                "organization"
              ) {
                setMenuUser(
                  JSON.parse(localStorage.getItem("menuList"))[i].sub[j].button
                );
                
                var dataStr = {};
                dataStr.create = false;
                dataStr.edit = false;
                dataStr.delete = false;
                dataStr.view = false;
                dataStr.recentActivation = false;
                dataStr.importfunction = false;
                dataStr.excel = false;
                dataStr.pdf = false;
                var strval;
                strval = JSON.parse(localStorage.getItem("menuList"))[i].sub[
                  j
                ].button.split(",");
                var edit = false;
                for (var k = 0; k < strval.length; k++) {
                  if (parseInt(strval[k]) === 1) {
                    dataStr.create = true;
                  }
                  if (parseInt(strval[k]) === 2) {
                    dataStr.view = true;
                  }
                  if (parseInt(strval[k]) === 4) {
                    dataStr.edit = true;
                  }
                  if (parseInt(strval[k]) === 3) {
                    dataStr.delete = true;
                  }
                  if (parseInt(strval[k]) === 18) {
                    dataStr.recentActivation = true;
                  }
                  if (parseInt(strval[k]) === 9) {
                    dataStr.importfunction = true;
                  }
                  if (parseInt(strval[k]) === 5) {
                    dataStr.excel = true;
                  }
                  if (parseInt(strval[k]) === 6) {
                    dataStr.pdf = true;
                  }
                }
                setCommonFilter(dataStr);
                setTempcommonFilter(dataStr);
                break;
              }
            }
          }
        }
      }
    }

    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 2;
    }

    return displaydefaulttab;
  };

  const [tabValue, setTabValue] = useState(() => callfun());

  const handleTabChange = (event, newValue) => {
    setOrgToEdit(null);
    setTabValue(newValue);
    setCreate("Create Organization");
  };

  const navigate = useNavigate();
  const [orgData, setOrgData] = useState();
  const handleOrgView = (orgEditedId, array) => {
  
    //setTabValue(0);

    // var dataStr = { ...tempcommonFilter };
    // var displaydefaulttab = 2;
    // if (tabShow === "6") {
    //   setTabShow("5");
    //   displaydefaulttab = 0;
    // } else if (tabShow === "5") {
    //   displaydefaulttab = 0;
    // } else if (tabShow === "4") {
    //   displaydefaulttab = 1;
    // } else if (tabShow === "1") {
    //   displaydefaulttab = 1;
    // } else if (tabShow === "2") {
    //   displaydefaulttab = 5;
    // } else if (tabShow === "3") {
    //   displaydefaulttab = 0;
    // } else if (tabShow === "8") {
    //   setTabShow("5");
    //   displaydefaulttab = 0;
    // }
    //  setTabValue(displaydefaulttab);
    //  setCreate("view Organization");
  
    // var tempProps = JSON.parse(JSON.stringify(orgEditedId));
    // tempProps.clickedval = "view";
    // setOrgToEdit(Object.preventExtensions(tempProps));

    navigate('#view');
    setOrgData(orgEditedId);
    
    
  }


  const handleOrgEdit = (orgEditedId) => {
    setTabValue(0);
    setCreate("Edit Organization");

    var dataStr = { ...tempcommonFilter };
    var displaydefaulttab = 2;
    if (tabShow === "6") {
      setTabShow("5");
      displaydefaulttab = 0;
    } else if (tabShow === "5") {
      displaydefaulttab = 0;
    } else if (tabShow === "4") {
      displaydefaulttab = 1;
    } else if (tabShow === "1") {
      displaydefaulttab = 1;
    } else if (tabShow === "2") {
      displaydefaulttab = 5;
    } else if (tabShow === "3") {
      displaydefaulttab = 0;
    } else if (tabShow === "8") {
      setTabShow("5");
      displaydefaulttab = 0;
    }
    setTabValue(displaydefaulttab);
    setOrgToEdit(orgEditedId);
  };

  const handleCancel = () => {
    var dataStr = { ...tempcommonFilter };
    var tabshowval = "6";
    var displaydefaulttab = 2;
    if (
      dataStr.create === false &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("2");
      tabshowval = "2";
      displaydefaulttab = 0;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === false &&
      dataStr.importfunction === false
    ) {
      setTabShow("3");
      tabshowval = "3";
      displaydefaulttab = 1;
    } else if (
      dataStr.create === true &&
      dataStr.recentActivation === true &&
      dataStr.importfunction === false
    ) {
      setTabShow("1");
      tabshowval = "1";
      displaydefaulttab = 2;
    }
    setTabValue(displaydefaulttab);
    setCreate("Create Organization");
  };

  const TABS = [
    {
      label: "Recent Activity",
      children: <RecentActivity />,
    },
    {
      label: create,
      children: (
        <CreateOrganization orgToEdit={orgToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Organization List",
      children: (
        <ListOrganization onEditOrg={handleOrgEdit} onViewOrg={handleOrgView} menuUser={menuUser} />
      ),
    },
  ];

  const TABS2 = [
    {
      label: "Organization List",
      children: (
        <ListOrganization onEditOrg={handleOrgEdit} onViewOrg={handleOrgView} menuUser={menuUser} />
      ),
    },
  ];

  const TABS3 = [
    {
      label: create,
      children: (
        <CreateOrganization orgToEdit={orgToEdit} onCancel={handleCancel} />
      ),
    },
    {
      label: "Organization List",
      children: (
        <ListOrganization onEditOrg={handleOrgEdit} onViewOrg={handleOrgView} menuUser={menuUser} />
      ),
    },
  ];
  const isSuccess = useSelector((state) => state.controls.alert);

  const dispatch = useDispatch();
  useEffect(()=>{
    if(isSuccess.isSuccess){
      setTimeout(() => dispatch(snackService([null, null, null])),6000)
    }
  },[isSuccess.isSuccess])
  return (
    <StyledPageLayout>
      <Breadcrumb mainDiv="Admin" subDiv="Organization"></Breadcrumb>
      {tabShow === "1" && (
        <StyledTabs
          tabs={TABS}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "2" && (
        <StyledTabs
          tabs={TABS2}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}
      {tabShow === "3" && (
        <StyledTabs
          tabs={TABS3}
          handleChange={handleTabChange}
          value={tabValue}
        />
      )}

<FormDialog title={'View Organization'} padding={false} size="md">
        <ViewOrganization data={orgData}/>
    </FormDialog>
       {
          isSuccess.isSuccess &&
          <StyledSnackBar message={isSuccess.message} status={isSuccess.status} />
        }
    </StyledPageLayout>
    
  );
};

export default Organization;
