import { Box, Typography } from '@mui/material'
import React from 'react'

function UserChat(props) {
  return (
    (props?.message!=="" && props?.message!==undefined) &&
    <>
    <div style={{ display: 'flex', alignItems: 'center', paddingBottom:"8px",paddingTop:"9px" }}>
        <Box sx={{background:'linear-gradient(45deg, #7646ef, #c200dc)', borderRadius:'10px 10px 0px 10px', padding:'5px 8px'}}>
        <Typography variant='body2' sx={{color:'#fff'}}>{props.message}</Typography>
    </Box> 
    </div>
    </>       
  )
}

export default UserChat