import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { ControlsBox, StyledPageLayout } from '../../theme/StyledEle'
import { Breadcrumb } from '../shared/Breadcrumbs'
import { GoogleMap, Marker, Polyline, useJsApiLoader } from '@react-google-maps/api'
import { GOOGLE_MAP_KEY } from '../../constants/appConstant'
import { Alert, Avatar, Backdrop, Box, Button, ListItemAvatar, ListItemButton, ListItemText, Menu, Paper, Snackbar, alpha, useTheme } from '@mui/material'
import { GridLoader } from 'react-spinners'
import moment from 'moment'
import { usePlaybackMutation } from '../../store/slice/ApiSlice'
import Controls from './Controls'
import { endMarker, startMarker } from '../Constans'
import FilterDropdown from '../NewDropdown/FilterDropdown'
import PlaybackBottomTrails from './PlaybackBottomTrails'
import playbackMarker from '../../assets/images/StatusGif/playbackMarker.png'
import { FiLayers } from 'react-icons/fi'
import { Dark, Day } from "../../theme/gmapTheme";
import { useSelector } from 'react-redux'
import { RiZoomInLine, RiZoomOutLine } from 'react-icons/ri'
import DefaultMap from '../../assets/images/roadamap.png'
import Terrain from '../../assets/images/terrain.png'
import Satellite from '../../assets/images/satellite.png'

//import { StartFlag } from '../'

function PlaybackMain() {

    //Theme
    const theme = useTheme();

    //Google Map
    const [zoom, setZoom] = useState(5);
    const [center, setCenter] = useState({lat:13.09743629050399, lng: 80.25891516014238});
    const [defaultCenter, setDefaultCenter] = useState({ lat: 52.92214338014607, lng: -1.4703258589163344 });
    const [libraries] = useState(["drawing", "places", "geometry"]);
    const [mapzoom, setMapzoom] = useState(10);
    const {isLoaded} = useJsApiLoader({
        id: "google-map-script",
        googleMapsApiKey: GOOGLE_MAP_KEY,
        libraries,
    })
    const [map, setMap] = useState(null);

    const gmode = useSelector((state) => state.controls.customizer.mapMode);

    //Map Onload
    const onLoad  = useCallback(function callback(map) {
        const bounds = new window.google.maps.LatLngBounds(defaultCenter);
        map.fitBounds(bounds);
        setMap(map);
        setMapzoom(5);
        //setZoomLevel(map.getZoom());
    },[gmode])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, []);

    //Google Map Options
    const [maxZoom, setMaxZoom] = useState(22);
    const options = {
        zoom:mapzoom,
        center:center,
        minZoom:2,
        maxZoom:maxZoom,
        fullscreenControl: false,
        streetViewControl: false,
        zoomControl: false,
        mapTypeControl: false,
        disableDefaultUI: true,
        featureType: "poi.business",
        stylers: [{ visibility: "off" }],
        featureType: "transit",
        elementType: "labels.icon",
        stylers: [{ visibility: "off" }],
        featureType: "poi",
        stylers: [{ visibility: "off" }],
        tilt:47.5,
        heading: 320,
    }
    const mapContainerStyle = {
        height:'inherit',
        position:'relative'
    }

    /* --------------------------------------------------------------------- */
    // onsubmit form data
    const [ getPlaybackData, {data:playbackData, isLoading:playbackLoading, isSuccess:plackbackSuccess}] = usePlaybackMutation();
    const [playbackDatas, setPlaybackDatas] = useState(null);
    const [tripData,setTripData] = useState(null);
    const [playData, setPlayData] = useState([]);
    const [allPlaybackData, setAllPlaybackData] = useState(null);
    const [playCor, setPlayCor] = useState(null);
    const [startlocation, setStartLocation] = useState({lat:null, lng:null});
    const [endLocation, setEndLocation] = useState({lat:null, lng:null});
    const [fromDate, setFromDate] = useState(moment().format('yyyy-MM-DD 00:00:00'));
    const [endDate, setEndDate] = useState(moment().format('yyyy-MM-DD 23:59:59'));

   

    const [playPath, setPlayPath] = useState([]);
    var linePartArr=[...playPath];
    const [play, setPlay] = useState(false);
    const tick = useRef();
    const drawPolyInterval = useRef();
    const firstStart = useRef(true);
    const [newSpeed, setNewSpeed] = useState(550);
    const [actualSpeed, setActualSpeed] = useState(550);
    //Get All Data
    const [allData, setAllData] = useState([]);
    var TableArry = [...allData]
    const [init, setInit] = useState(0);
    let [opeMsg, setOpenMsg] = useState(false);
    let [msg, setMsg] = useState(null);
    let [days, setDays] = useState(0);
    let commonPayload = {}
  const onSubmitForm = (data) => {
        setAllData([]);
        setPlayPath([]);
        setPlaybackDatas(null);
        commonPayload.truckId=data.vehicleId;
        commonPayload.orgId= data.fleetId;
        commonPayload.startDate= data.fromDate;
        commonPayload.endDate= data.toDate;
        commonPayload.startOdometer= 0;
        commonPayload.endOdometer= 0;
        //Service Call
        getPlaybackData(commonPayload);
  }
    /* --------------------------------------------------------------------- */

    useEffect(() => {
        if(plackbackSuccess){
            handleDays(0,0);
            setPlayData(allPlaybackData?.playBackList[0].playBackList);
            generatePath(playbackData?.playBackList[0].playBackList)
        }
    },[plackbackSuccess])

   

    /* ---------------------------------------------------------------------- */
        //get onload line
        const [path, setPath] = useState([]);
        let [dir, setDir] = useState(90);
    /* ---------------------------------------------------------------------- */
    const haight = {lat:13.09743629050399, lng: 80.25891516014238}
    const oceanBeach ={lat:13.081217764745254, lng: 80.17960761151427,};
    const [aniPos, setAniPos] = useState({lat:null, lng:null});
    let [pos, setPos] = useState(haight);

    

    //set PlayPath
    const generatePath = (data) => {
        if(data !== undefined && data[0].latlng !== undefined && data[0].latlng !== null && data !== undefined){
            setStartLocation({lat:parseFloat(data[0].latlng?.split(',')[0]), lng:parseFloat(data[0].latlng?.split(',')[1])});
            setEndLocation({lat:parseFloat(data[data.length - 1].latlng?.split(',')[0]), lng:parseFloat(data[data.length - 1].latlng?.split(',')[1])});
            setCenter({lat:parseFloat(data[0].latlng?.split(',')[0]), lng:parseFloat(data[0].latlng?.split(',')[1])})
        }
        
        var pathArry=[];
        for(let i=0; i < data?.length; i++){
        var pathData={};
        pathData.lat=parseFloat(data[i].latlng.split(',')[0]);
        pathData.lng=parseFloat(data[i].latlng.split(',')[1]);
        pathArry.push(pathData);
        }
        setPath(pathArry);
        setAniPos({lat:pathArry[0]?.lat, lng:pathArry[0]?.lng})
    }

    //Set Days
    const handleDays = (event, selectDay) => {
        setPlayData([]);
        setPlayPath([]);
        setAllData([]);
        setDays(selectDay);
        if(selectDay === 1){
            setTripData(playbackData?.playBackList[0]);
            setPlayData(playbackData?.playBackList[0].playBackList);
            let play = playbackData?.playBackList[0].playBackList
            generatePath(play);
        }
        else if(selectDay === 2){
            setTripData(playbackData?.playBackList[1]);
            setPlayData(playbackData?.playBackList[1].playBackList);
            let play = playbackData?.playBackList[1].playBackList
            generatePath(play);
        }else{
            setTripData(playbackData);
            if(playbackData?.playBackList[1]){
                setPlayData([...playbackData?.playBackList[0].playBackList, ...playbackData?.playBackList[1].playBackList])
                let play = [...playbackData?.playBackList[0].playBackList, ...playbackData?.playBackList[1].playBackList]
                generatePath(play);
            }else{
                setPlayData(playbackData?.playBackList[0].playBackList);
                let play = playbackData?.playBackList[0].playBackList;
                generatePath(play);
            }
            
            
        }
    }


    // Get Data from service
    useEffect(() => {
        if(plackbackSuccess){
            if(playbackData !== null && playbackData?.playBackList[0] !== undefined){
                setMaxZoom(22);
                setZoom(13);
                setPlayPath([]);
                setPlaybackDatas(playbackData?.playBackList[0]);
                setAllPlaybackData(playbackData);
                setTripData(playbackData);
                setPlayData(playbackData?.playBackList[0].playBackList);
                let startLocation = playbackData?.playBackList[0].startLocation.split(',');
                let endLocation = playbackData?.playBackList[0].endLocation.split(',');
                setStartLocation({lat:parseFloat(startLocation[0]), lng:parseFloat(startLocation[1])});
                setEndLocation({lat:parseFloat(endLocation[0]), lng:parseFloat(endLocation[1])});
                setCenter({lat:parseFloat(startLocation[0]), lng:parseFloat(startLocation[1])});
                // map.panTo({lat:parseFloat(startLocation[0]),lng:parseFloat(startLocation[1])});
                
                
                //Generate Path
                // var pathArry=[];
                // for(let i=0; i < playData.length; i++){
                // var pathData={};
                // pathData.lat=parseFloat(playData[i].latlng.split(',')[0]);
                // pathData.lng=parseFloat(playData[i].latlng.split(',')[1]);
                // pathArry.push(pathData);
                // }
                // setPath(pathArry);
                // setAniPos({lat:pathArry[0].lat, lng:pathArry[0].lng})
            }else{
                setMapzoom(5);
                setCenter(center);
                setMaxZoom(5);
            }
        }
    },[plackbackSuccess])


    useEffect(() => {
        if (firstStart.current) {
          firstStart.current = !firstStart.current;
          return;
        }
        if(play){
          animatePolyline();
        }else{
          clearInterval(tick.current);
        }
      },[play])




    const animatePolyline = () => {
        var i = init;
        var drawSpeed = actualSpeed;
        if(play){
        setMaxZoom(18)
        setMapzoom(18);
            tick.current = setInterval(() => {
            var part = [];
            var feedData = [];
            part.push(path[i]);
            feedData.push(playData[i]);
            if(path[i] !== undefined){
                linePartArr=[...linePartArr, ...part];
                setPlayPath(linePartArr);
                TableArry=[...feedData, ...TableArry];
                setAllData(TableArry);
            }else{
                clearInterval(tick.current)
            }
            i++;
            setInit(i);
        }, actualSpeed);
    }
        else{
            clearInterval(tick.current);
        }
    }

    useEffect(() => {
        if(map !== null && playPath.length > 0){
            setMapzoom(13);
            const point1LatLng = new window.google.maps.LatLng(playPath.slice(-1)[0].lat, playPath.slice(-1)[0].lng);
            const point2LatLng = new window.google.maps.LatLng(playPath.slice(-2)[0].lat, playPath.slice(-2)[0].lng)
            const angle = window.google.maps.geometry.spherical.computeHeading(point1LatLng, point2LatLng);
            //transition(playPath.slice(-1)[0].lat, playPath.slice(-1)[0].lng)
            let lat = playPath.slice(-1)[0].lat;
            let lng = playPath.slice(-1)[0].lng;            //animatedMove(animeMarker.current, playPath.slice(-2), 2);
            setDir(angle - 180);
            //setCenter({lat:parseFloat(lat), lng:parseFloat(lng)});
            map.panTo({lat:parseFloat(lat), lng:parseFloat(lng)});
            setAniPos({lat:parseFloat(lat), lng:parseFloat(lng)});
            //animateCircle()
        }
    },[playPath]);


    const playAnything = () => {
        setPlay(!play);
    }

    const StopAnimatoin = () => {
        setPlayPath([]);
        setAllData([]);
        setActualSpeed(550);
        setNewSpeed(550)
        setPlay(false);
        clearInterval(tick.current);
        setInit(0);
    }

    //Icon Forward
    const moveForward = () => {
        setPlay(false);
        if(path.length >= playPath.length){
            var nextCor = path[playPath.length]
            var nextData = playData[playPath.length + 1]
            setPlayPath([...playPath, nextCor]);
            setAllData([nextData, ...allData,]);
            setInit(init + 1);
        }
    }

      //Icon Backward
    const moveBackword = () => {
        setPlay(false);
        if(playPath.length > 0){
            var splicArry = playPath.splice(-1);
            var allDatas = allData.splice(0,1);
            setPlayPath([...playPath]);
            setAllData([...allData]);
            setInit(init - 1);
        }
        
    }


    //Start Marker
    const Starticon = {
        url:startMarker,
        scale:2,
        fillOpacity: 1,
        rotation:dir,
        
    }
        //End Marker
        const Endicon = {
        url:endMarker,
        scale:2,
        fillOpacity: 1,
    }
    
     //Icon Moving Speed
     const handleSpeed = (e, newSpeed) => {
        setPlay(false);
        setNewSpeed(newSpeed);
        setActualSpeed(1200 - newSpeed)
    }

    //Increse Speed
    const speedUp = (speedVal) => {
        setPlay(false);
        setNewSpeed(newSpeed + 100);
        setActualSpeed(1200 - speedVal);
    }
    const speedDown = (speedVal) => {
        setPlay(false);
        setNewSpeed(newSpeed - 100);
        setActualSpeed(1200 - speedVal);
    }

    //Map Controls
    const [openMapEl, setOpenMapEl] = useState(null);
    const [mapType, setMapType] = useState("roadmap");

    const openMap = Boolean(openMapEl);
    const handleMapLayer = (event) => {
        setOpenMapEl(event.currentTarget);
    };
    const handleMapLayerClose = () => {
        setOpenMapEl(null);
    };
    const handleMapType = (mapTypename) => {
        setMapType(mapTypename);
        handleMapLayerClose();
    };



    const googleMap = useMemo(() => {
        return(
            isLoaded ? 
                <GoogleMap
                    options={{
                        mapTypeId: mapType,
                        ...options,
                        ...gmode !== 1 ? Dark : Day
                    }}
                    mapContainerStyle={mapContainerStyle}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    zoom={zoom}
                >
                    {
                        playbackData !== null &&
                        <Marker 
                        position={startlocation}
                        animation={2}
                        title='Start'
                        icon={Starticon}
                        /> 
                    }
    
                {
                        playbackData !== null &&
                        <Marker 
                        position={endLocation}
                        animation={2}
                        title='Start'
                        icon={Endicon}
                        /> 
                    }
    
                    {
                        playbackData !== null && playPath.length > 0 && 
                        <Marker 
                        position={aniPos}
                        title='animated-marker'
                        icon={{
                            path:window.google.maps.SymbolPath.CIRCLE,
                            strokeWeight: 0,
                            fillOpacity: 0,
                            scale: 7
                        }}
                    />
                    }
                    {
                        playbackData !== null && playPath.length === 0 && 
                        <Polyline 
                        path={path}
                        options={{
                            strokeColor:'#28282b',
                            strokeWeight:2,
                            geodesic: true,
                            strokeOpacity:1,
                        }}
                    />
                    }
                    
                    {/* Draw Line after play */}
                    {
                        playbackData !== null && playPath.length > 0 && 
                        <Polyline 
                        path={playPath}
                        options={{
                            strokeColor:'#f00',
                            strokeWeight:2,
                            geodesic: true,
                            strokeOpacity:1,
                        }}
                    />
                    }
                    {/* Playback Bottom */}
                    <PlaybackBottomTrails data={playbackDatas} tripData={tripData} trailsData={allData} rawData={allPlaybackData} selectDays={handleDays} selectedDay={days}/>
    
                    {/* <Paper sx={{zIndex:9999, position:'absolute', top:'25px'}}>
                        <Button onClick={animate}>Animate</Button>
                    </Paper> */}
                </GoogleMap>
                : 
                <Backdrop open={true} sx={{position:'absolute', background:alpha(theme.palette.background.paper, .6), zIndex:999}}>
                    <GridLoader color={theme.palette.secondary.main} />
                </Backdrop>
        )
    },[isLoaded, mapzoom, mapType, playPath])




  return (
    <StyledPageLayout sx={{p:0, height: "100vh", width:"100%", overflow:'hidden'}}>
            <Box sx={{p:2}}>
                <Breadcrumb mainDiv="Tracking" subDiv="Playback" />
                <FilterDropdown getData={onSubmitForm} status={false} isShowAll={false} allowDays={2} onload={false} last7={false}/>
            </Box>
            
            <Box sx={{position:'relative', height:'100%', overflow:'hidden'}}>
            <ControlsBox className='zoom-control-wrapper' direction='row'
                sx={{
                  flexDirection:'column',
                  alignItems:'center',
                  justifyContent:'center',
                }}>
                <Button className="map-btn" onClick={() => setMapzoom(map.zoom + 1)} disabled={mapzoom === 22}><RiZoomInLine/></Button>
                <Button className="map-btn" onClick={() => setMapzoom(map.zoom - 1)} disabled={mapzoom === 3}><RiZoomOutLine/></Button>
                <Button className="map-btn"
                id="basicmaplayer-button"
                aria-controls={openMap ? 'maplayer-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={openMap ? 'true' : undefined}
                onClick={handleMapLayer}
              >
                  <FiLayers />
              </Button>
              <Menu
            id="maplayer-menu"
            anchorEl={openMapEl}
            open={openMap}
            onClose={handleMapLayerClose}
            MenuListProps={{
              'aria-labelledby': 'maplayer-button',
            }}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
                <ListItemButton className="small-menuItem" onClick={() => handleMapType('roadmap')} selected={mapType === 'roadmap'}>
                    <ListItemAvatar>
                        <Avatar src={DefaultMap} variant="rounded" sx={{width:'25px', height:'25px'}} />
                    </ListItemAvatar>
                    <ListItemText>RoadMap</ListItemText>
                </ListItemButton>
                <ListItemButton className="small-menuItem" onClick={() => handleMapType('terrain')} selected={mapType === 'terrain'}>
                    <ListItemAvatar>
                        <Avatar src={Terrain} variant="rounded" sx={{width:'25px', height:'25px'}}/>
                    </ListItemAvatar>
                    <ListItemText>Terrain</ListItemText>
                </ListItemButton>
                <ListItemButton className="small-menuItem" onClick={() => handleMapType('satellite')} selected={mapType === 'satellite'} >
                    <ListItemAvatar>
                        <Avatar src={Satellite} variant="rounded" sx={{width:'25px', height:'25px'}}/>
                    </ListItemAvatar>
                    <ListItemText>Satellite</ListItemText>
                </ListItemButton>
                </Menu>
            </ControlsBox>

            {/* Controls */}
            <Controls 
                    play={play}
                    path={path}
                    playPath={playPath}
                    onPlay={playAnything}
                    onStop={StopAnimatoin}
                    onForward={moveForward}
                    onBackward={moveBackword}
                    onSpeed={handleSpeed}
                    speedValue={newSpeed}
                    speedUp={speedUp}
                    speedDown={speedDown}
                    />
    
    
                {googleMap}
            </Box>

    </StyledPageLayout>
  )
}

export default PlaybackMain