import { CardContent } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useMemo, useState } from "react";
import { Breadcrumb } from "../shared/Breadcrumbs";
import MouseOverPopover from "../shared/PopOver";
import { DataTable } from "../shared/data_table/DataTable";

import {
  useAuditLogMutation,
  useOverSpeedMutation,
} from "../../store/slice/ApiSlice";
import {
  StyledCardShadow,
  StyledPageLayout,
  StyledTab,
  StyledTabBox,
  StyledTabsGroup
} from "../../theme/StyledEle";
import GetLocation from "../Location";
import FilterDropdown from "../NewDropdown/FilterDropdown";
import DriverAttendance from "./DriverAttendance";
import DriverLogReport from "./DriverLog";
import { useGetDriverAttendanceListMutation, useGetDriverAttendanceMutation } from "../../store/slice/ReportSlice/ReportSlice";

const text =
  "This report shows driver attendance and log.";

const DriverSummaryReport = () => {
  let userData = JSON.parse(localStorage.getItem("userData"));
  let resellerId = userData.resellerId;
  let orgId = userData.orgId;
  let userId = userData.id;
  let orgName = userData.orgName;
  let userName = userData.userName;

  const [responseData, setResponseData] = useState([]);
  const [newPage, setNewPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [totalElements, setTotalElements] = useState(5);
  const [totalPages, setTotalPages] = useState(1);
  const [value, setValue] = useState(0);

  const handleTapChanage = (event, newValue) => {
    setValue(newValue);
  };
  const [auditLog] = useAuditLogMutation();
  let auditPayload = {
    //Audit Common Payload
    orgId: orgId,
    userId: userId, //User ID
    orgName: orgName, //Org Name
    userName: userName,
  };
  useEffect(async () => {
    auditPayload.message = "Reports > DriverSummary";
    auditPayload.action = "View";
    auditPayload.featureName = "DriverSummary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditLog(auditPayload);
  }, []);

  // Get Data from New Dropdown
  const [getDriverDetails, { data: driverData, isLoading, isSuccess }] =
  useGetDriverAttendanceListMutation();
  let commonPayload = {};

  const onSubmitForm = (data) => {
    setNewPage(0);
    setRowsPerPage(25);
    commonPayload.resellerId = resellerId;
    commonPayload.regionId = data.regionId;
    commonPayload.dealerId = data.dealerId;
    commonPayload.customerId = data.customerId;
    commonPayload.orgId = data.fleetId;
    commonPayload.vehicleId = data.vehicleId;
    commonPayload.search = "";
    commonPayload.startDate = data.fromDate;
    commonPayload.endDate = data.toDate;
    commonPayload.start = newPage;
    commonPayload.length = rowsPerPage;
    commonPayload.driverId=data.driverId;
    
    //Service Call
    setPayload(commonPayload);
    if (value === 0) {
      commonPayload.tab = 1;
      getDriverDetails(commonPayload);

      auditPayload.message = "Reports > DriverSummary > Driver Attendance "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    } else {
      //Driver Log
      commonPayload.tab = 0;
      getDriverDetails(commonPayload);
      auditPayload.message = "Reports > DriverSummary > Driver Log "+data.customerName+" > "+data.fleetName+" > "+data.vehicleName+" > "+data.selectedPeriodName+"("+data.fromDate+"-"+data.toDate+")"+" > "+"Submit";
    }

    auditPayload.action = "Submit";
    auditPayload.featureName = "DriverSummary";
    auditPayload.customerName = "All";
    auditPayload.fleetName = "All";
    auditPayload.result = "Success";
    auditPayload.access = "Portal";
    auditPayload.severity = "INFO";
    auditPayload.userName = userName;
    auditLog(auditPayload);
  };

  
  React.useEffect(() => {
    if (value === 0) {
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.tab = 1;
        getDriverDetails(newobj);
      }
    } else {
      //Day wise
      if (payload !== null) {
        let newobj = {};
        newobj = payload;
        newobj.tab = 0;
        getDriverDetails(newobj);
      }
    }
  }, [value]);

  const [payload, setPayload] = useState(null);

  //Search Text
  const handleSearch = async (searchText) => {
    payload.search = searchText;
    if (payload.search.length >= 3) {
      payload.start = 0;
      payload.length = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getDriverDetails(payload);
    } else if (payload.search.length === 0) {
      payload.start = 0;
      payload.length = 25;
      setNewPage(0);
      setRowsPerPage(25);
      getDriverDetails(payload);
      
    }
  };

  //Page Change
  const pageChange = (newPage, rowsPerPage) => {
    setRowsPerPage(rowsPerPage);
    payload.length = rowsPerPage;
    if(totalElements < rowsPerPage * newPage){
      setNewPage(0)
      payload.start = 0;
      getDriverDetails(payload);
    }else{
      setNewPage(newPage);
      payload.start = newPage;
      getDriverDetails(payload);
    }
  };

  
  useEffect(() => {
    if (isSuccess) {
      setResponseData([]);
      if (driverData.data !== null) {
        setResponseData(driverData.data.content);
        setTotalElements(driverData.data.totalElements);
        setTotalPages(driverData.data.totalPages);
      } else {
        setResponseData([]);
      }
    }
  }, [isSuccess]);



  return (
    <StyledPageLayout>
      <Breadcrumb
        mainDiv="Reports"
        subDiv="DriverSummary"
        includePopup={true}
      ></Breadcrumb>
      <MouseOverPopover text={text} title={"DriverSummary"} />
        <FilterDropdown
          getData={onSubmitForm}
          status={false}
          allowDays={7}
          allowToday={true}
          last6Month={true}
          time = {true}
          allowedMonth = {3}
          last31={false}
          getDataLoading={isLoading}
          offToday={true}
          DriverShow={true}
         
        />
        <Box sx={{ m: 0 }}>
          <StyledTabBox>
            <StyledTabsGroup
              value={value}
              variant="scrollable"
              onChange={handleTapChanage}
              sx={{
                "& .MuiTabs-flexContainer": {
                  flexDirection: "row",
                },
              }}
            >
              <StyledTab label={"Driver Log"} key={0} />
              <StyledTab label={"Driver Attendance"} key={1} />
               
            </StyledTabsGroup>
          </StyledTabBox>
          </Box>
          {value === 1 && <DriverAttendance
                        data={responseData}
                        payload={payload}
                        isLoadingOut={isLoading}
                        handleSearch={handleSearch}
                        pageChange={pageChange}
                        newPage={newPage}
                        rowsPerPage={rowsPerPage}
                        totalElements={totalElements}
          />}
          {value === 0 && <DriverLogReport
                        data={responseData}
                        payload={payload}
                        isLoadingOut={isLoading}
                        handleSearch={handleSearch}
                        pageChange={pageChange}
                        newPage={newPage}
                        rowsPerPage={rowsPerPage}
                        totalElements={totalElements}
          />}

    </StyledPageLayout>
  );
};

export default DriverSummaryReport;
