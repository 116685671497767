import React, { useState, useEffect, useRef } from "react";
import {
  HashRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
  useLocation,
} from "react-router-dom";
import routes from "./routes";
import { getElement } from "../Utils/helper";
import PageNotFound from "../components/page-not-found/PageNotFound";
import { useSelector } from "react-redux";
import Login from "../components/login/login";
import { useIdleTimer } from "react-idle-timer";
import SignIn2 from "../components/login/userActivation";
import SignIn1 from "../components/login/forgotPassword";
import BookRide from "../components/BookRide/BookRide";
import NewUserActivation from "../components/login/newUserActivation";
import ShareLocation from "../components/trackLocation/ShareLocation";
// import Login from "../components/NewLogin/Login";

const RouteView = () => {
  const getRoutes = (parent, index) => {
    return (
        <Route path={parent.path} element={getElement(parent)} key={index + 1}>
          {parent.children && parent.children.map((child) => getRoutes(child))}
        </Route>
    );
  };

  //get site title from service
  const siteTitle = useSelector((state) => state.controls.siteIdentity.title);
  const siteTagLine = useSelector((state) => state.controls.siteIdentity.tagLine);


  useEffect(() => {
    document.title = `${siteTitle} - ${siteTagLine}`;
  },[siteTagLine, siteTitle]);

  return (
    <Routes>
    {/* <Route element={<Login />} path="/" /> */}
    <Route element={<Login />} path="/" />
    <Route element={<NewUserActivation />} path="/userActivation" />
    <Route element={<SignIn1 />} path="/forgotPassword" />
    <Route element={<BookRide />} path="/testRide" />
    <Route element={<ShareLocation />} path="/publictrack/Tracking/" />

    <Route path="*" element={<PageNotFound />} />
      {routes.map((parent, index) => getRoutes(parent, index))}
    </Routes>)
};

export default RouteView;
