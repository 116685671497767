import { dashboardApi } from "../ApiSlice";

const dashboardSlice = dashboardApi.injectEndpoints({
  endpoints: builder => ({

    //DashboardList
    getDashboardData: builder.mutation({
      query: (body) => ({
        url: "chordChart/getDashboardData",
        method: "POST",
        body: body
      }),
    }),

    //DistanceDashboardList
    getDistanceData: builder.mutation({
      query: (body) => ({
        url: "chordChart/getDistanceTraveledData",
        method: "POST",
        body: body
      }),
    }),

        //DurationDashboardList
        getDurationData: builder.mutation({
          query: (body) => ({
            url: "chordChart/getDurationDashboardData",
            method: "POST",
            body: body
          }),
        }),
  })
})


export const { useGetDashboardDataMutation,useGetDistanceDataMutation,useGetDurationDataMutation } = dashboardSlice;