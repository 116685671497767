import PersonIcon from "@mui/icons-material/Person";
import LockIcon from "@mui/icons-material/Lock";
import ErrorIcon from "@mui/icons-material/Error";
import AccountTreeTwoToneIcon from "@mui/icons-material/AccountTreeTwoTone";
import FileUploadIcon from '@mui/icons-material/FileUpload';
const icons = { PersonIcon, LockIcon, ErrorIcon, AccountTreeTwoToneIcon,FileUploadIcon };

export const Menus = [
  {
    id: "1",
    title: "Profile",
    icon: icons["PersonIcon"],
  },
  {
    id: "2",
    title: "Password",
    icon: icons["LockIcon"],
  },
  {
    id: "3",
    title: "Alert Preference",
    icon: icons["ErrorIcon"],
  },
  //  {
  //   id: '4',
  //   title: 'Tree View Settings',
  //   icon: icons['AccountTreeTwoToneIcon'],
  //   },
  // {
  //   id: '4',
  //   title: 'Document Upload',
  //   icon: icons['FileUploadIcon'],
  //       },

      //  {
      //     id: '5',
      //     title: 'Customer Settings',
      //     icon: icons['PersonIcon'],
      //   },
];
